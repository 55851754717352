import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Forbidden = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { title, message } = location.state || {};
  return (
    <React.Fragment>
      <Helmet>
        <title>Forbidden - DocKit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Stack sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              alt="Under development"
              src="/static/images/not_found.png"
              style={{
                marginTop: 30,
                display: 'inline-block',
                maxWidth: '100%',
                width: 400
              }}
            />
            <Typography
              align="center"
              color="textPrimary"
              variant="heading"
              sx={{ mt: 3 }}
            >
              {title ?? 'Forbidden'}
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="label"
              sx={{ mt: 1 }}
            >
              {message ?? 'Access to this resource is denied!'}
            </Typography>
            <Button
              type="submit"
              variant="contained"
              size="small"
              align='center'
              onClick={() => navigate('/home')}
              sx={{ mt: 3 }}
            >
              Home
            </Button>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Forbidden;
