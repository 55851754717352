import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { ListSubheader, Tooltip } from '@mui/material';
import { ClinicMenuItems } from '../../utils/MenuItems';
import { validateNumber, validateStringForNull } from '../../utils/FieldValidations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { Utils } from '../../utils/UtilFunctions';

const drawerWidth = Utils.isMobile() ? Utils.getWindowWidth() :250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  marginTop: 64,
  paddingBottom: '100px'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: '0px',
  [theme.breakpoints.up('sm')]: {
    width: '0px' // `calc(${theme.spacing(8)} + 1px)`
  },
  marginTop: 64
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 10,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);
const getMenuItems = () => {
  return ClinicMenuItems.getMenuItems();
};

const ClinicSideBar = ({ open, setIsSideBarOpen }) => {
  const [clinicId, setClinicId] = useState(undefined);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const clinic = searchParams.get('clinicId');
    setClinicId(clinic);
    if (!validateNumber(clinic)) {
      navigate('/404');
    };
  });

  const handleNavigation = (item) => {
    if (!validateStringForNull(item.link)) {
      item.link = '/clinic';
    }
    if (setIsSideBarOpen != undefined) {
      setIsSideBarOpen(false);
    }
    navigate(item.link + '?clinicId=' + clinicId);
  };

  return (
    <Drawer variant="permanent" open={open}>
      {getMenuItems().map((list, i) => (
        <List key={i} subheader={list.header != '' ? <ListSubheader>{list.header}</ListSubheader> : ''}>
          {list.items.map((item, j) => (
            <ListItem key={j} disablePadding sx={{ display: 'block' }} onClick={() => {handleNavigation(item);}}>
              <Tooltip title={open ? '' : item.name} placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      ))}
    </Drawer>
  );
};

ClinicSideBar.propTypes = {
  open: PropTypes.bool,
  setIsSideBarOpen: PropTypes.func
};

export default ClinicSideBar;
