import { AccordionDetails, AccordionSummary, Alert, AppBar,
  Box, Button, Card, CardContent, Chip, DialogActions,
  DialogContent, Divider, FormControl, FormControlLabel, Grid, IconButton, InputBase,
  List, ListItem, ListItemButton, ListItemText, MenuItem,
  Radio,
  RadioGroup,
  Select, Snackbar, Stack, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import Services from '../../utils/Services';
import TSSnackbar from '../tscomponents/TSSnackbar';
import TSBackDrop from '../tscomponents/TSBackDrop';
import PropTypes from 'prop-types';
import { Utils } from '../../utils/UtilFunctions';
import { validateNumber, validateStringForNull } from '../../utils/FieldValidations';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { APPOINTMENT_STATUS, CRITERIA_FOR_FILTER, SORT_BY_OPTIONS } from '../../utils/EnumDefinitions';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CreateModifyPatient from '../managepatient/CreateModifyPatient';
import APIData from '../../utils/APIData';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';


function TabPanel(props) {
  const { node, value, index } = props;
  if (value == index) {
    return (node);
  } else {
    return <React.Fragment/>;
  }
}

TabPanel.propTypes = {
  node: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const CustomListItemButton = styled(ListItemButton)`
  position: relative;

  &&.Mui-selected {
    box-shadow: 0 0 6px rgba(66, 133, 244, 0.5);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: #4285F4;
    }
  }
`;

const SearchPatientAppointment = ({ clinicId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] =useState('');
  const [patientAppointmentList, setPatientAppointmentList] = useState([]);
  const [visitHistoryForSelectedAppointment, setVisitHistoryForSelectedAppointment] = useState(undefined);
  const [selectedVisitTab, setSelectedVisitTab] = useState(1);
  const [isFirstSearchSuccess, setIsFirstSearchSuccess] = useState(false);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(undefined);
  const [selectedAppointmentDetails, setSelectedAppointmentDetails] = useState(undefined);
  const [expandAccordion, setExpandAccordion] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [diagnosisTreatmentMap, setDiagnosisTreatmentMap] = useState({});
  const [isFilterOptionDialogOpen, setIsFilterOptionDialogOpen] = useState(false);
  const [distinctDoctors, setDistinctDoctors] = useState([]);
  const [distinctAppointmentStatus, setDistinctAppointmentStatus] = useState([]);
  const [filterSortCriteria, setFilterSortCriteria] = useState({
    doctor_id: [],
    appointment_status: [],
    date: {
      criteria: '',
      start_date: null,
      end_date: null
    },
    sort_by: ''
  });
  const [tempFilterSortCriteria, setTempFilterSortCriteria] = useState({});
  const [filteredAppointmentList, setFilteredAppointmentList] = useState([]);
  const [isFilteredAppointmentListEmpty, setIsFilterAppointmentListEmpty] = useState(false);
  const [isManagePatientDialogOpen, setIsManagePatientDialogOpen] = useState(false);
  const [isDeletePatientDialogOpen, setIsDeletePatientDialogOpen] = useState(false);
  const [isNoPatientsSnackBarOpen, setIsNoPatientsSnackBarOpen] = useState(false);
  const [pastPatientParameters, setPastPatientParameters] = useState(undefined);

  const APIToken = {
    SEARCH_CLINIC_PATIENTS: 'CSPA01',
    GET_APPOINTMENT_LIST: 'CSPA02',
    GET_APPOINTMENT_DETAILS: 'CSPA03',
    DELETE_PATIENT: 'CSPA04'
  };

  const handlePatientSearchClick = () => {
    if (validateNumber(phoneNumber) ) {
      raiseGetPatientsDetails();
    }
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    let hideLoading = true;
    if (apiData == APIData.searchClinicPatients && apiToken == APIToken.SEARCH_CLINIC_PATIENTS ) {
      setPatientDetails(response.data);
      setIsFirstSearchSuccess(true);
      setSelectedPatientDetails(undefined);
      setPatientAppointmentList([]);
      setFilteredAppointmentList([]);
      setSelectedAppointmentDetails(undefined);
      setVisitHistoryForSelectedAppointment(undefined);
      setDiagnosisTreatmentMap({});
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_APPOINTMENT_LIST ) {
      setPatientAppointmentList(response.data);
      setFilteredAppointmentList(response.data);
      setSelectedAppointmentDetails(undefined);
      setVisitHistoryForSelectedAppointment(undefined);
      setDiagnosisTreatmentMap({});
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      setVisitHistoryForSelectedAppointment(response.data);
      if (response.data.visit_notes != undefined) {
        const newObj = {};
        response.data.visit_notes.map((visit) => {
          const key = visit.visit_no;
          const obj = {
            diagnosis: visit.diagnosis ?? '',
            treatment: visit.treatment ?? ''
          };
          newObj[key] = obj;
        });
        setDiagnosisTreatmentMap(newObj);
        setSelectedVisitTab(1);
      }
      setPastPatientParameters(response.data.patient_parameters_details == undefined || response.data.patient_parameters_details == null ?
        undefined : response.data.patient_parameters_details.patient_parameters );
    } else if (apiData == APIData.deletePatient && apiToken == APIToken.DELETE_PATIENT ) {
      showSnackBar('success', response.message ?? 'patient deleted successfully');
      hideLoading = false;
      raiseGetPatientsDetails();
    }
    if (hideLoading) {
      setIsLoading(false);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    let showSnackBarMsg = true;
    if (apiData == APIData.searchClinicPatients && apiToken == APIToken.SEARCH_CLINIC_PATIENTS) {
      if (err.status == 404) {
        showSnackBarMsg = false;
        setIsNoPatientsSnackBarOpen(true);
      } else {
        defaultMsg = 'No patients found';
      }
      setPatientDetails([]);
      setSelectedPatientDetails(undefined);
      setPatientAppointmentList([]);
      setFilteredAppointmentList([]);
      setSelectedAppointmentDetails(undefined);
      setVisitHistoryForSelectedAppointment(undefined);
      setDiagnosisTreatmentMap({});
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_APPOINTMENT_LIST ) {
      defaultMsg = 'Failed to retrieve Appointment list';
      setPatientAppointmentList([]);
      setFilteredAppointmentList([]);
      setSelectedAppointmentDetails(undefined);
      setVisitHistoryForSelectedAppointment(undefined);
      setDiagnosisTreatmentMap({});
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      defaultMsg = 'Failed to Get Appointment Details';
      setVisitHistoryForSelectedAppointment(undefined);
      setDiagnosisTreatmentMap({});
    } else if (apiData == APIData.deletePatient && apiToken == APIToken.DELETE_PATIENT ) {
      defaultMsg = 'Failed to delete patient';
    }
    if (showSnackBarMsg == true) {
      showSnackBar('error', err.message ?? defaultMsg);
    }
    setIsLoading(false);
  };

  const raiseGetPatientsDetails = () => {
    setIsLoading(true);
    const finalParams = 'phone=' + phoneNumber + '&use_global_search=false';
    Services.sendBackendRequest({ apiData: APIData.searchClinicPatients, uriValues: [clinicId], params: finalParams },
      APIToken.SEARCH_CLINIC_PATIENTS, processSuccess, processError);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpandAccordion(isExpanded);
  };

  const getUniqueDoctors = () => {
    const doctors ={};
    patientAppointmentList.map((details) => {
      if (!doctors.hasOwnProperty(details.doctor_id)) {
        doctors[details.doctor_id] = details.doctor_name;
      };
    });
    const sortedDoctors = Object.entries(doctors)
      .sort((a, b) => a[1].localeCompare(b[1]))
      .reduce((doctorByOrder, [id, name]) => {
        doctorByOrder[id] = name;
        return doctorByOrder;
      }, {});
    return sortedDoctors;
  };

  const handleManagePatientDialogClose = () => {
    setIsManagePatientDialogOpen(false);
  };

  const handleSuccessfulCommit = () => {
    handleManagePatientDialogClose();
    if (selectedPatientDetails != undefined) {
      showSnackBar('success', 'Patient Details Modified Successfully');
    } else {
      showSnackBar('success', 'Patient Created Successfully');
    }
    handlePatientSearchClick();
  };

  const getDiagnosisValues = (key) => {
    if (diagnosisTreatmentMap[key] != undefined) {
      return (
        <Card
          sx={{
            m: 2,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}
        >
          <CardContent>
            <Grid container justifyContent='space-between' rowSpacing={1} columnSpacing={1}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant='h6'>
                    Diagnosis
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputBase
                    sx={{
                      backgroundColor: 'white',
                      transitionDuration: '.2s',
                      cursor: 'pointer',
                      fontWeight: 500,
                      zIndex: 2,
                      borderRadius: 3,
                      p: 1
                    }}
                    variant="outlined"
                    multiline
                    rows={2}
                    size="small"
                    fullWidth
                    value={validateStringForNull(diagnosisTreatmentMap[key].diagnosis) ?
                      diagnosisTreatmentMap[key].diagnosis : 'No information Available' }
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant='h6'>
                    Treatment
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputBase
                    sx={{
                      backgroundColor: 'white',
                      zIndex: 2,
                      borderRadius: 3,
                      p: 1
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    rows={2}
                    value={validateStringForNull(diagnosisTreatmentMap[key].treatment) ?
                      diagnosisTreatmentMap[key].treatment : 'No information Available' }
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
  };

  const getPatientParametersContent = () => {
    return (
      <Card
        sx={{
          m: 2,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center'
        }}
      >
        <CardContent>
          <Grid container justifyContent='space-between' rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12}>
              <Typography variant='h5'>
                Patient Parameters
              </Typography>
            </Grid>
            {Object.keys(pastPatientParameters).map((key) => (
              <Grid item container xs={12} sm={6} key={key} >
                <Grid item xs={12} sm={6}>
                  <Typography variant='h6'>
                    {Utils.getLabelToDisplay(key)}
                  </Typography>
                  <InputBase
                    sx={{
                      backgroundColor: 'white',
                      transitionDuration: '.2s',
                      cursor: 'pointer',
                      fontWeight: 500,
                      zIndex: 2,
                      borderRadius: 3,
                      p: 1
                    }}
                    variant="outlined"
                    multiline
                    rows={2}
                    size="small"
                    fullWidth
                    value={pastPatientParameters[key]}
                    disabled
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const getVisitTabContent = (visitNo) => {
    const visitDetails = visitHistoryForSelectedAppointment.visit_details.filter((visit) => visit.visit_no === visitNo)[0];
    const key = visitNo;
    if (visitDetails.clinic_visit_time != undefined) {
      if (selectedAppointmentDetails.appointment_status == 0 || selectedAppointmentDetails.appointment_status == 2) {
        return (
          <React.Fragment>
            {getDiagnosisValues(key)}
            <Accordion sx={{ m: 2 }}
              expanded={expandAccordion}
              onChange={handleAccordionChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Timeline</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Timeline position="alternate" sx={{ mt: 2 }}>
                  {visitDetails.clinic_visit_time != undefined ? (
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                      >
                        <Typography variant="h6" component="span">
                          Visited Clinic
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'blue' }} />
                        <TimelineDot sx={{ color: 'blue', backgroundColor: 'white' }}>
                          <WhereToVoteIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ backgroundColor: 'blue' }} />
                      </TimelineSeparator>
                      <TimelineContent sx={{ mt: 2, px: 2 }}>
                        {visitDetails.clinic_visit_time.display}
                      </TimelineContent>
                    </TimelineItem>
                  ) : ''}
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      <Typography variant="h6" component="span">
                        waiting {visitDetails.wait_time != undefined ? visitDetails.wait_time.display: ''}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: 'blue' }} />
                      <TimelineDot sx={{ color: '#F2BE22', backgroundColor: 'white' }}>
                        <HourglassTopOutlinedIcon />
                      </TimelineDot>
                      <TimelineConnector sx={{ backgroundColor: '#F2BE22' }} />
                      <TimelineConnector sx={{ backgroundColor: '#F2BE22' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ mt: 2, px: 2 }}>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        );
      } else if (selectedAppointmentDetails.appointment_status == 3) {
        return (
          <React.Fragment>
            {getDiagnosisValues(key)}
            <Accordion sx={{ m: 2 }}
              expanded={expandAccordion}
              onChange={handleAccordionChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Timeline</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Timeline position="alternate" sx={{ mt: 2 }}>
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Visited Clinic
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: 'blue' }} />
                      <TimelineDot sx={{ color: 'blue', backgroundColor: 'white' }}>
                        <WhereToVoteIcon />
                      </TimelineDot>
                      <TimelineConnector sx={{ backgroundColor: 'blue' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ mt: 2, px: 2 }}>
                      <Typography variant="h6" component="span">
                        {visitDetails.clinic_visit_time.display}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  {visitDetails.in_time != undefined ? (
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                      >
                        <Typography variant="h6" component="span">
                          Consultation
                        </Typography>
                        <Typography variant='h6'>(Waited for {visitDetails.wait_time != undefined ?
                          visitDetails.wait_time.display : ''})
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'blue' }} />
                        <TimelineDot sx={{ color: 'red', backgroundColor: 'white' }}>
                          <LoginIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{ backgroundColor: 'red' }} />
                      </TimelineSeparator>
                      <TimelineContent sx={{ mt: 2, px: 2 }}>
                        <Typography variant="h6" component="span">
                          {visitDetails.in_time.display}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ) : ''}
                  {visitDetails.consulting_time != undefined ? (
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                      >
                        <Typography variant="h6" component="span">Consulting - {visitDetails.consulting_time != undefined ?
                          visitDetails.consulting_time.display : ''}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ backgroundColor: 'red' }} />
                        <TimelineConnector sx={{ backgroundColor: 'red' }} />
                        <TimelineDot sx={{ color: 'green', backgroundColor: 'white' }}>
                          <HourglassTopOutlinedIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ backgroundColor: 'green' }} />
                        <TimelineConnector sx={{ backgroundColor: 'green' }} />
                      </TimelineSeparator>
                      <TimelineContent sx={{ mt: 2, px: 2 }}>
                      </TimelineContent>
                    </TimelineItem>
                  ) : ''}
                </Timeline>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        );
      } else if (selectedAppointmentDetails.appointment_status == 4 ) {
        return (
          <React.Fragment>
            {getDiagnosisValues(key)}
            <Accordion sx={{ m: 2 }}
              expanded={expandAccordion}
              onChange={handleAccordionChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Timeline</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Timeline position="alternate">
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Visited Clinic
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: 'blue' }} />
                      <TimelineDot sx={{ color: 'blue', backgroundColor: 'white' }}>
                        <WhereToVoteIcon />
                      </TimelineDot>
                      <TimelineConnector sx={{ backgroundColor: 'blue' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ mt: 2, px: 2 }}>
                      <Typography variant="h6" component="span">
                        {visitDetails.clinic_visit_time.display}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  {visitDetails.in_time != undefined ? (
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                      >
                        <Typography variant="h6" component="span">
                          Consulation
                        </Typography>
                        <Typography variant='h6'>(Waitied for {visitDetails.wait_time != undefined ?
                          visitDetails.wait_time.display : '' })
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'blue' }} />
                        <TimelineDot sx={{ color: 'red', backgroundColor: 'white' }}>
                          <LoginIcon/>
                        </TimelineDot>
                        <TimelineConnector sx={{ backgroundColor: 'red' }}/>
                      </TimelineSeparator>
                      <TimelineContent sx={{ mt: 2, px: 2 }}>
                        <Typography variant="h6" component="span">
                          {visitDetails.in_time.display}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ) : ''}
                  {visitDetails.out_time != undefined ? (
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                      >
                        <Typography variant="h6" component="span">
                          Out
                        </Typography>
                        <Typography variant='h6'>(Consultation Time - {visitDetails.consulting_time != undefined ?
                          visitDetails.consulting_time.display : ''})
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector sx={{ backgroundColor: 'red' }} />
                        <TimelineDot sx={{ color: 'green', backgroundColor: 'white' }}>
                          <LogoutIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ backgroundColor: 'green' }} />
                      </TimelineSeparator >
                      <TimelineContent sx={{ mt: 2, px: 2 }}>
                        <Typography variant="h6" component="span">
                          {visitDetails.out_time.display}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ) : ''}
                </Timeline>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        );
      }
    } else {
      return (
        <Typography color='gray' textAlign='center' sx={{ mt: 2 }} > Patient not visited</Typography>
      );
    }
  };

  const handleFilterOptionDialogOpen = () => {
    if (patientAppointmentList != undefined) {
      setDistinctDoctors(getUniqueDoctors());
      const uniqueAppointmentStatus = [...new Set(patientAppointmentList.map((details) =>
        details.appointment_status))].sort((a, b) => a - b);
      setDistinctAppointmentStatus(uniqueAppointmentStatus);
      setTempFilterSortCriteria(filterSortCriteria);
      setIsFilterOptionDialogOpen(true);
    }
  };

  const handleAppointmentListItemSelection = (obj) => {
    if ( selectedAppointmentDetails == undefined || selectedAppointmentDetails.appointment_id != obj.appointment_id) {
      setSelectedAppointmentDetails(obj);
      if (obj.appointment_status > 1) {
        raiseGetAppointmentVisitDetails(obj.appointment_id);
      } else {
        setVisitHistoryForSelectedAppointment(undefined);
        setDiagnosisTreatmentMap({});
      }
    }
  };

  const raiseGetPatientAppointmentList = (patientId) => {
    setIsLoading(true);
    const finalParams = `patient_id=${patientId}`;
    Services.sendBackendRequest({ apiData: APIData.getAppointmentList, uriValues: [clinicId], params: finalParams },
      APIToken.GET_APPOINTMENT_LIST, processSuccess, processError);
  };

  const handlePatientSelection = (obj) => {
    if (selectedPatientDetails == undefined || selectedPatientDetails.patient_id != obj.patient_id) {
      setSelectedPatientDetails(obj);
      raiseGetPatientAppointmentList(obj.patient_id);
    }
  };

  const getPatientDetailsPrimaryText = (obj) => {
    let details = obj.patient_name;
    if (validateStringForNull(obj.sex)) {
      details+=', ' + obj.sex;
    }
    if (validateStringForNull(obj.age)) {
      details+=', ' + obj.age.display;
    }
    return details;
  };

  const getPatientAddressPrimaryText = (obj) => {
    let details = '';
    if (validateStringForNull(obj.area)) {
      details+= obj.area;
    }
    if (validateStringForNull(obj.address)) {
      details+=', ' + obj.address;
    }
    if (validateStringForNull(obj.city)) {
      details+=', ' + obj.city;
    }
    if (validateStringForNull(obj.state)) {
      details+=', ' + obj.state;
    }
    if (validateStringForNull(obj.country)) {
      details+=', ' + obj.country;
    }
    return details;
  };

  const handleAppointmentListSessionPrimaryText = (obj) => {
    let details = '';
    if (validateStringForNull(obj.appointment_date)) {
      details+= obj.appointment_date.display;
    }
    if (validateStringForNull(obj.session_start_time)) {
      details+=' | ' + obj.session_start_time.display;
    }
    if (validateStringForNull(obj.session_end_time)) {
      details+=' - ' + obj.session_end_time.display;
    }
    return details;
  };

  const handleNoPatientsSnackbarCreateClick = () => {
    setIsManagePatientDialogOpen(true);
    setIsNoPatientsSnackBarOpen(false);
  };

  const handleAppointmentStatusPrimaryText = (obj) => {
    switch (obj.appointment_status) {
    case 0:
      return (
        <Chip sx={{ backgroundColor: '#B31312', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[0]} />
      );
    case 1:
      return (
        <Chip sx={{ backgroundColor: '#22A699', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[1]} />
      );
    case 2:
      return (
        <Chip sx={{ backgroundColor: '#F2BE22', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[2]} />
      );
    case 3:
      return (
        <Chip sx={{ backgroundColor: '#205295', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[3]} />
      );
    case 4:
      return (
        <Chip sx={{ backgroundColor: '#379237', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[4]} />
      );
    case -1:
      return (
        <Chip sx={{ backgroundColor: '#3D3B40', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[-1]} />
      );
    }
    return details;
  };

  const raiseGetAppointmentVisitDetails = (appId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getAppointmentDetails, uriValues: [clinicId, appId] },
      APIToken.GET_APPOINTMENT_DETAILS, processSuccess, processError);
  };

  const unSelectPatient = () => {
    setSelectedPatientDetails(undefined);
    setPatientAppointmentList([]);
    setFilteredAppointmentList([]);
    setSelectedAppointmentDetails(undefined);
    setVisitHistoryForSelectedAppointment(undefined);
    setDiagnosisTreatmentMap({});
  };

  const unSelectPatientAppointment = () => {
    setSelectedAppointmentDetails(undefined);
    setVisitHistoryForSelectedAppointment(undefined);
    setDiagnosisTreatmentMap({});
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  const handleDoctorNameSelectionClick = (doctorId) => {
    setFilterSortCriteria((prevFilteredAppointmentList) => {
      const isDoctorSelected = prevFilteredAppointmentList.doctor_id.includes(doctorId);
      if (isDoctorSelected) {
        const updatedDoctorIds = prevFilteredAppointmentList.doctor_id.filter((id) => id !== doctorId);
        return { ...prevFilteredAppointmentList, doctor_id: updatedDoctorIds };
      } else {
        const updatedDoctorIds = [...prevFilteredAppointmentList.doctor_id, doctorId];
        return { ...prevFilteredAppointmentList, doctor_id: updatedDoctorIds };
      }
    });
  };

  const handleDateCriteriaChange = (event) => {
    const selectedValue = event.target.value;
    setFilterSortCriteria((prevFilteredAppointmentList) => ({ ...prevFilteredAppointmentList,
      date: { ...prevFilteredAppointmentList.date,
        criteria: selectedValue
      }
    }));
  };

  const handleDateChange = (newValue, field) => {
    setFilterSortCriteria((prevFilteredAppointmentList) => ({ ...prevFilteredAppointmentList,
      date: { ...prevFilteredAppointmentList.date,
        [field]: newValue
      }
    }));
  };

  const handleAppointmentstatusSelectionClick = (appointmentStatus) => {
    setFilterSortCriteria((prevSelectedAppointmentStatus) => {
      const isAppointmentStatusSelected = prevSelectedAppointmentStatus.appointment_status.includes(appointmentStatus);
      if (isAppointmentStatusSelected) {
        const updateAppointmentStatus = prevSelectedAppointmentStatus.appointment_status.filter((status) => status !== appointmentStatus);
        return { ...prevSelectedAppointmentStatus, appointment_status: updateAppointmentStatus };
      } else {
        const updateAppointmentStatus = [...prevSelectedAppointmentStatus.appointment_status, appointmentStatus];
        return { ...prevSelectedAppointmentStatus, appointment_status: updateAppointmentStatus };
      }
    });
  };

  const handleFilterAppointmentApplyClick = () => {
    const filteredAppointments = [];
    const selectedStartDate = filterSortCriteria.date.start_date != undefined ?
      moment(filterSortCriteria.date.start_date).format('yyyy-MM-DD') : null;
    const selectedEndDate = filterSortCriteria.date.end_date != undefined ?
      moment(filterSortCriteria.date.end_date).format('yyyy-MM-DD') : null;
    patientAppointmentList.map((appointment) => {
      let valid = true;
      if (filterSortCriteria.doctor_id.length > 0 && !filterSortCriteria.doctor_id.includes(appointment.doctor_id)) {
        valid = false;
      }
      if (valid == true && filterSortCriteria.appointment_status.length > 0 &&
        !filterSortCriteria.appointment_status.includes(appointment.appointment_status)) {
        valid = false;
      }
      if (valid == true && validateStringForNull(filterSortCriteria.date.criteria)) {
        const appointmentDate = appointment.appointment_date.value;
        if (filterSortCriteria.date.criteria == '1') {
          if (moment(appointmentDate).isAfter(selectedStartDate)) {
            valid = false;
          }
        } else if (filterSortCriteria.date.criteria == '2') {
          if (moment(appointmentDate).isBefore(selectedStartDate) || moment(appointmentDate).isAfter(selectedEndDate)) {
            valid = false;
          }
        } else if ( filterSortCriteria.date.criteria == '3') {
          if (moment(appointmentDate).isBefore(selectedStartDate)) {
            valid = false;
          }
        }
      }
      if (valid) {
        filteredAppointments.push(appointment);
      }
    });
    if (validateStringForNull(filterSortCriteria.sort_by)) {
      if (filterSortCriteria.sort_by === '1') {
        filteredAppointments.sort((a, b) => moment(b.appointment_date.value).diff(a.appointment_date.value));
      } else if (filterSortCriteria.sort_by === '2') {
        filteredAppointments.sort((a, b) => moment(a.appointment_date.value).diff(b.appointment_date.value));
      } else if (filterSortCriteria.sort_by === '3') {
        filteredAppointments.sort((a, b) => a.doctor_name.localeCompare(b.doctor_name));
      } else if (filterSortCriteria.sort_by === '4') {
        filteredAppointments.sort((a, b) => a.appointment_status - b.appointment_status);
      }
    }
    if (filteredAppointments.length != 0) {
      setIsFilterAppointmentListEmpty(false);
      if (selectedAppointmentDetails != undefined) {
        const isAppointmentIdPresent = filteredAppointments.some((appointment) =>
          appointment.doctor_id === selectedAppointmentDetails.doctor_id &&
          appointment.appointment_id === selectedAppointmentDetails.appointment_id);
        if (!isAppointmentIdPresent) {
          setSelectedAppointmentDetails(undefined);
          setVisitHistoryForSelectedAppointment(undefined);
        }
      }
      setFilteredAppointmentList(filteredAppointments);
    } else {
      setIsFilterAppointmentListEmpty(true);
      setFilteredAppointmentList([]);
    }
    setTempFilterSortCriteria(filterSortCriteria);
    setIsFilterOptionDialogOpen(false);
  };

  const handleDeletePatientRequest = () => {
    if (selectedPatientDetails != undefined) {
      raiseDeletePatientRequest(selectedPatientDetails.patient_id);
    }
  };

  const raiseDeletePatientRequest = (patientId) => {
    setIsLoading(true);
    setIsDeletePatientDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.deletePatient, uriValues: [clinicId, patientId] },
      APIToken.DELETE_PATIENT, processSuccess, processError);
  };

  const handleClearFilterClick = () => {
    const obj = {
      doctor_id: [],
      appointment_status: [],
      date: {
        criteria: '',
        start_date: null,
        end_date: null
      },
      sort_by: ''
    };
    setFilterSortCriteria(obj);
    const originalAppointmentList = cloneDeep(patientAppointmentList);
    setFilteredAppointmentList(originalAppointmentList);
    setTempFilterSortCriteria(obj);
    setIsFilterAppointmentListEmpty(false);
  };

  const handleSortByOptionchange = (event) => {
    setFilterSortCriteria({ ...filterSortCriteria, sort_by: event.target.value });
  };

  const handleFilterDialogClose = () => {
    setFilterSortCriteria(tempFilterSortCriteria);
    setIsFilterOptionDialogOpen(false);
  };

  const handleCreatePatientClick = () => {
    setSelectedPatientDetails(undefined);
    setIsManagePatientDialogOpen(true);
  };

  return (
    <React.Fragment>
      <Grid container minHeight='85vh' paddingBottom='10px' justifyContent='center' columnGap={2}
        alignItems={isFirstSearchSuccess == true ? 'flex-start' : 'center'} >
        <Grid item container xs={12} md={12} sx={{ py: 2, width: 'fit-content' }}
          justifyContent='center'
        >
          {isFirstSearchSuccess == false ? (
            <Grid item xs={10} md={11} sx={{ p: 2 }}>
              <Typography variant="h4" sx={{ textAlign: 'center', mb: 0 }}>
                Patient Search
              </Typography>
            </Grid>
          ) : ''}
          <Grid item container justifyContent='center' rowSpacing={1} columnSpacing={1}>
            <Grid item xs={8} md={8} lg={4}>
              <Paper
                component="form"
                elevation={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 'fit-content',
                  pb: 1, pt: 0.5,
                  transition: 'border-color 0.3s ease-in-out'
                }}
              >
                <TextField
                  color='primary'
                  id="phone"
                  name="phone"
                  size='small'
                  variant='standard'
                  sx={{ ml: 1, mr: 1, flex: 1 }}
                  label="Phone No"
                  value={phoneNumber}
                  autoComplete ='off'
                  InputProps={{ disableUnderline: true }}
                  onChange={(event) => {handlePhoneNumberChange(event);}}
                  onBlur={handlePatientSearchClick}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary"
                  onClick={handlePatientSearchClick}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item >
              <Paper
                component="form"
                elevation={4}
                sx={{
                  width: 'fit-content',
                  alignItems: 'center',
                  height: 'fit-content',
                  pb: 1, pt: 1,
                  transition: 'border-color 0.3s ease-in-out'
                }}
              >
                <Tooltip title='Create Patient'>
                  <IconButton color="primary"
                    onClick={handleCreatePatientClick}
                  >
                    <PersonAddAlt1Icon />
                  </IconButton>
                </Tooltip>
                {selectedPatientDetails != undefined ? (
                  <React.Fragment>
                    <Tooltip title='Modify Patient'>
                      <IconButton color="success"
                        onClick={() => {setIsManagePatientDialogOpen(true);}}
                      >
                        <ManageAccountsIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete Patient'>
                      <IconButton color="error"
                        onClick={() => {setIsDeletePatientDialogOpen(true);}}
                      >
                        <PersonRemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                ) : ''}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {patientDetails.length != 0 ? (
          !Utils.isDesktop() && selectedPatientDetails != undefined ? (
            <Grid item xs={12} sx={{ mb: 2, backgroundColor: 'white' }} marginTop={{ lg: 0 }}>
              <CustomListItemButton
                selected={true}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='overline' sx={{ fontSize: 11, textTransform: 'none', color: '#526D82' }}>
                      Selected Patient
                    </Typography>
                  </Grid>
                  <Grid item xs={9} lg={9}>
                    <Stack>
                      <ListItemText primary={getPatientDetailsPrimaryText(selectedPatientDetails)} />
                      <ListItemText secondary={getPatientAddressPrimaryText(selectedPatientDetails)} />
                    </Stack>
                  </Grid>
                  <Grid item container xs={3} sx={{ pr: 2 }}
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                  >
                    <Grid item xs={3}>
                      <Tooltip title=''>
                        <IconButton sx={{ mr: 1 }} color='primary'
                          onClick={() => {unSelectPatient();}}>
                          <ArrowCircleRightIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </CustomListItemButton>
            </Grid>
          ) :(
            <Grid item xs={12} sm={12} md={3.7} lg={3.9} sx={{ backgroundColor: 'white', minHeight: '70vh' }} marginTop={{ lg: 0 }} >
              <Grid item container xs={12} justifyContent='flex-start' position='sticky' top={0} alignItems='center' >
                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                  <Typography variant="h4" sx={{ textAlign: 'center', py: 1 }}>
                    Patients
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ maxHeight: '65vh', overflow: 'auto' }}>
                  <List sx={{ backgroundColor: 'white' }}>
                    {patientDetails.map((obj) =>(
                      <React.Fragment key={obj.patient_id} >
                        <CustomListItemButton selected={selectedPatientDetails != undefined && selectedPatientDetails.patient_id == obj.patient_id}
                          onClick={() => {handlePatientSelection(obj);}}>
                          <Grid container>
                            <Grid item xs={9} lg={9}>
                              <Stack>
                                <ListItemText primary={getPatientDetailsPrimaryText(obj)} />
                                <ListItemText secondary={getPatientAddressPrimaryText(obj)} />
                              </Stack>
                            </Grid>
                          </Grid>
                        </CustomListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          )
        ) : ''}
        {filteredAppointmentList.length != 0 ? (
          !Utils.isDesktop() && selectedAppointmentDetails != undefined ? (
            <Grid item xs={12} sx={{ mb: 2, backgroundColor: 'white' }} marginTop={{ lg: 0 }} position='sticky' top={0}>
              <CustomListItemButton
                selected={true}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='overline' sx={{ fontSize: 11, color: '#526D82', textTransform: 'none' }}>
                      Selected Appointment
                    </Typography>
                  </Grid>
                  <Grid item xs={9} lg={9}>
                    <Stack>
                      <ListItemText primary={selectedAppointmentDetails.doctor_name} />
                      <ListItemText secondary={handleAppointmentListSessionPrimaryText(selectedAppointmentDetails)} />
                      <ListItem sx={{ pl: 0 }}>{handleAppointmentStatusPrimaryText(selectedAppointmentDetails)}</ListItem>
                    </Stack>
                  </Grid>
                  <Grid item container xs={3} sx={{ pr: 2 }}
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                  >
                    <Grid item xs={3}>
                      <Tooltip title=''>
                        <IconButton sx={{ mr: 1 }} color='primary'
                          onClick={() => {unSelectPatientAppointment();}}>
                          <ArrowCircleRightIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </CustomListItemButton>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={3.9} lg={3.9} sx={{ backgroundColor: 'white', minHeight: '70vh' }}
              marginTop={{ lg: 0 }} position='sticky' top={0}>
              <Grid item container xs={12} >
                <Grid item xs={10} md={10} sx={{ p: 1 }}>
                  <Typography variant="h4" sx={{ textAlign: 'center', py: 1 }}>
                    Appointments
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2} sx={{ p: 1 }}>
                  <Tooltip title='Filter Appointment'>
                    <IconButton color="primary"
                      onClick={handleFilterOptionDialogOpen}
                    >
                      <FilterAltIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ maxHeight: '60vh', overflow: 'auto' }}>
                  <List sx={{ backgroundColor: 'white' }}>
                    {filteredAppointmentList.map((obj) => (
                      <React.Fragment key={obj.appointment_id} >
                        <CustomListItemButton selected={selectedAppointmentDetails != undefined &&
                          selectedAppointmentDetails.appointment_id == obj.appointment_id}
                        onClick={() => {handleAppointmentListItemSelection(obj);}}
                        >
                          <Grid container>
                            <Grid item xs={9} lg={9}>
                              <Stack>
                                <ListItemText primary={obj.doctor_name} />
                                <ListItemText secondary={handleAppointmentListSessionPrimaryText(obj)} />
                              </Stack>
                            </Grid>
                            <Grid item xs={3} sx={{ pt: 2 }}>
                              {handleAppointmentStatusPrimaryText(obj)}
                            </Grid>
                          </Grid>
                        </CustomListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          )
        ) : ''}
        {isFilteredAppointmentListEmpty == true ? (
          <Grid item xs={12} sm={12} md={3.9} lg={3.9} sx={{ backgroundColor: 'white', minHeight: '70vh' }}
            marginTop={{ lg: 0 }} position='sticky' top={0}>
            <Grid item xs={12} sx={{ p: 1 }}>
              <Typography variant="h4" sx={{ textAlign: 'center', py: 1 }}>
                Appointments
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ textAlign: 'center', p: 1, mt: 4 }}>
                  No Appointments found for applied filters.
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Modify filter and try again.
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent='center' alignItems='center' sx={{ py: 2 }} >
              <Grid item sx={{ px: 2 }} >
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color='error'
                  onClick={handleClearFilterClick}
                >
                  Clear Filter
                </Button>
              </Grid>
              <Grid item >
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  onClick={handleFilterOptionDialogOpen}
                >
                  Modify Filter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : ''}
        {visitHistoryForSelectedAppointment != undefined && visitHistoryForSelectedAppointment.visit_notes != undefined? (
          <Grid item xs={12} sm={12} md={3.5} lg={3.9} sx={{ backgroundColor: 'white', minHeight: '70vh' }} marginTop={{ lg: 0 }}>
            <Grid item container xs={12} >
              <Grid item xs={12} sx={{ p: 2 }}>
                <Typography variant="h4" sx={{ textAlign: 'center', mb: 1 }}>
                  Visit Details
                </Typography>
              </Grid>
            </Grid>
            {validateStringForNull(pastPatientParameters) ? (
              getPatientParametersContent()
            ) : ''}
            <Grid item xs={12} sx={{ p: 1 }}>
              <React.Fragment>
                <Tabs value={selectedVisitTab}
                  sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    border: '1px'
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: 'white'
                    }
                  }}
                  onChange={(event, newValue) => setSelectedVisitTab(newValue)}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  indicatorColor="primary">
                  {visitHistoryForSelectedAppointment.visit_notes != undefined ? (
                    visitHistoryForSelectedAppointment.visit_notes.map((visit) => (
                      <Tab key={visit.visit_no}
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Visit {visit.visit_no}
                          </Box>
                        } {...a11yProps(0)} value={visit.visit_no}
                        sx={{
                          minWidth: '20px',
                          minHeight: '40px',
                          '&.Mui-selected': {
                            color: 'white',
                            backgroundColor: '#3a79ce',
                            borderRadius: 3
                          }
                        }}
                      />
                    ))
                  ) : ''}
                </Tabs>
                {visitHistoryForSelectedAppointment.visit_notes != undefined ? (
                  visitHistoryForSelectedAppointment.visit_notes.map((visit) => (
                    <TabPanel key={visit.visit_no} value={selectedVisitTab}
                      index={visit.visit_no} node={getVisitTabContent(visit.visit_no)}/>
                  ))
                ) :''}
              </React.Fragment>
            </Grid>
          </Grid>
        ) : ''}
      </Grid>
      <TSSideBarDialog
        open={isFilterOptionDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleFilterDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sort & Filter Appointments
            </Typography>
          </Toolbar>
        </AppBar>
        {patientAppointmentList != undefined ? (
          <Grid container justifyContent='flex-start' alignItems='flex-start'>
            <Grid item xs={12}>
              <Typography sx={{ m: 2, mb: 0, fontWeight: 'bold' }} >
                Sort By
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ m: 2 }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="sort-by"
                  name="sort-by"
                  value={filterSortCriteria.sort_by}
                  onChange={(event) => {handleSortByOptionchange(event);}}
                >
                  {Object.entries(SORT_BY_OPTIONS).map(([key, label]) => (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio size='small' />}
                      label={<Typography variant='h6'>{label}</Typography>}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid item xs={12}>
              <Typography sx={{ m: 2, mb: 0, fontWeight: 'bold' }} >
                Filter By
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sx={{ m: 2, mb: 0 }}>
                <Typography variant="overline">
                  Doctor
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ m: 1, mt: 0 }}>
                {Object.keys(distinctDoctors).map((doctorId, i) => (
                  <Chip sx={{ m: 1 }}
                    key={i} label={distinctDoctors[doctorId]}
                    onClick={() => handleDoctorNameSelectionClick(doctorId)}
                    color={filterSortCriteria.doctor_id.includes(doctorId) ? 'primary' : undefined}
                  />
                ))}
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid item container xs={12}>
              <Grid item xs={12} sx={{ m: 2, mb: 0 }}>
                <Typography variant="overline" >
                  Status
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ m: 1, mt: 0 }}>
                {distinctAppointmentStatus.map((appstatus, i) => (
                  <Chip sx={{ m: 1 }}
                    key={i} label={APPOINTMENT_STATUS[appstatus]}
                    onClick={() => handleAppointmentstatusSelectionClick(appstatus)}
                    color={filterSortCriteria.appointment_status.includes(appstatus) ? 'primary' : undefined}
                  />
                ))}
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid item xs={12} sm={12} sx={{ m: 2, mb: 0 }}>
              <Typography variant="overline">
                Date
              </Typography>
            </Grid>
            <Grid item container xs={12} columnSpacing={1} rowSpacing={2} sx={{ m: 2, mt: 0 }} >
              <Grid item xs={6} sm={2.5}>
                <FormControl fullWidth>
                  <Select
                    value={filterSortCriteria.date.criteria}
                    onChange={handleDateCriteriaChange}
                    size='small'
                  >
                    {Object.keys(CRITERIA_FOR_FILTER).map((key) => (
                      <MenuItem key={key} value={key}>
                        {CRITERIA_FOR_FILTER[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {filterSortCriteria.date.criteria == '1' || filterSortCriteria.date.criteria == '3' ? (
                <Grid item xs={6} sm={4} >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Date"
                      format="DD/MM/YYYY"
                      sx={{ width: '100%' }}
                      value={filterSortCriteria.date.start_date}
                      name="date"
                      disableFuture
                      onChange= {(newValue) => {handleDateChange(newValue, 'start_date');}}
                      slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                    />
                  </LocalizationProvider>
                </Grid>
              ) : ''}
              {filterSortCriteria.date.criteria == '2' ? (
                <React.Fragment>
                  <Grid item xs={6.1} sm={4} >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="Start Date"
                        format="DD/MM/YYYY"
                        sx={{ width: '100%' }}
                        value={filterSortCriteria.date.start_date}
                        name="start_date"
                        disableFuture
                        onChange= {(newValue) => {handleDateChange(newValue, 'start_date');}}
                        slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5.9} sm={4} >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="End Date"
                        format="DD/MM/YYYY"
                        sx={{ width: '100%' }}
                        value={filterSortCriteria.date.end_date}
                        name="end_date"
                        disableFuture
                        onChange= {(newValue) => {handleDateChange(newValue, 'end_date');}}
                        slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </React.Fragment>
              ) : ''}
            </Grid>
            <Grid item container justifyContent='flex-end' sx={{ m: 2 }}>
              <Grid item sx={{ mr: 2 }}>
                <Button
                  size="small"
                  color='primary'
                  onClick={handleClearFilterClick}
                  sx={{ borderRadius: 15, fontSize: '13px' }}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  size="small"
                  color='primary'
                  sx={{ borderRadius: 15, fontSize: '13px' }}
                  onClick={handleFilterAppointmentApplyClick}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : ''}
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isManagePatientDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleManagePatientDialogClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {validateStringForNull(selectedPatientDetails) ? 'Modify Patient' : 'Create Patient'}
            </Typography>
          </Toolbar>
        </AppBar>
        <CreateModifyPatient clinicId={clinicId}
          patientId = {selectedPatientDetails != undefined ? selectedPatientDetails.patient_id : undefined}
          onSuccessfulCommit={handleSuccessfulCommit}
          prefilledInfo={selectedPatientDetails == undefined ? { phone: phoneNumber } : undefined }
        />
      </TSSideBarDialog>
      <TSConfirmationDialog
        open={isDeletePatientDialogOpen}
      >
        <DialogContent>
          <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
            <strong>Delete Patient</strong>
          </Typography>
          <Typography variant='h5'>
            Are you sure you want to delete this Patient?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            size="small"
            color='primary'
            onClick={() => setIsDeletePatientDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color='primary'
            onClick={handleDeletePatientRequest}
          >
            Delete Patient
          </Button>
        </DialogActions>
      </TSConfirmationDialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isNoPatientsSnackBarOpen}
      >
        <Alert variant="filled"
          severity={'error'} sx={{ width: '100%' }}
          action={
            <>
              <Button
                color="success"
                size="small"
                variant='contained'
                sx={{ mr: 1 }}
                onClick={handleNoPatientsSnackbarCreateClick}
              >
                Create one
              </Button>
              <IconButton
                onClick={() => setIsNoPatientsSnackBarOpen(false)}
                sx={{ mr: 1 }}
                color="inherit" size="small"
              >
                <CloseIcon />
              </IconButton>
            </>
          }>
          {'No patients found.'}
        </Alert>
      </Snackbar>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

SearchPatientAppointment.propTypes = {
  clinicId: PropTypes.string.isRequired
};

export default SearchPatientAppointment;
