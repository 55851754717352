import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';

const TSHomeBackDrop = ({ isLoading }) => {
  return (
    <Backdrop open={isLoading} sx={{ mx: 3, mb: 3, mt: 9.5, borderRadius: '16px', backgroundColor: 'rgba(55, 55, 55, 0.5) !important' }}>
      <CircularProgress style={{ color: 'main.primary' }} />
    </Backdrop>
  );
};

TSHomeBackDrop.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

export default TSHomeBackDrop;
