import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Services from '../../utils/Services';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Card, CardContent, Grid,
  IconButton, InputBase, InputLabel, Tab, Tabs, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import TSBackDrop from '../tscomponents/TSBackDrop';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import PatientParameterDetails from './PatientParameterDetails';
import CloseIcon from '@mui/icons-material/Close';
import { validateStringForNull } from '../../utils/FieldValidations';
import RefreshIcon from '@mui/icons-material/Refresh';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { cloneDeep } from 'lodash';
import LogoutIcon from '@mui/icons-material/Logout';
import APIData from '../../utils/APIData';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import TSTimer from '../tscomponents/TSTimer';
import { CookieUtils } from '../../utils/CookieUtils';

function TabPanel(props) {
  const { node, value, index } = props;
  if (value == index) {
    return (node);
  } else {
    return <React.Fragment/>;
  }
}

TabPanel.propTypes = {
  node: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ManageSingleAppointment = ({ clinicId, appointmentId, onCheckOut }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [pastAppointmentDetails, setPastAppointmentDetails] = useState([]);
  const [pastAppointmentDetailedObj, setPastAppointmentDetailedObj] = useState({});

  const [isPatientDetailsVisible, setIsPatientDetailsVisible] = useState(true);
  const [isPatientParametersVisible, setIsPatientParametersVisible] = useState(true);
  const [isCurrentAppointmentDetailsVisible, setIsCurrentAppointmentDetailsVisible] = useState(true);
  const [isPastAppointmentDetailsVisible, setIsPastAppointmentDetailsVisible] = useState(true);
  const [isPatientParameterDialogOpen, setIsPatientParameterDialogOpen] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const [pastPatientParameters, setPastPatientParameters] = useState({});

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const getCurrentDefaultTab = () => {
    if (appointmentDetails.visit_details != undefined && appointmentDetails.visit_details.length > 0) {
      return appointmentDetails.visit_details[0].visit_no;
    }
    return -1;
  };

  const [currentTabValue, setCurrentTabValue] = useState(getCurrentDefaultTab());
  const [pastTabValue, setPastTabValue] = useState({});

  const [currentDiagnosisValues, setCurrentDiagnosisValues] = useState({});
  const [pastDiagnosisValues, setPastDiagnosisValues] = useState({});

  const APIToken = {
    GET_APPOINTMENT_HISTORY: 'CMSA01',
    GET_APPOINTMENT_DETAILS: 'CMSA02',
    GET_PAST_APPOINTMENTS_DETAILS: 'CMSA03',
    MODIFY_APPOINTMENT_DETAILS: 'CMSA04',
    CHECK_OUT: 'CMSA05'
  };

  useEffect(() => {
    raiseGetAppointmentDetails(true);
  }, [appointmentId]);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    let hideLoading = true;
    if (apiData == APIData.getPastAppointmentHistory && apiToken == APIToken.GET_APPOINTMENT_HISTORY ) {
      setPastAppointmentDetails(response.data);
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      setAppointmentDetails(response.data);
      const newObj = {};
      getAppointmentVisitNotes(response, newObj, false);
      setCurrentDiagnosisValues(newObj);
      if (callbackValues.isLoadPastHistory) {
        hideLoading = false;
        raiseGetPastAppointmentHistoryDetails(response.data.patient_details.patient_id, response.data.op_details.op_id);
      }
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_PAST_APPOINTMENTS_DETAILS ) {
      const obj = pastAppointmentDetailedObj;
      const appId = callbackValues.appointment_id;
      obj[appId] = response.data;
      setPastAppointmentDetailedObj(obj);
      const newObj = pastDiagnosisValues;
      getAppointmentVisitNotes(response, newObj, true);
      setPastDiagnosisValues(newObj);
      const ppObj = pastPatientParameters;
      ppObj[appId] = response.data.patient_parameters_details == undefined || response.data.patient_parameters_details == null ?
        undefined : response.data.patient_parameters_details.patient_parameters;
      setPastPatientParameters(ppObj);
      setExpandedAccordion(appId);
    } else if (apiData == APIData.modifyAppointmentDetails && apiToken == APIToken.MODIFY_APPOINTMENT_DETAILS ) {
      showSnackBar('success', response.message ?? 'Details updated successfully');
      hideLoading = false;
      if (!callbackValues.isPast) {
        raiseGetAppointmentDetails(false);
      } else {
        raiseGetPastIndividualAppointment(callbackValues.appointmentId);
      }
    } else if (apiData == APIData.checkOutPatient && apiToken == APIToken.CHECK_OUT ) {
      if (onCheckOut != undefined) {
        onCheckOut();
      }
    }
    if (hideLoading) {
      setIsLoading(false);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg ='Unhandled Exception';
    if (apiData == APIData.getPastAppointmentHistory && apiToken == APIToken.GET_APPOINTMENT_HISTORY) {
      defaultMsg = 'Failed to Get Past Appointment History';
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      defaultMsg = 'Failed to Get Appointment Details';
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_PAST_APPOINTMENTS_DETAILS ) {
      defaultMsg = 'Failed to Get Appointment Details';
    } else if (apiData == APIData.modifyAppointmentDetails && apiToken == APIToken.MODIFY_APPOINTMENT_DETAILS ) {
      defaultMsg = 'Failed to update diagnosis/treatment details';
    } else if (apiData == APIData.checkOutPatient && apiToken == APIToken.CHECK_OUT ) {
      defaultMsg = 'Failed to check-out patient';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const getAppointmentVisitNotes = (response, newObj, isPast) => {
    const appId = response.data.basic_details.appointment_id;
    if (response.data.visit_notes != undefined) {
      response.data.visit_notes.map((visit) => {
        const key = isPast ? appId + ':' + visit.visit_no : visit.visit_no;
        const obj = {
          diagnosis: visit.diagnosis ?? '',
          treatment: visit.treatment ?? ''
        };
        newObj[key] = obj;
      });
      if (isPast) {
        const past = pastTabValue;
        past[appId] = response.data.visit_notes.length;
        setPastTabValue(past);
      } else {
        setCurrentTabValue(response.data.visit_notes.length);
      }
    }
  };

  const raiseGetPastAppointmentHistoryDetails = (patientId, currentOpId) => {
    setIsLoading(true);
    const finalParams = `doctor_id=${CookieUtils.getStaffId()}&op_id=${currentOpId}`;
    Services.sendBackendRequest({ apiData: APIData.getPastAppointmentHistory, uriValues: [clinicId, patientId], params: finalParams },
      APIToken.GET_APPOINTMENT_HISTORY, processSuccess, processError);
  };

  const raiseGetAppointmentDetails = (loadPastHistory) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getAppointmentDetails, uriValues: [clinicId, appointmentId] },
      APIToken.GET_APPOINTMENT_DETAILS, processSuccess, processError, { isLoadPastHistory: loadPastHistory });
  };

  const raiseGetPastIndividualAppointment = (appId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getAppointmentDetails, uriValues: [clinicId, appId] },
      APIToken.GET_PAST_APPOINTMENTS_DETAILS, processSuccess, processError, { appointment_id: appId });
  };

  const raiseUpdateCurrentDiagnosisDetails = (appId, isPast, finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyAppointmentDetails, uriValues: [clinicId, appId], params: finalParams },
      APIToken.MODIFY_APPOINTMENT_DETAILS, processSuccess, processError, { isPast: isPast, appointmentId: appId });
  };

  const raisePunchOutRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.checkOutPatient, uriValues: [clinicId, appointmentId] },
      APIToken.CHECK_OUT, processSuccess, processError);
  };

  const hideShowDiv = (id) => {
    switch (id) {
    case 1:
      setIsPatientDetailsVisible(!isPatientDetailsVisible);
      break;
    case 2:
      setIsPatientParametersVisible(!isPatientParametersVisible);
      break;
    case 3:
      setIsCurrentAppointmentDetailsVisible(!isCurrentAppointmentDetailsVisible);
      break;
    case 4:
      setIsPastAppointmentDetailsVisible(!isPastAppointmentDetailsVisible);
      break;
    };
  };

  const getPatientBasicDetails = () => {
    return (
      <Grid item container xs={12} sx={{ backgroundColor: 'white', mb: 2, pl: 1 }} justifyContent='center' alignItems='center'>
        <Grid item container xs={12} sx={{ pl: 1, mt: 1 }} alignItems='center'>
          <Grid item xs={7.5} sm={9} lg={9.9}>
            <InputLabel>
              Basic Information
            </InputLabel>
          </Grid>
          <Grid item xs={1.5} sm={1} lg={0.7}>
            <Tooltip title='Check-Out'>
              <IconButton sx={{ backgroundColor: '#FEE3EC' }} color='error' onClick={() => raisePunchOutRequest()}>
                <LogoutIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1.5} sm={1} lg={0.7}>
            <Tooltip title='Refresh Appointment Details'>
              <IconButton color="primary" onClick={() => raiseGetAppointmentDetails(false)}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1.5} sm={1} lg={0.7}>
            <Tooltip title='Hide'>
              <IconButton color='inherit' onClick={() => hideShowDiv(1)}>
                {isPatientDetailsVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {appointmentDetails.patient_details != undefined && isPatientDetailsVisible ? (
          <Grid item container xs={12}>
            <Grid item xs={12} sm={12} lg={6}>
              <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }}>
                {appointmentDetails.patient_details.patient_name},{appointmentDetails.patient_details.sex},
                {appointmentDetails.patient_details.age.display}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} >
              <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
                Phone: {appointmentDetails.patient_details.phone}
              </Typography>
            </Grid>
            {appointmentDetails.visit_details != undefined && appointmentDetails.visit_details.length > 0 ? (
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
                  Wait Time: {appointmentDetails.visit_details[0].wait_time != undefined ?
                    appointmentDetails.visit_details[0].wait_time.value : '' }
                </Typography>
              </Grid>
            ) : ''}
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  const getPatientParametersDetails = () => {
    return (
      <Grid item container xs={12} sx={{ backgroundColor: 'white', mb: 2, pl: 1 }} justifyContent='center' alignItems='center'>
        <Grid item container xs={12} sx={{ pl: 1 }} alignItems='center'>
          <Grid item xs={9} sm={10} lg={11}>
            <InputLabel>
              Patient Parameters
            </InputLabel>
          </Grid>
          <Grid item xs={1.5} sm={1} lg={0.5}>
            <Tooltip title='Edit Patient Parameters'>
              <IconButton color='primary' onClick={(e) => handlePatientParameterDialogClick(e, true)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1.5} sm={1} lg={0.5}>
            <Tooltip title='Hide/Show'>
              <IconButton color='inherit' onClick={() => hideShowDiv(2)}>
                {isPatientParametersVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {isPatientParametersVisible ? (
          <Grid item container xs={12}>
            {appointmentDetails.patient_parameters_details != undefined && appointmentDetails.patient_parameters_details != null ? (
              Object.keys(appointmentDetails.patient_parameters_details.patient_parameters).map((key) => (
                <Grid item xs={12} sm={6} lg={3} key={key}>
                  <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }}>
                    {Utils.getLabelToDisplay(key)}: {appointmentDetails.patient_parameters_details.patient_parameters[key]}
                  </Typography>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" display="block" gutterBottom sx={{ p: 1 }}>
                  Patient Parameters not found. Click Edit and record parameters
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  const handlePatientParameterDialogClick = (e, isOpen) => {
    e.stopPropagation();
    setIsPatientParameterDialogOpen(isOpen);
    if (!isOpen) {
      raiseGetAppointmentDetails();
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTabValue(newValue);
  };

  const handlePastTabChange = (newValue, appId) => {
    const obj = cloneDeep(pastTabValue);
    obj[appId] = newValue;
    setPastTabValue(obj);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  const handleCurrentAppTextChange = (event, visitNo) => {
    const obj = currentDiagnosisValues[visitNo];
    obj[event.target.name] = event.target.value;
    setCurrentDiagnosisValues({ ...currentDiagnosisValues,
      [visitNo]: obj });
  };

  const handlePastAppTextChange = (event, key) => {
    const obj = pastDiagnosisValues[key];
    obj[event.target.name] = event.target.value;
    setPastDiagnosisValues({ ...pastDiagnosisValues,
      [key]: obj });
  };

  const handleCurrentAppResetClick = (visitNo) => {
    if (appointmentDetails.visit_notes != undefined) {
      const visitNotes = appointmentDetails.visit_notes.filter((visit) => visit.visit_no === visitNo)[0];
      const obj = currentDiagnosisValues[visitNo];
      obj.diagnosis = visitNotes.diagnosis;
      obj.treatment = visitNotes.treatment;
      setCurrentDiagnosisValues({ ...currentDiagnosisValues,
        [visitNo]: obj });
    }
  };

  const handlePastAppResetClick = (appId, visitNo) => {
    if (pastAppointmentDetailedObj[appId].visit_notes != undefined) {
      const visitNotes = pastAppointmentDetailedObj[appId].visit_notes.filter((visit) => visit.visit_no === visitNo)[0];
      const key = appId + ':' + visitNo;
      const obj = pastDiagnosisValues[key];
      obj.diagnosis = visitNotes.diagnosis;
      obj.treatment = visitNotes.treatment;
      setPastDiagnosisValues({ ...pastDiagnosisValues,
        [key]: obj });
    }
  };

  const getCurrentModifiedParams = (visitNo) => {
    const modifiedParams = {};
    const obj = currentDiagnosisValues[visitNo];
    if (obj == undefined) {
      throw new Error('Invalid Inputs');
    }

    const visitNotes = appointmentDetails.visit_notes.filter((visit) => visit.visit_no == visitNo)[0];
    if (validateStringForNull(obj.diagnosis) && visitNotes.diagnosis !== obj.diagnosis) {
      modifiedParams.diagnosis = obj.diagnosis;
    }

    if (validateStringForNull(obj.treatment) && visitNotes.treatment !== obj.treatment) {
      modifiedParams.treatment = obj.treatment;
    }

    if (Object.keys(modifiedParams).length > 0 ) {
      modifiedParams.visit_no = visitNo;
    } else {
      throw new Error('Nothing to update! No new changes');
    }
    return modifiedParams;
  };

  const getPastModifiedParams = (appId, visitNo) => {
    const modifiedParams = {};
    const obj = pastDiagnosisValues[appId + ':' + visitNo];
    if (obj == undefined) {
      throw new Error('Invalid Inputs');
    }

    const visitNotes = pastAppointmentDetailedObj[appId].visit_notes.filter((visit) => visit.visit_no == visitNo)[0];
    if (validateStringForNull(obj.diagnosis) && visitNotes.diagnosis !== obj.diagnosis) {
      modifiedParams.diagnosis = obj.diagnosis;
    }

    if (validateStringForNull(obj.treatment) && visitNotes.treatment !== obj.treatment) {
      modifiedParams.treatment = obj.treatment;
    }

    if (Object.keys(modifiedParams).length > 0 ) {
      modifiedParams.visit_no = visitNo;
    } else {
      throw new Error('Nothing to update! No new changes');
    }
    return modifiedParams;
  };

  const handleCurrentAppDetailsUpdate = (visitNo) => {
    setIsLoading(true);
    try {
      const params = getCurrentModifiedParams(visitNo);
      raiseUpdateCurrentDiagnosisDetails(appointmentId, false, params);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to update diagnosis/treatment details');
      setIsLoading(false);
    }
  };

  const handlePastAppDetailsUpdate = (appId, visitNo) => {
    setIsLoading(true);
    try {
      const params = getPastModifiedParams(appId, visitNo);
      raiseUpdateCurrentDiagnosisDetails(appId, true, params);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to update diagnosis/treatment details');
      setIsLoading(false);
    }
  };

  const getTabContent = (visitNo) => {
    const index = appointmentDetails.visit_details.findIndex((visit) => visit.visit_no === visitNo);
    const visitDetails = appointmentDetails.visit_details[index];
    return (
      <Grid container sx={{ p: 4, pt: 2 }} justifyContent='space-between'>
        <Grid item container xs={12} sx={{ mb: 2 }} alignItems='center'>
          <Grid item xs={12} sm={6} lg={3} >
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
              Visit Time: {visitDetails.clinic_visit_time.display}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2.5}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }}>
              In Time: {visitDetails.in_time != undefined ? visitDetails.in_time.display : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2.5}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
              Wait Time: {visitDetails.wait_time != undefined ? visitDetails.wait_time.value : ''}
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={6} lg={4}>
            {index == 0 ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5" display="block" gutterBottom sx={{ p: 0, justifyContent: 'flex-end', display: 'flex', mr: 1 }} >
                    Consulting Time:
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                  <TSTimer
                    time={visitDetails.consulting_time != undefined ? visitDetails.consulting_time.value : ''}/>
                </Grid>
              </>
            ):(
              <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
                Consulting Time: {visitDetails.consulting_time != undefined ? visitDetails.consulting_time.display: ''}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={5.75} sx={{ mb: 2 }}>
          <TextField
            id="outlined"
            name="diagnosis"
            size='small'
            autoComplete ='off'
            value={currentDiagnosisValues[visitNo] != undefined ? (currentDiagnosisValues[visitNo].diagnosis ?? '') : ''}
            sx={{ minHeight: '50px' }}
            multiline
            onChange={(event) => handleCurrentAppTextChange(event, visitNo)}
            rows={4}
            label="Diagnosis"
            fullWidth
          />
        </Grid>
        <Grid item container xs={12} md={5.75} sx={{ mb: 2 }}>
          <TextField
            id="outlined"
            name="treatment"
            value={currentDiagnosisValues[visitNo] != undefined ? (currentDiagnosisValues[visitNo].treatment ?? '') : ''}
            size='small'
            autoComplete ='off'
            onChange={(event) => handleCurrentAppTextChange(event, visitNo)}
            rows={4}
            multiline
            label="Treatment"
            fullWidth
          />
        </Grid>
        <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 1 }} >
          <Grid item>
            <Button
              type="submit"
              variant="text"
              size="small"
              onClick={() => handleCurrentAppResetClick(visitNo)}
            >
            Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="small"
              onClick={() => handleCurrentAppDetailsUpdate(visitNo)}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getPastTabContent = (appId, visitNo) => {
    const visitDetails = pastAppointmentDetailedObj[appId].visit_details.filter((visit) => visit.visit_no === visitNo)[0];
    const key = appId + ':' + visitNo;
    return (
      <Grid container sx={{ p: 4, pt: 2 }} justifyContent='space-between'>
        <Grid item container xs={12} sx={{ mb: 2 }} justifyContent='space-between'>
          <Grid item xs={12} md={5.75} >
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
              Clinic Visit Time: {visitDetails.clinic_visit_time.display}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5.75}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }}>
              In Time: {visitDetails.in_time.display}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5.75}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
              Wait Time: {visitDetails.wait_time != undefined ? visitDetails.wait_time.value : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5.75}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} >
              Consulting Time: {visitDetails.consulting_time != undefined ? visitDetails.consulting_time.value : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={5.75} sx={{ mb: 2 }}>
          <TextField
            id="outlined"
            name="diagnosis"
            size='small'
            value={pastDiagnosisValues[key] != undefined ? (pastDiagnosisValues[key].diagnosis ?? '') : ''}
            sx={{ minHeight: '50px' }}
            multiline
            autoComplete ='off'
            onChange={(event) => handlePastAppTextChange(event, key)}
            rows={4}
            label="Diagnosis"
            fullWidth
          />
        </Grid>
        <Grid item container xs={12} md={5.75} sx={{ mb: 2 }}>
          <TextField
            id="outlined"
            name="treatment"
            value={pastDiagnosisValues[key] != undefined ? (pastDiagnosisValues[key].treatment ?? '') : ''}
            size='small'
            onChange={(event) => handlePastAppTextChange(event, key)}
            rows={4}
            multiline
            autoComplete ='off'
            label="Treatment"
            fullWidth
          />
        </Grid>
        <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 1 }} >
          <Grid item>
            <Button
              type="submit"
              variant="text"
              size="small"
              onClick={() => handlePastAppResetClick(appId, visitNo)}
            >
            Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="small"
              onClick={() => handlePastAppDetailsUpdate(appId, visitNo)}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getCurrentAppointmentDetails = () => {
    return (
      <Grid item container xs={12} sx={{ backgroundColor: 'white', mb: 2, pl: 1 }} justifyContent='center' alignItems='center'>
        <Grid item container xs={12} sx={{ pl: 1 }} alignItems='center'>
          <Grid item xs={10.5} sm={11} lg={11.5}>
            <InputLabel>
              Current Appointment Details
            </InputLabel>
          </Grid>
          <Grid item xs={1.5} sm={1} lg={0.5}>
            <Tooltip title='Hide/Show'>
              <IconButton color='inherit' onClick={() => hideShowDiv(3)}>
                {isCurrentAppointmentDetailsVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {isCurrentAppointmentDetailsVisible && appointmentDetails.visit_details != undefined && appointmentDetails.visit_details.length > 0 ? (
          <Grid item xs={12}>
            <Tabs value={currentTabValue}
              sx={{ backgroundColor: 'white', width: '100%', border: '1px' }}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              indicatorColor="primary">
              {appointmentDetails.visit_details.map((visit) => (
                <Tab key={visit.visit_no}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Visit {visit.visit_no}
                    </Box>
                  } {...a11yProps(0)} value={visit.visit_no}/>
              ))}
            </Tabs>
            {appointmentDetails.visit_notes.map((visit) => (
              <TabPanel key={visit.visit_no} value={currentTabValue} index={visit.visit_no} node={getTabContent(visit.visit_no)}/>
            ))}
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  const handleAccordionChange = (appId) => (event, isExpanded) => {
    if (pastAppointmentDetailedObj[appId] != undefined) {
      setExpandedAccordion(isExpanded ? appId : '');
    } else {
      raiseGetPastIndividualAppointment(appId);
    }
  };

  const getPatientParametersContent = (obj) => {
    return (
      <Card
        sx={{
          m: 1,
          display: 'flex',
          alignItems: 'flex-start'
        }}
      >
        <CardContent>
          <Grid container justifyContent='space-between' rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12}>
              <Typography variant='h5'>
                Patient Parameters
              </Typography>
            </Grid>
            {obj == undefined ? (
              <Grid item xs={12}>
                <Typography variant="h6" display="block" gutterBottom sx={{ p: 1 }}>
                  Patient Parameters not recorded
                </Typography>
              </Grid>
            ) : (
              Object.keys(obj).map((key) => (
                <Grid item container xs={12} sm={4} key={key} >
                  <Grid item xs={12} sm={6}>
                    <Typography variant='h6'>
                      {Utils.getLabelToDisplay(key)}
                    </Typography>
                    <InputBase
                      sx={{
                        backgroundColor: 'white',
                        transitionDuration: '.2s',
                        cursor: 'pointer',
                        fontWeight: 500,
                        zIndex: 2,
                        borderRadius: 3,
                        p: 1
                      }}
                      variant="outlined"
                      multiline
                      rows={2}
                      size="small"
                      fullWidth
                      value={obj[key]}
                      disabled
                    />
                  </Grid>
                </Grid>
              ))
            ) }
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const getPastAppointmentDetails = () => {
    return (
      <Grid item container xs={12} sx={{ backgroundColor: 'white', pl: 1 }} justifyContent='center' alignItems='center'>
        <Grid item container xs={12} sx={{ pl: 1 }} alignItems='center'>
          <Grid item xs={10.5} sm={11} lg={11.5}>
            <InputLabel>
              View Past Appointment History
            </InputLabel>
          </Grid>
          <Grid item xs={1.5} sm={1} lg={0.5}>
            <Tooltip title='Hide/Show'>
              <IconButton color='inherit' onClick={() => hideShowDiv(4)}>
                {isPastAppointmentDetailsVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {isPastAppointmentDetailsVisible && pastAppointmentDetails != undefined ? (
          <Grid item xs={12} sx={{ p: 2 }}>
            {pastAppointmentDetails.length > 0 ? (
              pastAppointmentDetails.map((history) => (
                <Accordion expanded={expandedAccordion === history.appointment_id}
                  key={history.appointment_id} onChange={handleAccordionChange(history.appointment_id)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {history.op_date.display}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      {history.session_start_time.display} - {history.session_end_time.display}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {pastAppointmentDetailedObj[history.appointment_id] != undefined ? (
                      <>
                        {getPatientParametersContent(pastPatientParameters[history.appointment_id])}
                        <Tabs value={pastTabValue[history.appointment_id]}
                          sx={{ backgroundColor: 'white', width: '100%', border: '1px' }}
                          onChange={(event, newValue) => handlePastTabChange(newValue, history.appointment_id)}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          indicatorColor="primary">
                          {pastAppointmentDetailedObj[history.appointment_id].visit_details.map((visit) => (
                            <Tab key={visit.visit_no}
                              label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  Visit {visit.visit_no}
                                </Box>
                              } {...a11yProps(0)} value={visit.visit_no}/>
                          ))}
                        </Tabs>
                        {pastAppointmentDetailedObj[history.appointment_id].visit_notes.map((visit) => (
                          <TabPanel key={visit.visit_no} value={pastTabValue[history.appointment_id]}
                            index={visit.visit_no} node={getPastTabContent(history.appointment_id, visit.visit_no)}/>
                        ))}
                      </>
                    ) : ''}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography variant="h4" sx={{ textAlign: 'center', p: 5 }}>
                No Visit history
              </Typography>
            )}
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid container>
        {getPatientBasicDetails()}
        {getPatientParametersDetails()}
        {getCurrentAppointmentDetails()}
        {getPastAppointmentDetails()}
      </Grid>
      <TSSideBarDialog
        open={isPatientParameterDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => handlePatientParameterDialogClick(e, false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Patient Parameter Details
            </Typography>
          </Toolbar>
        </AppBar>
        {validateStringForNull(appointmentId) ?
          <PatientParameterDetails clinicId={clinicId} appointmentId={appointmentId} /> : ''}
      </TSSideBarDialog>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ManageSingleAppointment.propTypes = {
  clinicId: PropTypes.string.isRequired,
  appointmentId: PropTypes.string.isRequired,
  onCheckOut: PropTypes.func
};

export default ManageSingleAppointment;
