import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, List, ListItemText, ListItemButton, Typography, Divider, IconButton, DialogTitle,
  TextField, DialogContent, DialogActions, Tooltip, AppBar, Toolbar, Paper, Stack, Box, Table, TableHead,
  TableRow, TableCell, tableCellClasses, InputLabel, TableBody, Tabs, Tab, Snackbar, Alert
} from '@mui/material';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { Utils } from '../../utils/UtilFunctions';
import TSSnackbar from '../tscomponents/TSSnackbar';
import Services from '../../utils/Services';
import { validateStringForNull } from '../../utils/FieldValidations';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import CreateAppointment from './CreateAppointment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { EVENT_ID, REFERRED_BY } from '../../utils/EnumDefinitions';
import PatientParameterDetails from './PatientParameterDetails';
import { cloneDeep } from 'lodash';
import { subscribe, unsubscribe } from '../../utils/SSEHelper';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CreateModifyPatient from '../managepatient/CreateModifyPatient';
import APIData from '../../utils/APIData';
import ReplayIcon from '@mui/icons-material/Replay';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import OpSummary from '../opsummary/OpSummary';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageSingleAppointment from './ManageSingleAppointment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import TSTimer from '../tscomponents/TSTimer';
import ModifyAppointment from './ModifyAppointment';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

function TabPanel(props) {
  const { node, value, index } = props;
  if (value == index) {
    return (node);
  } else {
    return <React.Fragment/>;
  }
}

TabPanel.propTypes = {
  node: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ManageAppointmentTabContent = ({ clinicId, opId, isDoctor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [opDetails, setOpDetails] = useState({
    op_id: opId,
    op_end_time: undefined,
    op_start_time: undefined,
    doctor_id: '',
    doctor_name: '',
    session_name: '',
    op_date: '',
    session_id: ''
  });
  const [currentVisitTab, setCurrentVisitTab] = useState(1);
  const [appointmentDetails, setAppointmentDetails] = useState(undefined);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [checkedInPatientAppId, setCheckedInPatientAppId] = useState(undefined);

  const [notVisitedAppointmentList, setNotVisitedAppointmentList] = useState([]);
  const [visitedAppointmentList, setVisitedAppointmentList] = useState([]);
  const [cancelledAppointmentList, setCancelledAppointmentList] = useState([]);
  const [completedAppointmentList, setCompletedAppointmentList] = useState([]);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState({
    notVisited: undefined,
    visited: undefined,
    cancelled: undefined,
    completed: undefined,
    forModify: undefined
  });
  const [cancelReason, setCancelReason] = useState('');

  const [isDialogOpen, setIsDialogOpen] = useState({
    notVisited: false,
    cancelled: false,
    completed: false,
    cancelConfirmation: false,
    newAppointment: false,
    opSummary: false,
    patientParameters: false,
    endOPConfirmation: false,
    modifyPatient: false,
    startOPErrorCustomSnackBar: false,
    appointmentDetailsView: false
  });

  const [isReMarkVisitOperation, setIsReMarkVisitOperation] = useState(false);
  const [startOpErrorObj, setStartOpErrorObj] = useState({});
  const [isWaitingPatientsListVisible, setIsWaitingPatientsListVisible] = useState(true);

  const APIToken = {
    GET_YET_TO_VISIT_APPOINTMENTS: 'CMAT01',
    GET_CANCELLED_APPOINTMENTS: 'CMA02',
    GET_CONSULTED_APPOINTMENTS: 'CMA03',
    GET_VISITED_APPOINTMENTS: 'CMA04',
    GET_APPOINTMENT_DETAILS: 'CMA05',
    GET_OP: 'CMA06',
    START_OP: 'CMA07',
    END_OP: 'CMA08',
    RESTART_OP: 'CMA09',
    MARK_VISIT: 'CMA10',
    CHECK_IN: 'CMA11',
    CHECK_OUT: 'CMA12',
    CANCEL_APPOINTMENT: 'CMA13',
    RESTORE_APPOINTMENT: 'CMA14'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (opId != undefined) {
      raiseGetOpRequest(false, true);
    }
  }, []);

  window.addEventListener('beforeunload', function(event) {
    unsubscribe();
  });

  const getEventData = (data) => {
    switch (data) {
    case EVENT_ID.CREATE_APPOINTMENT:
      raiseGetNotVisitedAppointmentList();
      break;
    case EVENT_ID.CANCEL_APPOINTMENT:
    case EVENT_ID.MARK_CLINIC_VISIT:
      raiseGetNotVisitedAppointmentList();
      raiseGetVisitedAppointmentList();
      break;
    case EVENT_ID.START_OP:
    case EVENT_ID.END_OP:
      raiseGetOpRequest(true, false);
      break;
    case EVENT_ID.CHECK_IN:
    case EVENT_ID.CHECK_OUT:
      raiseGetVisitedAppointmentList();
      break;
    }
  };

  const startEndOPEventHandlerFunc = () => {
    raiseGetOpRequest(false, false);
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    let hideLoading = false;
    if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_YET_TO_VISIT_APPOINTMENTS ) {
      hideLoading = true;
      setNotVisitedAppointmentList(response.data);
      if (isDoctor) {
        if (callbackValues.isShowDialog) {
          setIsDialogOpen({ ...isDialogOpen, notVisited: true });
        }
      }
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_CANCELLED_APPOINTMENTS ) {
      hideLoading = true;
      setCancelledAppointmentList(response.data);
      setSelectedAppointmentId({ ...selectedAppointmentId,
        notVisited: undefined,
        visited: undefined,
        cancelled: undefined,
        completed: undefined
      });
      setIsDialogOpen({ ...isDialogOpen,
        cancelled: true
      });
    } else if (apiData == APIData.startOP && apiToken == APIToken.START_OP) {
      showSnackBar('success', response.message ?? 'OP started');
      raiseGetOpRequest(false, false);
    } else if (apiData == APIData.endOP && apiToken == APIToken.END_OP) {
      if (Object.keys(startOpErrorObj).length == 0) {
        setIsDialogOpen({ ...isDialogOpen,
          opSummary: true,
          endOPConfirmation: false
        });
      } else {
        setIsDialogOpen({ ...isDialogOpen, startOPErrorCustomSnackBar: false });
      }
      showSnackBar('success', response.message ?? 'OP ended');
      raiseGetOpRequest(false, false);
      setStartOpErrorObj({});
    } else if (apiData == APIData.restartOP && apiToken == APIToken.RESTART_OP) {
      showSnackBar('success', response.message ?? 'OP restarted successfully');
      raiseGetOpRequest(false, false);
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_CONSULTED_APPOINTMENTS) {
      hideLoading = true;
      setCompletedAppointmentList(response.data);
      setSelectedAppointmentId({ ...selectedAppointmentId,
        notVisited: undefined,
        visited: undefined,
        cancelled: undefined,
        completed: undefined
      });
      setIsDialogOpen({ ...isDialogOpen,
        completed: true
      });
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_VISITED_APPOINTMENTS) {
      hideLoading = true;
      setVisitedAppointmentList(response.data);
      if (response.data.length > 0) {
        if (isDoctor == true) {
          setSelectedAppointmentId({ ...selectedAppointmentId,
            visited: response.data[0].appointment_id
          });
        }
      }
      if (isDoctor == true) {
        const arr = response.data.filter((obj) => obj.in_time != undefined);
        if (arr.length > 0) {
          setCheckedInPatientAppId(arr[0].appointment_id);
        } else {
          setCheckedInPatientAppId(undefined);
          if (Utils.isMobile()) {
            setIsWaitingPatientsListVisible(true);
          }
        }
      }
    } else if (apiData == APIData.markPatientVisit && apiToken == APIToken.MARK_VISIT ) {
      if (isReMarkVisitOperation == true) {
        setSelectedAppointmentId({ ...selectedAppointmentId,
          completed: undefined
        });
      } else {
        setSelectedAppointmentId({ ...selectedAppointmentId,
          notVisited: undefined
        });
      }
      setIsReMarkVisitOperation(false);
      raiseGetNotVisitedAppointmentList();
      raiseGetVisitedAppointmentList();
      showSnackBar('success', response.message ?? 'Appointment successfully marked for consultation');
    } else if (apiData == APIData.checkInPatient && apiToken == APIToken.CHECK_IN ) {
      setSelectedAppointmentId({ ...selectedAppointmentId,
        visited: undefined
      });
      raiseGetVisitedAppointmentList();
    } else if (apiData == APIData.checkOutPatient && apiToken == APIToken.CHECK_OUT ) {
      setSelectedAppointmentId({ ...selectedAppointmentId,
        visited: undefined
      });
      raiseGetVisitedAppointmentList();
    } else if (apiData == APIData.cancelAppointment && apiToken == APIToken.CANCEL_APPOINTMENT ) {
      setSelectedAppointmentId({ ...selectedAppointmentId,
        notVisited: undefined,
        visited: undefined
      });
      setCancelReason('');
      raiseGetNotVisitedAppointmentList();
      raiseGetVisitedAppointmentList();
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      hideLoading = true;
      setAppointmentDetails(response.data);
      setIsEditModeOn(false);
      setIsDialogOpen({ ...isDialogOpen, appointmentDetailsView: true });
      setSelectedAppointmentId({ ...selectedAppointmentId,
        forModify: callbackValues.appointmentId
      });
    } else if (apiData == APIData.getOp && apiToken == APIToken.GET_OP ) {
      raiseGetNotVisitedAppointmentList();
      raiseGetVisitedAppointmentList();
      hideLoading = true;
      const newTabInfo = cloneDeep(opDetails);
      if (response.data.basic_details != undefined) {
        newTabInfo.session_name = response.data.basic_details.session_start_time.display + ' - ' +
        response.data.basic_details.session_end_time.display;
        newTabInfo.op_date = response.data.basic_details.op_date;
        newTabInfo.doctor_name = response.data.doctor_details.doctor_name;
        newTabInfo.doctor_id = response.data.doctor_details.doctor_id;
        newTabInfo.session_id = response.data.basic_details.session_id;
        if (callbackValues.isSubscribe) {
          if (response.data.basic_details.register_for_sse == true) {
            subscribe(clinicId, opId, getEventData, startEndOPEventHandlerFunc);
          }
          raiseRefreshAPIrequest(response.data.op_end_time);
        }
        if (response.data.basic_details.op_end_time != undefined) {
          newTabInfo.op_start_time = response.data.basic_details.op_start_time.display;
          newTabInfo.op_end_time = response.data.basic_details.op_end_time.display;
          if (callbackValues.showOPSummary) {
            if (Object.keys(startOpErrorObj).length == 0) {
              setIsDialogOpen({ ...isDialogOpen,
                opSummary: true
              });
            }
            showSnackBar('success', response.message ?? 'OP consultation has ended successfully.');
          }
        } else if (response.data.basic_details.op_start_time != undefined) {
          newTabInfo.op_start_time= response.data.basic_details.op_start_time.display;
          newTabInfo.op_end_time = undefined;
        }
        if (response.data.basic_details.register_for_sse != true ) {
          unsubscribe();
        }
        setOpDetails(newTabInfo);
      }
    } else if (apiData == APIData.restoreAppointment && apiToken == APIToken.RESTORE_APPOINTMENT ) {
      hideLoading = true;
      setSelectedAppointmentId({ ...selectedAppointmentId,
        cancelled: undefined
      });
      showSnackBar('success', response.message ?? 'Appointment restored successfully');
    }
    if (hideLoading) {
      setIsLoading(false);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    let showSnackBarMsg = true;
    if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_YET_TO_VISIT_APPOINTMENTS) {
      defaultMsg = 'Failed to retrieve Appointment list';
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_CANCELLED_APPOINTMENTS ) {
      defaultMsg = 'Failed to retrieve Appointment list';
    } else if (apiData = APIData.startOP && apiToken == APIToken.START_OP ) {
      if (err.status == 422 && err.data != undefined) {
        showSnackBarMsg = false;
        const obj = {
          message: err.message,
          data: err.data
        };
        setStartOpErrorObj(obj);
        setIsDialogOpen({ ...isDialogOpen, startOPErrorCustomSnackBar: true });
      } else {
        defaultMsg = 'Failed to start OP';
      }
    } else if (apiData = APIData.endOP && apiToken == APIToken.END_OP) {
      defaultMsg = 'Failed to end OP';
    } else if (apiData = APIData.restartOP && apiToken == APIToken.RESTART_OP) {
      defaultMsg = 'Failed to Restart OP';
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_CONSULTED_APPOINTMENTS ) {
      defaultMsg = 'Failed to retrieve Appointment list';
    } else if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_VISITED_APPOINTMENTS ) {
      defaultMsg = 'Failed to retrieve Appointment list';
    } else if (apiData == APIData.markPatientVisit && apiToken == APIToken.MARK_VISIT ) {
      defaultMsg = 'Failed to mark patient visit';
    } else if (apiData == APIData.checkInPatient && apiToken == APIToken.CHECK_IN ) {
      defaultMsg = 'Failed to check-in patient';
    } else if (apiData == APIData.checkOutPatient && apiToken == APIToken.CHECK_OUT ) {
      defaultMsg = 'Failed to check-out patient';
    } else if (apiData == APIData.cancelAppointment && apiToken == APIToken.CANCEL_APPOINTMENT ) {
      defaultMsg = 'Failed to cancel Appointment';
    } else if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      defaultMsg = 'Failed to Get Appointment Details';
    } else if (apiData == APIData.getOp && apiToken == APIToken.GET_OP ) {
      defaultMsg = 'Failed to get OP details';
    } else if (apiData == APIData.restoreAppointment && apiToken == APIToken.RESTORE_APPOINTMENT ) {
      defaultMsg = 'Failed to restore Appointment';
    }
    if (showSnackBarMsg == true) {
      showSnackBar('error', err.message ?? defaultMsg);
    }
    setIsLoading(false);
  };

  const raiseRefreshAPIrequest = (opEndTime) => {
    if (opEndTime == undefined) {
      return;
    }
    const intervalId = setInterval(() => {
      if (isDoctor != true) {
        raiseGetNotVisitedAppointmentList(false, true);
      }
      raiseGetVisitedAppointmentList(true);
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  };

  const hideShowWaitingPatients = () => {
    setIsWaitingPatientsListVisible(!isWaitingPatientsListVisible);
  };

  const raiseGetNotVisitedAppointmentList = (showDialog, isBackgroundAPI) => {
    if (isBackgroundAPI != true) {
      setIsLoading(true);
    }
    const finalParams = `op_id=${opDetails.op_id}&appointment_type=2`;
    Services.sendBackendRequest({ apiData: APIData.getAppointmentList, uriValues: [clinicId], params: finalParams },
      APIToken.GET_YET_TO_VISIT_APPOINTMENTS, processSuccess, processError, { isShowDialog: showDialog });
  };

  const raiseGetCancelledAppointmentList = () => {
    setIsLoading(true);
    const finalParams = `op_id=${opDetails.op_id}&appointment_type=6`;
    Services.sendBackendRequest({ apiData: APIData.getAppointmentList, uriValues: [clinicId], params: finalParams },
      APIToken.GET_CANCELLED_APPOINTMENTS, processSuccess, processError);
  };

  const raiseStartOpRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.startOP, uriValues: [clinicId, opDetails.op_id] },
      APIToken.START_OP, processSuccess, processError);
  };

  const raiseEndOpRequest = (opId) => {
    setIsLoading(true);
    setIsDialogOpen({ ...isDialogOpen,
      endOPConfirmation: false
    });
    Services.sendBackendRequest({ apiData: APIData.endOP, uriValues: [clinicId, opId] },
      APIToken.END_OP, processSuccess, processError);
  };

  const handleRestartOpClick = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.restartOP, uriValues: [clinicId, opDetails.op_id] },
      APIToken.RESTART_OP, processSuccess, processError);
  };

  const raiseGetCompletedAppointmentList = () => {
    setIsLoading(true);
    const finalParams = `op_id=${opDetails.op_id}&appointment_type=4`;
    Services.sendBackendRequest({ apiData: APIData.getAppointmentList, uriValues: [clinicId], params: finalParams },
      APIToken.GET_CONSULTED_APPOINTMENTS, processSuccess, processError);
  };

  const raiseGetVisitedAppointmentList = ( isBackgroundAPI) => {
    if (isBackgroundAPI != true) {
      setIsLoading(true);
    }
    const finalParams = `op_id=${opDetails.op_id}&appointment_type=5`;
    Services.sendBackendRequest({ apiData: APIData.getAppointmentList, uriValues: [clinicId], params: finalParams },
      APIToken.GET_VISITED_APPOINTMENTS, processSuccess, processError);
  };

  const raiseMarkVisitRequest = (appointmentId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.markPatientVisit, uriValues: [clinicId, appointmentId] },
      APIToken.MARK_VISIT, processSuccess, processError);
  };

  const raisePunchInRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.checkInPatient, uriValues: [clinicId, selectedAppointmentId.visited] },
      APIToken.CHECK_IN, processSuccess, processError);
  };

  const raisePunchOutRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.checkOutPatient, uriValues: [clinicId, selectedAppointmentId.visited] },
      APIToken.CHECK_OUT, processSuccess, processError);
  };

  const raiseCancelApmtRequest = (apmtId, reason) => {
    setIsLoading(true);
    setIsDialogOpen({ ...isDialogOpen,
      cancelConfirmation: false
    });
    const finalParams = {
      reason: reason
    };
    Services.sendBackendRequest({ apiData: APIData.cancelAppointment, uriValues: [clinicId, apmtId], params: finalParams },
      APIToken.CANCEL_APPOINTMENT, processSuccess, processError);
  };

  const raiseGetAppointmentDetails = (appointmentId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getAppointmentDetails, uriValues: [clinicId, appointmentId] },
      APIToken.GET_APPOINTMENT_DETAILS, processSuccess, processError, { appointmentId: appointmentId });
  };

  const getAppointmentStatusTextStyle = () => {
    const style = {
      width: 'fit-content',
      pr: 1,
      color: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      fontWeight: 'bold',
      '&::before': {
        content: '"Consulting"',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 0,
        height: '100%',
        borderRight: '4px solid #2e7d32',
        overflow: 'hidden',
        color: '#2e7d32',
        animation: 'load91371 4s linear infinite'
      },
      '@keyframes load91371': {
        '0%, 10%, 100%': {
          width: 0
        },
        '10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100%': {
          borderRightColor: 'transparent'
        },
        '11%, 21%, 31%, 41%, 51%, 61%, 71%, 81%, 91%': {
          borderRightColor: '#2e7d32'
        },
        '60%, 80%': {
          width: '100%'
        }
      }
    };
    return style;
  };

  const getSelectedCompletedAppointmentStyle = (appId) => {
    if (selectedAppointmentId.completed == appId) {
      const style = {
        color: '#205295'
      };
      return style;
    }
  };

  const handlePatientDetailsModifyClick = (patientId) => {
    setSelectedPatientId(patientId);
    setIsDialogOpen({ ...isDialogOpen, modifyPatient: true });
  };

  const handleMarkVisitClick = (e) => {
    let appId = undefined;
    setIsReMarkVisitOperation(false);
    e.stopPropagation();
    if (validateStringForNull(selectedAppointmentId.notVisited)) {
      appId = selectedAppointmentId.notVisited;
    } else if (validateStringForNull(selectedAppointmentId.completed)) {
      setIsReMarkVisitOperation(true);
      appId = selectedAppointmentId.completed;
    }
    setAppointmentDetails(undefined);
    if (validateStringForNull(appId)) {
      raiseMarkVisitRequest(appId);
    } else {
      showSnackBar('info', 'Select an Appointment to mark visit');
    }
  };

  const handlePunchIn = (e) => {
    e.stopPropagation();
    if (validateStringForNull(selectedAppointmentId.visited)) {
      raisePunchInRequest();
    }
  };

  const handleSuccessfulApmtCreation = (msg) => {
    setIsDialogOpen({ ...isDialogOpen,
      newAppointment: false
    });
    showSnackBar('success', msg ?? 'Appointment created successfully');
    if (isDoctor != true) {
      raiseGetNotVisitedAppointmentList();
    }
  };

  const handleSuccessfulApmtModification = () => {
    if (validateStringForNull(selectedAppointmentId.notVisited) &&
      selectedAppointmentId.forModify == selectedAppointmentId.notVisited) {
      raiseGetNotVisitedAppointmentList();
    } else if (validateStringForNull(selectedAppointmentId.visited) &&
      selectedAppointmentId.forModify == selectedAppointmentId.visited ) {
      raiseGetVisitedAppointmentList();
    } else if (validateStringForNull(selectedAppointmentId.completed) &&
      selectedAppointmentId.forModify == selectedAppointmentId.completed) {
      raiseGetCompletedAppointmentList();
    }
    raiseGetAppointmentDetails(selectedAppointmentId.forModify);
  };

  const handlePunchOut = (e) => {
    e.stopPropagation();
    if (validateStringForNull(selectedAppointmentId.visited)) {
      raisePunchOutRequest();
    }
  };

  const handleCancelApmtClick = (e) => {
    e.stopPropagation();
    if (validateStringForNull(selectedAppointmentId.notVisited) || validateStringForNull(selectedAppointmentId.visited)) {
      setIsDialogOpen({ ...isDialogOpen,
        cancelConfirmation: true
      });
    }
  };

  const handleCancelApmtConfirmationClick = () => {
    let apmtId = '';
    if (validateStringForNull(selectedAppointmentId.notVisited)) {
      apmtId = selectedAppointmentId.notVisited;
    } else if (validateStringForNull(selectedAppointmentId.visited)) {
      apmtId = selectedAppointmentId.visited;
    }
    let reason = cancelReason;
    if (!validateStringForNull(reason)) {
      reason = 'N/A';
    }
    if (validateStringForNull(apmtId)) {
      raiseCancelApmtRequest(apmtId, reason);
    }
  };

  const handleCancellationReasonTextChange = (event) => {
    setCancelReason(event.target.value);
  };

  const getAppointmentPrimaryText = (obj) => {
    let details = obj.patient_name;
    if (validateStringForNull(obj.sex)) {
      details+=', ' + obj.sex;
    }
    if (validateStringForNull(obj.age)) {
      details+=', ' + obj.age.display;
    }
    return details;
  };

  const getAppointmentSecondaryText = (obj) => {
    let details = 'Token No: ' + obj.token_no;
    if (obj.order_no != undefined) {
      details+=' | Order No: ' + obj.order_no;
    }
    if (obj.appointment_time != undefined) {
      details+=' | Appointment Time: ' + obj.appointment_time.display;
    }
    return details;
  };

  const handleSnackbarEndOPClick = () => {
    raiseEndOpRequest(startOpErrorObj.data.op_id);
  };

  const handleListItemSelection = (appId, type) => {
    switch (type) {
    case 1:
      setSelectedAppointmentId({ ...selectedAppointmentId,
        visited: undefined,
        cancelled: undefined,
        completed: undefined,
        notVisited: selectedAppointmentId.notVisited === appId ? undefined : appId
      });
      break;
    case 2:
      setSelectedAppointmentId({ ...selectedAppointmentId,
        notVisited: undefined,
        cancelled: undefined,
        completed: undefined,
        visited: selectedAppointmentId.visited === appId ? undefined : appId
      });
      break;
    case 3:
      setSelectedAppointmentId({ ...selectedAppointmentId,
        notVisited: undefined,
        visited: undefined,
        cancelled: undefined,
        completed: selectedAppointmentId.completed === appId ? undefined : appId
      });
      break;
    case 4:
      setSelectedAppointmentId({ ...selectedAppointmentId,
        notVisited: undefined,
        visited: undefined,
        completed: undefined,
        cancelled: selectedAppointmentId.cancelled === appId ? undefined : appId
      });
      break;
    };
  };

  const isPatientCheckedIn = () => {
    if (validateStringForNull(selectedAppointmentId.visited)) {
      const selObj = visitedAppointmentList.filter((obj) => obj.appointment_id == selectedAppointmentId.visited);
      return selObj[0].in_time != undefined;
    }
    return false;
  };

  const handleAppointmentDetailsViewClick = (e) => {
    let appId = undefined;
    e.stopPropagation();
    if (validateStringForNull(selectedAppointmentId.notVisited)) {
      appId = selectedAppointmentId.notVisited;
    } else if (validateStringForNull(selectedAppointmentId.visited)) {
      appId = selectedAppointmentId.visited;
    } else if (validateStringForNull(selectedAppointmentId.cancelled)) {
      appId = selectedAppointmentId.cancelled;
    } else if (validateStringForNull(selectedAppointmentId.completed)) {
      appId = selectedAppointmentId.completed;
    }
    setAppointmentDetails(undefined);
    if (validateStringForNull(appId)) {
      raiseGetAppointmentDetails(appId);
    } else {
      showSnackBar('info', 'Select an Appointment to view');
    }
  };

  const getTableRow = (key, value) => {
    if (value != undefined) {
      return (
        <TableRow key={key} >
          <TableCell >
            {key}
          </TableCell>
          <TableCell >
            {value.toString()}
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow></TableRow>
      );
    }
  };

  const handlePatientParameterDialogClick = (e, isOpen) => {
    e.stopPropagation();
    setIsDialogOpen({ ...isDialogOpen,
      patientParameters: isOpen
    });
  };

  const getAppointmentBasicDetails = () => {
    const basicDetails = appointmentDetails.basic_details;
    return (
      <Box>
        <PaperBase elevation = {12} sx={{
          flexGrow: 1,
          m: 5
        }} >
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <InputLabel>
                  Basic Information
                  </InputLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getTableRow('Token No', basicDetails.token_no )}
              {getTableRow('Appointment Date',
                basicDetails.appointment_date != undefined ? basicDetails.appointment_date.display : undefined )}
              {getTableRow('Appointment Time', basicDetails.appointment_time.format('hh:mm:ss A'))}
              {getTableRow('Cancelled', basicDetails.cancelled)}
              {getTableRow('Waiting Time', basicDetails.total_wait_time )}
              {getTableRow('Consulting Time', basicDetails.total_consulting_time )}
              {getTableRow('Referred By', REFERRED_BY[basicDetails.referred_by] )}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
    );
  };

  const getAppointmentPatientDetails = () => {
    const patientDetails = appointmentDetails.patient_details;
    return (
      <Box>
        <PaperBase elevation = {12} sx={{
          flexGrow: 1,
          m: 5,
          mt: 0
        }} >
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <InputLabel>
                  Patient Information
                  </InputLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getTableRow('Patient Name', patientDetails.patient_name )}
              {getTableRow('Age', patientDetails.age.display )}
              {getTableRow('Sex', patientDetails.sex )}
              {getTableRow('Phone No', patientDetails.phone )}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
    );
  };

  const getPatientParametersContent = () => {
    const patientParameters = appointmentDetails.patient_parameters_details.patient_parameters;
    return (
      <Box>
        <PaperBase elevation = {12} sx={{
          flexGrow: 1,
          m: 5,
          mt: 0
        }} >
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <InputLabel>
                  Patient Parameters
                  </InputLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientParameters != undefined && patientParameters != null ? (
                Object.keys(patientParameters).map((key) => (
                  getTableRow(Utils.getLabelToDisplay(key), patientParameters[key])
                ))
              ) : (
                ''
              )}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
    );
  };

  const getAppointmentVisitTableData = (visitNo) => {
    const visitDetails = appointmentDetails.visit_details;
    const patientAppointmentDetails = appointmentDetails.visit_notes;
    const filterpatientAppointmentDetails = patientAppointmentDetails.filter((obj) => (obj.visit_no == visitNo));
    const filterVisitDetails = visitDetails.filter((obj) => (obj.visit_no == visitNo));
    return (
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none'
          }
        }}
      >
        <TableBody>
          {getTableRow('Clinic Visit Time',
            filterVisitDetails[0].clinic_visit_time != undefined ? filterVisitDetails[0].clinic_visit_time.display : undefined)}
          {getTableRow('Wait Time', filterVisitDetails[0].wait_time != undefined ? filterVisitDetails[0].wait_time.display : undefined)}
          {getTableRow('In Time',
            filterVisitDetails[0].in_time != undefined ? filterVisitDetails[0].in_time.display : undefined)}
          {getTableRow('Out Time',
            filterVisitDetails[0].out_time != undefined ? filterVisitDetails[0].out_time.display : undefined)}
          {getTableRow('Consulting Time', filterVisitDetails[0].consulting_time != undefined ?
            filterVisitDetails[0].consulting_time.display : undefined)}
          {getTableRow('Diagnosis', filterpatientAppointmentDetails[0].diagnosis)}
          {getTableRow('Treatment', filterpatientAppointmentDetails[0].treatment)}
        </TableBody>
      </Table>
    );
  };

  const getAppointmentVisitDetails = () => {
    return (
      <Box>
        <PaperBase elevation = {12} sx={{
          flexGrow: 1,
          m: 5,
          mt: 0
        }} >
          <InputLabel sx={{ p: 2 }}>
            Visit Details
          </InputLabel>
          <Tabs value={currentVisitTab}
            sx={{ backgroundColor: 'white', width: '100%', border: '1px', mb: 3 }}
            onChange={(e, newValue) => setCurrentVisitTab(newValue)}
            scrollButtons
            variant="fullWidth"
            allowScrollButtonsMobile
            indicatorColor="primary">
            {appointmentDetails.visit_details.map((obj) => (
              <Tab key={obj.visit_no}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Visit No : {obj.visit_no}
                  </Box>
                } id={obj.visit_no} value={obj.visit_no}/>
            ))}
          </Tabs>
          {appointmentDetails.visit_details.map((obj) => (
            <TabPanel key={obj.visit_no} value={currentVisitTab} index={obj.visit_no} node={getAppointmentVisitTableData(obj.visit_no)}/>
          ))}
        </PaperBase>
      </Box>
    );
  };

  const getSelectedAppointmentDetails = () => {
    if (isEditModeOn == true) {
      return <ModifyAppointment clinicId={clinicId} appointmentId={selectedAppointmentId.forModify}
        onSuccessfulCommit={handleSuccessfulApmtModification}/>;
    }
    return (
      <React.Fragment>
        {getAppointmentBasicDetails()}
        {getAppointmentPatientDetails()}
        {appointmentDetails.patient_parameters_details != undefined ? getPatientParametersContent() : ''}
        {appointmentDetails.visit_details != undefined ? getAppointmentVisitDetails() : ''}
      </React.Fragment>
    );
  };

  const handleAppointmentDialogCloseClick = () => {
    setIsDialogOpen({ ...isDialogOpen, appointmentDetailsView: false });
    setIsEditModeOn(false);
    setSelectedAppointmentId({ ...selectedAppointmentId,
      forModify: undefined
    });
  };

  const getEditOptionButtons = () => {
    if (isEditModeOn == true) {
      return (
        <Button
          variant="text"
          startIcon={<CancelIcon />}
          color='inherit'
          onClick={() => setIsEditModeOn(false)}
        >
          Cancel
        </Button>
      );
    } else {
      return (
        <Button
          variant="text"
          startIcon={<EditIcon />}
          color='inherit'
          onClick={() => setIsEditModeOn(true)}
        >
          Edit
        </Button>
      );
    }
  };

  const getOpStatus = () => {
    if (opDetails.op_start_time == undefined) {
      return 'Not Started';
    } else if ( opDetails.op_end_time == undefined) {
      return 'Running';
    } else {
      return 'Ended';
    }
  };

  const raiseGetOpRequest = (showOPSummary, isSubscribe) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getOp, uriValues: [clinicId, opDetails.op_id] },
      APIToken.GET_OP, processSuccess, processError, { showOPSummary: showOPSummary, isSubscribe: isSubscribe });
  };

  const raiseRestoreAppointmentRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.restoreAppointment, uriValues: [clinicId, selectedAppointmentId.cancelled] },
      APIToken.RESTORE_APPOINTMENT, processSuccess, processError);
  };

  const handleSuccessfulCommit = () => {
    setIsDialogOpen({ ...isDialogOpen, modifyPatient: false });
    showSnackBar('success', 'Patient Details Modified Successfully');
    raiseGetVisitedAppointmentList();
    raiseGetNotVisitedAppointmentList();
  };

  const getEndOPConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isDialogOpen.endOPConfirmation}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>End OP</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to end this OP?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size="small"
              color='primary'
              onClick={() => setIsDialogOpen({ ...isDialogOpen, endOPConfirmation: false })}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color='primary'
              onClick={() => {raiseEndOpRequest(opDetails.op_id);}}
            >
              End OP
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Grid
        sx={{ my: 2 }}
        container
        justifyContent='space-between'
      >
        <Grid item container xs={12} md={7.8} sx={{ backgroundColor: 'white', mb: 2 }}
          marginRight={{ lg: 1 }} justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} textAlign={{ lg: 'center' }}>
              Doctor Name: {opDetails.doctor_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} >
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} textAlign={{ lg: 'center' }}>
              Date: {opDetails.op_date.display}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} textAlign={{ lg: 'center' }}>
              Time: {opDetails.session_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="h5" display="block" gutterBottom sx={{ p: 1 }} textAlign={{ lg: 'center' }}>
                Status: {getOpStatus()}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4} sx={{ backgroundColor: 'white', py: 1, mb: 2 }}
          justifyContent='flex-start' alignItems="center">
          {opDetails.op_start_time == undefined ? (
            <Grid item xs={4} textAlign='center'>
              <Tooltip title='Start OP'>
                <Button
                  variant='contained'
                  startIcon={<AlarmOnIcon/>}
                  size='small'
                  color='success'
                  onClick={() => raiseStartOpRequest()}
                >
                  Start
                </Button>
              </Tooltip>
            </Grid>
          ) : (opDetails.op_end_time == undefined ? (
            <Grid item xs={4} textAlign='center'>
              <Tooltip title='End OP'>
                <Button
                  variant='contained'
                  startIcon={<AlarmOffIcon/>}
                  size='small'
                  color='error'
                  onClick={() => setIsDialogOpen({ ...isDialogOpen, endOPConfirmation: true })}
                >
                  End
                </Button>
              </Tooltip>
            </Grid>
          ) : '')}
          {opDetails.op_end_time != undefined ? (
            <React.Fragment>
              <Grid item xs={4} textAlign='center'>
                <Tooltip title='Restart OP'>
                  <Button
                    variant='contained'
                    startIcon={<RestartAltIcon/>}
                    size='small'
                    color='success'
                    onClick={() => handleRestartOpClick()}
                  >
                    Restart
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={2} textAlign='center'>
                <Tooltip title='OP Summary'>
                  <IconButton color='primary' onClick={() => setIsDialogOpen({ ...isDialogOpen, opSummary: true })}>
                    <SummarizeIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </React.Fragment>
          ) : ''}
          {opDetails.op_end_time == undefined && isDoctor == false ? (
            <Grid item xs={2} textAlign='center'>
              <Tooltip title='Create new Appointment'>
                <IconButton color='primary' onClick={() => setIsDialogOpen({ ...isDialogOpen, newAppointment: true })}>
                  <NoteAddIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
          ) : ''}
          {isDoctor == true ? (
            <Grid item xs={2} textAlign='center'>
              <Tooltip title='Yet to visit appointments'>
                <IconButton color='primary' onClick={() => raiseGetNotVisitedAppointmentList(true)}>
                  <ListAltIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
          ) : ''}
          <Grid item xs={2} textAlign='center'>
            <Tooltip title='Cancelled Appointments'>
              <IconButton color='error' onClick={() => raiseGetCancelledAppointmentList()}>
                <EventBusyIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} textAlign='center'>
            <Tooltip title='Completed Appointments'>
              <IconButton color='success' onClick={() => raiseGetCompletedAppointmentList()}>
                <EventAvailableIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} md={isDoctor == true ? 3.5 : 5.9} sx={{ mt: 2, backgroundColor: 'white' }} marginTop={{ lg: 0 }}>
          <Grid item container xs={12} alignContent='center'>
            <Grid item xs={9} md={10} sx={{ p: 2 }}>
              <Typography variant="h4" sx={{ textAlign: 'center', mb: 1 }}>
                Waiting Patients
              </Typography>
            </Grid>
            <Grid item xs={3} md={2} sx={{ py: 1 }}>
              <Tooltip title='Refresh waiting patient list'>
                <IconButton color="primary" onClick={() => raiseGetVisitedAppointmentList()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              {Utils.isMobile() && isDoctor ? (
                <Tooltip title='Hide/Show'>
                  <IconButton color='inherit' onClick={() => hideShowWaitingPatients()}>
                    {isWaitingPatientsListVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                  </IconButton>
                </Tooltip>
              ) : ''}
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            {isWaitingPatientsListVisible ? (
              <Grid item xs={12}>
                <Divider/>
              </Grid>
            ) : ''}
          </Grid>
          {isWaitingPatientsListVisible ? (
            <Grid item xs={12}>
              <List sx={{ backgroundColor: 'white' }}>
                {visitedAppointmentList.map((obj, i) => (
                  <React.Fragment key={obj.appointment_id}>
                    <ListItemButton key={obj.appointment_id}
                      selected={selectedAppointmentId.visited == obj.appointment_id}
                      onClick={() => {handleListItemSelection(obj.appointment_id, 2);}}>
                      <Grid container>
                        <Grid item xs={9} sm={8} md={12} lg={isDoctor ? 9 : 8}>
                          <ListItemText primary={getAppointmentPrimaryText(obj)} secondary={getAppointmentSecondaryText(obj)}/>
                        </Grid>
                        <Grid item container sx={{ mt: 1 }} xs={3} sm={4} md={12} lg={isDoctor ? 3: 4} justifyContent='flex-end' alignItems='center' >
                          {obj.in_time != undefined ? (
                            <Grid item xs={12} sm={6} lg={isDoctor ? 12 : 6}>
                              <Typography sx={getAppointmentStatusTextStyle()}>
                                Consulting
                              </Typography>
                            </Grid>
                          ) : ''}
                          <Grid item xs={12} sm={6} lg={isDoctor ? 12 : 6 } sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <TSTimer
                              time={obj.consulting_time != undefined ? obj.consulting_time.value : obj.wait_time.value}/>
                          </Grid>
                        </Grid>
                        {selectedAppointmentId.visited == obj.appointment_id ? (
                          <Grid item container xs={12} sx={{ pt: 1 }}
                            backgroundColor = {selectedAppointmentId.visited === obj.appointment_id ? '' : 'white' }
                            direction='row'
                            justifyContent='flex-end'
                            alignItems='center'
                          >
                            {isPatientCheckedIn() ? '' : (
                              <Grid item>
                                <Tooltip title='Cancel Appointment'>
                                  <IconButton sx={{ mb: 2, mr: 1, backgroundColor: 'white' }} color='error'
                                    onClick={(e) => handleCancelApmtClick(e)}>
                                    <CancelIcon/>
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                            <Grid item>
                              <Tooltip title='Edit Patient Details'>
                                <IconButton sx={{ mr: 1, mb: 2, backgroundColor: 'white' }}
                                  color='primary' onClick={() => handlePatientDetailsModifyClick(obj.patient_id)}>
                                  <ManageAccountsIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title='Add Patient Parameters'>
                                <IconButton sx={{ mb: 2, mr: 1, backgroundColor: 'white' }} color='primary'
                                  onClick={(e) => handlePatientParameterDialogClick(e, true)}>
                                  <SpeakerNotesIcon/>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title='View Appointment'>
                                <IconButton
                                  sx={{ mb: 2, mr: 1, backgroundColor: 'white' }} color='primary'
                                  onClick={(e) => handleAppointmentDetailsViewClick(e)}
                                >
                                  <VisibilityIcon/>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            {isPatientCheckedIn() ? (
                              <Grid item>
                                <Tooltip title='Check-Out'>
                                  <IconButton sx={{ mb: 2, mr: 1, backgroundColor: '#FEE3EC' }} color='error' onClick={(e) => handlePunchOut(e)}>
                                    <LogoutIcon/>
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            ) : (
                              <Grid item>
                                <Tooltip title='Check-In'>
                                  <IconButton sx={{ mb: 2, mr: 1, backgroundColor: '#CEE5D0' }} color='success' onClick={(e) => handlePunchIn(e)}>
                                    <LoginIcon/>
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        ) : ''}
                      </Grid>
                    </ListItemButton>
                  </React.Fragment>
                ))}
              </List>
              {visitedAppointmentList.length <= 0 ? (
                <Typography sx={{ p: 5, flex: 1, backgroundColor: 'white', textAlign: 'center' }} variant="h5" component="div">
                  No waiting patients
                </Typography>
              ) : ''}
            </Grid>
          ): ''}
        </Grid>
        {isDoctor == true && checkedInPatientAppId != undefined ? (
          <Grid item xs={12} md={8.4} sx={{ mt: 2 }} marginTop={{ lg: 0 }} alignContent='center'>
            <ManageSingleAppointment clinicId={clinicId} appointmentId={checkedInPatientAppId} onCheckOut={() => raiseGetVisitedAppointmentList()}/>
          </Grid>
        ) : isDoctor == true && checkedInPatientAppId == undefined ? (
          <Grid item container xs={12} md={8.4} sx={{ mt: 2, backgroundColor: 'white' }} marginTop={{ lg: 0 }} alignContent='center'>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ textAlign: 'center', mb: 1, p: 5 }}>
                No patients checked-in for consultation
              </Typography>
            </Grid>
          </Grid>
        ) : ''}
        {isDoctor == false ? (
          <Grid item xs={12} md={5.9} sx={{ mt: 2, backgroundColor: 'white' }} marginTop={{ lg: 0 }}>
            <Grid item container xs={12} >
              <Grid item xs={10} md={11} sx={{ p: 2 }}>
                <Typography variant="h4" sx={{ textAlign: 'center', mb: 1 }}>
                  Appointments
                </Typography>
              </Grid>
              <Grid item xs={2} md={1} sx={{ p: 1 }}>
                <Tooltip title='Refresh Appointments'>
                  <IconButton color="primary" onClick={() => raiseGetNotVisitedAppointmentList()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ backgroundColor: 'white' }}>
                {notVisitedAppointmentList.map((obj) => (
                  <ListItemButton key={obj.appointment_id}
                    selected={selectedAppointmentId.notVisited == obj.appointment_id}
                    onClick={() => {handleListItemSelection(obj.appointment_id, 1);}}>
                    <Grid container>
                      <Grid item xs={12} lg={7}>
                        <Stack>
                          <ListItemText primary={getAppointmentPrimaryText(obj)} secondary={getAppointmentSecondaryText(obj)} />
                        </Stack>
                      </Grid>
                      {selectedAppointmentId.notVisited == obj.appointment_id ? (
                        <Grid item container xs={12} lg={5} sx={{ pt: 1 }}
                          backgroundColor = {selectedAppointmentId.notVisited === obj.appointment_id ? '' : 'white' }
                          direction='row' justifyContent='flex-end'>
                          <Tooltip title='Cancel Appointment'>
                            <IconButton sx={{ mb: 2, mr: 1 }} color='error'
                              onClick={(e) => handleCancelApmtClick(e)}>
                              <CancelIcon/>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='View Appointment'>
                            <IconButton sx={{ mb: 2, mr: 1 }} color='primary'
                              onClick={(e) => handleAppointmentDetailsViewClick(e)}
                            >
                              <VisibilityIcon/>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Mark Visit'>
                            <IconButton sx={{ mb: 2, mr: 1 }} color='success'
                              onClick={(e) => handleMarkVisitClick(e)}>
                              <CheckCircleIcon/>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ) : ''}
                    </Grid>
                  </ListItemButton>
                ))}
              </List>
              {notVisitedAppointmentList.length <= 0 ? (
                <Typography sx={{ p: 5, flex: 1, backgroundColor: 'white', textAlign: 'center' }} variant="h5" component="div">
                  No Appointments
                </Typography>
              ) : ''}
            </Grid>
          </Grid>
        ) : ''}
      </Grid>
      <TSConfirmationDialog
        fullWidth
        maxWidth={'sm'}
        open={isDialogOpen.cancelConfirmation}
      >
        <DialogTitle>
          <Typography textAlign='center' sx={{ mb: 1 }}>
            Reason for cancellation
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            maxRows={4}
            name="reason"
            autoComplete ='off'
            value={cancelReason}
            onChange={(event) => {handleCancellationReasonTextChange(event);}}
            required
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
          <Button
            color='primary'
            size='small'
            onClick={() => setIsDialogOpen({ ...isDialogOpen, cancelConfirmation: false })}
          >
            Close
          </Button>
          <Button
            color='primary'
            size='small'
            variant='contained'
            onClick={handleCancelApmtConfirmationClick}
          >
            Cancel Appointment
          </Button>
        </DialogActions>
      </TSConfirmationDialog>
      <TSSideBarDialog
        open={isDialogOpen.newAppointment}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsDialogOpen({ ...isDialogOpen, newAppointment: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Appointment
            </Typography>
          </Toolbar>
        </AppBar>
        <CreateAppointment clinicId={clinicId} prefilledInfo={opDetails} onSuccessfulCommit={handleSuccessfulApmtCreation}/>
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isDialogOpen.cancelled}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsDialogOpen({ ...isDialogOpen, cancelled: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Cancelled Appointments
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => handleAppointmentDetailsViewClick(e)}
              sx={{ mr: 2 }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => raiseGetCancelledAppointmentList()}
              aria-label="close"
            >
              <RefreshIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={12}>
            {cancelledAppointmentList.length > 0 ? (
              <List sx={{ backgroundColor: 'white' }}>
                {cancelledAppointmentList.map((obj) => (
                  <ListItemButton key={obj.appointment_id}
                    selected={selectedAppointmentId.cancelled == obj.appointment_id}
                    onClick={() => {handleListItemSelection(obj.appointment_id, 4);}}>
                    <ListItemText primary={getAppointmentPrimaryText(obj)} secondary={getAppointmentSecondaryText(obj)} />
                    {selectedAppointmentId.cancelled == obj.appointment_id && obj.appointment_status == 0 ? (
                      <Grid item container xs={3}
                        backgroundColor={selectedAppointmentId.cancelled == obj.appointment_id ? '' : 'white'}
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                      >
                        <Grid item>
                          <Tooltip title='Restore Appointment'>
                            <IconButton sx={{ mr: 1 }} color='success'
                              onClick={raiseRestoreAppointmentRequest}>
                              <ReplayIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : ''}
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <Typography sx={{ m: 2 }} align='center' variant="h5" component="div">
                No Cancelled Appointments
              </Typography>
            )}
          </Grid>
        </Grid>
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isDialogOpen.completed}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsDialogOpen({ ...isDialogOpen, completed: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Completed Appointments
            </Typography>
            {opDetails.op_end_time == undefined ? (
              <Tooltip title='Re-Mark Visit'>
                <IconButton sx={{ mr: 2 }} color='inherit'
                  onClick={(e) => handleMarkVisitClick(e)}>
                  <CheckCircleIcon/>
                </IconButton>
              </Tooltip>
            ) : ''}
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => handleAppointmentDetailsViewClick(e)}
              sx={{ mr: 2 }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => raiseGetCompletedAppointmentList()}
              aria-label="close"
            >
              <RefreshIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={12}>
            {completedAppointmentList.length > 0 ? (
              <List sx={{ backgroundColor: 'white' }}>
                {completedAppointmentList.map((obj) => (
                  <ListItemButton key={obj.appointment_id}
                    sx={getSelectedCompletedAppointmentStyle(obj.appointment_id)}
                    selected={selectedAppointmentId.completed == obj.appointment_id}
                    onClick={() => {handleListItemSelection(obj.appointment_id, 3);}}>
                    <ListItemText primary={getAppointmentPrimaryText(obj)} secondary={getAppointmentSecondaryText(obj)} />
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <Typography sx={{ m: 2 }} align='center' variant="h5" component="div">
                No Completed Appointments
              </Typography>
            )}
          </Grid>
        </Grid>
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isDialogOpen.appointmentDetailsView}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleAppointmentDialogCloseClick()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Appointment Details
            </Typography>
            {!validateStringForNull(selectedAppointmentId.cancelled) ? getEditOptionButtons() : '' }
          </Toolbar>
        </AppBar>
        {appointmentDetails != undefined ? getSelectedAppointmentDetails() : ''}
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isDialogOpen.opSummary}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsDialogOpen({ ...isDialogOpen, opSummary: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              OP Summary
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <OpSummary clinicId={clinicId} opId={opDetails.op_id} />
        </DialogContent>
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isDialogOpen.patientParameters}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => handlePatientParameterDialogClick(e, false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Patient Parameter Details
            </Typography>
          </Toolbar>
        </AppBar>
        {validateStringForNull(selectedAppointmentId.visited) ?
          <PatientParameterDetails clinicId={clinicId} appointmentId={selectedAppointmentId.visited} /> : ''}
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isDialogOpen.notVisited}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsDialogOpen({ ...isDialogOpen, notVisited: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Yet To Visit Appointments
            </Typography>
            <Tooltip title='Refresh Appointments'>
              <IconButton color='inherit' onClick={() => raiseGetNotVisitedAppointmentList()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Grid item sx={{ mt: 2, backgroundColor: 'white' }}>
          <Grid item xs={12}>
            <List sx={{ backgroundColor: 'white' }}>
              {notVisitedAppointmentList.map((obj) => (
                <ListItemButton key={obj.appointment_id}
                  selected={selectedAppointmentId.notVisited == obj.appointment_id}
                  onClick={() => {handleListItemSelection(obj.appointment_id, 1);}}>
                  <Grid container>
                    <Grid item xs={12} lg={7}>
                      <Stack>
                        <ListItemText primary={getAppointmentPrimaryText(obj)} secondary={getAppointmentSecondaryText(obj)} />
                      </Stack>
                    </Grid>
                    {selectedAppointmentId.notVisited == obj.appointment_id ? (
                      <Grid item container xs={12} lg={5} sx={{ pt: 1 }}
                        backgroundColor = {selectedAppointmentId.notVisited === obj.appointment_id ? '' : 'white' }
                        direction='row' justifyContent='flex-end'>
                        <Tooltip title='Cancel Appointment'>
                          <IconButton sx={{ mb: 2, mr: 1 }} color='error'
                            onClick={(e) => handleCancelApmtClick(e)}>
                            <CancelIcon/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='View Appointment'>
                          <IconButton sx={{ mb: 2, mr: 1 }} color='primary'
                            onClick={(e) => handleAppointmentDetailsViewClick(e)}
                          >
                            <VisibilityIcon/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Mark Visit'>
                          <IconButton sx={{ mb: 2, mr: 1 }} color='success'
                            onClick={(e) => handleMarkVisitClick(e)}>
                            <CheckCircleIcon/>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : ''}
                  </Grid>
                </ListItemButton>
              ))}
            </List>
            {notVisitedAppointmentList.length <= 0 ? (
              <Typography sx={{ p: 5, flex: 1, backgroundColor: 'white', textAlign: 'center' }} variant="h5" component="div">
                No Appointments
              </Typography>
            ) : ''}
          </Grid>
        </Grid>
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isDialogOpen.modifyPatient}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsDialogOpen({ ...isDialogOpen, modifyPatient: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Modify Patient Details
            </Typography>
          </Toolbar>
        </AppBar>
        <CreateModifyPatient clinicId={clinicId}
          patientId={selectedPatientId != undefined ? selectedPatientId : ''}
          onSuccessfulCommit={handleSuccessfulCommit} />
      </TSSideBarDialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isDialogOpen.startOPErrorCustomSnackBar}
      >
        <Alert variant="filled"
          severity={'error'} sx={{ width: '100%', alignItems: 'center' }}
          action={
            <React.Fragment>
              <Button
                color="success"
                size="small"
                variant='contained'
                sx={{ mr: 1, minWidth: 'fit-content' }}
                onClick={handleSnackbarEndOPClick}
              >
                End OP
              </Button>
              <IconButton
                onClick={() => setIsDialogOpen({ ...isDialogOpen, startOPErrorCustomSnackBar: false })}
                sx={{ mr: 5 }}
                color="inherit" size="small"
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }>
          {startOpErrorObj.message}
        </Alert>
      </Snackbar>
      {getEndOPConfirmationDialog()}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ManageAppointmentTabContent.propTypes = {
  clinicId: PropTypes.string.isRequired,
  opId: PropTypes.string.isRequired,
  isDoctor: PropTypes.bool.isRequired
};
export default ManageAppointmentTabContent;
