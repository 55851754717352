import React from 'react';
import { Helmet } from 'react-helmet';
import ManageOPDetails from '../components/Appointment/ManageOPDetails';
import { Box } from '@mui/material';

const ManageOp = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Manage OP - DocKit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          minHeight: '100%',
          width: '100%',
          alignItems: 'flex-start',
          py: 2,
          pt: 10,
          px: 2
        }}
      >
        <ManageOPDetails />
      </Box>
    </React.Fragment>
  );
};

export default ManageOp;
