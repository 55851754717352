import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DAYS_OF_WEEK } from '../../utils/EnumDefinitions';
import PropTypes from 'prop-types';
import Services from '../../utils/Services';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import APIData from '../../utils/APIData';
import { validateStringForNull } from '../../utils/FieldValidations';
import { CircleRounded } from '@mui/icons-material';
import TSHomeBackDrop from '../tscomponents/TSHomeBackDrop';

const ViewOpenHours = ({ clinicId, staffId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [OpenHours, setOpenHours] = useState([]);
  const APIToken = {
    GET_OPEN_HOURS: 'CSOH01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetOpenHoursDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if ( apiToken == APIToken.GET_OPEN_HOURS ) {
      if (apiData == APIData.getStaffOpenHoursDetails) {
        setOpenHours(response.data ?? []);
      } else if (apiData == APIData.getClinicDetails) {
        setOpenHours(response.data.open_hours ?? []);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if ( apiToken == APIToken.GET_OPEN_HOURS ) {
      if (apiData == APIData.getStaffOpenHoursDetails || apiData == APIData.getClinicDetails) {
        setSnackBarStatus({
          open: true,
          severity: 'error',
          message: err.message ?? 'Failed to retrieve Open Hours'
        });
      }
    }
    setIsLoading(false);
  };

  const raiseGetOpenHoursDetails = () => {
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getStaffOpenHoursDetails, uriValues: [clinicId, staffId] },
        APIToken.GET_OPEN_HOURS, processSuccess, processError);
    } else {
      const params = 'fetch_modules=open_hours';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
        APIToken.GET_OPEN_HOURS, processSuccess, processError);
    }
  };

  const getOpenHoursData = () => {
    const data = [];
    if (OpenHours.length > 0) {
      Object.keys(DAYS_OF_WEEK).forEach((i) => {
        const filterDays = OpenHours.filter((obj) => obj.day.value == i);
        if (filterDays.length > 0) {
          const dayWithTimings = (
            <Grid item container xs={12} key={`${i}_timings`}
              sx={{ borderBottom: '0.75px solid #E0E0E0', px: 0, py: 2, width: '100%', ml: 0,
                display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Grid item xs={5} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} >
                <CircleRounded
                  sx={{
                    fontSize: 'small',
                    color: filterDays[0].start_time !== undefined ? '#84C602' : '#ff3300'
                  }}
                />
                <Typography variant="subHeading2" sx={{ display: 'flex', ml: 1, color: 'main.black' }}>
                  {DAYS_OF_WEEK[i].toUpperCase()}
                </Typography>
              </Grid>
              <Grid item container xs={7} sm={6} direction='row' rowSpacing={1.5}>
                {filterDays.map((filterDay, index) => (
                  <Grid item xs={12} key={`${i}_${index}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                    {filterDay.start_time !== undefined && (
                      <Grid item>
                        <Typography variant='subHeading2' style={{ whiteSpace: 'nowrap', width: '100%' }}
                          color= 'main.black'>{filterDay.start_time.display}</Typography>
                      </Grid>
                    )}
                    {filterDay.start_time !== undefined && filterDay.end_time !== undefined && (
                      <Grid item>
                        <Divider
                          sx={{
                            width: '15px',
                            height: '2px',
                            borderStyle: 'groove',
                            borderBottomWidth: 'thin',
                            backgroundColor: 'main.textLightGrey',
                            mx: { xs: 1, sm: 1.5 }
                          }} />
                      </Grid>
                    )}
                    {filterDay.end_time !== undefined && (
                      <Grid item>
                        <Typography variant='subHeading2' style={{ whiteSpace: 'nowrap', textAlign: 'flex-end' }}
                          color= 'main.black'>{filterDay.end_time.display}</Typography>
                      </Grid>
                    )}
                    {!filterDay.start_time && !filterDay.end_time && (
                      <Typography variant='subHeading2' color= 'main.black'>Closed</Typography>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          );
          data.push(dayWithTimings);
        }
      });
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="subHeading1" sx={{ display: 'flex', justifyContent: 'center', color: 'main.black', mt: 8 }}>
            {isLoading ? 'Loading...' : 'No Information Available'}
          </Typography>
        </Grid>
      );
    }
    return data;
  };

  return (
    <React.Fragment>
      <Grid container sx= {{ mt: 1 }}>
        {getOpenHoursData()}
      </Grid>
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewOpenHours.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string
};

export default ViewOpenHours;
