import { Grid, Table, TableBody, TableCell,
  tableCellClasses, TextField, Button, Container, Paper,
  TableRow, IconButton, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { Utils } from '../../utils/UtilFunctions';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { validateDate, validateEmailId, validateNumber, validateStringForNull } from '../../utils/FieldValidations';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import APIData from '../../utils/APIData';
import { cloneDeep } from 'lodash';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';

const PaperBase = styled(Paper)(()=>({
  width: 'auto',
  maxWidth: 'md'
}));

const ManageClinicDetails = ({ clinicId, setClinicName, showEdit }) => {
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicDetails, setClinicDetails] = useState({
    clinic_name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    email: '',
    phone: '',
    website: '',
    start_date: null
  });
  const [originalValue, setOriginalValue] = useState(clinicDetails);
  const APIToken = {
    GET_CLINIC_BASIC_DETAILS: 'CSCD01',
    MODIFY_CLINIC_BASIC_DETAILS: 'CSCD02'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetClinicDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CLINIC_BASIC_DETAILS) {
      if (response.data.basic_details != undefined) {
        const clinicDetailsObj = {
          clinic_name: response.data.basic_details.clinic_name,
          address: response.data.basic_details.address,
          city: response.data.basic_details.city,
          state: response.data.basic_details.state,
          country: response.data.basic_details.country,
          pincode: response.data.basic_details.pincode,
          email: response.data.basic_details.email ?? '',
          phone: response.data.basic_details.phone,
          website: response.data.basic_details.website ?? '',
          start_date: response.data.basic_details.start_date != undefined ?
            moment(response.data.basic_details.start_date.value, 'yyyy-MM-DD') : null
        };
        setClinicDetails(clinicDetailsObj);
        if (setClinicName != undefined) {
          setClinicName(response.data.basic_details.clinic_name);
        }
        setOriginalValue(clinicDetailsObj);
      }
    } else if (apiData == APIData.modifyClinicBasicDetails && apiToken == APIToken.MODIFY_CLINIC_BASIC_DETAILS) {
      showSnackBar('success', response.message ?? 'Clinic Details updated successfully');
      setOriginalValue(clinicDetails);
      setIsEditModeOn(false);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let errorMsg = 'Unhandled exception';
    if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CLINIC_BASIC_DETAILS ) {
      errorMsg = 'Failed to retrieve Clinic Details';
    } else if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CLINIC_BASIC_DETAILS ) {
      errorMsg = 'Failed to update Clinic Details';
    }
    showSnackBar('error', errorMsg);
    setIsLoading(false);
  };

  const getModifiedParams = (params) => {
    const modifiedParams = {};
    if (originalValue.clinic_name != params.clinic_name) {
      modifiedParams.clinic_name = params.clinic_name;
    }
    if (originalValue.address != params.address) {
      modifiedParams.address = params.address;
    }
    if (originalValue.city != params.city) {
      modifiedParams.city = params.city;
    }
    if (originalValue.state != params.state) {
      modifiedParams.state = params.state;
    }
    if (originalValue.country != params.country) {
      modifiedParams.country = params.country;
    }
    if (originalValue.pincode != params.pincode) {
      modifiedParams.pincode = params.pincode;
    }
    if (originalValue.email != params.email) {
      modifiedParams.email = validateStringForNull(params.email) ? params.email : null;
    }
    if (originalValue.phone != params.phone) {
      modifiedParams.phone = params.phone;
    }
    if (originalValue.website != params.website) {
      modifiedParams.website = params.website;
    }
    if (originalValue.start_date != null && params.start_date == null) {
      modifiedParams.start_date = null;
    } else if (moment(originalValue.start_date).format('yyyy-MM-DD') != moment(params.start_date).format('yyyy-MM-DD')) {
      modifiedParams.start_date = moment(params.start_date).format('yyyy-MM-DD');
    }
    return modifiedParams;
  };

  const validateParams = (params) => {
    if (!validateStringForNull(params.clinic_name)) {
      throw new Error('Invalid Clinic Name');
    }
    if (!validateStringForNull(params.address)) {
      throw new Error('Invalid Clinic Address');
    }
    if (!validateStringForNull(params.city)) {
      throw new Error('Invalid City');
    }
    if (!validateNumber(params.phone)) {
      throw new Error('Invalid Phone Number');
    }
    if (!validateNumber(params.pincode)) {
      throw new Error('Invalid PinCode');
    }
    if (validateStringForNull(params.email) && !validateEmailId(params.email)) {
      throw new Error('Invalid Email Id');
    }
    if (!validateStringForNull(params.country)) {
      throw new Error('Invalid Country');
    }
    if (params.start_date != null && !validateDate(params.start_date)) {
      throw new Error('Invalid Clinic Start Date');
    }
  };

  const raiseGetClinicDetails = () => {
    const params = 'fetch_modules=basic_details';
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
      APIToken.GET_CLINIC_BASIC_DETAILS, processSuccess, processError);
  };

  const raiseModifyClinicDetailsRequest = (params) => {
    const finalParams = getModifiedParams(params);
    if (Object.keys(finalParams).length == 0) {
      showSnackBar('warning', 'Nothing to update');
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyClinicBasicDetails, uriValues: [clinicId], params: finalParams },
      APIToken.MODIFY_CLINIC_BASIC_DETAILS, processSuccess, processError);
  };

  const handleUpdateClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(clinicDetails);
      validateParams(params);
      raiseModifyClinicDetailsRequest(params);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Clinic Basic Details Modification failed.');
      setIsLoading(false);
    }
  };

  const handleResetClick = () => {
    setClinicDetails(originalValue);
  };

  const getTableRow = (key, value) => {
    if (value != undefined) {
      return (
        <TableRow >
          <TableCell >
            {key}
          </TableCell>
          <TableCell >
            {value.toString()}
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow></TableRow>
      );
    }
  };

  const handleCancelClick = () => {
    handleResetClick();
    setIsEditModeOn(false);
  };

  const handleTextChange = (event) => {
    setClinicDetails({ ...clinicDetails, [event.target.name]: event.target.value });
  };

  const getClinicInformationContent = () => {
    return (
      <PaperBase elevation={1} sx={{
        flexGrow: 1,
        m: 5,
        mt: 0
      }} >
        <Grid container justifyContent='center' >
          <Grid item xs={11}>
            <InputLabel sx={{ p: 2 }}>
              Clinic Information
            </InputLabel>
          </Grid>
          <Grid item xs={1}>
            {showEdit && (
              <IconButton color='primary' onClick={() => setIsEditModeOn(true)}>
                <EditIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none'
                }
              }}
            >
              <TableBody>
                {getTableRow('Clinic Name', clinicDetails.clinic_name )}
                {getTableRow('Adderss', clinicDetails.address )}
                {getTableRow('City', clinicDetails.city )}
                {getTableRow('State', clinicDetails.state )}
                {getTableRow('Pincode', clinicDetails.pincode )}
                {getTableRow('Country', clinicDetails.country )}
                {getTableRow('Email', clinicDetails.email )}
                {getTableRow('Phone', clinicDetails.phone )}
                {getTableRow('Website', clinicDetails.website )}
                {getTableRow('Start Date', clinicDetails.start_date == undefined ? undefined : clinicDetails.start_date.display )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PaperBase>
    );
  };

  const getModifyClinicInformationContent = () => {
    return (
      <Container maxWidth="sm" sx={{ py: 5 }}>
        <Grid
          container
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <InputLabel sx={{ py: 2 }}>
              Edit Clinic Information
            </InputLabel>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              label="Clinic Name"
              variant="outlined"
              name="clinic_name"
              value={clinicDetails.clinic_name}
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              label="Address"
              variant="outlined"
              multiline
              maxRows={4}
              name="address"
              value={clinicDetails.address}
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              size="small"
              value={clinicDetails.city}
              margin="normal"
              label="City"
              variant="outlined"
              name="city"
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              size="small"
              value={clinicDetails.state}
              margin="normal"
              label="State"
              variant="outlined"
              name="state"
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              size="small"
              value={clinicDetails.country}
              margin="normal"
              label="Country"
              variant="outlined"
              name="country"
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              size="small"
              value={clinicDetails.pincode}
              margin="normal"
              label="Pincode"
              name="pincode"
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              size="small"
              value={clinicDetails.phone}
              margin="normal"
              label="Phone"
              variant="outlined"
              name="phone"
              type='phone'
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              required
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              size="small"
              value={clinicDetails.email}
              margin="normal"
              label="Email"
              variant="outlined"
              name="email"
              type='email'
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <TextField
              fullWidth
              size="small"
              value={clinicDetails.website}
              margin="normal"
              label="WebSite"
              variant="outlined"
              name="website"
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
            />
          </Grid>
          <Grid item xs={12} sm={5.5} sx={{ mb: 2, mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Clinic Start Date"
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                value={clinicDetails.start_date}
                name='start_date'
                onChange={(newValue) => {setClinicDetails({ ...clinicDetails, start_date: newValue });}}
                slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems='center'
            sx={{ py: 1 }}
          >
            <Grid item>
              <Button
                type="submit"
                variant="text"
                size="small"
                sx={{ mr: 1 }}
                onClick={handleResetClick}
              >
              Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                size="small"
                sx={{ mr: 1 }}
                onClick={() => handleCancelClick()}
              >
               Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                size="small"
                onClick={handleUpdateClick}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <React.Fragment>
      {isEditModeOn == true ? getModifyClinicInformationContent() : getClinicInformationContent()}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ManageClinicDetails.propTypes = {
  clinicId: PropTypes.string,
  setClinicName: PropTypes.func,
  showEdit: PropTypes.bool
};

export default ManageClinicDetails;
