import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import ClinicSideBar from './ClinicSideBar';
import Services from '../../utils/Services';
import moment from 'moment';
import { Alert, AppBar, Button, IconButton, Snackbar, Toolbar, Typography } from '@mui/material';
import { validateStringForNull } from '../../utils/FieldValidations';
import CloseIcon from '@mui/icons-material/Close';
import ManageDoctorDetails from '../doctorsettings/ManageDoctorDetails';
import NetworkListener from './NetworkListener';
import APIData from '../../utils/APIData';
import { usePageVisibility } from '../../utils/PageVisibility';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import { CookieUtils } from '../../utils/CookieUtils';
import ClinicNavBar from './ClinicNavBar';

const ClinicLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const ClinicLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const ClinicLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const ClinicLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const ClinicLayout = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  const bannerMsg = CookieUtils.getBannerMsg();
  const [isOpen, setIsOpen] = useState(validateStringForNull(bannerMsg));
  const [isDoctorDetailsDialogOpen, setIsDoctorDetailsDialogOpen] = useState(false);
  const isSupported = typeof window !== undefined && 'wakeLock' in navigator;
  const APIToken = {
    GET_STAFF_DETAILS: 'CL01'
  };

  const isVisible = usePageVisibility();
  if (isVisible) {
    if (isSupported) {
      navigator.wakeLock.request('screen').then(() => {
        console.log('Overridden: Disabled Screen timeout');
      });
    } else {
      console.log('Feature \'Disable screen timeout\' not supported in this browser');
    }
  }

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getStaffDetails && apiToken == APIToken.GET_STAFF_DETAILS ) {
      CookieUtils.setClinicId(clinicId);
      CookieUtils.setStaffClinicRole(response.data.role_details.role);
      CookieUtils.setIsStaffActiveInClinic(response.data.role_details.is_active);
      CookieUtils.setStaffEmpId(response.data.role_details.emp_id);
      CookieUtils.setIsDoctor(response.data.role_details.is_doctor);
      CookieUtils.setClinicHashTimestamp(moment());

      setIsOpen(validateStringForNull(bannerMsg));
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (apiData == APIData.getStaffDetails && apiToken == APIToken.GET_STAFF_DETAILS) {
      if (err.status == 403) {
        if (err.success == 403003) {
          navigate('/403', { state: { title: 'Clinic not activated', message: err.message } });
        } else {
          console.log('403: ' + err.message);
        }
      } else {
        navigate('/404');
      }
    }
  };

  const raiseClinicRoleDetails = () => {
    CookieUtils.clearClinicHashCookies();
    const finalParams = 'fetch_modules=role_details';
    Services.sendBackendRequest({ apiData: APIData.getStaffDetails, uriValues: [clinicId, CookieUtils.getStaffId()], params: finalParams },
      APIToken.GET_STAFF_DETAILS, processSuccess, processError);
  };

  const validateClinicAccess = () => {
    if (clinicId == CookieUtils.getClinicId()) {
      const savedTS = moment(CookieUtils.getClinicHashTimestamp());
      const tempTime = moment.duration(moment().diff(savedTS));
      if (tempTime.hours() > 1) {
        raiseClinicRoleDetails();
      } else {
        setIsOpen(validateStringForNull(bannerMsg));
      }
    } else {
      raiseClinicRoleDetails();
    }
  };

  useEffect(() => {
    validateClinicAccess();
  }, []);

  const handleDoctorDetailsDialogClick = (isOpen) => {
    setIsOpen(false);
    setIsDoctorDetailsDialogOpen(isOpen);
  };

  return (
    <ClinicLayoutRoot>
      <ClinicNavBar onSideBarOpen={() => setIsSideBarOpen(!isSideBarOpen)}/>
      <ClinicSideBar open={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen}/>
      <ClinicLayoutWrapper>
        <ClinicLayoutContainer>
          <ClinicLayoutContent>
            <Outlet />
          </ClinicLayoutContent>
        </ClinicLayoutContainer>
      </ClinicLayoutWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
      >
        <Alert variant="filled"
          severity={'warning'} sx={{ width: '100%' }}
          action={
            <>
              <Button
                color="success"
                size="small"
                variant='contained'
                sx={{ m: 1 }}
                onClick={() => handleDoctorDetailsDialogClick(true)}
              >
                Update
              </Button>
              <IconButton
                onClick={() => setIsOpen(false)}
                sx={{ m: 1 }}
                color="inherit" size="small"
              >
                <CloseIcon />
              </IconButton>
            </>
          }>
          {bannerMsg}
        </Alert>
      </Snackbar>
      <NetworkListener/>
      <TSSideBarDialog
        open={isDoctorDetailsDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleDoctorDetailsDialogClick(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Doctor Details
            </Typography>
          </Toolbar>
        </AppBar>
        <ManageDoctorDetails />
      </TSSideBarDialog>
    </ClinicLayoutRoot>
  );
};

export default ClinicLayout;
