import moment from 'moment';

const validateStringForNull = (value) => (
  !(value === null || value === undefined || value === '')
);

const validateNumber = (value) => (
  !(value === null || value === undefined || value === '' || isNaN(value))
);

const validateEmailId = (value) => {
  const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regexp.test(value);
};

const validateDate = (value) => {
  if (validateStringForNull(value)) {
    const date = moment(value);
    return date.isValid();
  }
  return false;
};

const isFutureDate = (value) => {
  if (validateStringForNull(value)) {
    const date = moment(value);
    if (date.isValid()) {
      return date.isAfter(moment(), 'day');
    }
  }
  return false;
};

const isPastDate = (value) => {
  if (validateStringForNull(value)) {
    const date = moment(value);
    if (date.isValid()) {
      return date.isBefore(moment(), 'day');
    }
  }
  return false;
};

const isSameDate = (date1, date2) => {
  if (validateStringForNull(date1) && validateStringForNull(date2)) {
    return date1.isSame(date2, 'day');
  }
  return false;
};

const validateSettings = (settings) => {
  const validatedSettings = [];
  for (const setting of settings) {
    if (validateStringForNull(setting.key) && validateStringForNull(setting.value)) {
      const settingObj = {};
      settingObj.key = setting.key;
      settingObj.value = setting.value;
      validatedSettings.push(settingObj);
    }
  }
  return validatedSettings;
};

export { validateStringForNull, validateEmailId, validateNumber, validateDate, isFutureDate, isPastDate, validateSettings, isSameDate };
