import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';
import TSSnackbar from '../components/tscomponents/TSSnackbar';
import TSBackDrop from '../components/tscomponents/TSBackDrop';
import { Helmet } from 'react-helmet';
import { Utils } from '../utils/UtilFunctions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validateStringForNull } from '../utils/FieldValidations';
import Services from '../utils/Services';
import { USER_TYPE } from '../utils/EnumDefinitions';
import APIData from '../utils/APIData';

const theme = createTheme();

const ClinicInvite = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inviteId = searchParams.get('invite_id');
  const [inviteDetails, setInviteDetails] = useState(undefined);
  const [responseText, setResponseText] = useState(undefined);
  const APIToken = {
    GET_INVITE: 'PCI01',
    ACCEPT_INVITE: 'PCI02',
    DECLINE_INVITE: 'PCI03'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getInvite && apiToken == APIToken.GET_INVITE ) {
      setResponseText(undefined);
      setInviteDetails(response.data);
    } else if (apiData == APIData.acceptInvite && apiToken == APIToken.ACCEPT_INVITE ) {
      setInviteDetails(undefined);
      setResponseText('Thanks for joining us! \n Redirecting to signin page in 2 seconds');
      setTimeout(() => navigate('/signin', { replace: true }), 2000);
    } else if (apiData == APIData.declineInvite && apiToken == APIToken.DECLINE_INVITE ) {
      setInviteDetails(undefined);
      setResponseText('Thanks for your response.');
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    let showSnackBarMsg = true;
    if (apiData == APIData.getInvite && apiToken == APIToken.GET_INVITE) {
      showSnackBarMsg = false;
      setInviteDetails(undefined);
      setResponseText(err.message);
    } else if (apiData == APIData.acceptInvite && apiToken == APIToken.ACCEPT_INVITE ) {
      defaultMsg = 'Failed to accept invitation. Try Again';
    } else if (apiData == APIData.declineInvite && apiToken == APIToken.DECLINE_INVITE ) {
      defaultMsg = 'Failed to reject invitation. Try Again';
    }
    if (showSnackBarMsg) {
      showSnackBar('error', err.message ?? defaultMsg);
    }
    setIsLoading(false);
  };

  const raiseGetInviteRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getInvite, uriValues: [inviteId] },
      APIToken.GET_INVITE, processSuccess, processError);
  };

  const raiseAcceptInviteRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.acceptInvite, uriValues: [inviteId] },
      APIToken.ACCEPT_INVITE, processSuccess, processError);
  };

  const raiseDeclineInviteRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.declineInvite, uriValues: [inviteId] },
      APIToken.DECLINE_INVITE, processSuccess, processError);
  };

  const handleAcceptClick = () => {
    if (validateStringForNull(inviteDetails.staff_id)) {
      raiseAcceptInviteRequest();
    } else {
      setResponseText('To complete the process, register now!. \nRedirecting to signup page in 2 seconds');
      setInviteDetails(undefined);
      setTimeout(() => navigate(`/signup?invite_id=${inviteId}`, { replace: true }), 2000);
    }
  };

  useEffect(() => {
    if (!validateStringForNull(inviteId)) {
      navigate('/404');
    } else {
      raiseGetInviteRequest();
    }
  }, []);

  const getCopyright = () => {
    return (
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
        {'Copyright © '}
        <a color="inherit" rel="noreferrer" href="https://teqshack.in/" target="_blank">
          Teqshack
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Invitation - DocKit</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(/static/images/signin_bg.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LocalHospitalIcon />
              </Avatar>
              <Box sx={{ mt: 20, mb: 20 }}>
                {inviteDetails == undefined ? (
                  <Typography variant="h6" align='center'>
                    {responseText}
                  </Typography>
                ) : (
                  <Grid container justifyContent='space-between'>
                    <Grid item xs={12}>
                      <Typography variant="h6" align='center'>
                        You are invited to join <strong>{inviteDetails.clinic_name}</strong> as a <strong>{USER_TYPE[inviteDetails.role]}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5.5} sx={{ mt: 3, mb: 2 }}
                      justifyContent={{ xs: 'center', md: 'flex-end' }} display='flex'>
                      <Button
                        variant="contained"
                        onClick={handleAcceptClick}
                      >
                        Accept
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={5.5} sx={{ mt: 3, mb: 2 }}
                      justifyContent={{ xs: 'center', md: 'flex-start' }} display='flex'>
                      <Button
                        variant="outlined"
                        onClick={raiseDeclineInviteRequest}
                      >
                        Decline
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
              {getCopyright()}
            </Box>
          </Grid>
        </Grid>
        {getSnackbar}
        <TSBackDrop isLoading={isLoading} />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default ClinicInvite;
