import { Box, Button, Checkbox, Container, FormControlLabel, Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  tableCellClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { USER_TYPE } from '../../utils/EnumDefinitions';
import Autocomplete from '@mui/material/Autocomplete';
import APIData from '../../utils/APIData';
import { validateStringForNull } from '../../utils/FieldValidations';
import styled from '@emotion/styled';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

const ManageStaffRoleDetails = ({ clinicId, staffId, onSuccessfulCommit, isEditModeOn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [staffRoleDetails, setStaffRoleDetails] = useState({});
  const [originalValue, setOriginalValue] = useState({});
  const APIToken = {
    GET_USER_ROLE_DETAILS: 'CMUCRD01',
    MODIFY_USER_ROLE_DETAILS: 'CMUCRD02'
  };

  const getRoleOptions = () => {
    const roleArr = [];
    Object.keys(USER_TYPE).map((key) => {
      const obj = {
        label: USER_TYPE[key],
        value: key
      };
      roleArr.push(obj);
    });
    return roleArr;
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if ( clinicId != undefined && staffId != undefined) {
      raiseGetUserClinicDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getStaffDetails && apiToken == APIToken.GET_USER_ROLE_DETAILS ) {
      setStaffRoleDetails(response.data.role_details ?? {});
      setOriginalValue(response.data.role_details ?? {});
    } else if (apiData == APIData.modifyStaffRoleDetails && apiToken == APIToken.MODIFY_USER_ROLE_DETAILS) {
      showSnackBar('success', response.message ?? 'Staff role details updated successfully');
      if (onSuccessfulCommit != undefined) {
        onSuccessfulCommit();
      }
      setOriginalValue(staffRoleDetails);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getStaffDetails && apiToken == APIToken.GET_USER_ROLE_DETAILS) {
      defaultMsg = 'Failed to retrieve Staff Role Details';
    } else if (apiData == APIData.modifyStaffRoleDetails && apiToken == APIToken.MODIFY_USER_ROLE_DETAILS) {
      defaultMsg = 'Updating staff role details failed';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserClinicDetails = () => {
    const finalParams = 'fetch_modules=role_details';
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getStaffDetails, uriValues: [clinicId, staffId], params: finalParams },
      APIToken.GET_USER_ROLE_DETAILS, processSuccess, processError);
  };

  const handleValueChange = (newValue) => {
    if (validateStringForNull(newValue)) {
      setStaffRoleDetails({ ...staffRoleDetails,
        role: newValue.value
      });
    } else {
      setStaffRoleDetails({ ...staffRoleDetails,
        role: null
      });
    }
  };

  const handleCheckboxChange = (event) => {
    setStaffRoleDetails({ ...staffRoleDetails, is_doctor: event.target.checked });
  };

  const handleResetClick = () => {
    setStaffRoleDetails(originalValue);
  };

  const getModifiedParams = () => {
    const modifiedParams = {};
    if (parseInt(staffRoleDetails.role) != originalValue.role) {
      modifiedParams.role = parseInt(staffRoleDetails.role);
    }
    if (staffRoleDetails.is_doctor != originalValue.is_doctor) {
      modifiedParams.is_doctor = staffRoleDetails.is_doctor;
    }

    if (modifiedParams.role == 1 && staffRoleDetails.is_doctor == originalValue.is_doctor) {
      modifiedParams.is_doctor = staffRoleDetails.is_doctor;
    }

    if (Object.keys(modifiedParams).length > 0) {
      return modifiedParams;
    }
    return undefined;
  };

  const raiseModifyRoleRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyStaffRoleDetails, uriValues: [clinicId, staffId], params: finalParams },
      APIToken.MODIFY_USER_ROLE_DETAILS, processSuccess, processError);
  };

  const handleSaveClick = () => {
    try {
      const modifiedParams = getModifiedParams();
      if (modifiedParams != undefined) {
        setIsLoading(true);
        raiseModifyRoleRequest(modifiedParams);
      }
    } catch (err) {
      showSnackBar('error', err.message ?? 'Updating user clinic role details failed');
      setIsLoading(false);
    }
  };

  const getTableRow = (key, value) => {
    if (value != undefined) {
      return (
        <TableRow >
          <TableCell style={{ width: '50%' }}>
            {key}
          </TableCell>
          <TableCell style={{ width: '50%' }}>
            {value.toString()}
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow></TableRow>
      );
    }
  };

  const getModifyRoleInformationContent = () => {
    return (
      <Container maxWidth='md' sx={{ backgroundColor: 'white' }}>
        <Box sx={{ mb: 2 }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12} sx={{ my: 2 }}>
              <Autocomplete
                disablePortal
                id="role"
                name='role'
                value={USER_TYPE[staffRoleDetails.role] ?? null}
                onChange={(event, newValue) => {handleValueChange(newValue);}}
                options={getRoleOptions()}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                size="small"
                autoSelect
                autoHighlight
                renderInput={(params) => <TextField size="small" {...params} error={false} fullWidth label="Role" />}
              />
              {staffRoleDetails.role == 1 ? (
                <FormControlLabel
                  control={
                    <Checkbox checked={staffRoleDetails.is_doctor ?? false} onChange={(event) => handleCheckboxChange(event)}/>
                  }
                  label={<Typography variant="body2">Are You a Doctor?</Typography>}
                />
              ) : ''}
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size='small'
                onClick={handleResetClick}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size='small'
                sx={{
                  ml: 2
                }}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  };

  const getRoleInformationContent = () => {
    return (
      <Box>
        <PaperBase elevation = {2} sx={{
          flexGrow: 1,
          m: 5,
          mt: 0
        }} >
          <InputLabel sx={{ p: 2 }}>
          Role Details
          </InputLabel>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableBody>
              {getTableRow('Role', USER_TYPE[staffRoleDetails.role])}
              {getTableRow('Is Doctor', staffRoleDetails.is_doctor)}
              {getTableRow('Is Active', staffRoleDetails.is_active)}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
    );
  };

  return (
    <React.Fragment>
      {isEditModeOn == false ? getRoleInformationContent() : getModifyRoleInformationContent() }
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ManageStaffRoleDetails.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string,
  onSuccessfulCommit: PropTypes.func,
  isEditModeOn: PropTypes.bool
};

export default ManageStaffRoleDetails;
