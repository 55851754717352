import { Helmet } from 'react-helmet';
import {
  Button,
  Card,
  Chip,
  Fab,
  Grid,
  Typography,
  Tooltip,
  Avatar,
  Tabs,
  Tab,
  IconButton,
  Container,
  DialogContent,
  Box
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Services from '../utils/Services';
import TSSnackbar from '../components/tscomponents/TSSnackbar';
import { Utils } from '../utils/UtilFunctions';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import APIData from '../utils/APIData';
import { CustomHomeBackdrop, TSHomeSideBarDialog, Transition } from '../components/tscomponents/TSHomeSideBarDialog';
import ViewOpenHours from '../components/viewclinicdetails/ViewOpenHours';
import ViewHolidayHours from '../components/viewclinicdetails/ViewHolidayHours';
import { CookieUtils } from '../utils/CookieUtils';
import TSConfirmationDialog from '../components/tscomponents/TSConfirmationDialog';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import ViewClinicInformation from '../components/viewclinicdetails/ViewClinicInformation';
import TSHomeBackDrop from '../components/tscomponents/TSHomeBackDrop';

const Home = () => {
  const navigate = useNavigate();
  const [clinics, setClinics] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isClinicDetailsDialogOpen, setIsClinicDetailsDialogOpen] = useState(false);
  const [isClinicNotActivatedDialogOpen, setIsClinicNotActivatedDialogOpen] = useState(false);
  const [selectedClinicId, setSelectedClinicId] = useState(undefined);

  const APIToken = {
    GET_CLINICS: 'PH01'
  };
  const [selectedClinicDetailsTab, setSelectedClinicDetailsTab] = useState(0);

  const clinicDetailsTabsLabel = [
    'Clinic Information',
    'Working Hours',
    'Holiday Hours'
  ];

  const handleClinicDetailsTabChange = (event, newValue) => {
    setSelectedClinicDetailsTab(newValue);
  };
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getClinics && apiToken == APIToken.GET_CLINICS) {
      setClinics(response.data);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getClinics && apiToken == APIToken.GET_CLINICS) {
      defaultMsg = 'Failed to retrive clinics.';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetClinics = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getClinics },
      APIToken.GET_CLINICS, processSuccess, processError);
  };

  useEffect(() => {
    raiseGetClinics();
  }, []);

  const handleEnterClinicButtonClick = (clinic) => {
    if (clinic.activated_at == undefined) {
      setIsClinicNotActivatedDialogOpen(true);
      return;
    }
    CookieUtils.setClinicAddress(clinic.address);
    CookieUtils.setClinicCity(clinic.city);
    CookieUtils.setClinicState(clinic.state);
    CookieUtils.setClinicCountry(clinic.country);

    const clinicId = clinic.clinic_id;
    switch (clinic.role) {
    case 1:
      if (clinic.is_doctor) {
        navigate(`/clinic/doctor-appointments/manage?clinicId=${clinicId}`);
      } else {
        navigate(`/clinic?clinicId=${clinicId}`);
      }
      break;
    case 2:
      navigate(`/clinic/doctor-appointments/manage?clinicId=${clinicId}`);
      break;
    case 3:
      navigate(`/clinic/appointments/manage?clinicId=${clinicId}`);
      break;
    case 4:
      navigate(`/clinic/appointments/create?clinicId=${clinicId}`);
      break;
    }
  };

  const handleClinicDetailsDialogClick = (isOpen) => {
    setIsClinicDetailsDialogOpen(isOpen);
  };

  const handleViewButtonClick = (clinicId) => {
    setSelectedClinicId(clinicId);
    handleClinicDetailsDialogClick(true);
  };

  const getClinicCards = () => {
    return (
      <Grid item container columnSpacing={3} rowSpacing={3} xs={12} sx={{ px: { xs: 0, sm: 3 }, py: 3 }}>
        {clinics.map((clinic) => (
          <Grid item key={clinic.clinic_id} xs={12} sm={6} md={4} lg={4} xl={3}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 3,
                backgroundColor: 'main.textOnPrimary',
                height: 'auto',
                p: 3,
                borderColor: 'main.primary',
                borderWidth: '1.2px',
                borderStyle: 'solid',
                boxShadow: '3px 3px 2px #E0E0E0'
              }}
            >
              <Grid container>
                <Grid item container xs={9}>
                  <Grid item xs={12} sx={{ mb: 0.5 }}>
                    <Typography color="main.primary" variant='subHeading1'
                      style={{ wordWrap: 'break-word',
                        overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {clinic.clinic_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="main.primary" variant="body"
                      style={{ wordWrap: 'break-word',
                        overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {clinic.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="main.primary"
                      variant="body"
                      style={{ wordWrap: 'break-word',
                        overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {clinic.city}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="main.primary"
                      variant="body"
                      style={{ wordWrap: 'break-word',
                        overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {clinic.state}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Avatar
                    variant='circular'
                    src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                    sx={{
                      width: { xs: 55, sm: 61 },
                      height: { xs: 55, sm: 61 },
                      bgcolor: 'main.textLightGrey'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={{ mt: 1.5 }}>
                  <Chip sx={{ backgroundColor: 'main.accent',
                    height: { xs: 21 },
                    py: 1.25,
                    '& .MuiChip-label': { color: 'main.primary' } }}
                  icon={<Person2RoundedIcon
                    style={{ fontWeight: 'bold', fontSize: 'large', color: '#0B60B0' }}/>}
                  label={<Typography variant="label"
                    style={{ wordWrap: 'break-word', overflow: 'hidden' }}>
                    {clinic.role_display}</Typography>}/>
                </Grid>
                <Grid item xs={12} sx={{ mt: { xs: 1, sm: 1.5 } }}>
                  <Tooltip title='View Clinic Details'>
                    <Button
                      sx={{
                        width: { xs: '7rem', sm: '7.25rem' },
                        height: { xs: '1.4rem', sm: '1.6rem', lg: '1.8rem' },
                        borderRadius: '2rem',
                        mr: 1, mt: { xs: 0.7, sm: 0 }
                      }}
                      color='primary'
                      variant="outlined"
                      size='small'
                      onClick={() => handleViewButtonClick(clinic.clinic_id)}>
                      <VisibilityIcon style={{ fontWeight: 'bold', fontSize: 'large' }}/>
                      <Typography
                        color="main.primary"
                        variant="buttonLabel"
                        style={{ wordWrap: 'break-word', overflow: 'hidden' }}
                        sx={{ ml: 0.5 }}>
                        Clinic Details
                      </Typography>
                    </Button>
                  </Tooltip>
                  <Tooltip title='Enter Clinic'>
                    <Button
                      sx={{
                        width: { xs: '6.3rem', sm: '6.7rem' },
                        height: { xs: '1.4rem', sm: '1.6rem', lg: '1.8rem' },
                        borderRadius: '2rem',
                        mt: { xs: 0.7, sm: 0 }
                      }}
                      color='primary'
                      variant='contained'
                      size='small'
                      onClick={() => handleEnterClinicButtonClick(clinic)}
                    >
                      <Typography
                        color="white"
                        variant="buttonLabel"
                        style={{ wordWrap: 'break-word', overflow: 'hidden' }}
                        sx={{ mr: 0.5 }}>
                        Enter Clinic
                      </Typography>
                      <ArrowForwardIosIcon style={{ fontWeight: 'bold', fontSize: 'small' }}/>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getClinicActionCard = () => {
    return (
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ mt: 15 }}
      >
        <Grid item>
          <img
            alt="DocKit"
            src="/static/images/EmptyCinicsState.png"
            style={{ height: '4rem' }}
          />
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Typography
            align="center"
            color="textPrimary"
            variant="subHeading1"
          >
            No clinics have been setup
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 0.5 }}>
          <Typography
            align="center"
            color="textPrimary"
            variant="body"
          >
            Get started with DocKit by setting up your clinic
          </Typography>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            onClick={()=>{navigate('setup-clinic');}}
            sx={{ mt: 4, mb: 2, width: { xs: '9rem', sm: '12rem' } }}
          >
            Setup New Clinic
          </Button>
        </Grid>
      </Grid>
    );
  };

  const getClinics = () => {
    if (clinics == undefined) {
      return <React.Fragment/>;
    } else if (clinics.length > 0) {
      return getClinicCards();
    } else {
      return getClinicActionCard();
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Home - DocKit</title>
      </Helmet>
      <Container sx={{ pb: 2 }} maxWidth={false}>
        <Grid container sx={{ minHeight: '50vh', alignContent: 'flex-start' }}>
          <Grid item xs={12} sx={{ px: { xs: 0, sm: 3 } }}>
            <Typography
              variant="heading"
              color="text.primary"
            >
              Your associated clinics
            </Typography>
          </Grid>
          {getClinics()}
        </Grid>
      </Container>
      {clinics !== undefined && clinics.length > 0 ? (
        <Fab
          onClick={()=>{navigate('setup-clinic');}}
          sx={{ position: 'fixed', bottom: { xs: '75px', sm: '80px' }, right: { xs: '40px', lg: '72px' } }}
          size="small"
          color="primary"
          aria-label="setup">
          <img
            alt="Setup New Clinic"
            src="/static/images/SetupNewClinic.png"
            style={{ height: '4.5rem' }}
          />
        </Fab>
      ) : ''}
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
      <TSConfirmationDialog
        open={isClinicNotActivatedDialogOpen}
        sx={{
          '& .MuiButtonBase-root': {
            marginRight: 0
          }
        }}
      >
        <DialogContent sx={{ m: 1 }}>
          <Grid container>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }} >
              <WarningAmberIcon sx={{ color: 'passwordStrength.red', mr: 2 }} />
              <Typography variant='heading'>
                Clinic Not Activated
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant='label'>
                Please contact support to activate your clinic
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                size="small"
                color='primary'
                variant='contained'
                onClick={() => setIsClinicNotActivatedDialogOpen(false)}
                sx={{
                  width: '4rem',
                  height: '2rem',
                  mr: 0
                }}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </TSConfirmationDialog>
      <TSHomeSideBarDialog
        open={isClinicDetailsDialogOpen}
        TransitionComponent={Transition}
        slotProps = {{
          backdrop: {
            component: CustomHomeBackdrop
          }
        }}
        onClose={() => handleClinicDetailsDialogClick(false)}
      >
        <Grid container sx={{ my: 2 }}>
          <Grid item xs={10} alignContent='center' sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
            <Typography sx={{ ml: { lg: 4, sm: 2, md: 3, xs: 2 } }} variant="heading" component="div">
              Clinic Details
            </Typography>
          </Grid>
          <Grid item xs={2} justifyContent='flex-end' display='flex'
            sx={{ pr: 1, position: 'sticky', top: 0, zIndex: 2 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClinicDetailsDialogClick(false)}
            >
              <CloseIcon sx={{ fontSize: { xs: '1.5rem', lg: '2rem' } }}/>
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} px={{ sm: 0.1 }} sx={{ ml: { sm: -1, md: 0.5 }, position: 'sticky', top: 0, zIndex: 2 }}>
          <Tabs value={selectedClinicDetailsTab} onChange={handleClinicDetailsTabChange}
            variant='fullWidth'
            TabIndicatorProps={{ style: { height: '0px' } }}
          >
            {clinicDetailsTabsLabel.map((label, index) => (
              <Tab
                sx={{ pb: 0, height: '100%' }}
                key={index}
                label={
                  <Typography variant="label"
                    sx={{
                      textTransform: 'capitalize',
                      borderBottom: index == selectedClinicDetailsTab ? '4px solid #205295' : '0px',
                      px: 0.75, pb: 1
                    }}>
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
          <Grid item xs={12} sx={{ mt: -0.5, borderBottom: '1px solid #ddd' }} />
        </Grid>
        {selectedClinicDetailsTab === 0 && <Box sx={{ pl: { xs: 0, sm: 7 }, pt: 1, pb: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
          <ViewClinicInformation clinicId={selectedClinicId} showEdit={false} /></Box>}
        {selectedClinicDetailsTab === 1 && <Box sx={{ px: { xs: 2, sm: 7.5, lg: 8 },
          pt: 1.5, pb: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
          <ViewOpenHours clinicId={selectedClinicId} /></Box>}
        {selectedClinicDetailsTab === 2 && <Box sx={{ px: { xs: 2, sm: 7.5, lg: 8 },
          pt: 1.5, pb: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
          <ViewHolidayHours clinicId={selectedClinicId} /></Box>}
      </TSHomeSideBarDialog>
    </React.Fragment>
  );
};

export default Home;
