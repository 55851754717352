import React, { useState, useRef } from 'react';
import { Utils } from '../utils/UtilFunctions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Typography, Stack, Box, useMediaQuery } from '@mui/material';
import TSBackDrop from '../components/tscomponents/TSBackDrop';
import { IconButton, InputAdornment } from '@mui/material';
import { validateEmailId, validateNumber, validateStringForNull } from '../utils/FieldValidations';
import Services from '../utils/Services';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import APIData from '../utils/APIData';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SignUpLogoPanel from '../components/uicomponents/SignUpLogoPanel';
import { UiUtils } from '../utils/UiUtilFunctions';
import { API_RESPONSE_TYPE } from '../utils/EnumDefinitions';
import TSCustomServerStatus from '../components/tscomponents/TSCustomServerStatus';

const ResetPassword = () => {
  const navigate = useNavigate();
  const isXsScreen = useMediaQuery(UiUtils.xsScreenMaxWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [clientErrMsg, setClientErrMsg] = useState({});
  const [serverMsg, setServerMsg] = useState({});
  const [passwordChangeDetails, setPasswordChangeDetails] = useState({
    username: '',
    reset_code: ['D', 'K', '', '', '', '', '', ''],
    password: '',
    confirm_password: ''
  });
  const otpFieldsRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const APIToken = {
    GET_OTP_TO_RESET_PASSWORD: 'PRP01',
    VERIFY_OTP_TO_RESET_PASSWORD: 'PRP02',
    RESET_PASSWORD: 'PRP03'
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPasswordClick = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleShowConfirmPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    const successMsg = {};
    successMsg.type = API_RESPONSE_TYPE.success;
    if (apiData == APIData.getOtpToResetPassword && apiToken == APIToken.GET_OTP_TO_RESET_PASSWORD) {
      successMsg.message = 'OTP sent successfully to your email';
      setActiveStep(activeStep + 1);
    } else if (apiData == APIData.verifyOtpToResetPassword && apiToken == APIToken.VERIFY_OTP_TO_RESET_PASSWORD ) {
      successMsg.message = 'OTP verified successfully';
      setActiveStep(activeStep + 1);
    } else if (apiData == APIData.resetPassword && apiToken == APIToken.RESET_PASSWORD ) {
      successMsg.message = 'Password reset successful. Redirecting to login page..';
      setTimeout(() => navigate('/signin'), 3000);
    }
    setServerMsg(successMsg);
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    const errMsg = {};
    if (apiData == APIData.getOtpToResetPassword && apiToken == APIToken.GET_OTP_TO_RESET_PASSWORD ) {
      defaultMsg = 'Unable to retrieve OTP';
    } else if (apiData == APIData.verifyOtpToResetPassword && apiToken == APIToken.VERIFY_OTP_TO_RESET_PASSWORD ) {
      defaultMsg = 'OTP verification failed';
    } else if (apiData == APIData.resetPassword && apiToken == APIToken.RESET_PASSWORD ) {
      defaultMsg = 'Failed to reset password';
    }
    errMsg.message = err.message ?? defaultMsg;
    errMsg.type = API_RESPONSE_TYPE.error;
    setServerMsg(errMsg);
    setIsLoading(false);
  };

  const raiseGetOTPRequest = (inputParams) => {
    setIsLoading(true);
    setServerMsg({});
    const finalParams = {
      username: inputParams.username
    };
    Services.sendBackendRequest({ apiData: APIData.getOtpToResetPassword, params: finalParams },
      APIToken.GET_OTP_TO_RESET_PASSWORD, processSuccess, processError);
  };

  const raiseVerifyOTPRequest = (inputParams) => {
    setIsLoading(true);
    setServerMsg({});
    const finalParams = {
      username: inputParams.username,
      reset_code: inputParams.reset_code
    };
    Services.sendBackendRequest({ apiData: APIData.verifyOtpToResetPassword, params: finalParams },
      APIToken.VERIFY_OTP_TO_RESET_PASSWORD, processSuccess, processError);
  };

  const raiseResetPasswordRequest = (inputParams) => {
    setIsLoading(true);
    setServerMsg({});
    const finalParams = {
      username: inputParams.username,
      reset_code: inputParams.reset_code,
      password: inputParams.password
    };
    Services.sendBackendRequest({ apiData: APIData.resetPassword, params: finalParams },
      APIToken.RESET_PASSWORD, processSuccess, processError);
  };

  const validateParams = ( params) => {
    const clientErr = {};
    let isValid = true;
    switch (activeStep) {
    case 0:
      if (!validateEmailId(params.username)) {
        clientErr.username = 'Invalid Email Address';
        isValid = false;
      };
      setClientErrMsg(clientErr);
      return isValid;
    case 1:
      for (let i=2; i< passwordChangeDetails.reset_code.length; i++ ) {
        if (!validateNumber(passwordChangeDetails.reset_code[i])) {
          if (clientErr.reset_code == undefined) {
            clientErr.reset_code = [];
          }
          clientErr.reset_code.push(i);
          isValid = false;
        }
      }
      if (isValid == false) {
        clientErr.reset_code_msg = 'Invalid OTP';
      }
      setClientErrMsg(clientErr);
      return isValid;
    case 2:
      if ((!validateStringForNull(params.password)) ) {
        clientErr.password = 'Invalid Password';
      }
      if ((!validateStringForNull(params.confirm_password)) ) {
        clientErr.confirm_password = 'Invalid confirm password';
      } else if (params.password != params.confirm_password) {
        clientErr.confirm_password = 'Password Mismatched';
      };
      isValid = Object.keys(clientErr).length == 0;
      setClientErrMsg(clientErr);
      return isValid;
    }
  };

  const getFinalParams = (step) => {
    const finalParams = {};
    switch (step) {
    case 0:
      finalParams.username = passwordChangeDetails.username;
      return finalParams;
    case 1:
      const otp = passwordChangeDetails.reset_code.join('');
      finalParams.username = passwordChangeDetails.username;
      finalParams.reset_code = otp;
      return finalParams;
    case 2:
      const otpValue = passwordChangeDetails.reset_code.join('');
      finalParams.username = passwordChangeDetails.username;
      finalParams.reset_code = otpValue;
      finalParams.password = passwordChangeDetails.password;
      finalParams.confirm_password = passwordChangeDetails.confirm_password;
      return finalParams;
    }
  };

  const handleGetOtpClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(passwordChangeDetails);
      if (validateParams(params)) {
        const finalParams = getFinalParams(activeStep);
        raiseGetOTPRequest(finalParams);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      const errMsg = {
        message: err.message ?? 'Failed to get OTP',
        type: API_RESPONSE_TYPE.error
      };
      setServerMsg(errMsg);
      setIsLoading(false);
    }
  };

  const handleVerifyOtpClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(passwordChangeDetails);
      if (validateParams(params)) {
        const finalParams = getFinalParams(activeStep);
        raiseVerifyOTPRequest(finalParams);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      const errMsg = {
        message: err.message ?? 'OTP Verification Failed',
        type: API_RESPONSE_TYPE.error
      };
      setServerMsg(errMsg);
      setIsLoading(false);
    }
  };

  const handlePasswordChangeClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(passwordChangeDetails);
      if (validateParams(params)) {
        const finalParams = getFinalParams(activeStep);
        raiseResetPasswordRequest(finalParams);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      const errMsg = {
        message: err.message ?? 'Password reset failed',
        type: API_RESPONSE_TYPE.error
      };
      setServerMsg(errMsg);
      setIsLoading(false);
    }
  };

  const handleTextChange = (event) => {
    const errObj = cloneDeep(clientErrMsg);
    delete errObj[event.target.name];
    setClientErrMsg(errObj);
    setPasswordChangeDetails({ ...passwordChangeDetails, [event.target.name]: event.target.value });
  };

  const handleGoBackClick = () => {
    setActiveStep(0);
  };

  const passwordStrength = Utils.evaluatePasswordStrength(passwordChangeDetails.password);
  const pwStrengthObj = UiUtils.getPasswordStrengthTextAndImage(passwordStrength);

  const handleOTPTextChange = (index, value) => {
    if (value.length > 1) {
      value = value[0];
    }
    const newOtpValues = cloneDeep(passwordChangeDetails.reset_code);
    newOtpValues[index] = value;
    setPasswordChangeDetails({ ...passwordChangeDetails,
      reset_code: newOtpValues });
    if (value && index < passwordChangeDetails.reset_code.length - 1) {
      otpFieldsRefs[index + 1].current.focus();
    }
  };

  const handleGoHomeClick = () => {
    navigate('/signin');
  };

  const getStepContent = (step) => {
    switch (step) {
    case 0:
      return (
        <Grid item container xs={12}
          sx={{ backgroundColor: 'white', borderRadius: '15px',
            px: {
              lg: 12,
              xs: 2,
              sm: 12,
              md: 8
            }
          }}>
          <Stack sx={{ width: '100%', pt: { xs: 5, lg: 7.5 } }} spacing={2}>
            <Stack display= 'flex' alignItems='flex-start'>
              <IconButton
                edge="start"
                color="main.primary"
                onClick={handleGoHomeClick}
                aria-label="close"
              >
                <ArrowBackIcon />
              </IconButton>
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <Typography variant='heading'>Find Your Account</Typography>
              <Typography variant='body' sx={{ mt: 0.5 }}>Please type your Email or Mobile Number below</Typography>
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <Typography variant='label' sx={{ mt: 1 }}>Email or Phone Number*</Typography>
              <TextField
                error = {validateStringForNull(clientErrMsg.username)}
                margin="normal"
                required
                fullWidth
                id="username"
                name="username"
                autoComplete ='off'
                sx={UiUtils.getTextFieldStyle('username', clientErrMsg)}
                onChange={(event) => {handleTextChange(event);}}
                autoFocus
                size="small"
                value={passwordChangeDetails.username}
                helperText = {clientErrMsg.username}
              />
            </Stack>
            <TSCustomServerStatus status={serverMsg} />
            <Stack alignItems='center' >
              <Button
                type='submit'
                fullWidth
                size="small"
                variant="contained"
                onClick={handleGetOtpClick}
                sx={{
                  mt: 1, mb: 5,
                  height: '2.5rem',
                  width: '12rem',
                  borderRadius: '2rem'
                }}
              >
                Send Code
              </Button>
            </Stack>
          </Stack>
        </Grid>
      );
    case 1:
      return (
        <Grid item container xs={12}
          sx={{ backgroundColor: 'white', borderRadius: '15px',
            px: {
              lg: 12,
              xs: 2,
              sm: 12,
              md: 8
            }
          }}>
          <Stack sx={{ width: '100%', pt: { xs: 4, sm: 5, md: 6, lg: 7.5 } }} spacing={2}>
            <Stack display= 'flex' alignItems='flex-start'>
              <IconButton
                edge="start"
                color="main.primary"
                onClick={handleGoBackClick}
                aria-label="close"
              >
                <ArrowBackIcon />
              </IconButton>
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <Typography variant='heading'>Verification</Typography>
              <Typography variant='body' sx={{ mt: 0.5 }}>We have sent a verification code to your registered
                phone number and email </Typography>
            </Stack>
            <Stack direction='row' spacing={1} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              {passwordChangeDetails.reset_code.map((value, index) => (
                <Stack key={index}>
                  <TextField
                    error = {clientErrMsg.reset_code != undefined && clientErrMsg.reset_code.includes(index)}
                    inputRef={otpFieldsRefs[index]}
                    margin="normal"
                    required
                    fullWidth
                    id={`otp-${index}`}
                    name={`otp_${index}`}
                    autoFocus={index === 2}
                    disabled = {index == 0 || index == 1 ? true : false}
                    size="small"
                    autoComplete="off"
                    value={value}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    inputProps={{
                      style: {
                        height: isXsScreen ? '1rem' : '1.5rem',
                        width: isXsScreen ? '1rem' : '1.5rem',
                        textAlign: 'center',
                        fontSize: isXsScreen? 'medium' :'large'
                      }
                    }}
                    onChange={(event) => handleOTPTextChange(index, event.target.value)}
                  />
                </Stack>
              ))}
            </Stack>
            <Stack>
              {clientErrMsg.reset_code_msg != undefined ? (
                <Typography variant='errorText'>
                  {clientErrMsg.reset_code_msg}
                </Typography>
              ) : ''}
            </Stack>
            <TSCustomServerStatus status={serverMsg} />
            <Stack alignItems='center'>
              <Button
                type="button"
                fullWidth
                variant="contained"
                size="small"
                onClick={handleVerifyOtpClick}
                sx={{ height: '2.5rem', width: '12rem', borderRadius: '2rem', mb: 0 }}
                disabled={passwordChangeDetails.reset_code.some((value) => !value)}
              >
                Verify
              </Button>
            </Stack>
            <Stack alignItems='center'>
              <Typography variant='label' sx={{ mb: { sm: 4 } }}>
                Didn&apos;t get code?{' '}
                <Typography variant='hyperLinks'>
                  <Link
                    onClick={handleGetOtpClick}
                    sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                    Resend Code
                  </Link>
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      );
    case 2:
      return (
        <Grid item container xs={12}
          sx={{ backgroundColor: 'white', borderRadius: '15px',
            px: {
              lg: 12,
              xs: 2,
              sm: 12,
              md: 8
            }
          }}>
          <Stack sx={{ width: '100%', pt: { xs: 4, sm: 5, md: 6, lg: 7.5 } }} spacing={2}>
            <Stack sx={{ width: '100%' }}>
              <Typography variant='heading'> Change Password </Typography>
              <Typography variant='body' sx={{ mt: 0.5 }}>Please type your new password</Typography>
            </Stack>
            <Stack>
              <Typography variant='label'>Password*</Typography>
              <TextField
                error = {validateStringForNull(clientErrMsg.password)}
                margin="normal"
                required
                fullWidth
                id="password"
                size="small"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete= 'new-password'
                value={passwordChangeDetails.password}
                onChange={(event) => {handleTextChange(event);}}
                sx={UiUtils.getTextFieldStyle('password', clientErrMsg)}
                helperText = {clientErrMsg.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {validateStringForNull(passwordChangeDetails.password) ?
                        <img src={pwStrengthObj.indicatorImage} width='58px' /> : ''}
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordClick}
                        onMouseDown={handleShowPasswordClick}
                        sx={{ color: 'inputAdornment.color' }}
                      >
                        {showPassword ? <VisibilityOff sx={{ fontSize: 'inputAdornment.fontSize' }} /> :
                          <Visibility sx={{ fontSize: 'inputAdornment.fontSize' }} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {validateStringForNull(passwordChangeDetails.password) ? (
                <React.Fragment>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <img
                      src={pwStrengthObj.textImage}
                      style={{ marginRight: '5px', height: '0.8 rem', width: 'auto', verticalAlign: 'middle' }}
                    />
                    <Typography variant='body' style={{ color: pwStrengthObj.color, verticalAlign: 'middle' }}>
                      {pwStrengthObj.text}
                    </Typography>
                  </Box>
                </React.Fragment>
              ) : '' }
            </Stack>
            <Stack>
              <Typography variant='label'>Confirm New Password*</Typography>
              <TextField
                error = {validateStringForNull(clientErrMsg.confirm_password)}
                margin="normal"
                required
                fullWidth
                id="confirm_password"
                name="confirm_password"
                size="small"
                type={showConfirmPassword ? 'text' : 'password'}
                autoComplete= 'new-password'
                value={passwordChangeDetails.confirm_password}
                onChange={(event) => {handleTextChange(event);}}
                sx={UiUtils.getTextFieldStyle('confirm_password', clientErrMsg)}
                helperText = {clientErrMsg.confirm_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPasswordClick}
                        onMouseDown={handleShowConfirmPasswordClick}
                        sx={{ color: 'inputAdornment.color' }}
                      >
                        {showConfirmPassword ? <VisibilityOff sx={{ fontSize: 'inputAdornment.fontSize' }} /> :
                          <Visibility sx={{ fontSize: 'inputAdornment.fontSize' }} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
            <TSCustomServerStatus status={serverMsg} />
            <Stack alignItems='center' sx={{ mt: 4 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="small"
                sx={{ height: '2.5rem', width: '12rem', borderRadius: '2rem', mb: 2, mb: 5 }}
                onClick={handlePasswordChangeClick}
              >
                Change Password
              </Button>
            </Stack>
          </Stack>
        </Grid>
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title> Reset Password - DocKit </title>
      </Helmet>
      <Grid
        container
        sx={{ backgroundColor: 'main.primary', minHeight: '100vh' }}
      >
        <SignUpLogoPanel showVerificationImage={activeStep == 1} />
        <Grid item container xs={12} md={6} sx={{ p: 2, overflowY: { xs: 'scroll', sm: 'scroll', md: 'unset' },
          minHeight: { xs: '60vh', sm: '65vh', md: '100vh' } }}>
          {getStepContent(activeStep)}
        </Grid>
      </Grid>
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

export default ResetPassword;
