import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import { validateStringForNull } from '../../utils/FieldValidations';
import { API_RESPONSE_TYPE } from '../../utils/EnumDefinitions';

const TSCustomServerStatus = ({ status }) => {
  return validateStringForNull(status.type) ? (
    <Stack alignItems='center' sx={{ mt: 2 }}>
      <Box
        sx={{
          width: '100%',
          height: '2.1rem',
          backgroundColor: status.type == API_RESPONSE_TYPE.error ? 'serverStatus.errorBoxBackground' : 'serverStatus.successBoxBackground',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant= {status.type == API_RESPONSE_TYPE.error ? 'errorText' : 'successText'}
          textAlign='center'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {status.type == API_RESPONSE_TYPE.error ? <WarningIcon size='small' sx={{ fontSize: '1.3rem', mr: 1 }} /> :
            <DoneIcon size='small' sx={{ fontSize: '1.3rem', mr: 1 }} />}
          {validateStringForNull(status.message) ? status.message : 'Unhandled exception'}
        </Typography>
      </Box>
    </Stack>
  ) : '';
};

TSCustomServerStatus.propTypes = {
  status: PropTypes.object
};

export default TSCustomServerStatus;
