import { Navigate } from 'react-router-dom';
import HomeLayout from './components/layouts/HomeLayout';
import MainLayout from './components/layouts/MainLayout';
import AddClinic from './pages/AddClinic';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import ClinicHome from './pages/ClinicHome';
import ClinicLayout from './components/layouts/ClinicLayout';
import ModifyClinicInformation from './pages/ModifyClinicInformation';
import ManageAppointments from './pages/ManageAppointments';
import CreateNewAppointment from './pages/CreateNewAppointment';
import Logout from './pages/Logout';
import UserClinicInformation from './pages/UserClinicInformation';
import ModifyClinicTimings from './pages/ModifyClinicTimings';
import ModifyClinicContactInformation from './pages/ModifyClinicContactInformation';
import ModifyUserClinicTimings from './pages/ModifyUserClinicTimings';
import ModifyUserClinicContactInformation from './pages/ModifyUserClinicContactInformation';
import ManageStaff from './pages/ManageStaff';
import ClinicInvite from './pages/ClinicInvite';
import ManageOp from './pages/ManageOp';
import SearchPatients from './pages/SearchPatients';
import ClinicSettings from './pages/ClnicSettings';
import ClinicStaffSettings from './pages/ClinicStaffSettings';
import Forbidden from './pages/Forbidden';

const Routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'signin', element: <SignIn /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'resetpassword', element: <ResetPassword /> },
      { path: 'logout', element: <Logout /> },
      { path: 'invite', element: <ClinicInvite /> },
      { path: '*', element: <NotFound /> },
      { path: '', element: <Navigate to='/home' /> },
      { path: '403', element: <Forbidden /> },
      { path: '404', element: <NotFound /> }
    ]
  },
  {
    path: '/home',
    element: <HomeLayout />,
    children: [
      { path: '', element: <Home /> },
      { path: 'setup-clinic', element: <AddClinic /> },
      { path: '*', element: <Navigate to='/404' /> },
      { path: '403', element: <Forbidden /> },
      { path: '404', element: <Navigate to='/404' /> }
    ]
  },
  {
    path: '/clinic',
    element: <ClinicLayout />,
    children: [
      { path: 'modify/details', element: <ModifyClinicInformation /> },
      { path: 'modify/timings', element: <ModifyClinicTimings /> },
      { path: 'modify/contact', element: <ModifyClinicContactInformation /> },
      { path: 'view/details', element: <UserClinicInformation /> },
      { path: 'appointments/create', element: <CreateNewAppointment /> },
      { path: 'appointments/manage', element: <ManageAppointments isDoctor={false}/> },
      { path: 'doctor-appointments/manage', element: <ManageAppointments isDoctor={true} /> },
      { path: 'op/manage', element: <ManageOp /> },
      { path: 'modify/user/timings', element: <ModifyUserClinicTimings /> },
      { path: 'modify/user/contact', element: <ModifyUserClinicContactInformation /> },
      { path: 'manage/staff', element: <ManageStaff /> },
      { path: 'patient-appointments/search', element: <SearchPatients /> },
      { path: 'settings', element: <ClinicSettings /> },
      { path: 'staff/settings', element: <ClinicStaffSettings /> },
      { path: '', element: <ClinicHome /> },
      { path: '*', element: <Navigate to='/404' /> },
      { path: '403', element: <Forbidden /> },
      { path: '404', element: <Navigate to='/404' /> }
    ]
  }
];

export default Routes;
