import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OpSummaryCard from './OpSummaryCard';
import SummaryChart from './SummaryChart';
import Services from '../../utils/Services';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { Utils } from '../../utils/UtilFunctions';
import TSSnackbar from '../tscomponents/TSSnackbar';
import PropTypes from 'prop-types';
import APIData from '../../utils/APIData';

const OpSummary = ({ clinicId, opId }) => {
  const [opSummaryDetails, setOpSummaryDetails] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const APIToken = {
    GET_OP_SUMMARY: 'COS01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const getDoctorDetails = () => {
    if ( opSummaryDetails.degree != undefined) {
      return ( `(${opSummaryDetails.degree} ${opSummaryDetails.specialization})` );
    }
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getOpSummary && apiToken == APIToken.GET_OP_SUMMARY ) {
      setOpSummaryDetails(response.data);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.getOpSummary && apiToken == APIToken.GET_OP_SUMMARY) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Failed to get OP summary'
      });
    }
    setIsLoading(false);
  };

  const raiseGetOpSummaryRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getOpSummary, uriValues: [clinicId, opId] },
      APIToken.GET_OP_SUMMARY, processSuccess, processError);
  };

  useEffect(() => {
    if (clinicId != undefined) {
      raiseGetOpSummaryRequest();
    }
  }, []);

  return (
    <React.Fragment>
      {opSummaryDetails != undefined ? (
        <Grid container sx={{ mb: 5 }} rowSpacing={2}>
          <Grid item container rowSpacing={2}>
            <Grid item container xs={12} sm={12} rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Card sx={{ minHeight: 150 }}>
                  <CardContent>
                    <Typography sx={{ m: 1 }}>
                      <strong> {opSummaryDetails.doctor_name} </strong>{getDoctorDetails()}
                    </Typography>
                    <Typography variant='h6' sx={{ m: 1 }} color="textSecondary" >
                      {opSummaryDetails.op_date != undefined ? opSummaryDetails.op_date.display : ''} |
                      {opSummaryDetails.op_start_time.display} - {opSummaryDetails.op_end_time.display}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card sx={{ minHeight: 150 }}>
                  <CardContent>
                    <Typography sx={{ m: 1, mt: 0 }} variant='h6' color="#555555">
                      OP Duration
                    </Typography>
                    <Typography variant='h4' sx={{ m: 1 }} >
                      <strong> {opSummaryDetails.op_duration.display}</strong>
                    </Typography>
                    {opSummaryDetails.op_start_delayed_by != undefined ? (
                      <Typography variant='h6' sx={{ m: 1 }} color='textSecondary'>
                       Delayed by {opSummaryDetails.op_start_delayed_by.display}{' '}and
                       Extented by {opSummaryDetails.op_end_delayed_by.display}
                      </Typography>
                    ) : ''}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {opSummaryDetails.consulted_appointments_chart != undefined ? (
              <Grid item xs={12} sm={12}>
                <SummaryChart title='Consulted Patient' chartData={opSummaryDetails.consulted_appointments_chart} />
              </Grid>
            ) : ''}
          </Grid>
          <Grid item container direction='row' columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='Patients Consulted' data={
                {
                  value: opSummaryDetails.consulted_appointments.value,
                  increased: opSummaryDetails.consulted_appointments.is_increased != undefined ?
                    (opSummaryDetails.consulted_appointments.is_increased) : undefined,
                  percent_change: opSummaryDetails.consulted_appointments.percent != undefined ?
                    (opSummaryDetails.consulted_appointments.percent) : '',
                  op_day: opSummaryDetails.day.display
                }}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='Average Consulting Time' data={
                {
                  value: opSummaryDetails.average_patient_consulting_time != undefined ?
                    (opSummaryDetails.average_patient_consulting_time.display): '',
                  increased: opSummaryDetails.average_patient_consulting_time.is_increased != undefined ?
                    (opSummaryDetails.average_patient_consulting_time.is_increased): undefined,
                  percent_change: opSummaryDetails.average_patient_consulting_time.percent != undefined ?
                    (opSummaryDetails.average_patient_consulting_time.percent): '',
                  op_day: opSummaryDetails.day.display
                }
              }/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='Average Waiting Time' data={
                {
                  value: opSummaryDetails.average_patient_waiting_time != undefined ?
                    (opSummaryDetails.average_patient_waiting_time.display) : '',
                  increased: opSummaryDetails.average_patient_waiting_time.is_increased != undefined ?
                    (opSummaryDetails.average_patient_waiting_time.is_increased) : undefined,
                  percent_change: opSummaryDetails.average_patient_waiting_time.percent != undefined ?
                    (opSummaryDetails.average_patient_waiting_time.percent) : '',
                  op_day: opSummaryDetails.day.display
                }
              }/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='Max Waiting Time' data={
                {
                  value: opSummaryDetails.max_patient_waiting_time != undefined ?
                    (opSummaryDetails.max_patient_waiting_time.display) : '',
                  increased: opSummaryDetails.max_patient_waiting_time.is_increased != undefined ?
                    (opSummaryDetails.max_patient_waiting_time.is_increased) : undefined,
                  percent_change: opSummaryDetails.max_patient_waiting_time.percent != undefined ?
                    (opSummaryDetails.max_patient_waiting_time.percent) : '',
                  op_day: opSummaryDetails.day.display
                }
              }/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='Max Consulting Time' data={
                {
                  value: opSummaryDetails.max_patient_consulting_time != undefined ?
                    (opSummaryDetails.max_patient_consulting_time.display): '',
                  increased: opSummaryDetails.max_patient_consulting_time.is_increased != undefined ?
                    (opSummaryDetails.max_patient_consulting_time.is_increased): undefined,
                  percent_change: opSummaryDetails.max_patient_consulting_time.percent != undefined ?
                    (opSummaryDetails.max_patient_consulting_time.percent): '',
                  op_day: opSummaryDetails.day.display
                }
              }/>
            </Grid>
            {opSummaryDetails.peak_hours != undefined ? (
              <Grid item xs={12} sm={6} md={4} >
                <OpSummaryCard title='Peak Hours' data={
                  {
                    value: opSummaryDetails.peak_hours.start_time.display + ' - ' + opSummaryDetails.peak_hours.end_time.display,
                    description: opSummaryDetails.peak_hours.consulted_appointments + ' Patients'
                  }
                }/>
              </Grid>
            ) : ''}
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='New Patients' data={
                {
                  value: opSummaryDetails.new_patients_count
                }
              }/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='Late Appointments' data={
                {
                  value: opSummaryDetails.late_appointments
                }
              }/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='Cancelled Appointments' data={
                {
                  value: opSummaryDetails.cancelled_appointments
                }
              }/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <OpSummaryCard title='No Show Appointments' data={
                {
                  value: opSummaryDetails.no_show_appointments
                }
              }/>
            </Grid>
          </Grid>
        </Grid>
      ) : ''}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

OpSummary.propTypes = {
  clinicId: PropTypes.string,
  opId: PropTypes.string
};

export default OpSummary;
