import moment from 'moment';
import { validateStringForNull } from './FieldValidations';
import Services from './Services';

let reconnectFrequencyInSeconds = 1;
let sseObj = undefined;
let clinicId = undefined;
let opId = undefined;
let messageCallbackFunc = undefined;
let openCallbackFunc = undefined;
let reconnectCallQueued = false;
const isLoggingEnabled = false;

const logConsoleMsg = (msg) => {
  if (isLoggingEnabled == true) {
    console.log(moment().format('HH:mm:ss') + msg);
  }
};

const waitFunc = () => {
  return reconnectFrequencyInSeconds * 1000;
};

const tryToSetup = () => {
  logConsoleMsg('Reconnecting...');
  createEvent();
  reconnectFrequencyInSeconds *= 2;
  if (reconnectFrequencyInSeconds >= 64) {
    reconnectFrequencyInSeconds = 64;
  }
};

const reconnect = () => {
  const wait = waitFunc();
  logConsoleMsg('Will reconnect after ' + wait);
  setTimeout(tryToSetup, wait);
  reconnectCallQueued = true;
};

const createEvent = () => {
  reconnectCallQueued = false;
  logConsoleMsg('Creating SSE...');
  sseObj = undefined;
  if (validateStringForNull(clinicId) && validateStringForNull(opId)) {
    sseObj = Services.getSSEEventSource(clinicId, opId);
    sseObj.onopen = () => {
      logConsoleMsg('Connection Opened..');
      reconnectFrequencyInSeconds = 1;
      if (openCallbackFunc != undefined) {
        openCallbackFunc();
      }
    };
    sseObj.onmessage = (e) => {
      if (messageCallbackFunc != undefined) {
        messageCallbackFunc(JSON.parse(e.data));
      }
    };
    sseObj.onerror = (e) => {
      logConsoleMsg('Exception occurred..');
      sseObj.close();
      if (reconnectCallQueued == false && e.status != 200) {
        reconnect();
      }
    };
    sseObj.addEventListener('COMPLETED', () => {
      logConsoleMsg('Connection closed..');
      sseObj.close();
    });
  }
};

const subscribe = (clinic, op, msgCallback, openCallback) => {
  clinicId = clinic;
  opId = op;
  messageCallbackFunc = msgCallback;
  openCallbackFunc = openCallback;
  createEvent();
};

const unsubscribe = () => {
  if (sseObj != undefined) {
    sseObj.close();
  }
};

export { subscribe, unsubscribe };
