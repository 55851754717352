import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  DialogActions,
  DialogContent,
  Dialog,
  TextField,
  Tooltip,
  InputLabel
} from '@mui/material';
import { Utils } from '../../utils/UtilFunctions';
import TSSnackbar from '../tscomponents/TSSnackbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DAYS_OF_WEEK } from '../../utils/EnumDefinitions';
import { LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers';
import { validateDate, validateSettings, validateStringForNull } from '../../utils/FieldValidations';
import moment from 'moment';
import Services from '../../utils/Services';
import cloneDeep from 'lodash/cloneDeep';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import APIData from '../../utils/APIData';
import SettingsIcon from '@mui/icons-material/Settings';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import InfoIcon from '@mui/icons-material/Info';
import TSBackDrop from '../tscomponents/TSBackDrop';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ManageOpenHours = ({ clinicId, staffId, onSuccessfulCommit, isClinicSetupInProgress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateConfirmationDialogOpen, setIsUpdateConfirmationDialogOpen] = useState(false);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [selectedSessionSettings, setSelectedSessionSettings] = useState([]);
  const [defaultSettings, setDefaultSettings] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const APIToken = {
    GET_OPEN_HOURS: 'CMOH01',
    GET_TIMING_DEFAULT_SETTINGS: 'CMOH02',
    MODIFY_OPEN_HOURS: 'CMOH03'
  };

  const getInitialOpenHours = (defSettings) => {
    const arr = [];
    for (let i = 1; i <= 7; i++) {
      const obj = {
        day: i,
        timings: [{
          id: 1, start_time: null, end_time: null, settings: defSettings
        }],
        isClosed: false
      };
      arr.push(obj);
    }
    return arr;
  };

  const [openHours, setOpenHours] = useState(getInitialOpenHours(defaultSettings));
  const [originalValue, setOriginalValue] = useState(getInitialOpenHours(defaultSettings));

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetTimingsDefaultSettings();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if ( apiToken == APIToken.MODIFY_OPEN_HOURS ) {
      if (apiData == APIData.modifyStaffOpenHoursDetails || apiData == APIData.updateClinicOpenTimings) {
        if (onSuccessfulCommit != undefined) {
          onSuccessfulCommit();
        } else {
          showSnackBar('success', response.message ?? 'Timings updated successfully');
          raiseGetOpenHoursDetails();
        }
      }
    } else if ( apiToken == APIToken.GET_TIMING_DEFAULT_SETTINGS ) {
      if (apiData == APIData.getClinicStaffTimingsDefaultSettings || apiData == APIData.getClinicTimingsDefaultSettings ) {
        if (response.data.length > 0) {
          setShowSettings(true);
        }
        const data = response.data;
        setDefaultSettings(data);
        if (isClinicSetupInProgress != true) {
          raiseGetOpenHoursDetails(data);
        } else {
          setOpenHoursDetails([], data);
          setIsLoading(false);
        }
      }
    } else if (apiToken == APIToken.GET_OPEN_HOURS ) {
      if (apiData == APIData.getStaffOpenHoursDetails) {
        setOpenHoursDetails(response.data, callbackValues.settings);
      } else if (apiData == APIData.getClinicDetails) {
        setOpenHoursDetails(response.data.open_hours, callbackValues.settings);
      }
      setIsLoading(false);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiToken == APIToken.MODIFY_OPEN_HOURS && (apiData == APIData.modifyStaffOpenHoursDetails || apiData == APIData.updateClinicOpenTimings)) {
      defaultMsg = 'Failed to Update timings';
    } else if (apiToken == APIToken.GET_TIMING_DEFAULT_SETTINGS &&
     (apiData == APIData.getClinicStaffTimingsDefaultSettings || apiData == APIData.getClinicTimingsDefaultSettings)) {
      defaultMsg = 'Failed to retrieve settings';
    } else if (apiToken == APIToken.GET_OPEN_HOURS && (apiData == APIData.getStaffOpenHoursDetails || apiData == APIData.getClinicDetails)) {
      defaultMsg = 'Failed to retrieve Open Hours';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const validateParams = (inputparams) => {
    if (!validateStringForNull(clinicId)) {
      throw new Error('Invalid Clinic');
    }

    inputparams.forEach((param) => {
      param.day = parseInt(param.day);
      if (param.start_time == null || !validateDate(param.start_time)) {
        throw new Error('Invalid timings - select start time for ' + DAYS_OF_WEEK[param.day]);
      } else if (param.end_time == null || !validateDate(param.end_time)) {
        throw new Error('Invalid timings - select end time for ' + DAYS_OF_WEEK[param.day]);
      }
      param.start_time = moment(param.start_time).format('HH:mm:ss');
      param.end_time = moment(param.end_time).format('HH:mm:ss');
      if (validateStringForNull(param.settings)) {
        param.settings = validateSettings(param.settings);
      }
    });
    return inputparams;
  };

  const raiseRequest = (inputParams) => {
    setIsLoading(true);
    setIsUpdateConfirmationDialogOpen(false);
    const bodyParams = {
      open_hours: inputParams
    };
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.modifyStaffOpenHoursDetails, uriValues: [clinicId, staffId], params: bodyParams },
        APIToken.MODIFY_OPEN_HOURS, processSuccess, processError);
    } else {
      Services.sendBackendRequest({ apiData: APIData.updateClinicOpenTimings, uriValues: [clinicId], params: bodyParams },
        APIToken.MODIFY_OPEN_HOURS, processSuccess, processError);
    }
  };

  const raiseGetTimingsDefaultSettings = () => {
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getClinicStaffTimingsDefaultSettings, uriValues: [clinicId, staffId] },
        APIToken.GET_TIMING_DEFAULT_SETTINGS, processSuccess, processError);
    } else {
      Services.sendBackendRequest({ apiData: APIData.getClinicTimingsDefaultSettings, uriValues: [clinicId] },
        APIToken.GET_TIMING_DEFAULT_SETTINGS, processSuccess, processError);
    }
  };

  const getInputParams = () => {
    const params = [];
    openHours.map((dayObj) => {
      if (!dayObj.isClosed) {
        dayObj.timings.map((session) => {
          const obj = {
            day: dayObj.day,
            start_time: session.start_time,
            end_time: session.end_time,
            settings: session.settings
          };
          params.push(obj);
        });
      }
    });
    return params;
  };

  const handleUpdateClick = () => {
    setIsLoading(true);
    try {
      const params = getInputParams();
      const filteredParams = validateParams(params);
      raiseRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Updating timings failed');
      setIsLoading(false);
    }
  };

  const getOpenHoursDetails = (data, defSettings) => {
    const openHour = [];
    const days=Object.keys(DAYS_OF_WEEK).length;
    for (let i = 1; i <= days; i++) {
      const filterObj = data.filter((obj) => (obj.day.value == i));
      const timingArray = [];
      if (filterObj.length === 0) {
        const timings = {
          id: 1,
          start_time: null,
          end_time: null,
          settings: defSettings
        };
        timingArray.push(timings);
      } else {
        filterObj.map((session, j) => {
          const timings = {
            id: j + 1,
            start_time: session.start_time !== undefined ? moment(session.start_time.value, 'HH:mm:ss') : null,
            end_time: session.end_time !== undefined ? moment(session.end_time.value, 'HH:mm:ss') : null,
            settings: session.settings
          };
          timingArray.push(timings);
        });
      }
      const obj = {
        day: i,
        timings: timingArray,
        isClosed: timingArray.length == 1 && timingArray[0].start_time == null && timingArray[0].end_time == null
      };
      openHour.push(obj);
    }
    return openHour;
  };

  const handleCheckboxChange = (event, day) => {
    const cloned = cloneDeep(openHours);
    const objIndex = cloned.findIndex((obj) => obj.day == day);
    cloned[objIndex].isClosed = event.target.checked;
    if (!event.target.checked) {
      if (cloned[objIndex].timings.length == 0) {
        const obj = [{
          id: 1, start_time: null, end_time: null, settings: defaultSettings
        }];
        cloned[objIndex].timings = obj;
      }
    }
    setOpenHours(cloned);
  };

  const handleResetClick = () => {
    const params = cloneDeep(originalValue);
    setOpenHours(params);
  };

  const handleAdd = (day) => {
    const cloned = cloneDeep(openHours);
    const objIndex = cloned.findIndex((obj) => obj.day == day);
    const timings = cloned[objIndex].timings;
    const obj = {
      id: timings.length == 0 ? 1 : timings[timings.length - 1].id + 1,
      start_time: null,
      end_time: null,
      settings: defaultSettings
    };
    cloned[objIndex].timings.push(obj);
    setOpenHours(cloned);
  };

  const copySessionsFromAbove = (day) => {
    const cloned = cloneDeep(openHours);
    const objIndex = cloned.findIndex((obj) => obj.day == day);
    cloned[objIndex].isClosed = cloned[objIndex - 1].isClosed;
    if (!cloned[objIndex].isClosed) {
      cloned[objIndex].timings = cloneDeep(cloned[objIndex - 1].timings);
    }
    setOpenHours(cloned);
  };

  const handleRemoveClick = (day, i) => {
    const cloned = cloneDeep(openHours);
    const objIndex = cloned.findIndex((obj) => obj.day == day);
    const timings = cloned[objIndex].timings;
    timings.splice(i, 1);
    if (timings.length == 0) {
      cloned[objIndex].isClosed = true;
    }
    setOpenHours(cloned);
  };

  const handleTimeChange = (day, key, value, index) => {
    const cloned = cloneDeep(openHours);
    const objIndex = cloned.findIndex((obj) => obj.day == day);
    const timings = cloned[objIndex].timings;
    timings[index][key] = value;
    setOpenHours(cloned);
  };

  const raiseGetOpenHoursDetails = (settings) => {
    const defSettings = settings == undefined ? defaultSettings : settings;
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getStaffOpenHoursDetails, uriValues: [clinicId, staffId] },
        APIToken.GET_OPEN_HOURS, processSuccess, processError, { settings: defSettings });
    } else {
      const finalParams = 'fetch_modules=open_hours';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: finalParams },
        APIToken.GET_OPEN_HOURS, processSuccess, processError, { settings: defSettings });
    }
  };

  const setOpenHoursDetails = (data, defSettings) => {
    let openHoursValue = undefined;
    if (isClinicSetupInProgress == true) {
      openHoursValue = getInitialOpenHours(defSettings);
    } else {
      openHoursValue = getOpenHoursDetails(data, defSettings);
    }
    setOpenHours(openHoursValue);
    const originalParams = cloneDeep(openHoursValue);
    setOriginalValue(originalParams);
  };

  const handleSettingsClick = (selectedDay, sessionId, settings) => {
    const obj = {
      day: selectedDay,
      id: sessionId,
      settings: settings
    };
    setSelectedSessionSettings(obj);
    setIsSettingsDialogOpen(true);
  };

  const handleTimingUpdateDialogOpen = () => {
    setIsUpdateConfirmationDialogOpen(true);
  };

  const handleSave = () => {
    setIsLoading(true);
    try {
      const params = getInputParams();
      const filteredParams = validateParams(params);
      raiseRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Updating clinic timings failed');
      setIsLoading(false);
    }
  };

  const handleSkip = () => {
    onSuccessfulCommit();
  };

  const handleSaveSettingsClick = () => {
    const { day, id, settings } = selectedSessionSettings;
    const cloned = cloneDeep(openHours);
    const objIndex = cloned.findIndex((obj) => obj.day === day);
    const timings = cloned[objIndex].timings;
    const timingIndex = timings.findIndex((timing) => timing.id === id);
    cloned[objIndex].timings[timingIndex].settings = settings;
    setOpenHours(cloned);
    setIsSettingsDialogOpen(false);
    setSelectedSessionSettings([]);
  };

  const handleSettingsInputChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const updatedSettings = {
      ...selectedSessionSettings,
      settings: selectedSessionSettings.settings.map((setting) => {
        if (setting.key === key) {
          return { ...setting, value };
        }
        return setting;
      })
    };
    setSelectedSessionSettings(updatedSettings);
  };

  const handleSettingsDialogCancelClick = () => {
    setSelectedSessionSettings([]);
    setIsSettingsDialogOpen(false);
  };

  const getGrids = () => {
    const gridArr= [];
    openHours.map((dayObj, i) => {
      gridArr.push(
        <Grid key={dayObj.day} container sx={{ mb: 2 }} direction='column'>
          <Grid item container columnSpacing={3} alignItems="center">
            <Grid item xs={12} sm={12} md={5} lg={4} container alignItems={'center'}>
              <Grid item xs={6} sm={6} md={6} lg={5}>
                <Typography variant="h6">
                  {DAYS_OF_WEEK[dayObj.day]}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={7}>
                <FormControlLabel
                  control={
                    <Checkbox checked={dayObj.isClosed} onChange={(event) => handleCheckboxChange(event, dayObj.day)}/>
                  }
                  label={<Typography variant="body2">Closed</Typography>}/>
                { i != 0 ? (
                  <Button
                    variant='contained'
                    startIcon={<ContentCopyIcon/>}
                    size='small'
                    color='primary'
                    onClick={() => copySessionsFromAbove(dayObj.day)}
                  >
                    From Above
                  </Button>
                ) : ''}
              </Grid>
            </Grid>
            <Grid container item xs={12} sm ={12} md={7} lg={8} sx={{ mt: 2 }} direction='column'>
              {dayObj.isClosed == false ? (
                dayObj.timings.map((session, i) => (
                  <React.Fragment key={session.id}>
                    <Grid container key={session.id} item xs={'auto'} sx={{ mb: 2 }}>
                      <Grid item xs={5.5} sm={4} sx={{ mr: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopTimePicker
                            ampm
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock
                            }}
                            name='start_time'
                            label='Opens at'
                            value={session.start_time}
                            maxTime={session.end_time}
                            sx={{ width: '100%' }}
                            size='small'
                            onChange={(e) => handleTimeChange(dayObj.day, 'start_time', e, i)}
                            slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={5.5} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopTimePicker
                            ampm
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock
                            }}
                            name='end_time'
                            label='Closes at'
                            value={session.end_time}
                            minTime={session.start_time}
                            sx={{ width: '100%' }}
                            size='small'
                            onChange={(e) => handleTimeChange(dayObj.day, 'end_time', e, i)}
                            slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={3} justifyContent='flex-end' display='flex'>
                        <IconButton onClick={() => handleAdd(dayObj.day)} color='primary'>
                          <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                        </IconButton>
                        <IconButton onClick={() => handleRemoveClick(dayObj.day, i)} color='error'>
                          <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                        </IconButton>
                        {showSettings == true ? (
                          <IconButton onClick={() => handleSettingsClick(dayObj.day, session.id, session.settings)} color='primary'>
                            <SettingsIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                          </IconButton>
                        ) : ''}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
              ) : ''}
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <Divider/>
          </Grid>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth='md' sx={{ backgroundColor: 'white' }}>
      <Box sx={{ p: 2 }}>
        {getGrids()}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{ mt: 2 }}
        >
          <Grid item>
            {isClinicSetupInProgress == true ? (
              <>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => handleSkip()}
                >
                  Skip
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => handleResetClick()}
                >
                  Reset
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleTimingUpdateDialogOpen}
                >
                  Update
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <TSConfirmationDialog
        open={isUpdateConfirmationDialogOpen}
      >
        <DialogContent>
          <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
            <strong>Confirmation</strong>
          </Typography>
          <Typography variant='h5'>
            Already created OP consultation sessions will not be affected by this change. Click YES to continue the update or NO to cancel.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            size="small"
            color='primary'
            onClick={() => setIsUpdateConfirmationDialogOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            color='primary'
            onClick={handleUpdateClick}
          >
            Yes
          </Button>
        </DialogActions>
      </TSConfirmationDialog>
      {selectedSessionSettings.settings != undefined && selectedSessionSettings.settings.length > 0 ? (
        <Dialog
          open={isSettingsDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle variant='h6'>Additional Settings</DialogTitle>
          <DialogContent>
            {selectedSessionSettings.settings.map((settings) => (
              <Grid container spacing={2} key={settings.key}>
                <Grid item xs={12}>
                  <InputLabel key={settings.key} style={{ fontSize: '0.8rem', whiteSpace: 'normal' }}>{settings.label}</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size='small'
                    name={settings.key}
                    id={settings.key}
                    value={settings.value ?? ''}
                    sx={{ mb: 1, color: 'white' }}
                    variant="standard"
                    onChange={(event) => handleSettingsInputChange(event)}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={settings.description}>
                          <IconButton>
                            <InfoIcon sx={{ fontSize: '1rem' }} />
                          </IconButton>
                        </Tooltip>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button size='small' onClick={handleSettingsDialogCancelClick}>Cancel</Button>
            <Button size='small' variant='contained' onClick={handleSaveSettingsClick}>Save</Button>
          </DialogActions>
        </Dialog>
      ) : ''}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading}/>
    </Container>
  );
};

ManageOpenHours.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string,
  onSuccessfulCommit: PropTypes.func,
  isClinicSetupInProgress: PropTypes.bool
};
export default ManageOpenHours;
