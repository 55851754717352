
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar, IconButton, Tooltip, Menu, MenuItem, Divider, Typography,
  ListItemIcon,
  ListItemText,
  Grid
} from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import React, { useState } from 'react';
import MyAccount from '../accountsettings/MyAccount';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import ModifySettings from '../settings/ModifySettings';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import { CookieUtils } from '../../utils/CookieUtils';
import { Utils } from '../../utils/UtilFunctions';

const HomeNavbar = ({ onSideBarOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isAccountSettingsOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const staffId = CookieUtils.getStaffId();
  const navigate = useNavigate();
  const handleAccountSettingsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccountSettingsClose = () => {
    setAnchorEl(null);
  };

  const getMenuItems = () => {
    return [
      {
        id: 1,
        label: 'My Account',
        icon: <ManageAccountsIcon sx={{ color: 'main.primary' }} />,
        onclick: handleClickOpen
      },
      {
        id: 2,
        label: 'Settings',
        icon: <SettingsIcon sx={{ color: 'main.primary' }} />,
        onclick: handleSettingsClick
      },
      {
        id: 3,
        label: 'Logout',
        icon: <InputIcon sx={{ color: 'main.primary' }}/>,
        onclick: logoutClickOpen
      }
    ];
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logoutClickOpen = () => {
    navigate('/logout');
  };

  const handleSettingsClick = () => {
    setIsSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setIsSettingsOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container sx={{
        py: 1.5,
        px: { xs: 2, sm: 4 }
      }}>
        <Grid item>
          {onSideBarOpen != undefined ? (
            <IconButton sx={{ mr: 3 }} color="inherit" onClick={onSideBarOpen} size="large">
              <MenuIcon />
            </IconButton>
          ) : <React.Fragment/>}
          <RouterLink to="/home">
            <img
              alt="DocKit"
              src="/static/images/Logo.png"
              style={{ height: '3rem' }}
            />
          </RouterLink>
        </Grid>
        <Grid item xs></Grid>
        <Grid item sx={{ alignContent: 'center' }}>
          <Tooltip title="My Account">
            <IconButton
              onClick={handleAccountSettingsOpen}
              size="small"
              sx={{ ml: 2, color: '#FFF' }}
              aria-controls={isAccountSettingsOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isAccountSettingsOpen ? 'true' : undefined}
            >
              <img
                alt="My Account"
                src="/static/images/my-account.png"
                style={{ height: '2rem' }}
              />
              {!Utils.isMobile() ? (
                <Typography
                  color="main.textOnPrimary"
                  variant="buttonlabel"
                  fontSize='1rem'
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                  sx={{ mx: 1 }}>
                  {CookieUtils.getStaffName() ?? 'My Account'}
                </Typography>
              ) : ''}
              <KeyboardArrowDownIcon sx={{ height: '2rem' }}/>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isAccountSettingsOpen}
        onClose={handleAccountSettingsClose}
        onClick={handleAccountSettingsClose}
        sx={{ zIndex: 1500 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: '2px 2px 8px 2px #A8A8A8',
            borderRadius: '1rem',
            mt: 1,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 12,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 20
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {getMenuItems().map((menuItem) => (
          <MenuItem key={menuItem.id} onClick={menuItem.onclick}>
            <ListItemIcon>
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography variant="body">{menuItem.label}</Typography>}
            />
            <Divider/>
          </MenuItem>
        ))}
      </Menu>
      <TSSideBarDialog
        open={open}
        TransitionComponent={Transition}
      >
        <MyAccount handleClose={handleClose} />
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isSettingsOpen}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', mb: 5 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleSettingsClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Staff Settings (For All Clinics)
            </Typography>
          </Toolbar>
        </AppBar>
        <ModifySettings staffId={staffId} />
      </TSSideBarDialog>
    </React.Fragment>
  );
};

HomeNavbar.propTypes = {
  onSideBarOpen: PropTypes.func
};

export default HomeNavbar;
