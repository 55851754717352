const CONTACT_TYPE = {
  1: 'Appointment Phone Number',
  2: 'Appointment Web Link',
  3: 'Emergency Phone Number',
  4: 'Phone Number',
  5: 'Email'
};

const DAYS_OF_WEEK = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  7: 'SUNDAY'
};

const USER_TYPE = {
  1: 'SuperUser',
  2: 'Doctor',
  3: 'Nurse',
  4: 'Receptionist'
};

const REFERRED_BY = {
  1: 'Returning Patient',
  2: 'Patient Referral',
  3: 'Word of Mouth',
  4: 'Google Search',
  5: 'Website',
  6: 'Name Board',
  7: 'Pamphlet',
  8: 'Poster',
  9: 'Social Media',
  10: 'Newspaper Ad',
  11: 'TV Ad',
  12: 'YouTube Ad',
  13: 'Others'
};

const EVENT_ID = {
  CREATE_APPOINTMENT: 1,
  CANCEL_APPOINTMENT: 2,
  MARK_CLINIC_VISIT: 3,
  START_OP: 4,
  CHECK_IN: 5,
  CHECK_OUT: 6,
  END_OP: 7
};

const INVITE_STATUS = {
  0: 'Pending',
  1: 'Accepted',
  2: 'Rejected',
  3: 'Withdrawn'
};

const SESSION_STATUS = {
  1: 'OP not created',
  2: 'OP not started',
  3: 'OP started',
  4: 'OP ended'
};

const APPOINTMENT_STATUS = {
  0: 'Cancelled',
  1: 'Booked',
  2: 'Waiting',
  3: 'Consulting',
  4: 'Consulted',
  '-1': 'Auto Cancelled'
};

const AGE_IN_UNIT = {
  1: 'Days',
  2: 'Weeks',
  3: 'Months',
  4: 'Years'
};

const CRITERIA_FOR_FILTER = {
  1: 'Before',
  2: 'Between',
  3: 'After'
};

const SORT_BY_OPTIONS = {
  1: 'Newer',
  2: 'Older',
  3: 'Doctor',
  4: 'Status'
};

const SEX_OPTIONS = {
  M: 'Male',
  F: 'Female'
};

const API_RESPONSE_TYPE = {
  success: 1,
  error: 2
};

export {
  CONTACT_TYPE, DAYS_OF_WEEK, USER_TYPE, REFERRED_BY, INVITE_STATUS, EVENT_ID, SESSION_STATUS, APPOINTMENT_STATUS,
  AGE_IN_UNIT, CRITERIA_FOR_FILTER, SORT_BY_OPTIONS, SEX_OPTIONS, API_RESPONSE_TYPE
};
