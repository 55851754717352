import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem
} from '@mui/x-data-grid';
import { Box, Container,
  DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { EnumUtils, Utils } from '../../utils/UtilFunctions';
import Services from '../../utils/Services';
import { CONTACT_TYPE } from '../../utils/EnumDefinitions';
import { validateNumber, validateStringForNull } from '../../utils/FieldValidations';
import TSBackDrop from '../tscomponents/TSBackDrop';
import APIData from '../../utils/APIData';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

function EditToolbar(props) {
  const { rows, setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = rows.length + 1;
    setRows((oldRows) => [...oldRows, { id, contact_id: '', contact_type: '', contact: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'contact_type' }
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add New Contact
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  rows: PropTypes.array.isRequired,
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired
};

const ManageContactDetails = ({ clinicId, staffId }) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isRemoveContactDialogOpen, setIsRemoveContactDialogOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState();
  const APIToken = {
    GET_CONTACT_DETAILS: 'CMUCCD01',
    CREATE_CONTACT: 'CMUCCD02',
    MODIFY_CONTACT: 'CMUCCD03',
    DELETE_CONTACT: 'CMUCCD04'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (clinicId != undefined) {
      raiseGetContactDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiToken == APIToken.GET_CONTACT_DETAILS ) {
      let i = 1;
      const outArray = [];
      let activeContactDetails = undefined;
      if (apiData == APIData.getStaffDetails) {
        activeContactDetails = response.data.staff_contact_details.active;
      } else if (apiData == APIData.getClinicDetails) {
        activeContactDetails = response.data.clinic_contact_details.active;
      }
      if (activeContactDetails != undefined ) {
        activeContactDetails.map((contactDetail) => {
          contactDetail.id = i;
          outArray.push(contactDetail);
          i++;
        });
      }
      setRows(outArray);
      setIsLoading(false);
    } else if (apiData == APIData.deleteClinicContact && apiToken == APIToken.DELETE_CONTACT ) {
      setRows(rows.filter((row) => row.contact_id !== response.data.contact_id));
      showSnackBar('success', response.message ?? 'contact deleted successfully');
      raiseGetContactDetails();
    } else if (apiData == APIData.createClinicContact && apiToken == APIToken.CREATE_CONTACT) {
      showSnackBar('success', response.message ?? 'Contact created successfully');
      raiseGetContactDetails();
    } else if (apiData == APIData.updateClinicContact && apiToken == APIToken.MODIFY_CONTACT) {
      showSnackBar('success', response.message ?? 'Contact updated successfully');
      raiseGetContactDetails();
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getStaffDetails && apiToken == APIToken.GET_CONTACT_DETAILS) {
      defaultMsg = 'Failed to retrieve User Contact Details';
    } else if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CONTACT_DETAILS ) {
      defaultMsg = 'Failed to retrieve Clinic Contact Details';
    } else if (apiData == APIData.createClinicContact && apiToken == APIToken.CREATE_CONTACT) {
      setRowModesModel({ ...rowModesModel, [callbackValues.contact.id]: { mode: GridRowModes.Edit } });
      defaultMsg = 'Failed to create contact';
    } else if (apiData == APIData.updateClinicContact && apiToken == APIToken.MODIFY_CONTACT) {
      setRowModesModel({ ...rowModesModel, [callbackValues.contact.id]: { mode: GridRowModes.Edit } });
      defaultMsg = 'Failed to update contact';
    } else if (apiData == APIData.deleteClinicContact && apiToken == APIToken.DELETE_CONTACT ) {
      defaultMsg = 'Failed to delete contact';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetContactDetails = () => {
    let finalParams = '';
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      finalParams = 'fetch_modules=staff_contact_details';
      Services.sendBackendRequest({ apiData: APIData.getStaffDetails, uriValues: [clinicId, staffId], params: finalParams },
        APIToken.GET_CONTACT_DETAILS, processSuccess, processError);
    } else {
      finalParams = 'fetch_modules=clinic_contact_details';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: finalParams },
        APIToken.GET_CONTACT_DETAILS, processSuccess, processError);
    }
  };

  const raiseDeleteContact = (contact) => {
    setIsLoading(true);
    setIsRemoveContactDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.deleteClinicContact, uriValues: [clinicId, contact.contact_id] },
      APIToken.DELETE_CONTACT, processSuccess, processError);
  };

  const validateContact = (contact, isNew) => {
    try {
      if (!isNew && !validateNumber(contact.contact_id)) {
        throw new Error('Invalid Contact. Failed to update');
      }
      if (!validateNumber(contact.contact_type)) {
        throw new Error('Invalid Contact Type. Failed to commit');
      }
      if (!validateStringForNull(contact.contact)) {
        throw new Error('Invalid Contact Details. Failed to commit');
      }
      const param = {
        contact_type: parseInt(contact.contact_type),
        contact: contact.contact
      };
      if (staffId !== undefined && staffId !== null) {
        param.staff_id = staffId;
      }
      return param;
    } catch (err) {
      showSnackBar('error', err.message ?? 'Invalid Contact Details. Failed to commit');
      setIsLoading(false);
    }
    return '';
  };

  const raiseCreateContactRequest = (contact) => {
    setIsLoading(true);
    const param = validateContact(contact, true);
    if (param == '') {
      return;
    }
    const arr = [];
    arr.push(param);

    const finalParams = {
      contact_details: arr
    };
    Services.sendBackendRequest({ apiData: APIData.createClinicContact, uriValues: [clinicId], params: finalParams },
      APIToken.CREATE_CONTACT, processSuccess, processError, { contact: contact });
  };

  const raiseUpdateContactRequest = (contact) => {
    setIsLoading(true);
    const param = validateContact(contact, false);
    if (param == '') {
      return;
    }
    Services.sendBackendRequest({ apiData: APIData.updateClinicContact, uriValues: [clinicId, contact.contact_id], params: param },
      APIToken.MODIFY_CONTACT, processSuccess, processError, { contact: contact });
  };

  const handleContactRemoveDialogOpen = (id) => {
    setSelectedContactId(id);
    setIsRemoveContactDialogOpen(true);
  };

  const handleContactRemoveDialogClose = () => {
    setIsRemoveContactDialogOpen(false);
  };

  const getDeleteContactConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isRemoveContactDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Delete Contact</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to remove this Contact?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              color='primary'
              onClick={handleContactRemoveDialogClose}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={handleDeleteClick()}
            >
              Delete Contact
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = () => () => {
    const selRow = rows.filter((row) => row.id == selectedContactId)[0];
    if (validateNumber(selRow.contact_id)) {
      raiseDeleteContact(selRow);
    } else {
      setRows(rows.filter((row) => row.id !== selectedContactId));
    }
    setIsRemoveContactDialogOpen(false);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const raiseCreateOrModifyRequest = (row) => {
    return validateStringForNull(row.contact_id) ? raiseUpdateContactRequest(row) : raiseCreateContactRequest(row);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    raiseCreateOrModifyRequest(newRow);
    return updatedRow;
  };

  const getContactTypeString = (params) => {
    if (validateStringForNull(params.value)) {
      return CONTACT_TYPE[params.value];
    }
    return '';
  };

  const columns = [
    { field: 'contact_id', headerName: 'Contact Id', flex: 1 },
    { field: 'contact_type', headerName: 'Contact Type', width: 150, editable: true,
      valueFormatter: getContactTypeString,
      type: 'singleSelect',
      flex: 1,
      valueOptions: EnumUtils.getEnumOptions(CONTACT_TYPE)
    },
    { field: 'contact', headerName: 'Contact', width: 300, editable: true, flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              key='Save'
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              key='Cancel'
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            key="Delete"
            onClick={() => handleContactRemoveDialogOpen(id)}
            color="inherit"
          />
        ];
      }
    }
  ];

  return (
    <React.Fragment>
      <Container>
        <Grid
          container
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <DataGrid
              sx={{ width: 'auto', height: 500 }}
              rows={rows}
              columns={columns}
              editMode="row"
              disableColumnSelector
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              components={{
                Toolbar: EditToolbar
              }}
              componentsProps={{
                toolbar: { rows, setRows, setRowModesModel }
              }}
              experimentalFeatures={{ newEditingApi: true }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    contact_id: false
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {getDeleteContactConfirmationDialog()}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ManageContactDetails.propTypes = {
  clinicId: PropTypes.string.isRequired,
  staffId: PropTypes.string
};

export default ManageContactDetails;
