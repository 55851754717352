import {
  Box, Tab, Tabs
} from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ManageOpenHours from '../components/manageclinicdetails/ManageOpenHours';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ManageHolidayTimings from '../components/manageclinicdetails/ManageHolidayTimings';
import { CookieUtils } from '../utils/CookieUtils';

function TabPanel(props) {
  const { node, value, index } = props;
  if (value == index) {
    return (node);
  } else {
    return <React.Fragment/>;
  }
}

TabPanel.propTypes = {
  node: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ModifyUserClinicTimings = () => {
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(0);
  const clinicId = searchParams.get('clinicId');

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Manage Staff Timings - DocKit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          p: 2,
          pt: 10
        }}>
        <Tabs value={currentTab}
          sx={{ backgroundColor: 'white', width: '100%', border: '1px', mb: 3 }}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          scrollButtons
          variant="fullWidth"
          allowScrollButtonsMobile
          indicatorColor="primary">
          <Tab key={0}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Regular Hours
              </Box>
            } {...a11yProps(0)} value={0}/>
          <Tab key={1}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Holiday Hours
              </Box>
            } {...a11yProps(1)} value={1}/>
        </Tabs>
        <TabPanel key={0} value={currentTab} index={0} node={<ManageOpenHours clinicId={clinicId} staffId={CookieUtils.getStaffId()}/>}/>
        <TabPanel key={1} value={currentTab} index={1} node={<ManageHolidayTimings clinicId={clinicId} staffId={CookieUtils.getStaffId()} />}/>
      </Box>
    </React.Fragment>
  );
};

export default ModifyUserClinicTimings;
