import { Grid,
  Button, Typography, Tooltip,
  Box, DialogContent, DialogActions, Modal, InputLabel, Container } from '@mui/material';
import React, { useState } from 'react';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import PropTypes from 'prop-types';
import Services from '../../utils/Services';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import APIData from '../../utils/APIData';
import { CookieUtils } from '../../utils/CookieUtils';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 300,
    lg: 400
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const ExitClinic = ({ clinicId, clinicName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isLeaveClinicDialogOpen, setIsLeaveClinicDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const APIToken = {
    DELETE_STAFF: 'CEC01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.deleteStaff && apiToken == APIToken.DELETE_STAFF ) {
      setIsModalOpen(true);
      setTimeout(() => navigate('/home'), 3000);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.deleteStaff && apiToken == APIToken.DELETE_STAFF) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Unable to process your request right now'
      });
    }
    setIsLoading(false);
  };

  const raiseLeaveClinicRequest = () => {
    setIsLoading(true);
    setIsLeaveClinicDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.deleteStaff, uriValues: [clinicId, CookieUtils.getStaffId()] },
      APIToken.DELETE_STAFF, processSuccess, processError);
  };

  const getLeaveClinicContactConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isLeaveClinicDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Leave Clinic</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to leave this clinic?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size="small"
              color='primary'
              onClick={() => setIsLeaveClinicDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color='primary'
              onClick={raiseLeaveClinicRequest}
            >
              Leave Clinic
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm" >
        <Grid container justifyContent='space-between' alignItems='center' rowSpacing={3} columnSpacing={3} >
          <Grid item xs={12} >
            <InputLabel>
              Leave Clinic - {clinicName}
            </InputLabel>
          </Grid>
          <Grid item xs={12} >
            <Typography sx={{ mb: 2 }} variant='h5'>
            Are you sure you want to leave the clinic? You can only be added again with a fresh invite from the superuser of the clinic.
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} xs={12} >
            <Grid item>
              <Tooltip title='Leave Clinic'>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  onClick={() => setIsLeaveClinicDialogOpen(true)}
                >
                  Leave Clinic
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={isModalOpen}
        onClose={() => {}}
      >
        <Grid container sx={style} direction='column' justifyContent="center" alignItems="center">
          <Grid item>
            <CheckCircleIcon sx={{ fontSize: 40, color: 'green' }}/>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              Your exit request accepted.
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ mt: 2 }} variant="h6">
              Redirecting to home page..
            </Typography>
          </Grid>
        </Grid>
      </Modal>
      {getLeaveClinicContactConfirmationDialog()}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ExitClinic.propTypes = {
  clinicId: PropTypes.string.isRequired,
  clinicName: PropTypes.string.isRequired
};

export default ExitClinic;
