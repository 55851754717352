import { Autocomplete, Button, Container, DialogActions,
  DialogContent, Grid, Icon, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AGE_IN_UNIT, SEX_OPTIONS } from '../../utils/EnumDefinitions';
import { isFutureDate, validateNumber, validateStringForNull, validateDate, validateEmailId } from '../../utils/FieldValidations';
import TSSnackbar from '../tscomponents/TSSnackbar';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { EnumUtils, Utils } from '../../utils/UtilFunctions';
import Services from '../../utils/Services';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import APIData from '../../utils/APIData';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const PaperBase = styled(Paper)(()=>({
  width: 'fit-content',
  height: 'fit-content'
}));

const CreateModifyPatient = ({ clinicId, patientId, onSuccessfulCommit, prefilledInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const emptyPatientDetails = {
    patient_name: '',
    dob: null,
    age: '',
    age_in: '4',
    is_dummy_dob: '',
    sex: '',
    phone: '',
    blood_group: '',
    email: '',
    area: '',
    address: '',
    city: '',
    state: '',
    country: ''
  };
  const [patientDetails, setPatientDetails] = useState(emptyPatientDetails);
  const [showAgeField, setShowAgeField] = useState(false);
  const [isDobConfirmationDialogOpen, setIsDobConfirmationDialogOpen] = useState(false);
  const [originalValue, setOriginalValue] = useState(emptyPatientDetails);
  const [paramsFromValidation, setParamsFromValidation] = useState(undefined);
  const APIToken = {
    GET_PATIENT_DETAILS: 'CCMP01',
    CREATE_PATIENT: 'CCMP02',
    MODIFY_PATIENT: 'CCMP03'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(patientId)) {
      raiseGetPatientDetails(patientId);
    } else {
      handlePrefilledInfo();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getClinicPatientDetails && apiToken == APIToken.GET_PATIENT_DETAILS ) {
      const obj = {
        patient_name: response.data.patient_name,
        sex: response.data.sex,
        dob: response.data.dob != undefined ? moment(response.data.dob.value, 'yyyy-MM-DD') : null,
        age: response.data.age.value.age,
        age_in: response.data.age.value.age_in,
        is_dummy_dob: response.data.is_dummy_dob,
        phone: response.data.phone,
        blood_group: response.data.blood_group != undefined ? response.data.blood_group : '',
        email: response.data.email != undefined ? response.data.email : '',
        area: response.data.area,
        address: response.data.address != undefined ? response.data.address : '',
        city: response.data.city != undefined ? response.data.city : '',
        state: response.data.state != undefined ? response.data.state : '',
        country: response.data.country != undefined ? response.data.country : ''
      };
      setPatientDetails(obj);
      setOriginalValue(obj);
      setShowAgeField(response.data.is_dummy_dob != 0);
    } else if (apiData == APIData.createPatient && apiToken == APIToken.CREATE_PATIENT ) {
      handleSuccessSnackBar('success', response.message ?? 'Patient created successfully');
    } else if (apiData == APIData.modifyPatient && apiToken == APIToken.MODIFY_PATIENT ) {
      handleSuccessSnackBar('success', response.message ?? 'Patient details modified successfully');
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getClinicPatientDetails && apiToken == APIToken.GET_PATIENT_DETAILS) {
      defaultMsg = 'Patient not found';
    } else if (apiData == APIData.createPatient && apiToken == APIToken.CREATE_PATIENT ) {
      defaultMsg = 'Failed to create patient';
    } else if (apiData == APIData.modifyPatient && apiToken == APIToken.MODIFY_PATIENT ) {
      defaultMsg = 'Failed to modify patient';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const handlePrefilledInfo = () => {
    if (prefilledInfo != undefined) {
      Object.keys(prefilledInfo).map((key) => {
        setPatientDetails({ ...patientDetails,
          [key]: prefilledInfo[key]
        });
      });
    }
  };

  const handleValueChange = (name, newValue) => {
    if (validateStringForNull(newValue)) {
      setPatientDetails({ ...patientDetails,
        [name]: newValue.value
      });
    } else {
      setPatientDetails({
        ...patientDetails,
        [name]: null
      });
    }
  };

  const handleTextChange = (event) => {
    setPatientDetails({ ...patientDetails, [event.target.name]: event.target.value });
  };

  const handleSwitchChange = (event) => {
    setShowAgeField(!showAgeField);
  };

  const handleDobConfirmationDialogClose = (confirmed) => {
    setIsDobConfirmationDialogOpen(false);
    setIsLoading(false);
    if (confirmed) {
      if (patientId == undefined) {
        raiseCreatePatientRequest(paramsFromValidation);
      } else {
        raiseModifyPatientRequest(paramsFromValidation);
      }
    } else {
      setParamsFromValidation(undefined);
    }
  };

  const raiseGetPatientDetails = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getClinicPatientDetails, uriValues: [clinicId, patientId] },
      APIToken.GET_PATIENT_DETAILS, processSuccess, processError);
  };

  const handleSuccessSnackBar = (status, message) => {
    showSnackBar(status, message);
    setPatientDetails(emptyPatientDetails);
    setOriginalValue(emptyPatientDetails);
    if (onSuccessfulCommit != undefined) {
      onSuccessfulCommit();
    }
  };

  const raiseCreatePatientRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.createPatient, uriValues: [clinicId], params: finalParams },
      APIToken.CREATE_PATIENT, processSuccess, processError);
  };

  const raiseModifyPatientRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyPatient, uriValues: [clinicId, patientId], params: finalParams },
      APIToken.MODIFY_PATIENT, processSuccess, processError);
  };

  const validateParams = (inputParams) => {
    const finalParams = {};
    if (!validateStringForNull(inputParams.patient_name)) {
      throw new Error('Provide valid Patient Name');
    }
    if (!validateStringForNull(inputParams.sex)) {
      throw new Error('Provide valid Gender');
    }
    if (showAgeField == false) {
      if (!validateDate(inputParams.dob)) {
        throw new Error('Provide valid Date of Birth');
      } else if (isFutureDate(inputParams.dob)) {
        throw new Error('Future date cannot be selected for DOB. Provide valid input');
      }
    } else {
      if (!validateNumber(inputParams.age_in) && inputParams.age_in == 0) {
        throw new Error('Provide valid age unit');
      }
      if (!validateNumber(inputParams.age) && inputParams.age == 0) {
        throw new Error('Provide valid age');
      }
    }
    if (!validateNumber(inputParams.phone)) {
      throw new Error('Provide valid Phone Number');
    }
    if (!validateStringForNull(inputParams.area)) {
      throw new Error('Provide valid Area');
    }

    finalParams.phone = inputParams.phone;
    finalParams.patient_name = inputParams.patient_name;
    if (showAgeField == false) {
      finalParams.dob = moment(inputParams.dob).format('yyyy-MM-DD');
    } else {
      finalParams.age = parseInt(inputParams.age);
      finalParams.age_in = parseInt(inputParams.age_in);
    }
    finalParams.sex = inputParams.sex;
    finalParams.area = inputParams.area;

    if (validateStringForNull(inputParams.email)) {
      if (validateEmailId(inputParams.email)) {
        finalParams.email = inputParams.email;
      } else {
        throw new Error('Provide valid Email Id');
      }
    }
    if (validateStringForNull(inputParams.blood_group)) {
      finalParams.blood_group = inputParams.blood_group;
    }
    if (validateStringForNull(inputParams.address)) {
      finalParams.address = inputParams.address;
    }
    if (validateStringForNull(inputParams.state)) {
      finalParams.state = inputParams.state;
    }
    if (validateStringForNull(inputParams.city)) {
      finalParams.city = inputParams.city;
    }
    if (validateStringForNull(inputParams.country)) {
      finalParams.country = inputParams.country;
    }

    return finalParams;
  };

  const getModifiedParams = (params) => {
    const modifiedParams = {};
    if (originalValue.patient_name != params.patient_name) {
      modifiedParams.patient_name = params.patient_name;
    }
    if (originalValue.sex != params.sex) {
      modifiedParams.sex = params.sex;
    }
    if (showAgeField == false) {
      if (moment(originalValue.dob).format('yyyy-MM-DD') != moment(params.dob).format('yyyy-MM-DD')) {
        modifiedParams.dob = moment(params.dob).format('yyyy-MM-DD');
      }
    } else {
      if (originalValue.age != parseInt(params.age) || originalValue.age_in != parseInt(params.age_in)) {
        modifiedParams.age = parseInt(params.age);
        modifiedParams.age_in = parseInt(params.age_in);
      }
    }
    params.blood_group = params.blood_group ?? '';
    params.email = params.email ?? '';
    params.address = params.address ?? '';
    params.city = params.city ?? '';
    params.state = params.state ?? '';
    params.country = params.country ?? '';
    if (originalValue.phone != params.phone) {
      modifiedParams.phone = params.phone;
    }
    if (originalValue.blood_group != params.blood_group) {
      modifiedParams.blood_group = params.blood_group;
    }
    if (originalValue.email != params.email) {
      modifiedParams.email = params.email;
    }
    if (originalValue.area != params.area) {
      modifiedParams.area = params.area;
    }
    if (originalValue.address != params.address) {
      modifiedParams.address = params.address;
    }
    if (originalValue.city != params.city) {
      modifiedParams.city = params.city;
    }
    if (originalValue.state != params.state) {
      modifiedParams.state = params.state;
    }
    if (originalValue.country != params.country) {
      modifiedParams.country = params.country;
    }
    return modifiedParams;
  };

  const handleCreatePatientClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(patientDetails);
      const filteredParams = validateParams(params);
      const yearDifference = Utils.calculateDobYearDifference(filteredParams, showAgeField);
      if (yearDifference > 80) {
        const params = cloneDeep(filteredParams);
        setParamsFromValidation(params);
        setIsDobConfirmationDialogOpen(true);
      } else {
        raiseCreatePatientRequest(filteredParams);
      }
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Create Patient');
      setIsLoading(false);
    }
  };

  const handleModifyPatientClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(patientDetails);
      const validatedParams = validateParams(params);
      const finalParams = getModifiedParams(validatedParams);
      if (Object.keys(finalParams).length == 0) {
        throw new Error('Nothing to update');
      }
      const yearDifference = Utils.calculateDobYearDifference(finalParams, showAgeField);
      if (yearDifference > 80) {
        const params = cloneDeep(finalParams);
        setParamsFromValidation(params);
        setIsLoading(false);
        setIsDobConfirmationDialogOpen(true);
      } else {
        raiseModifyPatientRequest(finalParams);
      }
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Create Appointment');
      setIsLoading(false);
    }
  };

  const handleResetClick = () => {
    setPatientDetails(originalValue);
  };

  const getDobAlertText = () => {
    if (patientDetails.is_dummy_dob == 1 ) {
      return (
        <Grid item xs={12} sx={{ py: 1 }}>
          <Typography variant="body2" align='right'>
            <Icon >
              <ErrorOutlineIcon
                sx={{
                  fontSize: 18,
                  color: 'orange',
                  alignContent: 'flex-end'
                }}
              />
            </Icon> Provide correct DOB for accurate calculation
          </Typography>
        </Grid>
      );
    }
  };

  const getDobOrAge = (obj) => {
    if (obj == undefined) {
      return '';
    } else if (showAgeField == false) {
      return 'Are you sure your DOB is ' + moment(obj.dob, 'yyyy-MM-DD').format('DD/MM/YYYY') + '?';
    } else {
      return 'Are you sure your age is ' + obj.age + ' ' + AGE_IN_UNIT[obj.age_in] + '?';
    }
  };

  return (
    <Container
      sx={{
        py: 1,
        px: 1,
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <PaperBase elevation={12} sx={{ p: 5, backgroundColor: 'white' }}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12} >
            <Typography component="h5" variant="h5" sx={{ mb: 1 }} >
                Patient Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Name"
              label="Name"
              name="patient_name"
              autoComplete ='off'
              size='small'
              required
              value={patientDetails.patient_name}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="sex"
              name='sex'
              value={SEX_OPTIONS[patientDetails.sex] ?? null}
              onChange={(event, newValue) => {handleValueChange('sex', newValue);}}
              options={EnumUtils.getEnumOptions(SEX_OPTIONS)}
              isOptionEqualToValue={(option, value) => {
                return option.label === value;
              }}
              size="small"
              autoSelect
              autoHighlight
              renderInput={(params) => <TextField required size="small" {...params} error={false} fullWidth label="Sex" />}
            />
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent='flex-end' alignItems='center'>
            <Grid item>
              <Typography variant='body2'>DOB</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={showAgeField}
                onChange={handleSwitchChange}
                sx={{
                  '& .MuiSwitch-thumb': {
                    color: '#205295;'
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: '#172b4d'
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant='body2'>Age</Typography>
            </Grid>
          </Grid>
          {showAgeField === false ? (
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date of Birth"
                  format="DD/MM/YYYY"
                  sx={{ width: '100%' }}
                  value={patientDetails.dob}
                  name="dob"
                  disableFuture
                  onChange= {(newValue) => {setPatientDetails({ ...patientDetails, dob: newValue });}}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%', required: true } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={4} sm={2}>
                <TextField
                  label="Age"
                  sx={{ width: '100%' }}
                  value={patientDetails.age}
                  name="age"
                  size='small'
                  onChange={(event) => {handleTextChange(event);}}
                />
              </Grid>
              <Grid item xs={8} sm={4}>
                <Autocomplete
                  disablePortal
                  id="age-in-unit"
                  name='age_in'
                  value={AGE_IN_UNIT[patientDetails.age_in] ?? null}
                  onChange={(event, newValue) => {handleValueChange('age_in', newValue);}}
                  options={EnumUtils.getEnumOptions(AGE_IN_UNIT)}
                  isOptionEqualToValue={(option, value) => {
                    return option.label === value;
                  }}
                  size="small"
                  autoSelect
                  autoHighlight
                  renderInput={(params) => <TextField size="small" {...params} error={false} fullWidth label="Unit" />}
                />
              </Grid>
            </React.Fragment>
          )}
          {validateStringForNull(patientDetails.is_dummy_dob) ? getDobAlertText() : ''}
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              label="Phone Number"
              name="phone"
              autoComplete ='off'
              size='small'
              required
              value={patientDetails.phone}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="bloodgroup"
              label="Blood Group"
              name="blood_group"
              autoComplete ='off'
              size='small'
              value={patientDetails.blood_group}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              label="Email"
              name="email"
              autoComplete ='off'
              size='small'
              value={patientDetails.email}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="area"
              label="Area"
              name="area"
              required
              autoComplete ='off'
              size='small'
              value={patientDetails.area}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="address"
              label="Address"
              name="address"
              autoComplete ='off'
              size='small'
              value={patientDetails.address}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              label="City"
              name="city"
              autoComplete ='off'
              size='small'
              value={patientDetails.city}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="state"
              label="State"
              name="state"
              autoComplete ='off'
              size='small'
              value={patientDetails.state}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="country"
              label="Country"
              name="country"
              autoComplete ='off'
              size='small'
              value={patientDetails.country}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center'>
            <Grid item>
              <Button
                type="submit"
                variant="text"
                size="small"
                onClick={handleResetClick}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={validateStringForNull(patientId) ? handleModifyPatientClick : handleCreatePatientClick}
              >
                {validateStringForNull(patientId) ? 'Modify' : 'Create' }
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PaperBase>
      <TSConfirmationDialog
        open={isDobConfirmationDialogOpen}
      >
        <DialogContent>
          <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
            <strong>Confirmation</strong>
          </Typography>
          <Typography variant='h5'>
            {getDobOrAge(paramsFromValidation)}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            size="small"
            color='primary'
            onClick={() => {handleDobConfirmationDialogClose(false);}}
          >
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            color='primary'
            onClick={() => {handleDobConfirmationDialogClose(true);}}
          >
            Yes
          </Button>
        </DialogActions>
      </TSConfirmationDialog>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </Container>
  );
};

CreateModifyPatient.propTypes = {
  clinicId: PropTypes.string,
  patientId: PropTypes.string,
  onSuccessfulCommit: PropTypes.func,
  prefilledInfo: PropTypes.object
};

export default CreateModifyPatient;
