import HomeIcon from '@mui/icons-material/Home';
import { CookieUtils } from './CookieUtils';

const getSuperUserMenuItems = () => {
  return [
    {
      header: '',
      items: [
        {
          name: 'Home',
          icon: <HomeIcon/>,
          link: ''
        }
      ]
    },
    {
      header: 'Appointments',
      items: [
        {
          name: 'Create Appointment',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/create'
        },
        {
          name: 'Manage Appointments',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/manage'
        },
        {
          name: 'Search Patient',
          icon: <HomeIcon/>,
          link: '/clinic/patient-appointments/search'
        }
      ]
    },
    {
      header: 'Reports',
      items: [
        {
          name: 'Sample Report',
          icon: <HomeIcon/>,
          link: ''
        }
      ]
    },
    {
      header: 'Clinic Settings',
      items: [
        {
          name: 'Clinic Information',
          icon: <HomeIcon/>,
          link: '/clinic/modify/details'
        },
        {
          name: 'Clinic Timings',
          icon: <HomeIcon/>,
          link: '/clinic/modify/timings'
        },
        {
          name: 'Contact Details',
          icon: <HomeIcon/>,
          link: '/clinic/modify/contact'
        },
        {
          name: 'Manage Staffs',
          icon: <HomeIcon/>,
          link: '/clinic/manage/staff'
        },
        {
          name: 'Settings',
          icon: <HomeIcon />,
          link: '/clinic/settings'
        }
      ]
    },
    {
      header: 'User Settings',
      items: [
        {
          name: 'Clinic Information',
          icon: <HomeIcon/>,
          link: '/clinic/view/details'
        },
        {
          name: 'Timings',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/timings'
        },
        {
          name: 'Contact Details',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/contact'
        },
        {
          name: 'Settings',
          icon: <HomeIcon />,
          link: '/clinic/staff/settings'
        }
      ]
    },
    {
      header: 'Exit',
      items: [
        {
          name: 'Exit Clinic Portal',
          icon: <HomeIcon/>,
          link: '/'
        },
        {
          name: 'Logout',
          icon: <HomeIcon/>,
          link: '/logout'
        }
      ]
    }
  ];
};

const getDoctorSuperUserMenuItems = () => {
  return [
    {
      header: '',
      items: [
        {
          name: 'Home',
          icon: <HomeIcon/>,
          link: '/clinic/doctor-appointments/manage'
        }
      ]
    },
    {
      header: 'Appointments',
      items: [
        {
          name: 'Create Appointment',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/create'
        },
        {
          name: 'Doctor Appointments',
          icon: <HomeIcon/>,
          link: '/clinic/doctor-appointments/manage'
        },
        {
          name: 'Search Patient',
          icon: <HomeIcon/>,
          link: '/clinic/patient-appointments/search'
        }
      ]
    },
    {
      header: 'Reports',
      items: [
        {
          name: 'Sample Report',
          icon: <HomeIcon/>,
          link: ''
        }
      ]
    },
    {
      header: 'Clinic Settings',
      items: [
        {
          name: 'Clinic Information',
          icon: <HomeIcon/>,
          link: '/clinic/modify/details'
        },
        {
          name: 'Clinic Timings',
          icon: <HomeIcon/>,
          link: '/clinic/modify/timings'
        },
        {
          name: 'Contact Details',
          icon: <HomeIcon/>,
          link: '/clinic/modify/contact'
        },
        {
          name: 'Manage Staffs',
          icon: <HomeIcon/>,
          link: '/clinic/manage/staff'
        },
        {
          name: 'Settings',
          icon: <HomeIcon />,
          link: '/clinic/settings'
        }
      ]
    },
    {
      header: 'User Settings',
      items: [
        {
          name: 'Clinic Information',
          icon: <HomeIcon/>,
          link: '/clinic/view/details'
        },
        {
          name: 'Timings',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/timings'
        },
        {
          name: 'Contact Details',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/contact'
        },
        {
          name: 'Settings',
          icon: <HomeIcon />,
          link: '/clinic/staff/settings'
        }
      ]
    },
    {
      header: 'Exit',
      items: [
        {
          name: 'Exit Clinic Portal',
          icon: <HomeIcon/>,
          link: '/'
        },
        {
          name: 'Logout',
          icon: <HomeIcon/>,
          link: '/logout'
        }
      ]
    }
  ];
};

const getDoctorMenuItems = () => {
  return [
    {
      header: '',
      items: [
        {
          name: 'Home',
          icon: <HomeIcon/>,
          link: '/clinic/doctor-appointments/manage'
        }
      ]
    },
    {
      header: 'Appointments',
      items: [
        {
          name: 'Create Appointment',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/create'
        },
        {
          name: 'Doctor Appointments',
          icon: <HomeIcon/>,
          link: '/clinic/doctor-appointments/manage'
        },
        {
          name: 'Search Patient',
          icon: <HomeIcon/>,
          link: '/clinic/patient-appointments/search'
        }
      ]
    },
    {
      header: 'Reports',
      items: [
        {
          name: 'Sample Report',
          icon: <HomeIcon/>,
          link: ''
        }
      ]
    },
    {
      header: 'User Settings',
      items: [
        {
          name: 'Clinic Information',
          icon: <HomeIcon/>,
          link: '/clinic/view/details'
        },
        {
          name: 'Timings',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/timings'
        },
        {
          name: 'Contact Details',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/contact'
        },
        {
          name: 'Settings',
          icon: <HomeIcon />,
          link: '/clinic/staff/settings'
        }
      ]
    },
    {
      header: 'Exit',
      items: [
        {
          name: 'Exit Clinic Portal',
          icon: <HomeIcon/>,
          link: '/'
        },
        {
          name: 'Logout',
          icon: <HomeIcon/>,
          link: '/logout'
        }
      ]
    }
  ];
};

const getNurseMenuItems = () => {
  return [
    {
      header: '',
      items: [
        {
          name: 'Home',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/manage'
        }
      ]
    },
    {
      header: 'Appointments',
      items: [
        {
          name: 'Create Appointment',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/create'
        },
        {
          name: 'Manage Appointments',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/manage'
        },
        {
          name: 'Search Patient',
          icon: <HomeIcon/>,
          link: '/clinic/patient-appointments/search'
        }
      ]
    },
    {
      header: 'Reports',
      items: [
        {
          name: 'Sample Report',
          icon: <HomeIcon/>,
          link: ''
        }
      ]
    },
    {
      header: 'User Settings',
      items: [
        {
          name: 'Clinic Information',
          icon: <HomeIcon/>,
          link: '/clinic/view/details'
        },
        {
          name: 'Timings',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/timings'
        },
        {
          name: 'Contact Details',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/contact'
        }
      ]
    },
    {
      header: 'Exit',
      items: [
        {
          name: 'Exit Clinic Portal',
          icon: <HomeIcon/>,
          link: '/'
        },
        {
          name: 'Logout',
          icon: <HomeIcon/>,
          link: '/logout'
        }
      ]
    }
  ];
};

const getReceptionistsMenuItems = () => {
  return [
    {
      header: '',
      items: [
        {
          name: 'Home',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/create'
        }
      ]
    },
    {
      header: 'Appointments',
      items: [
        {
          name: 'Create Appointment',
          icon: <HomeIcon/>,
          link: '/clinic/appointments/create'
        },
        {
          name: 'Manage OP Details',
          icon: <HomeIcon/>,
          link: '/clinic/op/manage'
        },
        {
          name: 'Search Patient',
          icon: <HomeIcon/>,
          link: '/clinic/patient-appointments/search'
        }
      ]
    },
    {
      header: 'Reports',
      items: [
        {
          name: 'Sample Report',
          icon: <HomeIcon/>,
          link: ''
        }
      ]
    },
    {
      header: 'User Settings',
      items: [
        {
          name: 'Clinic Information',
          icon: <HomeIcon/>,
          link: '/clinic/view/details'
        },
        {
          name: 'Timings',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/timings'
        },
        {
          name: 'Contact Details',
          icon: <HomeIcon/>,
          link: '/clinic/modify/user/contact'
        }
      ]
    },
    {
      header: 'Exit',
      items: [
        {
          name: 'Exit Clinic Portal',
          icon: <HomeIcon/>,
          link: '/'
        },
        {
          name: 'Logout',
          icon: <HomeIcon/>,
          link: '/logout'
        }
      ]
    }
  ];
};

class ClinicMenuItems {
  static getMenuItems() {
    const role = CookieUtils.getStaffClinicRole();
    switch (role) {
    case 1:
      const isDoctor = CookieUtils.getIsStaffDoctorInClinic();
      if (isDoctor) {
        return getDoctorSuperUserMenuItems();
      } else {
        return getSuperUserMenuItems();
      }
    case 2:
      return getDoctorMenuItems();
    case 3:
      return getNurseMenuItems();
    case 4:
      return getReceptionistsMenuItems();
    default:
      return getReceptionistsMenuItems();
    }
  }
}

export { ClinicMenuItems };
