import {
  Box,
  Paper
} from '@mui/material';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DeleteClinic from '../components/modifyclinicsettings/DeleteClinic';
import ManageClinicDetails from '../components/manageclinicdetails/ManageClinicDetails';
import { CookieUtils } from '../utils/CookieUtils';

const PaperBase = styled(Paper)(()=>({
  width: 'fit-content',
  height: 'fit-content'
}));

const ModifyClinicInformation = () => {
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  const [clinicName, setClinicName] = useState('');
  return (
    <React.Fragment>
      <Helmet>
        <title>Manage Clinic Information - DocKit</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        pt: 10,
        px: 2,
        pb: 10,
        justifyContent: 'center',
        display: 'flex'
      }}>
        <PaperBase elevation={5}
          sx={{
            p: 5
          }}>
          <ManageClinicDetails clinicId={clinicId} setClinicName={setClinicName} showEdit={CookieUtils.getStaffClinicRole() == 1}/>
          {CookieUtils.getStaffClinicRole() == 1 ? (
            <DeleteClinic clinicId={clinicId} clinicName={clinicName} />
          ) : '' }
        </PaperBase>
      </Box>
    </React.Fragment>
  );
};

export default ModifyClinicInformation;
