import { useNavigate, useRoutes } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Routes from './Routes';
import './theme/App.css';
import Services from './utils/Services';
import SignIn from './pages/SignIn';
import { useEffect } from 'react';
import { validateStringForNull } from './utils/FieldValidations';
import APIData from './utils/APIData';
import { CookieUtils } from './utils/CookieUtils';

const App = () => {
  const navigate = useNavigate();
  const APIToken = {
    CHECK_LOGIN: 'PA01'
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.checkLogin && apiToken == APIToken.CHECK_LOGIN) {
      const userData = response.data;
      if (userData.staff_id !== CookieUtils.getStaffId()) {
        CookieUtils.clearCookies();
        navigate('/signin');
      }
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (apiData == APIData.checkLogin && apiToken == APIToken.CHECK_LOGIN) {
      // CookieUtils.clearCookies();
      // navigate('/signin');
    }
  };

  const checkLoginHash = () => {
    Services.sendBackendRequest({ apiData: APIData.checkLogin },
      APIToken.CHECK_LOGIN, processSuccess, processError);
  };

  const MINUTE_MS = 600000;

  useEffect(() => {
    const interval = setInterval(() => {
      const isValidHash = validateStringForNull(CookieUtils.getLoginhash());
      if (isValidHash) {
        checkLoginHash();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const getContent = () => {
    const routes = useRoutes(Routes);
    const isValidHash = validateStringForNull(CookieUtils.getLoginhash());
    if (!isValidHash) {
      if (window.location.pathname === '/invite' || window.location.pathname === '/404' ||
        window.location.pathname === '/signup' || window.location.pathname === '/resetpassword') {
        return routes;
      } else {
        return (<SignIn/>);
      }
    } else {
      if (window.location.pathname === '/signin' || window.location.pathname === '/signup' ||
       window.location.pathname === '/resetpassword') {
        navigate('/home');
      } else {
        return routes;
      }
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {getContent()}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
