import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container,
  Grid, IconButton, InputLabel, TextField, Tooltip, Typography } from '@mui/material';
import TSBackDrop from '../tscomponents/TSBackDrop';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import Services from '../../utils/Services';
import APIData from '../../utils/APIData';
import InfoIcon from '@mui/icons-material/Info';
import { validateStringForNull } from '../../utils/FieldValidations';
import { cloneDeep } from 'lodash';

const ModifySettings = ({ clinicId, staffId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [originalSettings, setOriginalSetings] = useState(settings);

  const APIToken = {
    GET_CLINIC_SETTINGS: 'CMAS01',
    GET_CLINIC_STAFF_SETTINGS: 'CMAS02',
    GET_STAFF_SETTINGS: 'CMAS03',
    MODIFY_CLINIC_SETTINGS: 'CMAS04',
    MODIFY_CLINIC_STAFF_SETTINGS: 'CMAS05',
    MODIFY_STAFF_SETTINGS: 'CMAS06'
  };

  useEffect(() => {
    getSettings();
  }, []);

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const isGetSettingsRequest = (apiData, apiToken) => {
    return ((apiData == APIData.getClinicStaffSettings && apiToken == APIToken.GET_CLINIC_STAFF_SETTINGS) ||
    (apiData == APIData.getClinicSettings && apiToken == APIToken.GET_CLINIC_SETTINGS) ||
    (apiData == APIData.getStaffSettings && apiToken == APIToken.GET_STAFF_SETTINGS));
  };

  const isModifySettingsRequest = (apiData, apiToken) => {
    return ((apiData == APIData.modifyClinicStaffSettings && apiToken == APIToken.MODIFY_CLINIC_STAFF_SETTINGS) ||
    (apiData == APIData.modifyClinicSettings && apiToken == APIToken.MODIFY_CLINIC_SETTINGS) ||
    (apiData == APIData.modifyStaffSettings && apiToken == APIToken.MODIFY_STAFF_SETTINGS));
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (isGetSettingsRequest(apiData, apiToken)) {
      setSettings(response.data);
      setOriginalSetings(response.data);
      setIsLoading(false);
    } else if (isModifySettingsRequest(apiData, apiToken)) {
      showSnackBar('success', response.message ?? 'Settings Updated Successfully');
      setIsLoading(false);
      getSettings();
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (isGetSettingsRequest(apiData, apiToken)) {
      showSnackBar('error', err.message ?? 'Failed to retrieve Settings');
    } else if (isModifySettingsRequest(apiData, apiToken)) {
      showSnackBar('error', err.message ?? 'Failed to Update Settings');
    }
    setIsLoading(false);
  };

  const getSettings = () => {
    setIsLoading(true);
    if (clinicId != undefined && staffId != undefined) {
      Services.sendBackendRequest({ apiData: APIData.getClinicStaffSettings, uriValues: [clinicId, staffId] },
        APIToken.GET_CLINIC_STAFF_SETTINGS, processSuccess, processError);
    } else if (clinicId != undefined) {
      Services.sendBackendRequest({ apiData: APIData.getClinicSettings, uriValues: [clinicId] },
        APIToken.GET_CLINIC_SETTINGS, processSuccess, processError);
    } else if (staffId != undefined) {
      Services.sendBackendRequest({ apiData: APIData.getStaffSettings },
        APIToken.GET_STAFF_SETTINGS, processSuccess, processError);
    } else {
      setIsLoading(false);
    }
  };

  const modifySettings = (params) => {
    setIsLoading(true);
    if (clinicId != undefined && staffId != undefined) {
      Services.sendBackendRequest({ apiData: APIData.modifyClinicStaffSettings, uriValues: [clinicId, staffId], params: params },
        APIToken.MODIFY_CLINIC_STAFF_SETTINGS, processSuccess, processError);
    } else if (clinicId != undefined) {
      Services.sendBackendRequest({ apiData: APIData.modifyClinicSettings, uriValues: [clinicId], params: params },
        APIToken.MODIFY_CLINIC_SETTINGS, processSuccess, processError);
    } else if (staffId != undefined) {
      Services.sendBackendRequest({ apiData: APIData.modifyStaffSettings, params: params },
        APIToken.MODIFY_STAFF_SETTINGS, processSuccess, processError);
    } else {
      setIsLoading(false);
    }
  };

  const handleSettingsInputChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const updatedSettings = settings.map((setting) => {
      if (setting.key === key) {
        return { ...setting, value };
      }
      return setting;
    });
    setSettings(updatedSettings);
  };

  const validateSettings = (settings) => {
    const validatedSettings = [];
    for (const setting of settings) {
      if (validateStringForNull(setting.key) && validateStringForNull(setting.value)) {
        const settingObj = {};
        settingObj.key = setting.key;
        settingObj.value = setting.value;
        validatedSettings.push(settingObj);
      }
    }
    return validatedSettings;
  };

  const validateParams = (inputParams) => {
    const modifiedParams = {};
    if (validateStringForNull(inputParams)) {
      modifiedParams.settings = validateSettings(inputParams);
    }
    return modifiedParams;
  };

  const handleSettingsResetClick = () => {
    const params = cloneDeep(originalSettings);
    setSettings(params);
  };

  const handleSaveSettingssClick = () => {
    const params = cloneDeep(settings);
    const filteredParams = validateParams(params);
    modifySettings(filteredParams);
  };

  const getTitle = () => {
    return (
      <React.Fragment>
        <Typography variant="h4" align='center' sx={{ backgroundColor: 'white', mb: 2 }} >
          {(clinicId != undefined && staffId != undefined) ? 'Staff Settings(For current clinic)' : 'Clinic Settings'}
        </Typography>
      </React.Fragment>
    );
  };


  return (
    <Container maxWidth='md' sx={{ backgroundColor: 'white' }}>
      <Grid container direction='column' >
        <Grid item xs={12}>
          {getTitle()}
        </Grid>
        {settings != undefined && settings.length > 0 ? (
          settings.map((setting) => (
            <Grid item container spacing={2} key={setting.key} justifyContent='space-between' alignItems='center' rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12}>
                <InputLabel key={setting.key} style={{ fontSize: '0.8rem', whiteSpace: 'normal' }}>{setting.label}</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size='small'
                  name={setting.key}
                  id={setting.key}
                  value={setting.value ?? setting.default_value}
                  sx={{ mb: 1, color: 'white' }}
                  variant="standard"
                  onChange={(event) => handleSettingsInputChange(event)}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={setting.description}>
                        <IconButton>
                          <InfoIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                      </Tooltip>
                    )
                  }}
                />
              </Grid>
            </Grid>
          ))
        ) : ''}
        {settings != undefined && settings.length > 0 ? (
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ mt: 2 }}
          >
            <Grid item>
              <Button
                variant='text'
                color='primary'
                size='small'
                onClick={() => handleSettingsResetClick()}
              >
                reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                size='small'
                onClick={() => handleSaveSettingssClick()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Typography
            variant="caption"
            sx={{
              marginRight: '10px',
              fontSize: '1rem',
              color: '#6b778c'
            }}
          >
            No Settings Found
          </Typography>
        )}
      </Grid>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </Container>
  );
};

ModifySettings.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string
};

export default ModifySettings;
