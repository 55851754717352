class APIData {
  constructor(apiPath, method, isAuthRequired) {
    this.apiPath = apiPath;
    this.method = method;
    this.isAuthRequired = isAuthRequired ?? true;
  }

  getAPIPath() {
    return this.apiPath;
  }

  getMethod() {
    return this.method;
  }

  getIsAuthRequired() {
    return this.isAuthRequired;
  }

  /** ***************** Login APIs *************************** **/
  static login = new APIData('staffs/login', 'POST', false);
  static signup = new APIData(`staffs/signup`, 'POST', false);
  static logout = new APIData(`staffs/logout`, 'POST');
  static checkLogin = new APIData(`staffs/check-login`, 'POST');
  static getStaffSignUpOtp = new APIData('staffs/signup/otp', 'POST', false);
  static getOtpToResetPassword = new APIData('staffs/password/forgot', 'POST', false);
  static verifyOtpToResetPassword = new APIData('staffs/password/verify-code', 'POST', false);
  static resetPassword = new APIData('staffs/password/reset', 'POST', false);

  /** ******************Staff  APIs****************************************/
  static updateUserPassword = new APIData('staffs/password/change', 'PUT');
  static updateUserDetails = new APIData('staffs', 'PUT');
  static getUserDetails = new APIData('staffs', 'GET');
  static deleteUserAccount = new APIData('staffs', 'DELETE');
  static searchStaffDetails = new APIData(`staffs/search`, 'GET');

  /** ******************Clinic APIs******************/
  static createClinic = new APIData(`clinics`, 'POST');
  static getClinics = new APIData(`clinics`, 'GET');
  static deleteClinic = new APIData(`clinics/{0}`, 'DELETE');
  static getClinicDetails = new APIData(`clinics/{0}`, 'GET');
  static modifyClinicBasicDetails = new APIData(`clinics/{0}`, 'PUT');
  static createClinicContact = new APIData(`clinics/{0}/contact`, 'POST');
  static updateClinicContact = new APIData(`clinics/{0}/contact/{1}`, 'PUT');
  static deleteClinicContact = new APIData(`clinics/{0}/contact/{1}`, 'DELETE');
  static updateClinicOpenTimings = new APIData(`clinics/{0}/timing/open-hours`, 'PUT');
  static updateClinicHolidayTimings = new APIData(`clinics/{0}/timing/holiday-hours`, 'PUT');

  /** ******************Staff under Clinic APIs******************/
  static getStaffDetails = new APIData(`clinics/{0}/staffs/{1}`, 'GET');
  static deleteStaff = new APIData(`clinics/{0}/staffs/{1}`, 'DELETE');
  static getStaffList = new APIData(`clinics/{0}/staffs`, 'GET');
  static modifyStaffRoleDetails = new APIData(`clinics/{0}/staffs/{1}`, 'PUT');
  static getStaffOpenHoursDetails = new APIData(`clinics/{0}/staffs/{1}/timing/open-hours`, 'GET');
  static modifyStaffOpenHoursDetails = new APIData(`clinics/{0}/staffs/{1}/timing/open-hours`, 'PUT');
  static getStaffHolidayHoursDetails = new APIData(`clinics/{0}/staffs/{1}/timing/holiday-hours`, 'GET');
  static modifyStaffHolidayHoursDetails = new APIData(`clinics/{0}/staffs/{1}/timing/holiday-hours`, 'PUT');

  /** ******************Doctor APIs**************************/
  static getDoctors = new APIData(`clinics/{0}/doctors`, 'GET');
  static getDoctorOpSessionDetails = new APIData(`clinics/{0}/doctors/{1}/op-sessions`, 'GET');
  static getDoctorDetails = new APIData(`doctors`, 'GET');
  static createDoctorDetails = new APIData(`doctors`, 'POST');
  static modifyDoctorDetails = new APIData(`doctors`, 'PUT');
  static deleteDoctorDetails = new APIData(`doctors`, 'DELETE');

  /** ******************Appointment APIs**********************************/
  static createAppointment = new APIData(`clinics/{0}/appointments`, 'POST');
  static createClinicAppointment = new APIData(`clinics/{0}/appointments/multiple`, 'POST');
  static getTokenDetailsWithOpId = new APIData(`clinics/{0}/op/{1}/token`, 'GET');
  static getTokenDetailsWithSessionId = new APIData(`clinics/{0}/doctors/{1}/token`, 'GET');
  static getAppointmentList = new APIData(`clinics/{0}/appointments`, 'GET');

  /** ******************Patient APIs**********************************/
  static searchClinicPatients = new APIData(`clinics/{0}/patients/search`, 'GET');
  static searchPatients = new APIData(`patients/search`, 'GET');
  static getClinicPatientDetails = new APIData(`clinics/{0}/patients/{1}`, 'GET');
  static createPatient = new APIData(`clinics/{0}/patients`, 'POST');
  static modifyPatient = new APIData(`clinics/{0}/patients/{1}`, 'PUT');
  static deletePatient = new APIData(`clinics/{0}/patients/{1}`, 'DELETE');

  /** ******************OP APIs**********************************/
  static createOp = new APIData(`clinics/{0}/op`, 'POST');
  static getOp = new APIData(`clinics/{0}/op/{1}`, 'GET');
  static startOP = new APIData(`clinics/{0}/op/{1}/start`, 'PUT');
  static endOP = new APIData(`clinics/{0}/op/{1}/end`, 'PUT');
  static restartOP = new APIData(`clinics/{0}/op/{1}/restart`, 'PUT');
  static deleteOP = new APIData(`clinics/{0}/op/{1}`, 'DELETE');
  static modifyOp = new APIData(`clinics/{0}/op/{1}`, 'PUT');
  static markPatientVisit = new APIData(`clinics/{0}/appointments/{1}/visit`, 'PUT');
  static checkInPatient = new APIData(`clinics/{0}/appointments/{1}/check-in`, 'PUT');
  static checkOutPatient = new APIData(`clinics/{0}/appointments/{1}/check-out`, 'PUT');
  static cancelAppointment = new APIData(`clinics/{0}/appointments/{1}`, 'DELETE');
  static restoreAppointment = new APIData(`clinics/{0}/appointments/{1}/restore`, 'PUT');
  static getAppointmentDetails = new APIData(`clinics/{0}/appointments/{1}`, 'GET');
  static modifyAppointmentDetails = new APIData(`clinics/{0}/appointments/{1}`, 'PUT');
  static getPastAppointmentHistory = new APIData(`clinics/{0}/patients/{1}/appointments`, 'GET');
  static getOpSummary = new APIData(`clinics/{0}/op/{1}/summary`, 'GET');

  /** ******************Patient Parameters APIs**********************************/
  static getPatientParametersList = new APIData(`patient-parameters`, 'GET');
  static getPatientParameters = new APIData(`clinics/{0}/appointments/{1}/pp`, 'GET');
  static modifyPatientParameters = new APIData(`clinics/{0}/appointments/{1}/pp`, 'PUT');
  static deletePatientParameters = new APIData(`clinics/{0}/appointments/{1}/pp`, 'DELETE');

  /** ******************Invite APIs**********************************/
  static inviteStaff = new APIData(`clinics/{0}/staffs/invite`, 'POST');
  static getInvite = new APIData(`invites/{0}`, 'GET');
  static acceptInvite = new APIData(`invites/{0}/accept`, 'PUT');
  static declineInvite = new APIData(`invites/{0}/reject`, 'PUT');
  static resendInvite = new APIData(`invites/{0}`, 'POST');
  static cancelStaffInvite = new APIData(`invites/{0}`, 'DELETE');

  /** **********************Settings APIs****************************/
  static getClinicSettings = new APIData(`clinics/{0}/settings`, 'GET');
  static modifyClinicSettings = new APIData(`clinics/{0}/settings`, 'PUT');
  static getStaffSettings = new APIData(`staffs/settings`, 'GET');
  static modifyStaffSettings = new APIData(`staffs/settings`, 'PUT');
  static getClinicStaffSettings = new APIData(`clinics/{0}/staffs/{1}/settings`, 'GET');
  static modifyClinicStaffSettings = new APIData(`clinics/{0}/staffs/{1}/settings`, 'PUT');
  static getClinicTimingsDefaultSettings = new APIData(`clinics/{0}/timing/settings`, 'GET');
  static getClinicStaffTimingsDefaultSettings = new APIData(`clinics/{0}/staffs/{1}/timing/settings`, 'GET');
}

export default APIData;
