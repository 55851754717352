import * as React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Grid, Paper } from '@mui/material';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { validateNumber, validateStringForNull, validateEmailId, validateDate, isFutureDate } from '../../utils/FieldValidations';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import { InputAdornment } from '@mui/material';
import TSBackDrop from '../tscomponents/TSBackDrop';
import Services from '../../utils/Services';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styled from '@emotion/styled';
import ManageDoctorDetails from '../doctorsettings/ManageDoctorDetails';
import { cloneDeep } from 'lodash';
import DeleteAccount from './DeleteAccount';
import APIData from '../../utils/APIData';
import { CookieUtils } from '../../utils/CookieUtils';

const PaperBase = styled(Paper)(()=>({
  height: 'fit-content'
}));

const MyAccount = ({ handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    staff_name: '',
    phone: '',
    email: '',
    dob: null
  });
  const [originalValue, setOriginalValue] = useState(userDetails);
  const [passwordDetails, setPasswordDetails] = useState({
    old_password: '',
    new_password: '',
    confirm_new_password: ''
  });

  const clearPassswordFields = () => {
    setPasswordDetails({
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    });
  };

  const APIToken = {
    UPDATE_USER_PASSWORD: 'CMA01',
    UPDATE_USER_DETAILS: 'CMA02',
    GET_USER_DETAILS: 'CMA03'
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const handleTextChange = (event) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value });
  };

  const handlePasswordChange = (event) => {
    setPasswordDetails({ ...passwordDetails, [event.target.name]: event.target.value });
  };

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const validateParams = ( params ) => {
    Object.keys(params).forEach((key) => {
      if (!validateStringForNull(params[key])) {
        throw new Error('Invalid password');
      }
    });
    if (params.new_password != params.confirm_new_password) {
      throw new Error('Password Mismatched');
    };
  };

  const raisePasswordUpdateRequest = (params) => {
    setIsLoading(true);
    const finalParams ={
      old_password: params.old_password,
      new_password: params.new_password
    };
    Services.sendBackendRequest({ apiData: APIData.updateUserPassword, params: finalParams },
      APIToken.UPDATE_USER_PASSWORD, processSuccess, processError);
  };

  const validateUserDetailsParams = (params) => {
    if (!validateStringForNull(params.staff_name)) {
      throw new Error('Invalid Name');
    }
    if (!validateEmailId(params.email)) {
      throw new Error('Invalid Email Address');
    }
    if (!validateNumber(params.phone)) {
      throw new Error('Invalid Phone Number');
    }
    if (params.dob != null && (!validateDate(params.dob) || isFutureDate(params.dob))) {
      throw new Error('Invalid Date of Birth');
    }
  };

  const getModifiedParams = (params) => {
    const modifiedParams = {};
    if (originalValue.staff_name != params.staff_name) {
      modifiedParams.staff_name = params.staff_name;
    }
    if (originalValue.phone != params.phone) {
      modifiedParams.phone = params.phone;
    }
    if (originalValue.email != params.email) {
      modifiedParams.email = params.email;
    }
    if (originalValue.dob != null && params.dob == null) {
      modifiedParams.dob = null;
    } else if (moment(originalValue.dob).format('yyyy-MM-DD') != moment(params.dob).format('yyyy-MM-DD')) {
      modifiedParams.dob = moment(params.dob).format('yyyy-MM-DD');
    }
    return modifiedParams;
  };

  const raiseUserDetailsUpdateRequest = (params) => {
    setIsLoading(true);
    const finalParams = getModifiedParams(params);
    if (Object.keys(finalParams).length == 0) {
      throw new Error('Nothing to update');
    }
    Services.sendBackendRequest({ apiData: APIData.updateUserDetails, params: finalParams },
      APIToken.UPDATE_USER_DETAILS, processSuccess, processError);
  };

  useEffect(() => {
    raiseGetUserDetails();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUserDetails && apiToken == APIToken.GET_USER_DETAILS ) {
      const profileDetails = {
        email: response.data.email,
        phone: response.data.phone,
        staff_name: response.data.staff_name,
        dob: validateStringForNull(response.data.dob) ? moment(response.data.dob, 'yyyy-MM-DD') : null
      };
      setUserDetails(profileDetails);
      setOriginalValue(profileDetails);
    } else if (apiData == APIData.updateUserDetails && apiToken == APIToken.UPDATE_USER_DETAILS) {
      showSnackBar('success', response.message ?? 'User Details Updated');
      setOriginalValue(userDetails);
    } else if (apiData == APIData.updateUserPassword && apiToken == APIToken.UPDATE_USER_PASSWORD) {
      showSnackBar('success', response.message ?? 'Password Updated');
      clearPassswordFields();
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getUserDetails && apiToken == APIToken.GET_USER_DETAILS) {
      defaultMsg = 'Failed to retrieve user details';
    } else if (apiData == APIData.updateUserDetails && apiToken == APIToken.UPDATE_USER_DETAILS) {
      defaultMsg = 'Failed to update user details';
    } else if (apiData == APIData.updateUserPassword && apiToken == APIToken.UPDATE_USER_PASSWORD) {
      defaultMsg = 'Failed to change password';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserDetails = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUserDetails }, APIToken.GET_USER_DETAILS, processSuccess, processError);
  };

  const handleProfileDetailsResetClick = () => {
    setUserDetails(originalValue);
  };

  const handleProfileDetailsUpdateClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(userDetails);
      validateUserDetailsParams(params);
      raiseUserDetailsUpdateRequest(params);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Update user details');
      setIsLoading(false);
    };
  };

  const handlePasswordUpdateClick = () =>{
    setIsLoading(true);
    try {
      const params = cloneDeep(passwordDetails);
      validateParams(params);
      raisePasswordUpdateRequest(params);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to reset password');
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <AppBar position='sticky'>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Account Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container direction='column' >
        <PaperBase
          elevation={12}
          sx={{
            pl: 5,
            pr: 5,
            m: 5
          }}
        >
          <Grid item>
            <Typography component="h5" variant="h5" marginTop={4}>
              My Profile Details
            </Typography>
          </Grid>
          <Grid item container justifyContent='space-between' alignItems='center' columnSpacing={2} >
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                name="staff_name"
                value={userDetails.staff_name}
                autoComplete ='off'
                onChange={(event) => {handleTextChange(event);}}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete ='off'
                value={userDetails.phone}
                onChange={(event) => {handleTextChange(event);}}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="normal"
                id="email"
                fullWidth
                label="Email"
                name="email"
                autoComplete ='off'
                value={userDetails.email}
                onChange={(event) => {handleTextChange(event);}}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date of Birth"
                  format="DD/MM/YYYY"
                  sx={{ width: '100%' }}
                  disableFuture
                  value={userDetails.dob}
                  name="dob"
                  onChange={(newValue) => {setUserDetails({ ...userDetails, dob: newValue });}}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} >
            <Grid item>
              <Button
                type="submit"
                variant="text"
                size="small"
                onClick={handleProfileDetailsResetClick}
              >
              Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={handleProfileDetailsUpdateClick}
              >
              Update
              </Button>
            </Grid>
          </Grid>
        </PaperBase>
      </Grid>
      <Grid container direction='column' >
        <PaperBase
          elevation={12}
          sx= {{
            p: 5,
            pb: 0,
            m: 5,
            mt: 0,
            mb: 0
          }}
        >
          <Grid item>
            <Typography component="h5" variant="h5" >
            Change your password
            </Typography>
          </Grid>
          <Grid item container justifyContent='space-between' alignItems='center' columnSpacing={2} >
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="old_password"
                label="Current Password"
                name="old_password"
                type={showPassword ? 'text' : 'password'}
                value={passwordDetails.old_password}
                autoComplete= 'new-password'
                onChange={(event) => {handlePasswordChange(event);}}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                margin="normal"
                required
                fullWidth
                id="new_password"
                label="New Password"
                name="new_password"
                type={showNewPassword ? 'text' : 'password'}
                value={passwordDetails.new_password}
                autoComplete= 'new-password'
                onChange={(event) => {handlePasswordChange(event);}}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleClickShowNewPassword}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirm_new_password"
                label="Confirm New Password"
                name="confirm_new_password"
                type="password"
                value={passwordDetails.confirm_new_password}
                autoComplete= 'new-password'
                onChange={(event) => {handlePasswordChange(event);}}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} >
            <Grid item >
              <Button
                type="submit"
                variant="text"
                size="small"
                onClick={clearPassswordFields}
              >
              Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={handlePasswordUpdateClick}
              >
              Update
              </Button>
            </Grid>
          </Grid>
        </PaperBase>
      </Grid>
      {CookieUtils.getIsDoctor() == true ? (<ManageDoctorDetails />) : ''}
      <DeleteAccount />
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

MyAccount.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default MyAccount;
