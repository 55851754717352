import {
  Box, Paper, Typography
} from '@mui/material';
import React from 'react';
import ManageContactDetails from '../components/manageclinicdetails/ManageContactDetails';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

const PaperBase = styled(Paper)(()=>({
  height: 'fit-content'
}));

const ModifyClinicContactInformation = () => {
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  return (
    <React.Fragment>
      <Helmet>
        <title>Manage User Contacts - DocKit</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        pt: 10,
        px: 2,
        pb: 10
      }}>
        <PaperBase elevation={5}
          sx={{
            p: 1,
            pb: 15
          }}>
          <Typography variant="h4" align='center' sx={{ pt: 3 }}>
            Manage Clinic Contact Information
          </Typography>
          <ManageContactDetails clinicId={clinicId} />
        </PaperBase>
      </Box>
    </React.Fragment>
  );
};

export default ModifyClinicContactInformation;
