import {
  AppBar,
  Box,
  Button,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  IconButton,
  Select,
  TextField,
  Toolbar,
  Typography,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Services from '../../utils/Services';
import { useSearchParams } from 'react-router-dom';
import { validateDate, validateNumber, validateSettings, validateStringForNull } from '../../utils/FieldValidations';
import { Container } from '@mui/system';
import moment from 'moment';
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { cloneDeep } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import OpSummary from '../opsummary/OpSummary';
import { Utils } from '../../utils/UtilFunctions';
import { SESSION_STATUS } from '../../utils/EnumDefinitions';
import APIData from '../../utils/APIData';
import TSSnackbar from '../tscomponents/TSSnackbar';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import { CookieUtils } from '../../utils/CookieUtils';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const ManageOPDetails = ({ manageCallbackFunc, isDoctor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [sessionDetails, setSessionDetails] = useState([]);
  const [opDetails, setOpDetails] = useState({
    doctor_id: '',
    doctor_name: '',
    op_date: moment(),
    session_id: '',
    op_id: '',
    op_start_time: '',
    op_end_time: '',
    session_start_time: null,
    session_end_time: null,
    settings: null
  });
  const [modifyOpDetails, setModifyOpDetails] = useState({
    session_start_time: null,
    session_end_time: null,
    session_name: '',
    settings: null
  });
  const [isModifyInProcess, setIsModifyInprocess] = useState(false);
  const [isOPSummaryDialogOpen, setIsOPSummaryDialogOpen] = useState(false);
  const [isDeleteOpDialogOpen, setIsDeleteOpDialogOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  const defaultModifyOPButtonName = 'Modify OP';
  const [modifyOPButtonName, setModifyOPButtonName] = useState(defaultModifyOPButtonName);
  const [opSettings, setOpSettings] = useState([]);
  const [isSettingsDisabled, setIsSettingsDisabled] = useState(false);
  const APIToken = {
    GET_DOCTORS: 'CMOPD01',
    GET_DOCTOR_SESSION: 'CMOPD02',
    CREATE_OP: 'CMOPD03',
    MODIFY_OP: 'CMOPD04',
    DELETE_OP: 'CMOPD05'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateNumber(clinicId)) {
      raiseDoctorListRequest();
    }
  }, [clinicId]);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.deleteOP && apiToken == APIToken.DELETE_OP) {
      showSnackBar('success', response.message ?? 'OP deleted Successfully');
      setOpDetails({ ...opDetails,
        op_id: '',
        session_id: '',
        op_start_time: '',
        op_end_time: '',
        session_start_time: null,
        session_end_time: null,
        settings: null
      });
      setSessionDetails([]);
      setModifyOpDetails({
        session_start_time: null,
        session_end_time: null,
        session_name: '',
        settings: null
      });
      setOpSettings([]);
      setIsSettingsDisabled(false);
      setIsModifyInprocess(false);
      raiseDoctorSessionDetails(opDetails.doctor_id, opDetails.op_date);
    } else if (apiData == APIData.getDoctorOpSessionDetails && apiToken == APIToken.GET_DOCTOR_SESSION) {
      setSessionDetails(response.data);
      setIsModifyInprocess(false);
      setModifyOPButtonName(defaultModifyOPButtonName);

      if (callbackValues.sessionId != undefined) {
        // for create and modify successful request it will come here
        const objIndex = response.data.findIndex((obj) => obj.session_id == callbackValues.sessionId);
        setIsSettingsDisabled(validateStringForNull(response.data[objIndex].op_id));
        setOpSettings(response.data[objIndex].settings);
      }
      setIsLoading(false);
    } else if (apiData == APIData.getDoctors && apiToken == APIToken.GET_DOCTORS) {
      setDoctorDetails(response.data);
      if (response.data.length == 1) {
        setOpDetails({ ...opDetails,
          doctor_id: response.data[0].doctor_id,
          doctor_name: response.data[0].doctor_name
        });
        if (validateDate(opDetails.op_date) && validateStringForNull(response.data[0].doctor_id)) {
          raiseDoctorSessionDetails(response.data[0].doctor_id, opDetails.op_date);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setPreSelectDoctor(response.data);
      }
    } else if (apiData == APIData.createOp && apiToken == APIToken.CREATE_OP) {
      showSnackBar('success', response.message ?? 'OP Created successfully');
      setModifyOPButtonName(defaultModifyOPButtonName);
      setOpDetails({ ...opDetails,
        op_id: response.data.op_id
      });
      if (validateDate(opDetails.session_start_time) || validateDate(opDetails.session_end_time)) {
        const modifiedTime = {};
        if (validateDate(opDetails.session_start_time)) {
          modifiedTime.session_start_time = opDetails.session_start_time;
        } else {
          modifiedTime.session_start_time = moment(response.data.session_start_time, 'HH:mm:ss');
        }
        if (validateStringForNull(opDetails.session_end_time)) {
          modifiedTime.session_end_time = opDetails.session_end_time;
        } else {
          modifiedTime.session_end_time = moment(response.data.session_end_time, 'HH:mm:ss');
        }
        setModifyOpDetails({ ...modifyOpDetails,
          session_start_time: modifiedTime.session_start_time,
          session_end_time: modifiedTime.session_end_time,
          session_name: moment(modifiedTime.session_start_time). format('hh:mm:ss A')+ ' - ' +
          moment(modifiedTime.session_end_time).format('hh:mm:ss A'),
          settings: null
        });
      }
      setSessionDetails([]);
      setOpSettings([]);
      raiseDoctorSessionDetails(opDetails.doctor_id, opDetails.op_date, opDetails.session_id);
    } else if (apiData == APIData.modifyOp && apiToken == APIToken.MODIFY_OP) {
      showSnackBar('success', response.message ?? 'OP details updated successfully');
      setIsModifyInprocess(false);
      setModifyOPButtonName(defaultModifyOPButtonName);
      setModifyOpDetails({ ...modifyOpDetails,
        session_start_time: response.data.session_start_time != undefined ?
          moment(response.data.session_start_time.value, 'HH:mm:ss') : null,
        session_end_time: response.data.session_end_time != undefined ?
          moment(response.data.session_end_time.value, 'HH:mm:ss') : null,
        session_name: response.data.session_start_time.display + ' - ' +
          response.data.session_end_time.display,
        settings: null
      });
      setSessionDetails([]);
      setOpSettings([]);
      raiseDoctorSessionDetails(opDetails.doctor_id, opDetails.op_date, opDetails.session_id);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.deleteOP && apiToken == APIToken.DELETE_OP) {
      defaultMsg = 'Failed to delete Op details';
    } else if (apiData == APIData.getDoctorOpSessionDetails && apiToken == APIToken.GET_DOCTOR_SESSION) {
      defaultMsg = 'Failed to retrieve Doctor Session details';
    } else if (apiData == APIData.getDoctors && apiToken == APIToken.GET_DOCTORS) {
      defaultMsg = 'Failed to retrieve Doctor List';
    } else if (apiData == APIData.createOp && apiToken == APIToken.CREATE_OP) {
      defaultMsg = 'Failed to create OP';
    } else if (apiData == APIData.modifyOp && apiToken == APIToken.MODIFY_OP) {
      defaultMsg = 'Failed to Update OP details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const handleManageClick = () => {
    try {
      if (!validateNumber(opDetails.doctor_id) || !validateStringForNull(opDetails.doctor_name)) {
        throw new Error('Select Doctor');
      } else if (!validateDate(opDetails.op_date)) {
        throw new Error('Select valid OP date');
      } else if (!validateNumber(opDetails.session_id) || !validateNumber(opDetails.op_id)) {
        throw new Error('Select valid OP session');
      }
      const newTabObj = {
        op_id: opDetails.op_id,
        doctor_name: opDetails.doctor_name,
        op_date: opDetails.op_date,
        session_id: opDetails.session_id
      };
      if (manageCallbackFunc != undefined) {
        manageCallbackFunc(newTabObj);
      }
    } catch (err) {
      showSnackBar('error', err.message);
    };
  };

  const handleComboboxChange = (event) =>{
    if (event.target.name == 'doctor_id') {
      const objIndex = doctorDetails.findIndex((obj) => obj.doctor_id == event.target.value);
      setOpDetails({ ...opDetails, [event.target.name]: event.target.value,
        session_id: '',
        op_start_time: '',
        op_end_time: '',
        op_id: '',
        session_start_time: null,
        session_end_time: null,
        doctor_name: doctorDetails[objIndex].doctor_name,
        settings: null
      });
      setOpSettings([]);
      setSessionDetails([]);
      if (validateDate(opDetails.op_date) && validateStringForNull(event.target.value)) {
        raiseDoctorSessionDetails(event.target.value, opDetails.op_date);
      }
    } else if (validateStringForNull(opDetails.doctor_id) && event.target.name == 'session_id') {
      const objIndex = sessionDetails.findIndex((obj) => obj.session_id == event.target.value);
      setOpSettings(sessionDetails[objIndex].settings);
      setIsSettingsDisabled(validateStringForNull(sessionDetails[objIndex].op_id));
      if (sessionDetails[objIndex].session_start_time != undefined ||sessionDetails[objIndex].session_end_time != undefined ) {
        setModifyOpDetails({ ...modifyOpDetails,
          session_start_time: sessionDetails[objIndex].session_start_time != undefined ?
            moment(sessionDetails[objIndex].session_start_time.value, 'HH:mm:ss') : null,
          session_end_time: sessionDetails[objIndex].session_end_time != undefined ?
            moment(sessionDetails[objIndex].session_end_time.value, 'HH:mm:ss') : null,
          session_name: sessionDetails[objIndex].session_start_time.display + ' - ' +
            sessionDetails[objIndex].session_end_time.display,
          settings: null
        });
        setOpDetails({ ...opDetails, [event.target.name]: event.target.value,
          op_id: sessionDetails[objIndex].op_id,
          op_start_time: sessionDetails[objIndex].op_start_time,
          op_end_time: sessionDetails[objIndex].op_end_time,
          session_start_time: sessionDetails[objIndex].session_start_time != undefined ?
            moment(sessionDetails[objIndex].session_start_time.value, 'HH:mm:ss') : null,
          session_end_time: sessionDetails[objIndex].session_end_time != undefined ?
            moment(sessionDetails[objIndex].session_end_time.value, 'HH:mm:ss') : null,
          settings: null
        });
      } else {
        setOpDetails({ ...opDetails, [event.target.name]: event.target.value,
          op_id: sessionDetails[objIndex].op_id,
          op_start_time: sessionDetails[objIndex].op_start_time,
          op_end_time: sessionDetails[objIndex].op_end_time,
          session_start_time: null,
          session_end_time: null,
          settings: null
        });
        setModifyOpDetails({ ...modifyOpDetails,
          session_start_time: null,
          session_end_time: null,
          session_name: '',
          settings: null
        });
      }
      setModifyOPButtonName(defaultModifyOPButtonName);
      setIsModifyInprocess(false);
    }
  };

  const raiseDeleteOpRequest = () => {
    setIsLoading(true);
    setIsDeleteOpDialogOpen(false);
    const opId = opDetails.op_id;
    Services.sendBackendRequest({ apiData: APIData.deleteOP, uriValues: [clinicId, opId] },
      APIToken.DELETE_OP, processSuccess, processError);
  };

  const handleDateChange = (name, value) => {
    setOpDetails({ ...opDetails,
      [name]: value,
      op_id: '',
      session_id: '',
      op_start_time: '',
      op_end_time: '',
      session_start_time: null,
      session_end_time: null,
      settings: null
    });
    setModifyOpDetails({
      session_start_time: null,
      session_end_time: null,
      session_name: '',
      settings: null
    });
    setOpSettings([]);
    setSessionDetails([]);
    if (validateDate(value) && validateStringForNull(opDetails.doctor_id)) {
      raiseDoctorSessionDetails(opDetails.doctor_id, value);
    }
  };

  const raiseDoctorSessionDetails = (docId, date, sessionId) => {
    setIsLoading(true);
    const finalParams = 'include_past_sessions=true' + '&op_date=' + moment(date).format('yyyy-MM-DD');
    Services.sendBackendRequest({ apiData: APIData.getDoctorOpSessionDetails, uriValues: [clinicId, docId], params: finalParams },
      APIToken.GET_DOCTOR_SESSION, processSuccess, processError, { sessionId: sessionId });
  };

  const setPreSelectDoctor = (doctorList) => {
    if (isDoctor == true) {
      const arr = doctorList.filter((obj) => obj.doctor_id === CookieUtils.getStaffId());
      if (arr.length > 0) {
        setOpDetails({ ...opDetails, doctor_id: arr[0].doctor_id,
          doctor_name: arr[0].doctor_name
        });
        if (validateDate(opDetails.op_date) && validateStringForNull(arr[0].doctor_id)) {
          raiseDoctorSessionDetails(arr[0].doctor_id, opDetails.op_date);
        }
      }
    }
  };

  const raiseDoctorListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getDoctors, uriValues: [clinicId] },
      APIToken.GET_DOCTORS, processSuccess, processError);
  };

  const validateCreateOpParams = (params) => {
    const validatedObj = {};
    if (!validateStringForNull(params.doctor_id)) {
      throw new Error('Select valid Doctor');
    }
    if (!validateStringForNull(params.session_id)) {
      throw new Error('Select valid Session');
    }
    validatedObj.doctor_id = params.doctor_id;
    validatedObj.session_id = params.session_id;
    if (validateDate(params.op_date)) {
      validatedObj.op_date = moment(params.op_date).format('yyyy-MM-DD');
    } else {
      throw new Error('Provide Valid OP Date');
    }
    if (params.session_start_time != null) {
      if (validateDate(params.session_start_time)) {
        validatedObj.session_start_time = moment(params.session_start_time).format('HH:mm:ss');
      } else {
        throw new Error('Provide Valid Session Start Time');
      }
    }
    if (params.session_end_time != null) {
      if (validateDate(params.session_end_time)) {
        validatedObj.session_end_time = moment(params.session_end_time).format('HH:mm:ss');
      } else {
        throw new Error('Provide Valid Session End Time');
      }
    }
    if (params.settings != null) {
      validatedObj.settings = validateSettings(params.settings);
    }

    return validatedObj;
  };

  const raiseCreateOpRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.createOp, uriValues: [clinicId], params: finalParams },
      APIToken.CREATE_OP, processSuccess, processError);
  };

  const handleCreateOpClick = () => {
    try {
      const params = cloneDeep(opDetails);
      const validatedObj = validateCreateOpParams(params);
      raiseCreateOpRequest(validatedObj);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Create OP');
      setIsLoading(false);
    };
  };

  const handleModifyOpClick = () => {
    try {
      if (isModifyInProcess == false) {
        setIsModifyInprocess(true);
        setIsSettingsDisabled(false);
        setModifyOPButtonName('Confirm');
      } else {
        const params = cloneDeep(modifyOpDetails);
        const validatedObj = validateModifyOpParams(params);
        raiseModifyOpRequest(validatedObj);
      }
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Modify OP');
      setIsLoading(false);
    };
  };

  const validateModifyOpParams = (params) => {
    const validatedObj = {};
    if (params.session_start_time != null) {
      if (validateDate(params.session_start_time)) {
        validatedObj.session_start_time = moment(params.session_start_time).format('HH:mm:ss');
      } else {
        throw new Error('Invalid Start Time');
      }
    }
    if (params.session_end_time != null) {
      if (validateDate(params.session_end_time)) {
        validatedObj.session_end_time = moment(params.session_end_time).format('HH:mm:ss');
      } else {
        throw new Error('Invalid End Time');
      }
    }
    if (params.settings != null) {
      validatedObj.settings = validateSettings(params.settings);
    }
    return validatedObj;
  };

  const raiseModifyOpRequest = (finalParams) => {
    setIsLoading(true);
    if (Object.keys(finalParams).length == 0) {
      throw new Error('Nothing to update');
    }
    const opId = opDetails.op_id;
    Services.sendBackendRequest({ apiData: APIData.modifyOp, uriValues: [clinicId, opId], params: finalParams },
      APIToken.MODIFY_OP, processSuccess, processError);
  };

  const handleSettingsInputChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const updatedSettings = opSettings.map((setting) => {
      if (setting.key === key) {
        return { ...setting, value };
      }
      return setting;
    });
    setOpSettings(updatedSettings);

    if (validateStringForNull(opDetails.op_id)) {
      setModifyOpDetails((prevDetails) => ({
        ...prevDetails,
        settings: updatedSettings
      }));
    } else {
      setOpDetails((prevDetails) => ({
        ...prevDetails,
        settings: updatedSettings
      }));
    }
  };

  const getOpSummaryContent = () => {
    return (
      <React.Fragment>
        <TSSideBarDialog
          open={isOPSummaryDialogOpen}
          TransitionComponent={Transition}
          onClose={() => {}}
        >
          <AppBar position='sticky'>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsOPSummaryDialogOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                OP Summary
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent dividers>
            <OpSummary clinicId={clinicId} opId={opDetails.op_id} />
          </DialogContent>
        </TSSideBarDialog>
      </React.Fragment>
    );
  };

  const getOpStatus = () => {
    if (validateStringForNull(opDetails.op_id)) {
      if (opDetails.op_end_time != undefined && opDetails.op_end_time != '') {
        return 4;
      } else if (opDetails.op_start_time != undefined && opDetails.op_start_time != '') {
        return 3;
      } else {
        return 2;
      }
    } else if (validateStringForNull(opDetails.session_id)) {
      return 1;
    } else {
      return -1;
    }
  };

  const getActionButtons = () => {
    const status = getOpStatus();
    if (status == -1) {
      return '';
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ mt: 2 }}
      >
        {status == 1 ? (
          <Grid item sx={{ pt: 2, pl: 2 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={() =>handleCreateOpClick()}
              size = 'small'
            >
              Create OP
            </Button>
          </Grid>
        ) : ''}
        {status == 2 && CookieUtils.getStaffClinicRole() < 3 ? (
          <Grid item sx={{ pt: 2, pl: 2 }} >
            <Button
              variant='contained'
              color='error'
              onClick={() =>setIsDeleteOpDialogOpen(true)}
              size = 'small'
            >
              Delete OP
            </Button>
          </Grid>
        ) : '' }
        {status == 2 ? (
          <Grid item sx={{ pt: 2, pl: 2 }} >
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleModifyOpClick()}
              size = 'small'
            >
              {modifyOPButtonName}
            </Button>
          </Grid>
        ) : ''}
        { status == 4 ? (
          <Grid item sx={{ pt: 2, pl: 2 }}>
            <Button
              variant='contained'
              color='success'
              onClick={() => setIsOPSummaryDialogOpen(true)}
              size = 'small'
            >
              Show OP Summary
            </Button>
          </Grid>
        ) : ''}
        { status > 1 && manageCallbackFunc != undefined ? (
          <Grid item sx={{ pt: 2, pl: 2 }} >
            <Button
              type="submit"
              variant="contained"
              size="small"
              onClick={handleManageClick}
            >
              Manage
            </Button>
          </Grid>
        ) : ''}
      </Grid>
    );
  };

  const getDeleteOpConfirmationDialog = () => {
    return (
      //  check Box
      <Box>
        <TSConfirmationDialog
          open={isDeleteOpDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Delete OP</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to delete the selected OP?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size='small'
              color='primary'
              onClick={() =>setIsDeleteOpDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={() => raiseDeleteOpRequest()}
            >
              Delete OP
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Container maxWidth='xs' sx={{ backgroundColor: 'white', p: 5, mt: 2 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4" align='center'>
              Select session details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth >
              <InputLabel size="small" id="doctor-list-select-label">Doctor Name</InputLabel>
              <Select
                labelId="doctor-list-select-label"
                id="doctor-list-select"
                label="doctor_name"
                name='doctor_id'
                disabled = {isDoctor}
                value={opDetails.doctor_id ?? ''}
                onChange={handleComboboxChange}
                size="small"
              >
                {doctorDetails.map((obj) => (
                  <MenuItem key={obj.doctor_id} value={obj.doctor_id}>
                    {obj.doctor_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label='Date'
                name='op_date'
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                value={opDetails.op_date}
                onChange={(e) => handleDateChange('op_date', e)}
                slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth >
              <InputLabel size="small" id="session-id-select-label">Session</InputLabel>
              <Select
                labelId="session-id-select-label"
                id="doctor-id-select"
                label="session_name"
                name='session_id'
                value={opDetails.session_id ?? ''}
                onChange={handleComboboxChange}
                size="small"
              >
                {sessionDetails.map((obj) => (
                  <MenuItem key={obj.session_id} value={obj.session_id}>
                    {obj.start_time.display.toUpperCase()} - {obj.end_time.display.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {isModifyInProcess == false && validateStringForNull(opDetails.session_id) && (!validateStringForNull(opDetails.op_id)) ? (
            <Grid
              container
              alignItems="center"
              sx={{ mt: 0 }}
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopTimePicker
                    ampm
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock
                    }}
                    name='new_session_start_time'
                    label='New Start Time'
                    sx={{ width: '100%' }}
                    value={opDetails.session_start_time}
                    maxTime={opDetails.session_end_time}
                    onChange={(time) => {setOpDetails({ ...opDetails, session_start_time: time });}}
                    slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopTimePicker
                    ampm
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock
                    }}
                    name='new_session_end_time'
                    label='New End Time'
                    sx={{ width: '100%' }}
                    value={opDetails.session_end_time}
                    minTime={opDetails.session_start_time}
                    onChange={(time) => {setOpDetails({ ...opDetails, session_end_time: time });}}
                    slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          ) : ''}
          {validateStringForNull(opDetails.op_id) && isModifyInProcess == true ? (
            <Grid
              container
              alignItems="center"
              sx={{ mt: 2 }}
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopTimePicker
                    ampm
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock
                    }}
                    name='session_start_time'
                    label='New Start Time'
                    sx={{ width: '100%' }}
                    value={modifyOpDetails.session_start_time}
                    maxTime={modifyOpDetails.session_end_time}
                    onChange={(time) => {setModifyOpDetails({ ...modifyOpDetails, session_start_time: time });}}
                    slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopTimePicker
                    ampm
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock
                    }}
                    sx={{ width: '100%' }}
                    name='session_end_time'
                    label='New End Time'
                    value={modifyOpDetails.session_end_time}
                    minTime={modifyOpDetails.session_start_time}
                    onChange={(time) => {setModifyOpDetails({ ...modifyOpDetails, session_end_time: time });}}
                    slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          ) : ''}
          {validateStringForNull(opDetails.op_id) && isModifyInProcess == false && validateDate(modifyOpDetails.session_start_time) &&
            validateDate(modifyOpDetails.session_end_time) ? (
              <Grid item xs={12}>
                <TextField
                  disabled
                  id="outlined"
                  name="session_name"
                  size='small'
                  autoComplete ='off'
                  value={modifyOpDetails.session_name}
                  label="Modified Session"
                  fullWidth
                />
              </Grid>
            ) : ''}
          {validateStringForNull(opDetails.session_id) ? (
            <Grid item xs={12}>
              <TextField
                disabled
                id="outlined"
                name="session_id"
                size='small'
                autoComplete ='off'
                value={SESSION_STATUS[getOpStatus()] ?? ''}
                label="Session Status"
                fullWidth
              />
            </Grid>
          ) : ''}
          {validateStringForNull(opDetails.session_id) && opSettings != undefined && opSettings.length > 0 ? (
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant='h5' >Additional Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {opSettings.map((settings) => (
                    <Grid item container spacing={2} key={settings.key} justifyContent='space-between'
                      alignItems='center' rowSpacing={3} columnSpacing={3}>
                      <Grid item xs={12}>
                        <InputLabel key={settings.key} style={{ fontSize: '0.8rem', whiteSpace: 'normal' }}>{settings.label}</InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          size='small'
                          name={settings.key}
                          id={settings.key}
                          value={settings.value ?? settings.default_value}
                          sx={{ mb: 1, color: 'white' }}
                          variant="standard"
                          disabled={isSettingsDisabled}
                          onChange={(event) => handleSettingsInputChange(event)}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title={settings.description}>
                                <IconButton>
                                  <InfoIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                              </Tooltip>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : ''}
          {getActionButtons()}
          {getOpSummaryContent()}
          {getDeleteOpConfirmationDialog()}
        </Grid>
      </Container>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading}/>
    </React.Fragment>
  );
};

ManageOPDetails.propTypes = {
  manageCallbackFunc: PropTypes.func,
  isDoctor: PropTypes.bool
};

export default ManageOPDetails;
