import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Paper, Table, TableBody, TableCell,
  tableCellClasses, TableRow } from '@mui/material';
import { InputLabel } from '@mui/material';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import APIData from '../../utils/APIData';
import { validateStringForNull } from '../../utils/FieldValidations';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

const ViewHolidayTimings = ({ clinicId, staffId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [holidayTimings, setHolidayTimings] = useState([]);
  const APIToken = {
    GET_HOLIDAY_HOURS: 'CSHT01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetHolidayOpenHoursDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if ( apiToken == APIToken.GET_HOLIDAY_HOURS ) {
      if (apiData == APIData.getStaffHolidayHoursDetails) {
        setHolidayTimings(response.data ?? []);
      } else if (apiData == APIData.getClinicDetails) {
        setHolidayTimings(response.data.holiday_hours ?? []);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if ( apiToken == APIToken.GET_HOLIDAY_HOURS ) {
      if (apiData == APIData.getStaffHolidayHoursDetails || apiData == APIData.getClinicDetails) {
        setSnackBarStatus({
          open: true,
          severity: 'error',
          message: err.message ?? 'Failed to retrieve Holiday Open Hours'
        });
      }
    }
    setIsLoading(false);
  };

  const raiseGetHolidayOpenHoursDetails = () => {
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getStaffHolidayHoursDetails, uriValues: [clinicId, staffId] },
        APIToken.GET_HOLIDAY_HOURS, processSuccess, processError);
    } else {
      const params = 'fetch_modules=holiday_hours';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
        APIToken.GET_HOLIDAY_HOURS, processSuccess, processError);
    }
  };

  const getHolidayHours = () => {
    const data = [];
    if (holidayTimings.length > 0 ) {
      let shouldPrint = true;
      let date = '';
      {holidayTimings.map((obj) => {
        shouldPrint = date != obj.special_date.display;
        date = obj.special_date.display;
        const openDay = (
          <TableRow key={data.length}>
            <TableCell style={{ width: '50%' }}>
              { shouldPrint == true ? obj.special_date.display : ''}
            </TableCell>
            <TableCell style={{ width: '50%' }}>
              {obj.start_time == undefined ? 'Closed' : (obj.start_time.display+ ' - ' + obj.end_time.display)}
            </TableCell>
          </TableRow>
        );
        data.push(openDay);
      });
      }
    } else {
      return (
        <TableRow>
          <TableCell >
            No Information Available
          </TableCell>
        </TableRow>
      );
    }
    return data;
  };

  return (
    <React.Fragment>
      <Box>
        <PaperBase elevation={2} sx={{
          flexGrow: 1,
          m: 5,
          mt: 0
        }}
        >
          <InputLabel sx={{ p: 2 }}>
            Holiday Open hours
          </InputLabel>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableBody>
              {getHolidayHours()}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewHolidayTimings.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string
};

export default ViewHolidayTimings;
