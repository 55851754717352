import styled from '@emotion/styled';
import { Box, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import InviteStaff from '../components/managestaff/InviteStaff';
import StaffList from '../components/managestaff/StaffList';

const PaperBase = styled(Paper)(()=>({
  width: 'fit-content',
  height: 'fit-content'
}));

const ManageStaff = () => {
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  const [refreshId, setRefreshId] = useState(1);
  return (
    <React.Fragment>
      <Helmet>
        <title>Manage Staffs - DocKit</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        pt: 10,
        px: 2,
        pb: 10,
        justifyContent: {
          md: 'center',
          lg: 'center'
        },
        display: 'flex'
      }}>
        <PaperBase elevation={5}
          sx={{
            p: 1,
            pb: 5
          }}>
          <Typography variant="h4" align='center' sx={{ pt: 3 }}>
            Manage Clinic Staffs
          </Typography>
          <StaffList clinicId={clinicId} refreshId={refreshId} />
          <InviteStaff clinicId={clinicId} onSuccess={() => setRefreshId(refreshId + 1)} />
        </PaperBase>
      </Box>
    </React.Fragment>
  );
};

export default ManageStaff;
