import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const TSConfirmationDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    maxHeight: 'calc(100% - 64px)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxWidth: '60%',
    margin: '32px'
  },
  '& .MuiButtonBase-root': {
    fontSize: '13px',
    marginRight: '30px',
    marginLeft: '30px',
    borderRadius: '60px'
  }
}));

export default TSConfirmationDialog;

