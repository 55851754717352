import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CustomHomeBackdrop = styled('div')(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'rgba(55, 55, 55, 0.5) !important', // Adjust the blur intensity with opacity
  borderRadius: '16px',
  margin: '24px',
  marginTop: '76px'
}));

const TSHomeSideBarDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  '& .MuiDialog-paper': {
    [theme.breakpoints.up('sm')]: {
      width: '60%',
      marginLeft: '0px'
    },
    [theme.breakpoints.up('md')]: {
      width: '43%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '35%'
    },
    width: '100%',
    minHeight: 'calc(100vh - 100px)',
    marginRight: '24px',
    marginLeft: '24px',
    marginBottom: '24px',
    borderRadius: 20
  }
}));

export { CustomHomeBackdrop, TSHomeSideBarDialog, Transition };
