import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import moment from 'moment/moment';

const TSTimer = ({ time }) => {
  let currentTime = moment(time, 'HH:mm:ss');
  const [displayTime, setDisplayTime] = useState(currentTime.format('HH:mm:ss'));

  useEffect(() => {
    const intervalId = setInterval(() =>{
      currentTime = currentTime.add(1, 's');
      setDisplayTime(currentTime.format('HH:mm:ss'));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [time]);

  return (
    <Typography textAlign='center' color='#205295'>
      {displayTime}
    </Typography>
  );
};

TSTimer.propTypes = {
  time: PropTypes.string
};

export default TSTimer;
