import { Alert, Snackbar } from '@mui/material';
import { useState, useEffect } from 'react';
import networkListenerUtil from '../../utils/NetworkListenerUtil';

const NetworkListener = () => {
  const networkState = networkListenerUtil();
  const [open, setOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [severity, setSeverity] = useState('info');
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (networkState) {
      setSeverity('success');
    } else {
      setSeverity('error');
    }

    if (isMounted) {
      setOpen(true);
    }
    setIsMounted(true);
  }, [networkState]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      onClose={() => {handleClose();}}
    >
      <Alert onClose={() => {handleClose();}} variant="filled"
        severity={severity} sx={{ width: '100%' }}>
        {networkState ? 'Back online' : 'No internet connection'}
      </Alert>
    </Snackbar>
  );
};

export default NetworkListener;
