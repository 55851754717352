import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  SvgIcon
} from '@mui/material';
import { green, red, blue, purple, orange } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import React from 'react';
import PropTypes from 'prop-types';

const colors = [red[500], blue[500], green[500], orange[500], purple[500]];

const OpSummaryCard = ({ title, data })=>{
  const randomIndex = Math.floor(Math.random() * 5);
  const getTrendArrow = ( isIncreased ) => {
    return (isIncreased == true ? (
      <SvgIcon component={ArrowUpwardIcon} style={{ color: green[500] }}/>
    ) : (
      <SvgIcon component={ArrowDownwardIcon} style={{ color: red[500] }}/>
    ));
  };

  return (
    <Card sx={ { minHeight: '150px' }}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              sx={{
                color: colors[randomIndex]
              }}
              gutterBottom
              variant="caption"
            >
              { title }
            </Typography>
            <Typography
              variant="h4"
              sx={{ mt: 1 }}
            >
              { data.value}
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mt: 1
          }}
        >
          <Grid
            container
          >
            {data.increased != undefined ? (
              getTrendArrow(data.increased)
            ) : ''}   {data.percent_change != undefined ? (
              <Typography
                sx={{ fontSize: '13px', mt: 0.5, ml: 0.7 }}
              >
                {data.percent_change}
              </Typography>
            ) : ''}
            <Grid item xs={12}>
              {data.increased != undefined ? (
                <Typography
                  color="textSecondary"
                  variant="caption"
                >
                  Compared to last {data.op_day}
                </Typography>
              ) : ''}
            </Grid>
            <Grid>
              {data.description != undefined ? (
                <Typography
                  color="textSecondary"
                  variant="caption"
                >
                  {data.description}
                </Typography>
              ) : ''}
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

OpSummaryCard.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string
};

export default OpSummaryCard;
