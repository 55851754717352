import React, { useState, useEffect } from 'react';
import { AppBar, Button, Chip,
  DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List,
  ListItemButton, ListItemText, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { validateStringForNull } from '../../utils/FieldValidations';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import Services from '../../utils/Services';
import TSSnackbar from '../tscomponents/TSSnackbar';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { Utils } from '../../utils/UtilFunctions';
import { APPOINTMENT_STATUS, EVENT_ID } from '../../utils/EnumDefinitions';
import APIData from '../../utils/APIData';
import ReplayIcon from '@mui/icons-material/Replay';
import { subscribe, unsubscribe } from '../../utils/SSEHelper';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import ModifyAppointment from './ModifyAppointment';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const ViewAppointments = ({ clinicId, opId, onSuccessfulCommit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(undefined);
  const [isCancelApmtDialogOpen, setIsCancelApmtDialogOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [isEditAppointmentDetailsDialogOpen, setIsEditAppointmentDialogOpen] = useState(false);

  const APIToken = {
    GET_APPOINTMENT_LIST: 'CVAPT01',
    CANCEL_APPOINTMENT: 'CVAPT02',
    RESTORE_APPOINTMENT: 'CVAPT03'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(opId)) {
      raiseGetAppointmentList();
    }
    subscribe(clinicId, opId, getEventData);
    return () => {
      unsubscribe();
    };
  }, []);

  window.addEventListener('beforeunload', function(event) {
    unsubscribe();
  });

  const getEventData = (data) => {
    switch (data) {
    case EVENT_ID.CANCEL_APPOINTMENT:
    case EVENT_ID.CREATE_APPOINTMENT:
      raiseGetAppointmentList();
      break;
    }
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_APPOINTMENT_LIST ) {
      setAppointmentList(response.data);
    } else if (apiData == APIData.cancelAppointment && apiToken == APIToken.CANCEL_APPOINTMENT ) {
      setSelectedAppointmentId(undefined);
      setCancelReason('');
      showSnackBar('success', response.message ?? 'Appointment cancelled successfully');
    } else if (apiData == APIData.restoreAppointment && apiToken == APIToken.RESTORE_APPOINTMENT ) {
      setSelectedAppointmentId(undefined);
      showSnackBar('success', response.message ?? 'Appointment restored successfully');
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getAppointmentList && apiToken == APIToken.GET_APPOINTMENT_LIST) {
      defaultMsg ='Failed to retrieve Appointment list';
    } else if (apiData == APIData.cancelAppointment && apiToken == APIToken.CANCEL_APPOINTMENT ) {
      defaultMsg ='Failed to cancel Appointment';
    } else if (apiData == APIData.restoreAppointment && apiToken == APIToken.RESTORE_APPOINTMENT ) {
      defaultMsg = 'Failed to restore Appointment';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetAppointmentList = () => {
    setIsLoading(true);
    const finalParams = `op_id=${opId}&appointment_type=7`;
    Services.sendBackendRequest({ apiData: APIData.getAppointmentList, uriValues: [clinicId], params: finalParams },
      APIToken.GET_APPOINTMENT_LIST, processSuccess, processError);
  };

  const raiseCancelApmtRequest = (apmtId, reason) => {
    setIsLoading(true);
    const finalParams = {
      reason: reason
    };
    Services.sendBackendRequest({ apiData: APIData.cancelAppointment, uriValues: [clinicId, apmtId], params: finalParams },
      APIToken.CANCEL_APPOINTMENT, processSuccess, processError);
  };

  const raiseRestoreAppointmentRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.restoreAppointment, uriValues: [clinicId, selectedAppointmentId] },
      APIToken.RESTORE_APPOINTMENT, processSuccess, processError);
  };

  const handleCancelApmtClick = (e) => {
    e.stopPropagation();
    if (validateStringForNull(selectedAppointmentId)) {
      setIsCancelApmtDialogOpen(true);
    }
  };

  const handleCancellationReasonTextChange = (event) => {
    setCancelReason(event.target.value);
  };

  const handleCancelApmtConfirmationClick = () => {
    setIsCancelApmtDialogOpen(false);
    let apmtId = '';
    if (validateStringForNull(selectedAppointmentId)) {
      apmtId = selectedAppointmentId;
    }
    let reason = cancelReason;
    if (!validateStringForNull(reason)) {
      reason = 'N/A';
    }
    if (validateStringForNull(apmtId)) {
      raiseCancelApmtRequest(apmtId, reason);
    }
  };

  const getAppointmentPrimaryText = (obj) => {
    let details = obj.patient_name;
    if (validateStringForNull(obj.sex)) {
      details+=', ' + obj.sex;
    }
    if (validateStringForNull(obj.age)) {
      details+=', ' + obj.age.display;
    }
    return details;
  };

  const getAppointmentSecondaryText = (obj) => {
    let details = 'Token No: ' + obj.token_no;
    if (obj.phone != undefined) {
      details+=' | Phone No: ' + obj.phone;
    }
    if (obj.appointment_time != undefined) {
      details+=' | Appointment Time: ' + obj.appointment_time.display;
    }
    return details;
  };

  const getAppointmentStatusText = (obj) => {
    switch (obj.appointment_status) {
    case 0:
      return (
        <Chip sx={{ backgroundColor: '#B31312', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[0]} />
      );
    case 1:
      return (
        <Chip sx={{ backgroundColor: '#22A699', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[1]} />
      );
    case 2:
      return (
        <Chip sx={{ backgroundColor: '#F2BE22', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[2]} />
      );
    case 3:
      return (
        <Chip sx={{ backgroundColor: '#205295', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[3]} />
      );
    case 4:
      return (
        <Chip sx={{ backgroundColor: '#54B435', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[4]} />
      );
    case -1:
      return (
        <Chip sx={{ backgroundColor: '#3D3B40', color: 'white', fontSize: '0.7rem' }} size='small' label= {APPOINTMENT_STATUS[-1]} />
      );
    }
  };

  const handleListItemSelection = (appId) => {
    if (selectedAppointmentId == appId) {
      setSelectedAppointmentId(undefined);
    } else {
      setSelectedAppointmentId(appId);
    }
  };

  const handleAppointmentDetailsEditClick = (event) => {
    event.stopPropagation();
    setIsEditAppointmentDialogOpen(true);
  };

  const handleSuccessfulApmtModification = (opId) => {
    if (onSuccessfulCommit != undefined) {
      onSuccessfulCommit(opId);
    }
    raiseGetAppointmentList();
    setIsEditAppointmentDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent='space-between'
      >
        {appointmentList != undefined ? (
          <Grid item xs={12}>
            <List sx={{ backgroundColor: 'white' }}>
              {appointmentList.map((obj, i) => (
                <React.Fragment key={obj.appointment_id}>
                  <ListItemButton key={obj.appointment_id}
                    selected={selectedAppointmentId == obj.appointment_id} onClick={() => {handleListItemSelection(obj.appointment_id);}}>
                    <Grid item xs={12}>
                      <Grid item xs={9}>
                        <ListItemText primary={getAppointmentPrimaryText(obj)} secondary={getAppointmentSecondaryText(obj)}/>
                        <ListItemText primary={getAppointmentStatusText(obj)}/>
                      </Grid>
                    </Grid>
                    {selectedAppointmentId == obj.appointment_id && (obj.appointment_status == 1 || obj.appointment_status == 2) ? (
                      <Grid item container xs={3}
                        backgroundColor={selectedAppointmentId == obj.appointment_id ? '' : 'white'}
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                      >
                        <Grid item>
                          <Tooltip title='Cancel Appointment'>
                            <IconButton sx={{ mr: 1 }} color='error'
                              onClick={(e) => handleCancelApmtClick(e)}>
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : (selectedAppointmentId == obj.appointment_id && obj.appointment_status == 0) ? (
                      <Grid item container xs={3}
                        backgroundColor={selectedAppointmentId == obj.appointment_id ? '' : 'white'}
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                      >
                        <Grid item>
                          <Tooltip title='Restore Appointment'>
                            <IconButton sx={{ mr: 1 }} color='success'
                              onClick={raiseRestoreAppointmentRequest}>
                              <ReplayIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : ''}
                    {selectedAppointmentId == obj.appointment_id && obj.appointment_status == 1 ? (
                      <Grid item container xs={3}
                        backgroundColor={selectedAppointmentId == obj.appointment_id ? '' : 'white'}
                        direction='row'
                        alignItems='center'
                      >
                        <Grid item>
                          <Tooltip title='Edit Appointment Details'>
                            <IconButton color='primary'
                              onClick={(e) => handleAppointmentDetailsEditClick(e)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : '' }
                  </ListItemButton>
                  <Divider/>
                </React.Fragment>
              ))}
            </List>
          </Grid>
        ) : ''}
      </Grid>
      <TSConfirmationDialog
        fullWidth
        maxWidth={'sm'}
        open={isCancelApmtDialogOpen}
      >
        <DialogTitle>
          <Typography textAlign='center' sx={{ mb: 1 }}>
            Reason for cancellation
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            maxRows={4}
            name="reason"
            autoComplete ='off'
            value={cancelReason}
            onChange={(event) => {handleCancellationReasonTextChange(event);}}
            required
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
          <Button
            color='primary'
            size='small'
            onClick={() => setIsCancelApmtDialogOpen(false)}
          >
            Close
          </Button>
          <Button
            color='primary'
            size='small'
            variant='contained'
            onClick={handleCancelApmtConfirmationClick}
          >
            Cancel Appointment
          </Button>
        </DialogActions>
      </TSConfirmationDialog>
      <TSSideBarDialog
        open={isEditAppointmentDetailsDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsEditAppointmentDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Appointment Details
            </Typography>
          </Toolbar>
        </AppBar>
        <ModifyAppointment clinicId={clinicId} appointmentId={selectedAppointmentId} onSuccessfulCommit={handleSuccessfulApmtModification}/>
      </TSSideBarDialog>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewAppointments.propTypes = {
  clinicId: PropTypes.string,
  opId: PropTypes.string,
  onSuccessfulCommit: PropTypes.func
};

export default ViewAppointments;
