import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const TSSideBarDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  '& .MuiDialog-paper': {
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    },
    minHeight: '100%',
    width: '100%',
    margin: '0px'
  }
}));

export { TSSideBarDialog, Transition };
