import { useEffect, useState } from 'react';
import { validateStringForNull } from './FieldValidations';

class UiUtils {
  static xsScreenMaxWidth = '(max-width:669px)';

  static getTextFieldStyle = (fieldName, errMsgObj) => {
    const style = {
      m: 0,
      mt: 0.5,
      '& .MuiOutlinedInput-root': {
        backgroundColor: validateStringForNull(errMsgObj[fieldName]) ? 'clientError.textFieldFill' : '',
        borderRadius: '5px'
      },
      '& .MuiFormHelperText-root': {
        ml: 0,
        fontFamily: 'TSFont-Bold'
      }
    };
    return style;
  };

  static getPasswordStrengthTextAndImage = (strength) => {
    if (strength <= 2) {
      return { text: 'Weak Password', color: '#FC3D3D', textImage: '/static/images/WeakPasswordTick.svg',
        indicatorImage: '/static/images/WeakPasswordIndicator.svg' };
      // Weak --> ex: password
    } else if (strength <= 3) {
      return { text: 'Acceptable Password', color: '#EFAD02', textImage: '/static/images/AcceptablePasswordTick.svg',
        indicatorImage: '/static/images/AcceptablePasswordIndicator.svg' };
      // Medium --> Password123
    } else {
      return { text: 'Strong Password', color: '#84C602', textImage: '/static/images/StrongPasswordTick.svg',
        indicatorImage: '/static/images/StrongPasswordIndicator.svg' };
      // Strong --> MyS3cr3t!
    }
  };

  static useWindowSize = () => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return size;
  };
}

export { UiUtils };
