import React, { useState, useEffect } from 'react';
import { Autocomplete, Button,
  Container, Grid, IconButton, InputAdornment,
  TextField } from '@mui/material';
import { validateStringForNull, validateDate } from '../../utils/FieldValidations';
import PropTypes from 'prop-types';
import Services from '../../utils/Services';
import TSSnackbar from '../tscomponents/TSSnackbar';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { EnumUtils, Utils } from '../../utils/UtilFunctions';
import { REFERRED_BY } from '../../utils/EnumDefinitions';
import APIData from '../../utils/APIData';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import TSTimeSlots from '../tscomponents/TSTimeSlots';

const ModifyAppointment = ({ clinicId, appointmentId, onSuccessfulCommit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({
    referred_by: '',
    appointment_time: null,
    diagnosis: '',
    treatment: '',
    clinic_visit_time: undefined,
    in_time: undefined,
    visit_no: null
  });
  const [originalValue, setOriginalValue] = useState(appointmentDetails);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allTimeSlots, setAllTimeSlots] = useState([]);

  const APIToken = {
    GET_APPOINTMENT_DETAILS: 'CMAPT01',
    GET_APPOINTMENT_SLOTS: 'CMAPT02',
    MODIFY_APPOINTMENT: 'CMAPT03'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(appointmentId) && validateStringForNull(clinicId)) {
      raiseGetAppointmentDetails(appointmentId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    let hideLoading = true;
    if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      setAppointmentDetailsData(response.data);
      hideLoading = false;
      raiseGetAppointmentSlots(response.data.op_details.op_id);
    } else if (apiData == APIData.getTokenDetailsWithOpId && apiToken == APIToken.GET_APPOINTMENT_SLOTS ) {
      setAllTimeSlots(response.data.slot_list);
    } else if (apiData == APIData.modifyAppointmentDetails && apiToken == APIToken.MODIFY_APPOINTMENT ) {
      showSnackBar('success', response.message ?? 'Appointment Details Updated');
      if (onSuccessfulCommit != undefined) {
        onSuccessfulCommit(appointmentDetails.opId);
      }
    }
    if (hideLoading) {
      setIsLoading(false);
    }
  };

  const setAppointmentDetailsData = (data) => {
    const modifyData = {
      referred_by: data.basic_details.referred_by != undefined ? data.basic_details.referred_by : '',
      appointment_time: validateStringForNull(data.basic_details.appointment_time) ?
        moment(data.basic_details.appointment_time, 'HH:mm:ss') : null,
      opId: data.op_details.op_id
    };

    if (data.visit_details != undefined && data.visit_details.length > 0) {
      modifyData.clinic_visit_time = data.visit_details[0].clinic_visit_time;
      modifyData.in_time = data.visit_details[0].in_time;
      modifyData.visit_no = data.visit_details[0].visit_no;
      if (data.visit_notes != undefined && data.visit_notes.length > 0) {
        const filtered = data.visit_notes.filter((obj) => obj.visit_no == modifyData.visit_no);
        modifyData.diagnosis = filtered != undefined ? filtered[0].diagnosis : '';
        modifyData.treatment = filtered != undefined ? filtered[0].treatment : '';
      } else {
        modifyData.diagnosis = '';
        modifyData.treatment = '';
      }
    } else {
      modifyData.clinic_visit_time = undefined;
      modifyData.in_time = undefined;
      modifyData.diagnosis = '';
      modifyData.treatment = '';
      modifyData.visit_no = null;
    }
    setAppointmentDetails(modifyData);
    setOriginalValue(modifyData);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getAppointmentDetails && apiToken == APIToken.GET_APPOINTMENT_DETAILS ) {
      defaultMsg = 'Failed to Get Appointment Details';
    } else if (apiData == APIData.getTokenDetailsWithOpId && apiToken == APIToken.GET_APPOINTMENT_SLOTS ) {
      defaultMsg = 'Failed to retrieve Current running Token Number';
    } else if (apiData == APIData.modifyAppointmentDetails && apiToken == APIToken.MODIFY_APPOINTMENT ) {
      defaultMsg = 'Failed to Update Appointment Details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetAppointmentDetails = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getAppointmentDetails, uriValues: [clinicId, appointmentId] },
      APIToken.GET_APPOINTMENT_DETAILS, processSuccess, processError);
  };

  const handleAppointmentDetailsResetClick = () => {
    setAppointmentDetails(originalValue);
  };

  const handleReferredByValueChange = (name, newValue) => {
    if (validateStringForNull(newValue)) {
      setAppointmentDetails({ ...appointmentDetails,
        [name]: newValue.value
      });
    } else {
      setAppointmentDetails({ ...appointmentDetails,
        [name]: null
      });
    }
  };

  const handleEditAppointmentDetailsInputChange = (event) => {
    setAppointmentDetails({ ...appointmentDetails, [event.target.name]: event.target.value });
  };

  const handleAppointmentTimeClearClick = () => {
    setAppointmentDetails({ ...appointmentDetails,
      appointment_time: ''
    });
  };

  const handleSlotClick = (appointmentTime) => {
    setAppointmentDetails({ ...appointmentDetails,
      appointment_time: moment(appointmentTime, 'hh:mm:ss')
    });
    setAnchorEl(null);
  };

  const handleTimeSlotsClose= () => {
    setAnchorEl(null);
  };

  const raiseGetAppointmentSlots = (opId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getTokenDetailsWithOpId, uriValues: [clinicId, opId] },
      APIToken.GET_APPOINTMENT_SLOTS, processSuccess, processError);
  };

  const getTime = (time) => {
    if (validateDate(time)) {
      return time.format( 'hh:mm:ss A');
    }
    return '';
  };

  const handleAppointmentDetailsUpdateClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(appointmentDetails);
      raiseAppointmentDetailsUpdateRequest(params);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Update Appointment Details');
      setIsLoading(false);
    };
  };

  const getModifiedParams = (params) => {
    const modifiedParams = {};
    if (originalValue.referred_by != params.referred_by) {
      modifiedParams.referred_by = parseInt(params.referred_by);
    }
    if (params.appointment_time == null) {
      modifiedParams.appointment_time = null;
    } else if (!validateDate(params.appointment_time)) {
      throw new Error('Invalid appointment time');
    } else if (originalValue.appointment_time.format('hh:mm:ss A') != params.appointment_time.format('hh:mm:ss A')) {
      modifiedParams.appointment_time = params.appointment_time.format('HH:mm:ss');
    }

    if (originalValue.diagnosis != params.diagnosis) {
      if (validateStringForNull(params.diagnosis)) {
        modifiedParams.diagnosis = params.diagnosis;
      } else {
        modifiedParams.diagnosis = null;
      }
    };
    if (originalValue.treatment != params.treatment) {
      if (validateStringForNull(params.treatment)) {
        modifiedParams.treatment = params.treatment;
      } else {
        modifiedParams.treatment = null;
      }
    };
    if (Object.keys(modifiedParams).length > 0 && validateStringForNull(params.visit_no)) {
      modifiedParams.visit_no = params.visit_no;
    }
    return modifiedParams;
  };

  const raiseAppointmentDetailsUpdateRequest = (params) => {
    setIsLoading(true);
    const finalParams = getModifiedParams(params);
    if (Object.keys(finalParams).length == 0) {
      setIsLoading(false);
      return;
    }
    Services.sendBackendRequest({ apiData: APIData.modifyAppointmentDetails, uriValues: [clinicId, appointmentId], params: finalParams },
      APIToken.MODIFY_APPOINTMENT, processSuccess, processError);
  };

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="referredBy"
              value={REFERRED_BY[appointmentDetails.referred_by] ?? null}
              name='referred_by'
              onChange={(event, newValue) => {handleReferredByValueChange('referred_by', newValue);}}
              options={EnumUtils.getEnumOptions(REFERRED_BY)}
              isOptionEqualToValue={(option, value) => {
                return option.label === value;
              }}
              size="small"
              autoSelect
              autoHighlight
              renderInput={(params) => <TextField size="small" {...params} error={false} fullWidth label="Referred By" />}
            />
          </Grid>
          {appointmentDetails.clinic_visit_time == undefined ? (
            <Grid item xs={12} sm={6} >
              <TextField
                id="appointment_time"
                name="appointment_time"
                size='small'
                autoComplete ='off'
                value={getTime(appointmentDetails.appointment_time)}
                label="Appointment Time"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(event) => {event.stopPropagation(); handleAppointmentTimeClearClick(event);}}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid> ) : ''}
          {appointmentDetails.in_time !== undefined ? (
            <Grid item xs={12} sm={12} >
              <TextField
                id="outlined"
                name="diagnosis"
                size='small'
                autoComplete ='off'
                multiline
                value={appointmentDetails.diagnosis ?? ''}
                label="Diagnosis"
                onChange={(event) => {handleEditAppointmentDetailsInputChange(event);}}
                fullWidth
              />
            </Grid> ) : ''}
          {appointmentDetails.in_time !== undefined ? (
            <Grid item xs={12} sm={12} >
              <TextField
                id="outlined"
                name="treatment"
                size='small'
                autoComplete ='off'
                multiline
                value={appointmentDetails.treatment ?? ''}
                label="Treatment"
                onChange={(event) => {handleEditAppointmentDetailsInputChange(event);}}
                fullWidth
              />
            </Grid> ) : ''}
          <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} >
            <Grid item>
              <Button
                type="submit"
                variant="text"
                size="small"
                onClick={handleAppointmentDetailsResetClick}
              >
              Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={handleAppointmentDetailsUpdateClick}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <TSTimeSlots timeSlots={allTimeSlots} anchorEl={anchorEl} onTimeSlotClick={handleSlotClick} onClose={handleTimeSlotsClose}/>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ModifyAppointment.propTypes = {
  clinicId: PropTypes.string,
  appointmentId: PropTypes.string,
  onSuccessfulCommit: PropTypes.func
};

export default ModifyAppointment;
