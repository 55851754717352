import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar, Box, IconButton, Tooltip, Menu, MenuItem, Divider, Typography
} from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MyAccount from '../accountsettings/MyAccount';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import ModifySettings from '../settings/ModifySettings';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import { CookieUtils } from '../../utils/CookieUtils';

const ClinicNavBar = ({ onSideBarOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isAccountSettingsOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const staffId = CookieUtils.getStaffId();
  const navigate = useNavigate();
  const handleAccountSettingsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAccountSettingsClose = () => {
    setAnchorEl(null);
  };

  const getMenuItems = () => {
    return [
      {
        id: 1,
        label: 'My Account',
        icon: <ManageAccountsIcon/>,
        onclick: handleClickOpen
      },
      {
        id: 2,
        label: 'Settings',
        icon: <SettingsIcon />,
        onclick: handleSettingsClick
      },
      {
        id: 3,
        label: 'Logout',
        icon: <InputIcon/>,
        onclick: logoutClickOpen
      }
    ];
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logoutClickOpen = () => {
    navigate('/logout');
  };

  const handleSettingsClick = () => {
    setIsSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setIsSettingsOpen(false);
  };

  return (
    <AppBar
      elevation={0}
    >
      <Toolbar>
        {onSideBarOpen != undefined ? (
          <IconButton sx={{ mr: 3 }} color="inherit" onClick={onSideBarOpen} size="large">
            <MenuIcon />
          </IconButton>
        ) : <React.Fragment/>}
        <RouterLink to="/home">
          <img
            alt="DocKit"
            src="/static/images/Logo.png"
            style={{ height: '3rem' }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }}/>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleAccountSettingsOpen}
            size="small"
            sx={{ ml: 2, color: '#FFF' }}
            aria-controls={isAccountSettingsOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isAccountSettingsOpen ? 'true' : undefined}
          >
            <AccountCircleIcon sx={{ width: 32, height: 32 }}/>
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isAccountSettingsOpen}
        onClose={handleAccountSettingsClose}
        onClick={handleAccountSettingsClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {getMenuItems().map((menuItem) => (
          <MenuItem key={menuItem.id} onClick={menuItem.onclick}>
            <IconButton color="inherit" size="small" >
              {menuItem.icon}
              <Typography sx={{ ml: 1 }} variant="button" noWrap>
                {menuItem.label}
              </Typography>
            </IconButton>
            <Divider/>
          </MenuItem>
        ))}
      </Menu>
      <TSSideBarDialog
        open={open}
        TransitionComponent={Transition}
      >
        <MyAccount handleClose={handleClose} />
      </TSSideBarDialog>
      <TSSideBarDialog
        open={isSettingsOpen}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', mb: 5 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleSettingsClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Staff Settings (For All Clinics)
            </Typography>
          </Toolbar>
        </AppBar>
        <ModifySettings staffId={staffId} />
      </TSSideBarDialog>
    </AppBar>
  );
};

ClinicNavBar.propTypes = {
  onSideBarOpen: PropTypes.func
};

export default ClinicNavBar;
