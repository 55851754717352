import { Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import HomeNavBar from './HomeNavBar';
import { Alert, AppBar, Button, Grid, IconButton, Snackbar, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import ManageDoctorDetails from '../doctorsettings/ManageDoctorDetails';
import { CookieUtils } from '../../utils/CookieUtils';
import { validateStringForNull } from '../../utils/FieldValidations';


const HomeLayout = () => {
  const bannerMsg = CookieUtils.getBannerMsg();
  const [isOpen, setIsOpen] = useState(validateStringForNull(bannerMsg));
  const [isDoctorDetailsDialogOpen, setIsDoctorDetailsDialogOpen] = useState(false);

  useEffect(() => {
    setIsOpen(validateStringForNull(bannerMsg));
  }, []);

  const handleDoctorDetailsDialogClick = (isOpen) => {
    setIsOpen(false);
    setIsDoctorDetailsDialogOpen(isOpen);
  };

  return (
    <React.Fragment>
      <Grid container sx={{ backgroundColor: 'main.primary', height: '100vh',
        px: 3
      }}>
        <Grid item xs={12}>
          <HomeNavBar/>
        </Grid>
        <Grid item container xs={12} sx={{ borderRadius: '16px', backgroundColor: 'main.background',
          maxHeight: 'calc(100vh - 100px)',
          minHeight: 'calc(100vh - 100px)',
          mb: 3
        }}>
          <Grid item xs={12} sx={{ mt: 3, overflowY: 'auto',
            maxHeight: { xs: 'calc(100vh - 190px)', sm: 'calc(100vh - 176px)' },
            minHeight: { xs: 'calc(100vh - 190px)', sm: 'calc(100vh - 176px)' }
          }}>
            <Outlet/>
          </Grid>
          <Grid item container xs={12} sx={{ alignItems: 'center', display: 'flex',
            borderRadius: '0 0 16px 16px', borderTop: '1px solid #A8A8A8', p: 1.5, px: 3 }}>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              <Typography variant='body' sx={{ color: 'main.textMiddleGrey' }}>DocKit - All Rights Reserved</Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center' } }}>
              <Typography variant='body' sx={{ color: 'main.textMiddleGrey' }}>Privacy Policy | Terms and Conditions</Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
              <Typography variant='body' sx={{ color: 'main.textMiddleGrey' }}>Talk to a human - 0000 0000</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
      >
        <Alert variant="filled"
          severity={'warning'} sx={{ width: '100%' }}
          action={
            <>
              <Button
                color="success"
                size="small"
                variant='contained'
                sx={{ m: 1 }}
                onClick={() => handleDoctorDetailsDialogClick(true)}
              >
                Update
              </Button>
              <IconButton
                onClick={() => setIsOpen(false)}
                sx={{ m: 1 }}
                color="inherit" size="small"
              >
                <CloseIcon />
              </IconButton>
            </>
          }>
          {bannerMsg}
        </Alert>
      </Snackbar>
      <TSSideBarDialog
        open={isDoctorDetailsDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleDoctorDetailsDialogClick(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Doctor Details
            </Typography>
          </Toolbar>
        </AppBar>
        <ManageDoctorDetails />
      </TSSideBarDialog>
    </React.Fragment>
  );
};

export default HomeLayout;
