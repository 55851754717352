import { Grid,
  Button, Typography, Tooltip, Paper,
  Box, DialogContent, DialogActions, Modal, InputLabel } from '@mui/material';
import React, { useState } from 'react';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import Services from '../../utils/Services';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import APIData from '../../utils/APIData';
import { CookieUtils } from '../../utils/CookieUtils';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 300,
    lg: 500
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const PaperBase = styled(Paper)(()=>({
  height: 'fit-content'
}));

const DeleteAccount = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const APIToken = {
    DELETE_ACCOUNT: 'CDA01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.deleteUserAccount && apiToken == APIToken.DELETE_ACCOUNT ) {
      setIsModalOpen(true);
      CookieUtils.clearCookies();
      setTimeout(() => navigate('/signin', { replace: true }), 2000);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.deleteUserAccount && apiToken == APIToken.DELETE_ACCOUNT) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Unable to delete your account'
      });
    }
    setIsLoading(false);
  };

  const handleDeleteAccountClick = () => {
    setIsDeleteAccountDialogOpen(false);
    raiseDeleteAccountRequest();
  };

  const raiseDeleteAccountRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.deleteUserAccount }, APIToken.DELETE_ACCOUNT, processSuccess, processError);
  };

  const getDeleteAccountConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isDeleteAccountDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Delete Account</strong>
            </Typography>
            <Typography variant='h5' textAlign='center'>
              Are you sure you want to delete this Account?
            </Typography>
            <Typography variant='h6'>
               Account will be marked as inactive. Please login again to activate it.
              If left inactive for 180 days, the account will be permanently deleted.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size="small"
              color='primary'
              onClick={() => setIsDeleteAccountDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color='primary'
              onClick={handleDeleteAccountClick}
            >
              Delete
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Grid container direction='column'>
        <PaperBase
          elevation={12}
          sx={{
            p: 5,
            pb: 0,
            m: 5
          }}
        >
          <Grid item xs={12} >
            <InputLabel sx={{ mb: 2 }}>
              Delete Account
            </InputLabel>
          </Grid>
          <Grid item xs={12} >
            <Typography sx={{ mb: 2 }} variant='h5'>
            Account will be marked as inactive. Please login again to activate it.
              If left inactive for 180 days, the account will be permanently deleted.
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} xs={12} >
            <Grid item>
              <Tooltip title='Delete Clinic'>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  onClick={() => setIsDeleteAccountDialogOpen(true)}
                >
                  Delete Account
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </PaperBase>
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={() => {}}
      >
        <Grid container sx={style} direction='column' justifyContent="center" alignItems="center">
          <Grid item>
            <CheckCircleIcon sx={{ fontSize: 40, color: 'green' }}/>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              Your Account has been deleted.
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ mt: 2 }} variant="h6">
              Redirecting to Signin page..
            </Typography>
          </Grid>
        </Grid>
      </Modal>
      {getDeleteAccountConfirmationDialog()}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

export default DeleteAccount;
