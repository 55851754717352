import React, { useState, useEffect } from 'react';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import { Container, Button, Typography, Grid, IconButton,
  DialogContent, DialogActions, Box, AppBar, Toolbar, Tooltip, Paper, Icon } from '@mui/material';
import { INVITE_STATUS, USER_TYPE } from '../../utils/EnumDefinitions';
import { DataGrid } from '@mui/x-data-grid';
import { validateStringForNull } from '../../utils/FieldValidations';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ViewOpenHours from '../manageclinicdetails/ViewOpenHours';
import ViewHolidayTimings from '../manageclinicdetails/ViewHolidayTimings';
import ViewContactDetails from '../manageclinicdetails/ViewContactDetails';
import ManageContactDetails from '../manageclinicdetails/ManageContactDetails';
import ManageOpenHours from '../manageclinicdetails/ManageOpenHours';
import SendIcon from '@mui/icons-material/Send';
import ShowInviteDetails from '../showuserclinicsettings/ShowInviteDetails';
import styled from '@emotion/styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HideSourceOutlinedIcon from '@mui/icons-material/HideSourceOutlined';
import APIData from '../../utils/APIData';
import ManageHolidayTimings from '../manageclinicdetails/ManageHolidayTimings';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import ViewStaffBasicDetails from '../manageclinicdetails/ViewStaffBasicDetails';
import ManageStaffRoleDetails from '../manageclinicdetails/ManageStaffRoleDetails';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const PaperBase = styled(Paper)(()=>({
  height: 'fit-content'
}));

const StaffList = ({ clinicId, refreshId }) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveStaffDialogOpen, setIsRemoveStaffDialogOpen] = useState(false);
  const [isRemoveInviteDialogOpen, setIsRemoveInviteDialogOpen] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [selectedInviteId, setSelectedInviteId] = useState();
  const [userClinicDetailsDialogOpen, setUserClinicDetailsDialogOpen] = useState(false);
  const [inviteDetailsDialogOpen, setInviteDetailsDialogOpen] = useState(false);
  const [staffId, setStaffId]= useState();
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const APIToken = {
    GET_STAFF_LIST: 'CSL01',
    DELETE_STAFF: 'CSL02',
    REINVITE_STAFF: 'CSL03',
    CANCEL_INVITE: 'CSL04'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetStaffListRequest();
  }, [refreshId]);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getStaffList && apiToken == APIToken.GET_STAFF_LIST ) {
      const staffArray = [];
      if (response.data.active_staffs != undefined) {
        response.data.active_staffs.map((activeStaff) => {
          activeStaff.status = 'Active';
          staffArray.push(activeStaff);
        });
      }
      if (response.data.inactive_staffs != undefined) {
        response.data.inactive_staffs.map((inActiveStaff) => {
          inActiveStaff.status = 'InActive';
          staffArray.push(inActiveStaff);
        });
      }
      if (response.data.invited_staffs != undefined) {
        response.data.invited_staffs.map((invitedStaff, i) => {
          invitedStaff.status = INVITE_STATUS[invitedStaff.invite_status];
          invitedStaff.staff_id = 'Pending_' + i;
          staffArray.push(invitedStaff);
        });
      }
      setRows(staffArray);
      setIsLoading(false);
    } else if (apiData == APIData.deleteStaff && apiToken == APIToken.DELETE_STAFF ) {
      showSnackBar('success', response.message ?? 'Staff removed successfully');
      setSelectedStaffId();
      raiseGetStaffListRequest();
      setIsRemoveStaffDialogOpen(false);
    } else if (apiData == APIData.resendInvite && apiToken == APIToken.REINVITE_STAFF ) {
      showSnackBar('success', response.message ?? 'Invite resent successfully');
      raiseGetStaffListRequest();
    } else if (apiData == APIData.cancelStaffInvite && apiToken == APIToken.CANCEL_INVITE ) {
      showSnackBar('success', response.message ?? 'Staff invite cancelled successfully');
      setSelectedInviteId();
      raiseGetStaffListRequest();
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getStaffList && apiToken == APIToken.GET_STAFF_LIST ) {
      defaultMsg = 'Failed to retrieve Staff List';
    } else if (apiData == APIData.deleteStaff && apiToken == APIToken.DELETE_STAFF) {
      defaultMsg = 'failed to remove staff';
      setIsRemoveStaffDialogOpen(false);
    } else if (apiData == APIData.resendInvite && apiToken == APIToken.REINVITE_STAFF ) {
      defaultMsg = 'failed to resend invite';
    } else if (apiData == APIData.cancelStaffInvite && apiToken == APIToken.CANCEL_INVITE ) {
      defaultMsg = 'Failed to cancel staff invite';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetStaffListRequest = () => {
    setIsLoading(true);
    const finalParams = 'ignore_curr_user=true';
    Services.sendBackendRequest({ apiData: APIData.getStaffList, uriValues: [clinicId], params: finalParams },
      APIToken.GET_STAFF_LIST, processSuccess, processError);
  };

  const raiseStaffExitRequest = () => {
    setIsLoading(true);
    setIsRemoveStaffDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.deleteStaff, uriValues: [clinicId, selectedStaffId] },
      APIToken.DELETE_STAFF, processSuccess, processError);
  };

  const raiseResendInviteRequest = (inviteId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.resendInvite, uriValues: [inviteId] },
      APIToken.REINVITE_STAFF, processSuccess, processError);
  };

  const raiseStaffInviteCancelRequest = () => {
    setIsLoading(true);
    setIsRemoveInviteDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.cancelStaffInvite, uriValues: [selectedInviteId] },
      APIToken.CANCEL_INVITE, processSuccess, processError);
  };

  const handleStaffExitClick = () => {
    if (validateStringForNull(selectedStaffId)) {
      raiseStaffExitRequest();
    }
  };

  const handleStaffInviteCancelClick = () => {
    if (validateStringForNull(selectedInviteId)) {
      raiseStaffInviteCancelRequest();
    }
  };

  const handleStaffRemoveDialogOpen = (staffId) => {
    setSelectedStaffId(staffId);
    setIsRemoveStaffDialogOpen(true);
  };

  const handleStaffRemoveDialogClose = () => {
    setIsRemoveStaffDialogOpen(false);
  };

  const handleStaffInviteCancelDialogOpen = (inviteId) => {
    setSelectedInviteId(inviteId);
    setIsRemoveInviteDialogOpen(true);
  };

  const handleStaffInviteCancelDialogClose = () => {
    setIsRemoveInviteDialogOpen(false);
  };

  const handleUserClinicDetailsDialogOpen = (staffId, isEditable, openEditmode) => {
    setStaffId(staffId);
    setCanEdit(isEditable);
    setIsEditModeOn(openEditmode);
    setUserClinicDetailsDialogOpen(true);
  };

  const handleUserClinicDetailsDialogClose = () => {
    setStaffId();
    setUserClinicDetailsDialogOpen(false);
    setIsEditModeOn(false);
  };

  const handleInviteDetailsDialogOpen = (inviteId) => {
    setSelectedInviteId(inviteId);
    setInviteDetailsDialogOpen(true);
  };

  const handleInviteDetailsDialogClose = () => {
    setSelectedInviteId();
    setInviteDetailsDialogOpen(false);
  };

  const onError = (err) => {
    showSnackBar('error', err.message ?? 'Failed to retrieve Invite Details');
    setInviteDetailsDialogOpen(false);
  };

  const getRemoveStaffConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isRemoveStaffDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Remove Staff</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to remove this staff?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size='small'
              color='primary'
              onClick={handleStaffRemoveDialogClose}
            >
              Cancel
            </Button>
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={() => handleStaffExitClick()}
            >
              Remove Staff
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };

  const getCancelStaffInviteConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isRemoveInviteDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Cancel Invite</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to cancel this Invite ?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              color='primary'
              size='small'
              onClick={handleStaffInviteCancelDialogClose}
            >
              Close
            </Button>
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={handleStaffInviteCancelClick}
            >
            Cancel Invite
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };


  const getDialogContent = () => {
    if (isEditModeOn == true) {
      return (
        <React.Fragment>
          <PaperBase
            elevation={0}
          >
            <Box>
              <ViewStaffBasicDetails clinicId={clinicId} staffId={staffId} />
            </Box>
          </PaperBase>
          <PaperBase
            elevation={12}
            sx={{
              m: 5,
              mt: 0
            }}
          >
            <Box>
              <Typography sx={{ p: 1, pt: 3 }} >
                Role Details
              </Typography>
              <ManageStaffRoleDetails clinicId={clinicId} staffId={staffId}
                onSuccessfulCommit={() => raiseGetStaffListRequest()} isEditModeOn={isEditModeOn}/>
            </Box>
          </PaperBase>
          <PaperBase
            elevation={12}
            sx={{
              m: 5,
              mt: 0,
              pb: 5
            }}
          >
            <Box>
              <Typography sx={{ p: 1, pt: 3 }} >
                Contact Details
              </Typography>
              <ManageContactDetails clinicId={clinicId} staffId={staffId} />
            </Box>
          </PaperBase>
          <PaperBase
            elevation={12}
            sx={{
              m: 5,
              mt: 0
            }}
          >
            <Box>
              <Typography sx={{ p: 1, pt: 3 }} >
                Open Hours Timings
              </Typography>
              <ManageOpenHours clinicId={clinicId} staffId={staffId} />
            </Box>
          </PaperBase>
          <PaperBase
            elevation={12}
            sx={{
              m: 5,
              mt: 0
            }}
          >
            <Box>
              <Typography sx={{ p: 1 }} >
                Holiday Timings
              </Typography>
              <ManageHolidayTimings clinicId={clinicId} staffId={staffId} />
            </Box>
          </PaperBase>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ViewStaffBasicDetails clinicId={clinicId} staffId={staffId} />
          <ManageStaffRoleDetails clinicId={clinicId} staffId={staffId} isEditModeOn={isEditModeOn}/>
          <ViewContactDetails clinicId={clinicId} staffId={staffId}/>
          <ViewOpenHours clinicId={clinicId} staffId={staffId} />
          <ViewHolidayTimings clinicId={clinicId} staffId={staffId} />
        </React.Fragment>
      );
    }
  };

  const getEditOptionButtons = () => {
    if (!canEdit) {
      return '';
    }
    if (isEditModeOn == true) {
      return (
        <Button
          variant="outlined"
          startIcon={<CancelIcon />}
          color='inherit'
          onClick={() => setIsEditModeOn(false)}
        >
          Cancel
        </Button>
      );
    } else {
      return (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          color='inherit'
          onClick={() => setIsEditModeOn(true)}
        >
          Edit
        </Button>
      );
    }
  };

  const columns = [
    { field: 'staff_id', headerName: 'Staff ID' },
    { field: 'is_expired', headerName: 'IS EXPIRED' },
    { field: 'emp_id', headerName: 'Employee ID', minWidth: 110 },
    { field: 'staff_name', headerName: 'Name', minWidth: 220,
      valueGetter: (params) => (params.row.staff_name ?? params.row.doctor_name)
    },
    { field: 'is_doctor', headerName: 'Is Doctor' },
    { field: 'role', headerName: 'Role', minWidth: 170,
      renderCell: ((params) => {
        if (params.value == 1 && params.row.is_doctor == true) {
          return (
            <React.Fragment>
              {USER_TYPE[params.value]}
              <Tooltip title="Doctor">
                <Icon
                  sx={{
                    backgroundImage: 'url(/static/images/doctor.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    fontSize: '3rem'
                  }}
                />
              </Tooltip>
            </React.Fragment>
          );
        }
        if (validateStringForNull(params.value)) {
          if (validateStringForNull(params.value)) {
            return USER_TYPE[params.value];
          }
          return '';
        }
      })
    },
    { field: 'status', headerName: 'Status', minWidth: 100,
      renderCell: ((params) => {
        if (params.row.status == 'Active') {
          return (
            <Tooltip title='Active'>
              <IconButton
                sx={{ color: '#4E9F3D' }}
              >
                <TaskAltIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          );
        } else if (params.row.status == 'InActive') {
          return (
            <Tooltip title='InActive'>
              <IconButton
                sx={{ color: '#950101' }}
              >
                <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          );
        } else if (params.row.status == 'Pending') {
          return (
            <Tooltip title='Pending'>
              <IconButton
                sx={{ color: '#C49102' }}
              >
                <HelpOutlineIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          );
        } else if (params.row.status == 'Withdrawn') {
          return (
            <Tooltip title='Withdrawn'>
              <IconButton
                sx={{ color: '#892CDC' }}
              >
                <HideSourceOutlinedIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          );
        } else if (params.row.status == 'Rejected') {
          return (
            <Tooltip title='Rejected'>
              <IconButton
                sx={{ color: '#FF1E1E' }}
              >
                <CancelOutlinedIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          );
        }
      })
    },
    { field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      renderCell: ((params) => {
        if (params.row.status == 'Active') {
          return (
            <React.Fragment>
              <Tooltip title='View Staff Details'>
                <IconButton
                  aria-label="view"
                  color='primary'
                  onClick={() => handleUserClinicDetailsDialogOpen(params.row.staff_id, true, false)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Edit Staff Details'>
                <IconButton
                  aria-label="edit"
                  color='primary'
                  onClick={() => handleUserClinicDetailsDialogOpen(params.row.staff_id, false, true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Remove Staff'>
                <IconButton
                  aria-label="delete"
                  color='error'
                  onClick={() => handleStaffRemoveDialogOpen(params.row.staff_id)}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </React.Fragment>
          );
        } else if (params.row.status == 'InActive') {
          return (
            <Tooltip title='View Staff Details'>
              <IconButton
                aria-label="view"
                color='primary'
                onClick={() => handleUserClinicDetailsDialogOpen(params.row.staff_id, false, false)}
              >
                <VisibilityIcon/>
              </IconButton>
            </Tooltip>
          );
        } else if (params.row.status == 'Pending') {
          return (
            <React.Fragment>
              <Tooltip title='View Staff Details'>
                <IconButton
                  aria-label="view"
                  color='primary'
                  onClick={() => handleInviteDetailsDialogOpen(params.row.invite_id)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Resend Invite'>
                <IconButton
                  aria-label="resend"
                  color='success'
                  onClick={() => raiseResendInviteRequest(params.row.invite_id)}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Cancel Invite'>
                <IconButton
                  aria-label="cancel"
                  color='error'
                  onClick={() => handleStaffInviteCancelDialogOpen(params.row.invite_id)}
                >
                  <CancelIcon/>
                </IconButton>
              </Tooltip>
            </React.Fragment>
          );
        }
      })
    }
  ];

  return (
    <React.Fragment>
      <Container sx={{ backgroundColor: 'white', mt: 5 }} maxWidth='md'>
        <Grid container>
          <Grid item xs={12} >
            <Typography sx={{ mb: 2 }}>
              Manage Staffs
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              sx={{ minHeight: 400, width: 'auto' }}
              rows={rows}
              columns={columns}
              getRowId={(row) => row.staff_id }
              disableColumnSelector
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    is_doctor: false,
                    staff_id: false,
                    is_expired: false
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Container>
      {getRemoveStaffConfirmationDialog()}
      {getCancelStaffInviteConfirmationDialog()}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
      <TSSideBarDialog
        open={userClinicDetailsDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleUserClinicDetailsDialogClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Staff Details
            </Typography>
            {getEditOptionButtons()}
          </Toolbar>
        </AppBar>
        {getDialogContent()}
      </TSSideBarDialog>
      <TSSideBarDialog
        open={inviteDetailsDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleInviteDetailsDialogClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Invite Details
            </Typography>
          </Toolbar>
        </AppBar>
        <ShowInviteDetails inviteId={selectedInviteId} onError={onError} />
      </TSSideBarDialog>
    </React.Fragment>
  );
};

StaffList.propTypes = {
  clinicId: PropTypes.string.isRequired,
  refreshId: PropTypes.number.isRequired
};

export default StaffList;
