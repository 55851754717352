import { Helmet } from 'react-helmet';
import React from 'react';
import ManageAppointmentTabs from '../components/Appointment/ManageAppointmentTabs';
import PropTypes from 'prop-types';

const ManageAppointments = ({ isDoctor }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Manage Appointments - DocKit</title>
      </Helmet>
      <ManageAppointmentTabs isDoctor={isDoctor} />
    </React.Fragment>
  );
};

ManageAppointments.propTypes = {
  isDoctor: PropTypes.bool.isRequired
};

export default ManageAppointments;
