import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  IconButton,
  Grid,
  FormControlLabel,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
  styled,
  Paper,
  Menu
} from '@mui/material';
import TSSnackbar from '../tscomponents/TSSnackbar';
import AddIcon from '@mui/icons-material/Add';
import { DesktopDatePicker, LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers';
import { isSameDate, validateDate, validateSettings, validateStringForNull } from '../../utils/FieldValidations';
import moment from 'moment';
import Services from '../../utils/Services';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import cloneDeep from 'lodash/cloneDeep';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import APIData from '../../utils/APIData';
import SettingsIcon from '@mui/icons-material/Settings';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import InfoIcon from '@mui/icons-material/Info';
import { Utils } from '../../utils/UtilFunctions';
import TSHomeBackDrop from '../tscomponents/TSHomeBackDrop';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';
import { UiUtils } from '../../utils/UiUtilFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    margin: 2
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    backgroundColor: '#ffffff'
  },
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#84C602 !important',
    opacity: '1 !important'
  }
}));

const SetupClinicHolidayTimings = ({ clinicId, staffId, onSuccessfulCommit, isClinicSetupInProgress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateConfirmationDialogOpen, setIsUpdateConfirmationDialogOpen] = useState(false);
  const [defaultSettings, setDefaultSettings] = useState([]);
  const [screenWidth] = UiUtils.useWindowSize();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [holidayTimings, setHolidayTimings] = useState([
    {
      id: 1,
      special_date: null,
      timings: [{
        id: 1, start_time: null, end_time: null, settings: defaultSettings
      }],
      isClosed: true
    }
  ]);
  const [originalValue, setOriginalValue] = useState([]);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [selectedSessionSettings, setSelectedSessionSettings] = useState([]);
  const APIToken = {
    GET_HOLIDAY_TIMINGS: 'CMHT01',
    GET_TIMING_SETTINGS: 'CMHT02',
    UPDATE_HOLIDAY_TIMINGS: 'CMHT03'
  };
  const [selectedDaySession, setSelectedDaySession] = useState({});
  const [showSettings, setShowSettings] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };

  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (clinicId != undefined) {
      raiseGetDefaultTimingsSettings();
    }
  }, []);

  const handleMenuClick = (event, holidayTimings, session) => {
    setAnchorEl(event.currentTarget);
    setSelectedDaySession({
      date: holidayTimings.special_date,
      id: holidayTimings.id,
      session: session.id,
      settings: session.settings
    });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedDaySession({});
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiToken == APIToken.GET_TIMING_SETTINGS) {
      if (apiData == APIData.getClinicTimingsDefaultSettings || apiData == APIData.getClinicStaffTimingsDefaultSettings) {
        if (response.data.length > 0) {
          setShowSettings(true);
        }
        setDefaultSettings(response.data);
        if (isClinicSetupInProgress != true) {
          raiseHolidayOpenHoursDetails(response.data);
        } else {
          setIsLoading(false);
        }
      }
    } else if (apiToken == APIToken.UPDATE_HOLIDAY_TIMINGS) {
      if (apiData == APIData.modifyStaffHolidayHoursDetails || apiData == APIData.updateClinicHolidayTimings) {
        if (onSuccessfulCommit != undefined) {
          onSuccessfulCommit();
        } else {
          showSnackBar('success', response.message ?? 'Timings updated successfully');
          raiseHolidayOpenHoursDetails();
        }
      }
    } else if (apiToken == APIToken.GET_HOLIDAY_TIMINGS) {
      if (apiData == APIData.getStaffHolidayHoursDetails) {
        setInitialHolidayTimings(response.data, callbackValues.settings);
      } else if (apiData == APIData.getClinicDetails) {
        setInitialHolidayTimings(response.data.holiday_hours, callbackValues.settings);
      }
      setIsLoading(false);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if ((apiData == APIData.modifyStaffHolidayHoursDetails || apiData == APIData.updateClinicHolidayTimings) &&
      apiToken == APIToken.UPDATE_HOLIDAY_TIMINGS) {
      defaultMsg = 'Failed to Update timings';
    } else if ((apiData == APIData.getClinicTimingsDefaultSettings || apiData == APIData.getClinicStaffTimingsDefaultSettings) &&
      apiToken == APIToken.GET_TIMING_SETTINGS) {
      defaultMsg = 'Failed to retrieve settings';
    } else if ((apiData == APIData.getClinicDetails || apiData == APIData.getStaffHolidayHoursDetails) &&
      apiToken == APIToken.GET_HOLIDAY_TIMINGS) {
      defaultMsg = 'Failed to retrieve HolidayOpenHours Details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const validateParams = (inputparams) => {
    if (!validateStringForNull(clinicId)) {
      throw new Error('Invalid Clinic');
    }
    const invalidDates = inputparams.filter((param) => !validateDate(param.special_date));
    if (invalidDates != 0) {
      throw new Error('Invalid holiday date provided');
    }
    const params = inputparams.filter((param) => validateDate(param.special_date));
    if (params.length == 0 && originalValue.length == 0) {
      throw new Error('No valid holiday timings provided');
    }

    params.forEach((param) => {
      if (!param.isClosed) {
        if (param.start_time == null || !validateDate(param.start_time)) {
          throw new Error('Invalid timings - select start time for ' + moment(param.special_date).format('DD/MM/yyyy'));
        } else if (param.end_time == null || !validateDate(param.end_time)) {
          throw new Error('Invalid timings - select start time for ' + moment(param.special_date).format('DD/MM/yyyy'));
        }
        param.start_time = moment(param.start_time).format('HH:mm:ss');
        param.end_time = moment(param.end_time).format('HH:mm:ss');
        if (validateStringForNull(param.settings)) {
          param.settings = validateSettings(param.settings);
        }
      }
      param.special_date = moment(param.special_date).format('yyyy-MM-DD');
      delete param.isClosed;
    });
    return params;
  };


  const raiseUpdateRequest = (inputParams) => {
    setIsLoading(true);
    setIsUpdateConfirmationDialogOpen(false);
    const bodyParams = {
      holiday_hours: inputParams
    };

    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.modifyStaffHolidayHoursDetails, uriValues: [clinicId, staffId], params: bodyParams },
        APIToken.UPDATE_HOLIDAY_TIMINGS, processSuccess, processError);
    } else {
      Services.sendBackendRequest({ apiData: APIData.updateClinicHolidayTimings, uriValues: [clinicId], params: bodyParams },
        APIToken.UPDATE_HOLIDAY_TIMINGS, processSuccess, processError);
    }
  };

  const setInitialHolidayTimings = (data, defSettings) => {
    const holidayHoursValue = getHolidayOpenHoursDetails(data, defSettings);
    setHolidayTimings(holidayHoursValue);
    const originalParams = cloneDeep(holidayHoursValue);
    setOriginalValue(originalParams);
  };

  const raiseGetDefaultTimingsSettings = () => {
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getClinicStaffTimingsDefaultSettings, uriValues: [clinicId, staffId] },
        APIToken.GET_TIMING_SETTINGS, processSuccess, processError);
    } else {
      Services.sendBackendRequest({ apiData: APIData.getClinicTimingsDefaultSettings, uriValues: [clinicId] },
        APIToken.GET_TIMING_SETTINGS, processSuccess, processError);
    }
  };

  const handleTimingUpdateDialogOpen = () => {
    setIsUpdateConfirmationDialogOpen(true);
  };

  const getInputParams = () => {
    const params = [];
    holidayTimings.map((dateObj) => {
      if (!dateObj.isClosed) {
        dateObj.timings.map((session) => {
          const obj = {
            special_date: dateObj.special_date,
            start_time: session.start_time,
            end_time: session.end_time,
            isClosed: dateObj.isClosed,
            settings: session.settings
          };
          params.push(obj);
        });
      } else {
        const obj = {
          special_date: dateObj.special_date,
          isClosed: dateObj.isClosed
        };
        params.push(obj);
      }
    });
    return params;
  };

  const handleUpdateClick = () => {
    setIsLoading(true);
    try {
      const params = getInputParams();
      const filteredParams = validateParams(params);
      raiseUpdateRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Updating holiday timings failed');
      setIsLoading(false);
    }
  };

  const handleResetClick = () => {
    const params = cloneDeep(originalValue);
    setHolidayTimings(params);
  };

  const raiseHolidayOpenHoursDetails = (settings) => {
    const defSettings = settings == undefined ? defaultSettings : settings;
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getStaffHolidayHoursDetails, uriValues: [clinicId, staffId] },
        APIToken.GET_HOLIDAY_TIMINGS, processSuccess, processError, { settings: defSettings });
    } else {
      const finalParams = 'fetch_modules=holiday_hours';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: finalParams },
        APIToken.GET_HOLIDAY_TIMINGS, processSuccess, processError, { settings: defSettings });
    }
  };

  const getHolidayOpenHoursDetails = (data, defSettings) => {
    const holidayOpenHour = [];
    data.map((session, i) => {
      const date = session.special_date != undefined ? moment(session.special_date.value, 'yyyy-MM-DD') : null;
      const filterObj = holidayOpenHour.filter((x) => isSameDate(x.special_date, date));
      if (filterObj.length == 0) {
        const timingArray = [];
        const timings = {
          id: 1,
          start_time: session.start_time != undefined ? moment(session.start_time.value, 'HH:mm:ss') : null,
          end_time: session.end_time != undefined ? moment(session.end_time.value, 'HH:mm:ss') : null,
          settings: session.settings != undefined ? session.settings : defSettings
        };
        timingArray.push(timings);
        const obj = {
          id: i,
          special_date: date,
          timings: timingArray,
          isClosed: session.start_time == undefined
        };
        holidayOpenHour.push(obj);
      } else {
        const timings = {
          id: filterObj[0].timings.length + 1,
          start_time: session.start_time != undefined ? moment(session.start_time.value, 'HH:mm:ss') : null,
          end_time: session.end_time != undefined ? moment(session.end_time.value, 'HH:mm:ss') : null,
          settings: session.settings
        };
        filterObj[0].timings.push(timings);
      }
    });
    return holidayOpenHour;
  };

  const handleAddDate = () => {
    setHolidayTimings([
      ...holidayTimings,
      {
        id: holidayTimings.length == 0 ? 1 : holidayTimings[holidayTimings.length - 1].id + 1,
        special_date: null,
        timings: [{
          id: 1, start_time: null, end_time: null, settings: defaultSettings
        }],
        isClosed: true
      }
    ]);
    handleMenuClose();
  };

  const handleSettingsDialogCancelClick = () => {
    setIsSettingsDialogOpen(false);
    setSelectedSessionSettings([]);
  };

  const handleSettingsClick = (selectedDay, sessionId, settings) => {
    const obj = {
      date: moment(selectedDay).format('yyyy-MM-DD'),
      id: sessionId,
      settings: settings != undefined ? settings : defaultSettings
    };
    setSelectedSessionSettings(obj);
    setIsSettingsDialogOpen(true);
    handleMenuClose();
  };

  const handleSaveSettingsClick = () => {
    const { date, id, settings } = selectedSessionSettings;
    const cloned = cloneDeep(holidayTimings);
    const objIndex = cloned.findIndex((obj) => moment(obj.special_date).format('yyyy-MM-DD') === date);
    const timings = cloned[objIndex].timings;
    const timingIndex = timings.findIndex((timing) => timing.id === id);
    cloned[objIndex].timings[timingIndex].settings = settings;
    setHolidayTimings(cloned);
    setIsSettingsDialogOpen(false);
    setSelectedSessionSettings([]);
  };

  const handleSettingsInputChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const updatedSettings = {
      ...selectedSessionSettings,
      settings: selectedSessionSettings.settings.map((setting) => {
        if (setting.key === key) {
          return { ...setting, value };
        }
        return setting;
      })
    };
    setSelectedSessionSettings(updatedSettings);
  };

  const handleSave = () => {
    setIsLoading(true);
    try {
      const params = getInputParams();
      const filteredParams = validateParams(params);
      raiseUpdateRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Updating clinic timings failed');
      setIsLoading(false);
    }
  };

  const handleSkip = () => {
    onSuccessfulCommit();
  };

  const handleAddSession = (dateId) => {
    const cloned = cloneDeep(holidayTimings);
    const objIndex = cloned.findIndex((obj) => obj.id == dateId);
    const timings = cloned[objIndex].timings;
    const obj = {
      id: timings.length == 0 ? 1 : timings[timings.length - 1].id + 1,
      start_time: null,
      end_time: null,
      setting: defaultSettings
    };
    cloned[objIndex].timings.push(obj);
    setHolidayTimings(cloned);
    handleMenuClose();
  };

  const handleRemoveDate = (dateId) => {
    const list = cloneDeep(holidayTimings);
    const objIndex = list.findIndex((obj) => obj.id == dateId);
    list.splice(objIndex, 1);
    setHolidayTimings(list);
    handleMenuClose();
  };

  const handleRemoveSession = (dateId, sessionId) => {
    const cloned = cloneDeep(holidayTimings);
    const objIndex = cloned.findIndex((obj) => obj.id == dateId);
    const timings = cloned[objIndex].timings;
    const sessionIndex = timings.findIndex((obj) => obj.id == sessionId);
    timings.splice(sessionIndex, 1);
    if (timings.length == 0) {
      cloned[objIndex].isClosed = true;
    }
    setHolidayTimings(cloned);
    handleMenuClose();
  };

  const handleDateChange = (key, value, dateIndex) => {
    const list = cloneDeep(holidayTimings);
    list[dateIndex][key] = value;
    setHolidayTimings(list);
  };

  const handleTimeChange = (dateId, key, value, sessionIndex) => {
    const cloned = cloneDeep(holidayTimings);
    const objIndex = cloned.findIndex((obj) => obj.id == dateId);
    const timings = cloned[objIndex].timings;
    timings[sessionIndex][key] = value;
    setHolidayTimings(cloned);
  };

  const handleCheckboxChange = (event, index) => {
    const list = cloneDeep(holidayTimings);
    list[index][event.target.name] = !event.target.checked;
    if (event.target.checked) {
      if (list[index].timings.length == 0) {
        const obj = [{
          id: 1, start_time: null, end_time: null
        }];
        list[index].timings = obj;
      }
    }
    setHolidayTimings(list);
  };

  const getActionButtons = (holidayTiming, session) => {
    const buttons = [
      <IconButton
        key="add-button"
        aria-label="add"
        color="primary"
        onClick={() => handleAddSession(holidayTiming.id)}
        size="small"
      >
        <AddIcon sx={{ fontSize: '1.1rem' }} />
      </IconButton>,
      <IconButton
        key="delete-button"
        aria-label="delete"
        color="error"
        onClick={() => handleRemoveSession(holidayTiming.id, session.id)}
        size="small"
      >
        <img src='/static/images/delete.svg' alt="Remove" style={{ height: '1.1rem' }} />
      </IconButton>
    ];
    if (showSettings) {
      buttons.push(
        <IconButton
          key="settings-button"
          onClick={() => handleSettingsClick(holidayTiming.special_date, session.id, session.settings)}
          color="primary"
        >
          <SettingsIcon style={{ fontSize: '1rem' }} />
        </IconButton>
      );
    }
    return buttons;
  };

  const getActionButtonsInMenu = () => {
    const buttons = [
      <IconButton
        key="add-button"
        aria-label="add"
        color="primary"
        onClick={() => handleAddSession(selectedDaySession.id)}
        size="small"
      >
        <AddIcon sx={{ fontSize: '1.1rem' }} />
      </IconButton>,
      <IconButton
        key="delete-button"
        aria-label="delete"
        color="error"
        onClick={() => handleRemoveSession(selectedDaySession.id, selectedDaySession.session)}
        size="small"
      >
        <img src='/static/images/delete.svg' alt="Remove" style={{ height: '1.1rem' }} />
      </IconButton>
    ];
    if (showSettings) {
      buttons.push(
        <IconButton
          key="settings-button"
          onClick={() => handleSettingsClick(selectedDaySession.date, selectedDaySession.session, selectedDaySession.settings)}
          color="primary"
        >
          <SettingsIcon style={{ fontSize: '1rem' }} />
        </IconButton>
      );
    }
    return buttons;
  };

  const getGrids = () => {
    const gridArr = [];
    holidayTimings.map((holidayTiming, i) => {
      gridArr.push(
        <Grid key={holidayTiming.id} container sx={{ mb: 1 }} direction='column'>
          <Grid item container columnSpacing={2} alignItems="center">
            <Grid item container xs={12} md={5} alignItems='center'>
              <Grid item sx={{ pr: 0, ml: { xs: -1, sm: 0 } }}>
                <IconButton color='error' size='small' onClick={() => handleRemoveDate(holidayTiming.id)} >
                  <img src='/static/images/delete.svg' alt="Remove" style={{ height: '1.1rem' }} />
                </IconButton>
              </Grid>
              <Grid item xs={7} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    format="DD/MM/YYYY"
                    sx={{
                      width: '100%',
                      '& .MuiSvgIcon-root': {
                        height: '20px',
                        color: 'main.primary'
                      }
                    }}
                    value={holidayTiming.special_date}
                    onChange={(e) => handleDateChange('special_date', e, i)}
                    slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%',
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '7px'
                        }
                      }
                    } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4} sm={4} md={4} display='flex'
                justifyContent='flex-start' alignItems='center' sx={{ pl: { xs: 2 } }}>
                <FormControlLabel
                  control={<CustomSwitch/>}
                  checked={!holidayTiming.isClosed}
                  name='isClosed'
                  onChange={(event) => handleCheckboxChange(event, i)}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={7} sx={{ mt: 2 }} direction='column'>
              {holidayTiming.isClosed == false ? (
                holidayTiming.timings.map((session, j) => (
                  <React.Fragment key={session.id}>
                    <Grid item container key={session.id} xs={'auto'} columnSpacing={1} sx={{ mb: 2 }}>
                      <Grid item container xs={11} sm={8} display='flex' justifyContent='space-evenly' alignItems='center'>
                        <Grid item xs={5.5}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopTimePicker
                              ampm
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock
                              }}
                              sx={{
                                width: '100%',
                                '& .MuiSvgIcon-root': {
                                  height: '20px',
                                  color: 'main.primary'
                                }
                              }}
                              name='start_time'
                              size='small'
                              value={session.start_time}
                              maxTime={session.end_time}
                              onChange={(e) => handleTimeChange(holidayTiming.id, 'start_time', e, j)}
                              slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%',
                                sx: {
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '7px'
                                  }
                                }
                              } }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={.5}>
                          <Divider sx={{ backgroundColor: 'black' }}/>
                        </Grid>
                        <Grid item xs={5.5}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopTimePicker
                              ampm
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock
                              }}
                              name='end_time'
                              sx={{
                                width: '100%',
                                '& .MuiSvgIcon-root': {
                                  height: '20px',
                                  color: 'main.primary'
                                }
                              }}
                              size='small'
                              value={session.end_time}
                              minTime={session.start_time}
                              onChange={(e) => handleTimeChange(holidayTiming.id, 'end_time', e, j)}
                              slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%',
                                sx: {
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '7px'
                                  }
                                }
                              } }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      {Utils.isMobile() ? (
                        <Grid item xs={0.2} >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuClick(e, holidayTiming, session)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                borderRadius: '1rem',
                                overflow: 'visible',
                                border: '0.5px solid', borderColor: 'main.textLightGrey',
                                '&:before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: 0,
                                  right: 22,
                                  width: 10,
                                  height: 10,
                                  bgcolor: 'background.paper',
                                  transform: 'translateY(-50%) rotate(45deg)',
                                  zIndex: 0,
                                  borderLeft: '0.5px solid #E0E0E0',
                                  borderTop: '0.5px solid #E0E0E0'
                                },
                                '& .MuiMenu-list': {
                                  padding: '0 !important'
                                },
                                '& .MuiPaper-root': {
                                  borderRadius: '8px !important'
                                }
                              }
                            }}
                            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                            keepMounted
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >{getActionButtonsInMenu() }</Menu>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={2} justifyContent='center' display='flex' alignItems='center'>
                          <Paper
                            elevation={3}
                            sx={{ display: 'flex', justifyContent: 'center',
                              alignItems: 'center', py: 1.5, borderRadius: '50px', height: '10px', width: 'fit-content',
                              border: '0.5px solid transparent', borderColor: 'main.primary',
                              boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.2)' }}
                          >
                            {getActionButtons(holidayTiming, session) }
                          </Paper>
                        </Grid>
                      )}
                    </Grid>
                  </React.Fragment>
                ))
              ) : ''}
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <Divider />
          </Grid>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Grid
      container
      sx={{ height: 'inherit' }}
    >
      <Grid item xs={12} sm={12} md={12} sx={{ height: 'fit-content' }}>
        <Stack sx={{ width: '100%' }}>
          <Typography variant='heading'> Holiday Hours </Typography>
          <Typography variant='body' sx={{ mt: 0.5, mb: { xs: 4 } }}>Please select your holiday hours to set up a new clinic below.
          </Typography>
        </Stack>
      </Grid>
      <Grid item container xs={12} sx={{ minHeight: '70%', alignContent: 'flex-start' }}>
        <Grid item xs={12} lg={11}>
          {getGrids()}
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='center'>
          <Button
            variant='outlined'
            startIcon={<AddIcon/>}
            size='small'
            color='primary'
            sx={{ borderRadius: '2rem', m: 2 }}
            onClick={() => handleAddDate()}
          >
            Add date
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        alignItems={{ xs: 'center', md: 'flex-end' }}
        sx={{ height: 'fit-content' }}
        xs={11}
      >
        <Grid item >
          {isClinicSetupInProgress == true ? (
            <>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => handleSkip()}
                sx={{ height: '2rem', width: screenWidth < 380 ? '6.5rem' : '8rem', borderRadius: '2rem', m: 2, mr: 0 }}
              >
                Skip
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={()=>handleSave()}
                sx={{ height: '2rem', width: screenWidth < 380 ? '6.5rem' : '8rem', borderRadius: '2rem', m: 2, mr: 0 }}
              >
                Continue
              </Button>
            </>
          ):(
            <>
              <Button
                variant='text'
                color='primary'
                onClick={()=>handleResetClick()}
              >
              Reset
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={handleTimingUpdateDialogOpen}
              >
              Update
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <TSConfirmationDialog
        open={isUpdateConfirmationDialogOpen}
      >
        <DialogContent>
          <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
            <strong>Confirmation</strong>
          </Typography>
          <Typography variant='h5'>
            Already created OP consultation sessions will not be affected by this change. Click YES to continue the update or NO to cancel.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            size="small"
            color='primary'
            onClick={() => setIsUpdateConfirmationDialogOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            color='primary'
            onClick={handleUpdateClick}
          >
            Yes
          </Button>
        </DialogActions>
      </TSConfirmationDialog>
      {selectedSessionSettings.settings != undefined && selectedSessionSettings.settings.length > 0 ? (
        <Dialog
          open={isSettingsDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle variant='h6'>Additional Settings</DialogTitle>
          <DialogContent>
            {selectedSessionSettings.settings.map((settings) => (
              <Grid container spacing={2} key={settings.key}>
                <Grid item xs={12}>
                  <InputLabel key={settings.key} style={{ fontSize: '0.8rem', whiteSpace: 'normal' }}>{settings.label}</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size='small'
                    name={settings.key}
                    id={settings.key}
                    value={settings.value ?? ''}
                    sx={{ mb: 1, color: 'white' }}
                    variant="standard"
                    onChange={(event) => handleSettingsInputChange(event)}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={settings.description}>
                          <IconButton>
                            <InfoIcon sx={{ fontSize: '1rem' }} />
                          </IconButton>
                        </Tooltip>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button size='small' onClick={handleSettingsDialogCancelClick}>Cancel</Button>
            <Button size='small' variant='contained' onClick={handleSaveSettingsClick}>Save</Button>
          </DialogActions>
        </Dialog>
      ) : ''}
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
    </Grid>
  );
};

SetupClinicHolidayTimings.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string,
  onSuccessfulCommit: PropTypes.func,
  isClinicSetupInProgress: PropTypes.bool
};
export default SetupClinicHolidayTimings;
