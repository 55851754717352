import {
  Grid
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ViewClinicContactDetails from './ViewClinicContactDetails';
import ViewClinicDetails from './ViewClinicDetails';

const ViewClinicInformation = ({ clinicId }) => {
  return (
    <Grid container rowGap={6} sx={{ ml: { sm: '-26px', md: '-26px' } }}>
      <ViewClinicDetails clinicId={clinicId}/>
      <ViewClinicContactDetails clinicId={clinicId}/>
    </Grid>
  );
};

ViewClinicInformation.propTypes = {
  clinicId: PropTypes.string
};

export default ViewClinicInformation;
