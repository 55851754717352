import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { CircularProgress, Modal, Typography } from '@mui/material';
import Services from '../../utils/Services';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import APIData from '../../utils/APIData';
import { CookieUtils } from '../../utils/CookieUtils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 300,
    lg: 400
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const LogoutAccount = () => {
  const navigate = useNavigate();
  useEffect(() => {
    raiseLogOutRequest();
  }, []);

  const APIToken = {
    LOGOUT_ACCOUNT: 'CLA01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.logout && apiToken == APIToken.LOGOUT_ACCOUNT ) {
      CookieUtils.clearCookies();
      setTimeout(() => navigate('/signin', { replace: true }), 2000);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (apiData == APIData.logout && apiToken == APIToken.LOGOUT_ACCOUNT ) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Failed to Log Out'
      });
      CookieUtils.clearCookies();
      setTimeout(() => navigate('/signin', { replace: true }), 2000);
    }
  };

  const raiseLogOutRequest = () => {
    Services.sendBackendRequest({ apiData: APIData.logout }, APIToken.LOGOUT_ACCOUNT, processSuccess, processError);
  };

  return (
    <React.Fragment>
      <Modal
        open
        onClose={() => {}}
      >
        <Grid container sx={style} direction='column' justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress color='inherit' />
          </Grid>
          <Grid item>
            <Typography variant="h6">
                  Logging Out. Please wait
            </Typography>
          </Grid>
        </Grid>
      </Modal>
      {getSnackbar}
    </React.Fragment>
  );
};

export default LogoutAccount;
