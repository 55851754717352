import { Box, Paper, Table, TableBody, TableCell,
  tableCellClasses, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CONTACT_TYPE } from '../../utils/EnumDefinitions';
import Services from '../../utils/Services';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import APIData from '../../utils/APIData';
import { validateStringForNull } from '../../utils/FieldValidations';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

const ViewContactDetails = ({ clinicId, staffId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactDetails, setcontactDetails] = useState([]);
  const APIToken = {
    GET_CONTACT_DETAILS: 'CSCD01'
  };

  useEffect(() => {
    if ( clinicId != undefined ) {
      raiseGetContactDetails();
    }
  }, []);

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if ( apiToken == APIToken.GET_CONTACT_DETAILS ) {
      if (apiData == APIData.getStaffDetails) {
        setcontactDetails(response.data.staff_contact_details.active ?? []);
      } else if (apiData == APIData.getClinicDetails) {
        setcontactDetails(response.data.clinic_contact_details.active ?? []);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if ( apiToken == APIToken.GET_CONTACT_DETAILS ) {
      if (apiData == APIData.getStaffDetails || apiData == APIData.getClinicDetails) {
        setSnackBarStatus({
          open: true,
          severity: 'error',
          message: err.message ?? 'Failed to retrieve Contact Details'
        });
      }
    }
    setIsLoading(false);
  };

  const raiseGetContactDetails = () => {
    let params = '';
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      params = 'fetch_modules=staff_contact_details';
      Services.sendBackendRequest({ apiData: APIData.getStaffDetails, uriValues: [clinicId, staffId], params: params },
        APIToken.GET_CONTACT_DETAILS, processSuccess, processError);
    } else {
      params = 'fetch_modules=clinic_contact_details';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
        APIToken.GET_CONTACT_DETAILS, processSuccess, processError);
    }
  };

  return (
    <React.Fragment>
      <Box>
        <PaperBase elevation={2} sx={{
          flexGrow: 1,
          m: 5,
          mt: 0
        }} >
          <InputLabel sx={{ p: 2 }}>
            Contact Details
          </InputLabel>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableBody>
              {contactDetails.length > 0 ? (contactDetails.map((contactDetail, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell style={{ width: '50%' }}>
                      {CONTACT_TYPE[contactDetail.contact_type]}
                    </TableCell>
                    <TableCell style={{ width: '50%' }}>
                      {contactDetail.contact}
                    </TableCell>
                  </TableRow>
                );
              })) : (
                <TableRow>
                  <TableCell >
                    No Information Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewContactDetails.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string
};

export default ViewContactDetails;
