import { AppBar,
  Button, DialogActions, DialogContent, Divider, FormControl, Grid, IconButton,
  InputAdornment, InputLabel, ListItemButton, MenuItem, Modal, Select, Switch,
  TextField, Toolbar, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Services from '../../utils/Services';
import TSSnackbar from '../tscomponents/TSSnackbar';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { EnumUtils, Utils } from '../../utils/UtilFunctions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { isFutureDate, isPastDate, validateDate, validateNumber, validateStringForNull } from '../../utils/FieldValidations';
import { Dialog, List, ListItem, ListItemText, Container, Typography } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactsIcon from '@mui/icons-material/Contacts';
import PropTypes from 'prop-types';
import { AGE_IN_UNIT, REFERRED_BY, SEX_OPTIONS } from '../../utils/EnumDefinitions';
import Autocomplete from '@mui/material/Autocomplete';
import { cloneDeep } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import ViewAppointments from './ViewAppointments';
import VisibilityIcon from '@mui/icons-material/Visibility';
import APIData from '../../utils/APIData';
import ClearIcon from '@mui/icons-material/Clear';
import { TSSideBarDialog, Transition } from '../tscomponents/TSSideBarDialog';
import TSTimeSlots from '../tscomponents/TSTimeSlots';
import { CookieUtils } from '../../utils/CookieUtils';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 350,
    lg: 600
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const CreateAppointment = ({ clinicId, prefilledInfo, onSuccessfulCommit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [sessionDetails, setSessionDetails] = useState([]);
  const [tokenNumber, setTokenNumber] = useState('');
  const [isPatientListDialogOpen, setIsPatientListDialogOpen] = useState(false);
  const [patientsDetails, setPatientDetails] = useState([]);
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [tokenMessage, setTokenMessage] = useState('');
  const [prefilledDisable, setPrefilledDisable] = useState(false);
  const [viewAppointmentsIconDisable, setIsViewAppointmentsIconDisable] = useState(true);
  const [viewAppointmentsDialogOpen, setIsViewAppointmentsDialogOpen] = useState(false);
  const [isPatientAddressInfoDialogOpen, setIsPatientAddressInfoDialogOpen] = useState(false);
  const [isDobConfirmationDialogOpen, setIsDobConfirmationDialogOpen] = useState(false);
  const [paramsFromValidation, setParamsFromValidation] = useState(undefined);
  const [showAgeField, setShowAgeField] = useState(false);
  const [lastSearchedPhoneNumber, setLastSearchedPhoneNumber] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [isPatientDetailsFieldDisabled, setIsPatientDetailsFieldDisabled] = useState(false);
  const [isAreaFieldDisabled, setIsAreaFieldDisabled] = useState(false);

  const APIToken = {
    CREATE_APPOINTMENT: 'CCA01',
    GET_DOCTORS: 'CCA02',
    GET_DOCTOR_OP_SESSION: 'CCA03',
    GET_TOKEN_WITH_OP_ID: 'CCA04',
    GET_TOKEN_WITH_SESSION_ID: 'CCA05',
    SEARCH_CLINIC_PATIENTS: 'CCA06'
  };

  const [appointmentDetails, setAppointmentDetails] = useState({
    doctor_id: '',
    op_date: moment(),
    session_id: '',
    op_id: '',
    phone: '',
    appointment_time: '',
    referred_by: 0,
    token_message: '',
    start_time: '',
    end_time: '',
    patient_name: '',
    sex: '',
    dob: null,
    age: '',
    age_in: '4',
    area: '',
    city: CookieUtils.getClinicCity(),
    state: CookieUtils.getClinicState(),
    country: CookieUtils.getClinicCountry(),
    address: ''
  });

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const handleSuccessfulApmtModification = (opId) => {
    if (appointmentDetails.op_id == opId) {
      raiseTokenDetails(opId);
    }
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    let hideLoading = true;
    if (apiData == APIData.createAppointment && apiToken == APIToken.CREATE_APPOINTMENT ) {
      setPatientDetails([]);
      setAppointmentDetails({
        ...appointmentDetails,
        phone: '',
        appointment_time: '',
        referred_by: 0,
        op_id: response.data.op_id,
        patient_name: '',
        sex: '',
        dob: null,
        patient_id: '',
        age: '',
        age_in: '4',
        area: '',
        city: CookieUtils.getClinicCity(),
        state: CookieUtils.getClinicState(),
        country: CookieUtils.getClinicCountry(),
        address: ''
      });
      setShowAgeField(false);
      setParamsFromValidation(undefined);
      setIsPatientDetailsFieldDisabled(false);
      setIsAreaFieldDisabled(false);
      if (response.data.op_id != undefined) {
        setIsViewAppointmentsIconDisable(false);
        hideLoading = false;
        raiseTokenDetails(response.data.op_id);
      } else {
        setTokenNumber(tokenNumber + 1);
      }
      if (onSuccessfulCommit != undefined) {
        onSuccessfulCommit(response.message);
      } else {
        setTokenMessage(response.message);
        setIsTokenModalOpen(true);
      }
    } else if (apiData == APIData.getDoctors && apiToken == APIToken.GET_DOCTORS) {
      setDoctorDetails(response.data);
      if (response.data.length == 1) {
        setAppointmentDetails({ ...appointmentDetails,
          doctor_id: response.data[0].doctor_id
        });
        hideLoading = false;
        raiseDoctorSessionDetails(response.data[0].doctor_id, appointmentDetails.op_date);
      }
    } else if (apiData == APIData.getDoctorOpSessionDetails && apiToken == APIToken.GET_DOCTOR_OP_SESSION) {
      setSessionDetails(response.data);
    } else if ((apiData == APIData.getTokenDetailsWithOpId && apiToken == APIToken.GET_TOKEN_WITH_OP_ID) ||
      (apiData == APIData.getTokenDetailsWithSessionId && apiToken == APIToken.GET_TOKEN_WITH_SESSION_ID)) {
      setTokenNumber(response.data.token_no);
      setAllTimeSlots(response.data.slot_list);
    } else if (apiData == APIData.searchClinicPatients && apiToken == APIToken.SEARCH_CLINIC_PATIENTS) {
      const length = response.data.length;
      if (length >= 1 ) {
        setPatientDetails(response.data);
        setIsPatientListDialogOpen(true);
      }
      if ((lastSearchedPhoneNumber != appointmentDetails.phone)) {
        clearAppointmentDetails();
        setIsPatientDetailsFieldDisabled(false);
        setIsAreaFieldDisabled(false);
      }
      setLastSearchedPhoneNumber(appointmentDetails.phone);
    }
    if (hideLoading) {
      setIsLoading(false);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.createAppointment && apiToken == APIToken.CREATE_APPOINTMENT) {
      defaultMsg = 'Failed to Create Appointment';
    } else if (apiData == APIData.getDoctors && apiToken == APIToken.GET_DOCTORS) {
      defaultMsg = 'Failed to retrieve Doctor List';
    } else if (apiData == APIData.getDoctorOpSessionDetails && apiToken == APIToken.GET_DOCTOR_OP_SESSION) {
      defaultMsg = 'Failed to retrieve Doctor Session details';
      setSessionDetails([]);
    } else if ((apiData == APIData.getTokenDetailsWithOpId && apiToken == APIToken.GET_TOKEN_WITH_OP_ID) ||
    (apiData == APIData.getTokenDetailsWithSessionId && apiToken == APIToken.GET_TOKEN_WITH_SESSION_ID)) {
      defaultMsg = 'Failed to retrieve Current running Token Number';
    } else if (apiData == APIData.searchClinicPatients && apiToken == APIToken.SEARCH_CLINIC_PATIENTS) {
      setIsPatientDetailsFieldDisabled(false);
      setIsAreaFieldDisabled(false);
      defaultMsg = 'No patients found';
      if ((lastSearchedPhoneNumber != appointmentDetails.phone)) {
        clearAppointmentDetails();
      }
      setLastSearchedPhoneNumber(appointmentDetails.phone);
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const clearAppointmentDetails = () => {
    setAppointmentDetails({
      ...appointmentDetails,
      patient_name: '',
      sex: '',
      dob: null,
      patient_id: '',
      age: '',
      age_in: '4',
      area: '',
      city: CookieUtils.getClinicCity(),
      state: CookieUtils.getClinicState(),
      country: CookieUtils.getClinicCountry(),
      address: ''
    });
  };

  const handleAppointmentTimeClearClick = () => {
    setAppointmentDetails({ ...appointmentDetails,
      appointment_time: ''
    });
  };

  const handleSlotClick = (appointmentTime) => {
    setAppointmentDetails({ ...appointmentDetails,
      appointment_time: appointmentTime
    });
    setAnchorEl(null);
  };

  const handleTimeSlotsClose= () => {
    setAnchorEl(null);
  };

  const handleComboboxChange = (event) =>{
    setAllTimeSlots([]);
    if (event.target.name == 'doctor_id' && validateDate(appointmentDetails.op_date) ) {
      setAppointmentDetails({ ...appointmentDetails, [event.target.name]: event.target.value,
        session_id: '',
        start_time: '',
        end_time: '',
        op_id: ''
      });
      setTokenNumber('');
      raiseDoctorSessionDetails(event.target.value, appointmentDetails.op_date);
    }
    if (validateStringForNull(appointmentDetails.doctor_id) && event.target.name == 'session_id') {
      const objIndex = sessionDetails.findIndex((obj) => obj.session_id == event.target.value);
      setAppointmentDetails({ ...appointmentDetails, [event.target.name]: event.target.value,
        start_time: sessionDetails[objIndex].session_start_time != undefined ?
          moment(sessionDetails[objIndex].session_start_time.value, 'HH:mm:ss').format() :
          moment(sessionDetails[objIndex].start_time.value, 'HH:mm:ss').format(),
        end_time: sessionDetails[objIndex].session_end_time != undefined ?
          moment(sessionDetails[objIndex].session_end_time.value, 'HH:mm:ss').format() :
          moment(sessionDetails[objIndex].end_time.value, 'HH:mm:ss').format(),
        op_id: sessionDetails[objIndex].op_id });
      if ( validateStringForNull(sessionDetails[objIndex].op_id)) {
        setIsViewAppointmentsIconDisable(false);
        raiseTokenDetails(sessionDetails[objIndex].op_id);
      } else {
        setIsViewAppointmentsIconDisable(true);
        raiseTokenDetails(undefined, event.target.value);
      }
    }
  };

  const handleDateChange = (date) => {
    setAppointmentDetails({ ...appointmentDetails,
      op_date: date,
      session_id: '',
      start_time: '',
      end_time: '',
      appointment_time: ''
    });
    setTokenNumber('');
    if (validateDate(date) && validateStringForNull(appointmentDetails.doctor_id)) {
      raiseDoctorSessionDetails(appointmentDetails.doctor_id, date);
    }
    setAllTimeSlots([]);
  };

  const handlePatientSearchClick = () => {
    if (validateNumber(appointmentDetails.phone) ) {
      setIsLoading(true);
      raiseGetPatientsDetails();
    }
  };

  const handleTextChange = (event) => {
    setAppointmentDetails({ ...appointmentDetails, [event.target.name]: event.target.value });
  };

  const handlePatientDetails = (patientId) => {
    const objIndex = patientsDetails.findIndex((obj) => obj.patient_id === patientId);
    setAppointmentDetails({ ...appointmentDetails,
      patient_name: patientsDetails[objIndex].patient_name,
      dob: patientsDetails[objIndex].dob != undefined ?
        moment(patientsDetails[objIndex].dob.value, 'yyyy-MM-DD'): null,
      sex: patientsDetails[objIndex].sex,
      patient_id: patientId,
      area: patientsDetails[objIndex].area != undefined ? patientsDetails[objIndex].area : '',
      city: patientsDetails[objIndex].city != undefined ? patientsDetails[objIndex].city : CookieUtils.getClinicCity(),
      state: patientsDetails[objIndex].state != undefined ? patientsDetails[objIndex].state : CookieUtils.getClinicState(),
      address: patientsDetails[objIndex].address ? patientsDetails[objIndex].address : '',
      country: patientsDetails[objIndex].country != undefined ? patientsDetails[objIndex].country : CookieUtils.getClinicCountry(),
      age: patientsDetails[objIndex].age.value.age,
      age_in: patientsDetails[objIndex].age.value.age_in
    });
    setIsPatientListDialogOpen(false);
    setShowAgeField(!validateStringForNull(patientsDetails[objIndex].dob));
    setIsPatientDetailsFieldDisabled(validateStringForNull(patientsDetails[objIndex].patient_id));
    setIsAreaFieldDisabled(validateStringForNull(patientsDetails[objIndex].area));
  };

  const validateParams = (inputParams) => {
    const finalParams = {};

    if (validateStringForNull(inputParams.op_id)) {
      finalParams.op_id = inputParams.op_id;
    } else {
      if (!validateStringForNull(inputParams.doctor_id)) {
        throw new Error('Select valid Doctor');
      }
      if (!validateDate(inputParams.op_date) || isPastDate(inputParams.op_date)) {
        throw new Error('Select valid Appointment Date');
      }
      if (!validateStringForNull(inputParams.session_id)) {
        throw new Error('Select valid Session Id');
      }
      finalParams.doctor_id = inputParams.doctor_id;
      finalParams.appointment_date = moment(inputParams.op_date).format('yyyy-MM-DD');
      finalParams.session_id = inputParams.session_id;
    }
    if (validateStringForNull(inputParams.appointment_time)) {
      finalParams.appointment_time = moment(inputParams.appointment_time, 'HH:mm:ss A').format('HH:mm:ss');
    }
    if (validateNumber(inputParams.referred_by) && inputParams.referred_by != 0) {
      finalParams.referred_by = parseInt(inputParams.referred_by);
    }
    if (!validateNumber(inputParams.phone)) {
      throw new Error('Provide valid Phone Number');
    } else {
      finalParams.phone = inputParams.phone;
    }
    if (validateStringForNull(inputParams.patient_id)) {
      finalParams.patient_id = inputParams.patient_id;
    } else {
      if (!validateStringForNull(inputParams.patient_name)) {
        throw new Error('Provide valid Patient Name');
      }
      if (showAgeField == false) {
        if (!validateDate(inputParams.dob) || isFutureDate(inputParams.dob)) {
          throw new Error('Provide valid Date of Birth');
        } else if (isFutureDate(inputParams.dob)) {
          throw new Error('Future date cannot be selected for DOB. Provide valid input');
        }
      } else {
        if (!validateNumber(inputParams.age_in) && inputParams.age_in == 0) {
          throw new Error('Provide valid age unit');
        }
        if (!validateNumber(inputParams.age) && inputParams.age == 0) {
          throw new Error('Provide valid age');
        }
      }
      if (!validateStringForNull(inputParams.sex)) {
        throw new Error('Provide valid Gender');
      }
      if (!validateStringForNull(inputParams.state)) {
        throw new Error('Provide valid State');
      }
      if (!validateStringForNull(inputParams.city)) {
        throw new Error('Provide valid City');
      }
      if (!validateStringForNull(inputParams.country)) {
        throw new Error('Provide valid country');
      }
      if (validateStringForNull(inputParams.address)) {
        finalParams.address = inputParams.address;
      }
      finalParams.phone = inputParams.phone;
      finalParams.patient_name = inputParams.patient_name;

      if (showAgeField == false) {
        finalParams.dob = moment(inputParams.dob).format('yyyy-MM-DD');
      } else {
        finalParams.age = parseInt(inputParams.age);
        finalParams.age_in = parseInt(inputParams.age_in);
      }
      finalParams.sex = inputParams.sex;
      finalParams.city = inputParams.city;
      finalParams.state = inputParams.state;
      finalParams.country = inputParams.country;
    }
    if (!isAreaFieldDisabled) {
      if (!validateStringForNull(inputParams.area)) {
        throw new Error('Provide valid Area');
      }
      finalParams.area = inputParams.area;
    }
    if (validateNumber(inputParams.referred_by) && inputParams.referred_by != 0) {
      finalParams.referred_by = parseInt(inputParams.referred_by);
    }
    return finalParams;
  };

  const raiseCreateAppointment = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.createAppointment, uriValues: [clinicId], params: finalParams },
      APIToken.CREATE_APPOINTMENT, processSuccess, processError);
  };

  const handleCreateAppointmentClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(appointmentDetails);
      const filteredParams = validateParams(params);
      const yearDifference = Utils.calculateDobYearDifference(filteredParams, showAgeField);
      if (yearDifference > 80) {
        setIsLoading(false);
        const params = cloneDeep(filteredParams);
        setParamsFromValidation(params);
        setIsDobConfirmationDialogOpen(true);
      } else {
        raiseCreateAppointment(filteredParams);
      }
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Create Appointment');
      setIsLoading(false);
    }
  };

  const validatePrefilledInfo = () => {
    return (prefilledInfo != undefined && validateStringForNull(prefilledInfo.doctor_name) && validateNumber(prefilledInfo.doctor_id) &&
      validateDate(prefilledInfo.op_date) && validateStringForNull(prefilledInfo.session_name) &&
      validateNumber(prefilledInfo.session_id));
  };

  useEffect(() => {
    if (!validatePrefilledInfo()) {
      raiseDoctorListRequest();
    } else {
      setAppointmentDetails({ ...appointmentDetails,
        doctor_id: prefilledInfo.doctor_id,
        op_date: moment(prefilledInfo.op_date, 'yyyy-MM-DD'),
        session_id: prefilledInfo.session_id,
        op_id: prefilledInfo.op_id
      });
      setPrefilledDisable(true);
      if (validateStringForNull(prefilledInfo.op_id)) {
        raiseTokenDetails(prefilledInfo.op_id);
        setIsViewAppointmentsIconDisable(false);
      } else {
        raiseTokenDetails(undefined, prefilledInfo.session_id);
      }
    }
  }, []);

  const getDobOrAgeDialogContent = (obj) => {
    if (obj == undefined) {
      return '';
    }
    if (showAgeField == false) {
      return 'Are you sure your DOB is ' + moment(obj.dob, 'yyyy-MM-DD').format('DD/MM/YYYY') + '?';
    } else {
      return 'Are you sure your age is ' + obj.age + ' ' + AGE_IN_UNIT[obj.age_in] + '?';
    }
  };

  const raiseDoctorListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getDoctors, uriValues: [clinicId] },
      APIToken.GET_DOCTORS, processSuccess, processError);
  };

  const raiseDoctorSessionDetails = (docId, opDate) => {
    setIsLoading(true);
    const finalParams = 'include_past_sessions=false' + '&op_date=' + moment(opDate).format('yyyy-MM-DD');
    Services.sendBackendRequest({ apiData: APIData.getDoctorOpSessionDetails, uriValues: [clinicId, docId], params: finalParams },
      APIToken.GET_DOCTOR_OP_SESSION, processSuccess, processError);
  };

  const raiseTokenDetails = (opId, sessionId) => {
    let apiRequest = undefined;
    setIsLoading(true);
    if (validateStringForNull(opId)) {
      apiRequest = Services.sendBackendRequest({ apiData: APIData.getTokenDetailsWithOpId, uriValues: [clinicId, opId] },
        APIToken.GET_TOKEN_WITH_OP_ID, processSuccess, processError);
    } else {
      const finalParams = 'op_date=' + moment(appointmentDetails.op_date).format('yyyy-MM-DD') + '&session_id='+ sessionId;
      apiRequest = Services.sendBackendRequest(
        { apiData: APIData.getTokenDetailsWithSessionId, uriValues: [clinicId, appointmentDetails.doctor_id], params: finalParams },
        APIToken.GET_TOKEN_WITH_SESSION_ID, processSuccess, processError);
    }
    if (apiRequest == undefined) {
      setIsLoading(false);
      return;
    }
  };

  const raiseGetPatientsDetails = () => {
    setIsLoading(true);
    const finalParams = 'phone=' + appointmentDetails.phone + '&use_global_search=true';
    Services.sendBackendRequest({ apiData: APIData.searchClinicPatients, uriValues: [clinicId], params: finalParams },
      APIToken.SEARCH_CLINIC_PATIENTS, processSuccess, processError);
  };

  const handleValueChange = (name, newValue) => {
    if (validateStringForNull(newValue)) {
      setAppointmentDetails({ ...appointmentDetails,
        [name]: newValue.value
      });
    } else {
      setAppointmentDetails({
        ...appointmentDetails,
        [name]: null
      });
    }
  };

  const handleConfirmationDialogClose = (confirmed) => {
    setIsDobConfirmationDialogOpen(false);
    if (confirmed) {
      raiseCreateAppointment(paramsFromValidation);
    } else {
      setParamsFromValidation(undefined);
    }
  };

  const handleSwitchChange = () => {
    setShowAgeField(!showAgeField);
  };

  return (
    <Container maxWidth="sm" sx={{ backgroundColor: 'white', py: 5 }}>
      <Grid container direction='column'>
        <Grid item container justifyContent='space-between' alignItems='center' rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12} >
            <Typography component="h5" variant="h5" sx={{ mb: 1 }} >
              Appointment Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} >
            {prefilledDisable ? (
              <TextField
                disabled
                id="outlined"
                name="doctor_id"
                size='small'
                value={prefilledInfo.doctor_name}
                label="Doctor Name"
                autoComplete ='off'
                fullWidth
              />
            ) : (
              <FormControl fullWidth >
                <InputLabel size="small" id="doctor-list-select-label">Doctor Name</InputLabel>
                <Select
                  labelId="doctor-list-select-label"
                  id="doctor-list-select"
                  label="doctor_name"
                  name='doctor_id'
                  value={appointmentDetails.doctor_id}
                  onChange={handleComboboxChange}
                  size="small"
                >
                  {doctorDetails.map((obj) => (
                    <MenuItem key={obj.doctor_id} value={obj.doctor_id}>
                      {obj.doctor_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {prefilledDisable ? (
              <TextField
                disabled
                id="outlined"
                name="op_date"
                size='small'
                autoComplete ='off'
                value={moment(prefilledInfo.op_date).format('DD/MM/YYYY')}
                label="Date"
                fullWidth
              />
            ) : (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date"
                  format="DD/MM/YYYY"
                  sx={{ width: '100%' }}
                  value={appointmentDetails.op_date}
                  name="op_date"
                  disablePast
                  onChange= {handleDateChange}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                />
              </LocalizationProvider>
            )}
          </Grid>
          <Grid item xs={12} sm={6} >
            {prefilledDisable ? (
              <TextField
                disabled
                id="outlined"
                name="session_id"
                size='small'
                autoComplete ='off'
                value={prefilledInfo.session_name}
                label="Session"
                fullWidth
              />
            ) : (
              <FormControl fullWidth >
                <InputLabel size="small" id="session-id-select-label">Session</InputLabel>
                <Select
                  labelId="session-id-select-label"
                  id="doctor-id-select"
                  label="session_name"
                  name='session_id'
                  value={appointmentDetails.session_id}
                  onChange={handleComboboxChange}
                  size="small"
                >
                  {sessionDetails.map((obj) => (
                    <MenuItem key={obj.session_id} value={obj.session_id}>
                      {obj.session_start_time != undefined ? obj.session_start_time.display.toUpperCase() : obj.start_time.display.toUpperCase()}
                      &nbsp;-&nbsp;
                      {obj.session_end_time != undefined ? obj.session_end_time.display.toUpperCase() : obj.end_time.display.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs= {9} sm={4.6} >
            <TextField
              disabled
              id="outlined"
              name="token_no"
              size='small'
              autoComplete ='off'
              value={tokenNumber}
              label="Token No"
              fullWidth
            />
          </Grid>
          <Grid item xs={3} sm={1.4}>
            <Tooltip title='View Appointments'>
              <span>
                <IconButton
                  color='primary'
                  onClick={() => setIsViewAppointmentsDialogOpen(true)}
                  disabled={viewAppointmentsIconDisable}
                  sx={{ backgroundColor: '#B9E9FC' }}
                >
                  <VisibilityIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              id="appointment_time"
              name="appointment_time"
              size='small'
              autoComplete ='off'
              value={appointmentDetails.appointment_time}
              label="Appointment Time"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(event) => {event.stopPropagation(); handleAppointmentTimeClearClick(event);}}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <Autocomplete
              disablePortal
              id="referred-by"
              name='referred_by'
              value={REFERRED_BY[appointmentDetails.referred_by] ?? null}
              onChange={(event, newValue) => handleValueChange('referred_by', newValue)}
              options={EnumUtils.getEnumOptions(REFERRED_BY)}
              isOptionEqualToValue={(option, value) => {
                return option.label === value;
              }}
              size="small"
              autoSelect
              autoHighlight
              renderInput={(params) => <TextField size="small" {...params} error={false} fullWidth label="Referred By" />}
            />
          </Grid>
          <Grid item xs={12} >
            <Typography component="h5" variant="h5" sx={{ mb: 1, mt: 2 }} >
              Patient Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              label="Phone Number"
              name="phone"
              size='small'
              value={appointmentDetails.phone}
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              onBlur={handlePatientSearchClick}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handlePatientSearchClick}
                    >
                      <PersonSearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Name"
              label="Name"
              name="patient_name"
              autoComplete ='off'
              size='small'
              value={appointmentDetails.patient_name}
              disabled={isPatientDetailsFieldDisabled}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="sex"
              name='sex'
              value={SEX_OPTIONS[appointmentDetails.sex] ?? null}
              onChange={(event, newValue) => handleValueChange('sex', newValue)}
              options={EnumUtils.getEnumOptions(SEX_OPTIONS)}
              disabled={isPatientDetailsFieldDisabled}
              isOptionEqualToValue={(option, value) => {
                return option.label === value;
              }}
              size="small"
              autoSelect
              autoHighlight
              renderInput={(params) => <TextField size="small" {...params} error={false} fullWidth label="Sex" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="area"
              label="Area"
              name="area"
              autoComplete ='off'
              size='small'
              value={appointmentDetails.area}
              disabled={isAreaFieldDisabled}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setIsPatientAddressInfoDialogOpen(true)} >
                    <ContactsIcon sx={{ ml: 2 }} />
                  </IconButton>
                )
              }}
            />
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent='flex-end' alignItems='center'>
            <Grid item>
              <Typography variant='button'>DOB</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={showAgeField}
                onChange={handleSwitchChange}
                disabled = {isPatientDetailsFieldDisabled}
                sx={{
                  '& .MuiSwitch-thumb': {
                    color: '#205295;'
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: '#172b4d'
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant='body2'>Age</Typography>
            </Grid>
          </Grid>
          {showAgeField === false ? (
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date of Birth"
                  format="DD/MM/YYYY"
                  sx={{ width: '100%' }}
                  value={appointmentDetails.dob}
                  disabled={isPatientDetailsFieldDisabled}
                  name="dob"
                  disableFuture
                  onChange= {(newValue) => {setAppointmentDetails({ ...appointmentDetails, dob: newValue });}}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={4} sm={2}>
                <TextField
                  label="Age"
                  sx={{ width: '100%' }}
                  value={appointmentDetails.age}
                  disabled={isPatientDetailsFieldDisabled}
                  name="age"
                  size='small'
                  onChange={(event) => {handleTextChange(event);}}
                />
              </Grid>
              <Grid item xs={8} sm={4}>
                <Autocomplete
                  disablePortal
                  id="age-in-unit"
                  name='age_in'
                  value={AGE_IN_UNIT[appointmentDetails.age_in] ?? null}
                  disabled={isPatientDetailsFieldDisabled}
                  onChange={(event, newValue) => handleValueChange('age_in', newValue)}
                  options={EnumUtils.getEnumOptions(AGE_IN_UNIT)}
                  isOptionEqualToValue={(option, value) => {
                    return option.label === value;
                  }}
                  size="small"
                  autoSelect
                  autoHighlight
                  renderInput={(params) => <TextField size="small" {...params} error={false} fullWidth label="Unit" />}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }}/>
      <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} >
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="small"
            onClick={handleCreateAppointmentClick}
          >
          Create
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={isPatientListDialogOpen}
        fullWidth = {true}
        maxWidth="xs"
        PaperProps={{
          style: { borderRadius: 25 }
        }}
      >
        <DialogContent>
          <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
            Patient associated with this phone number
          </Typography>
        </DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <List sx={{ width: '100%', maxWidth: 'xs' }}>
              {patientsDetails.map((obj) =>(
                <React.Fragment key={obj.patient_id} >
                  <ListItemButton onClick={() => {handlePatientDetails(obj.patient_id);}}>
                    <ListItem sx={{ py: 0 }} key={obj.patient_id} value={obj.patient_id}>
                      <ListItemText primary={obj.patient_name} secondary= {`${obj.age.display},${obj.sex}`}/>
                    </ListItem>
                  </ListItemButton>
                  <Divider sx={{ mx: 3 }} />
                </React.Fragment>
              ))}
            </List>
            <DialogActions>
              <Button
                sx={{
                  borderRadius: 10,
                  fontSize: '12px',
                  mr: 3
                }}
                color='primary'
                variant='contained'
                size='small'
                onClick={() => setIsPatientListDialogOpen(false)}
              >
               Close
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={isPatientAddressInfoDialogOpen}
        PaperProps={{
          style: { borderRadius: 25 }
        }}
      >
        <Grid sx={{ p: 4 }} container direction='column' justifyContent='space-between' alignItems='center' rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="address"
              label="Address"
              name="address"
              autoComplete ='off'
              size='small'
              value={appointmentDetails.address}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              label="City"
              name="city"
              autoComplete ='off'
              size='small'
              value={appointmentDetails.city}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="state"
              label="State"
              name="state"
              autoComplete ='off'
              size='small'
              value={appointmentDetails.state}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="country"
              label="Country"
              name="country"
              autoComplete ='off'
              size='small'
              value={appointmentDetails.country}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container justifyContent='center' alignItems='flex-end'>
          <Grid item xs={12} sm={6}>
            <Button
              sx={{
                borderRadius: 10,
                fontSize: '12px',
                ml: 15,
                mb: 2
              }}
              color='primary'
              variant='contained'
              size='small'
              onClick={() => setIsPatientAddressInfoDialogOpen(false)}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Modal
        open={isTokenModalOpen}
        onClose={() => setIsTokenModalOpen(false)}
      >
        <Grid container sx={style} justifyContent='center' >
          <Grid item xs={1}>
            <CheckCircleIcon sx={{ fontSize: 40, color: 'green' }} align='center'/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align='center'>
              Your Appointment has been booked successfully
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="h5" sx={{ mt: 2 }} align='center'>
              {tokenMessage}
            </Typography>
          </Grid>
        </Grid>
      </Modal>
      <TSSideBarDialog
        open={viewAppointmentsDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsViewAppointmentsDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Appointments
            </Typography>
          </Toolbar>
        </AppBar>
        <ViewAppointments clinicId={clinicId} opId={appointmentDetails.op_id} onSuccessfulCommit={handleSuccessfulApmtModification} />
      </TSSideBarDialog>
      <TSConfirmationDialog
        open={isDobConfirmationDialogOpen}
      >
        <DialogContent>
          <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
            <strong>Confirmation</strong>
          </Typography>
          <Typography variant='h5'>
            {getDobOrAgeDialogContent(paramsFromValidation)}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            size="small"
            color='primary'
            onClick={() => {handleConfirmationDialogClose(false);}}
          >
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            color='primary'
            onClick={() => {handleConfirmationDialogClose(true);}}
          >
            Yes
          </Button>
        </DialogActions>
      </TSConfirmationDialog>
      <TSTimeSlots timeSlots={allTimeSlots} anchorEl={anchorEl} onTimeSlotClick={handleSlotClick} onClose={handleTimeSlotsClose}/>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </Container>
  );
};

CreateAppointment.propTypes = {
  clinicId: PropTypes.string.isRequired,
  prefilledInfo: PropTypes.object,
  onSuccessfulCommit: PropTypes.func
};

export default CreateAppointment;
