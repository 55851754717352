import { Constants } from './Constants';
import * as Crypto from 'crypto-js';

class CookieUtils {
  static getParsedCookie(hashKey) {
    try {
      const bytes = Crypto.AES.decrypt(localStorage.getItem(hashKey), 'R#(@ui*54$@y');
      const rawStr = bytes.toString(Crypto.enc.Utf8);
      const parsedObj = JSON.parse(rawStr);
      if (parsedObj == null || parsedObj == undefined || parsedObj == '') {
        return {};
      }
      return parsedObj;
    } catch {
      return {};
    }
  }

  static getLoginhash() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    return parsedObj.loginHash;
  }

  static getStaffId() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    return parsedObj.staff_id;
  }

  static getStaffName() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    return parsedObj.staff_name;
  }

  static getBannerMsg() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    return parsedObj.banner_msg;
  }

  static getIsDoctor() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    return parsedObj.banner_msg != undefined || parsedObj.degree != undefined;
  }

  static getStaffEmpId() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    return parsedObj.emp_id;
  }

  static getClinicId() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    return parsedObj.clinic_id;
  }

  static getStaffClinicRole() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    return parsedObj.role;
  }

  static getIsStaffDoctorInClinic() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    return parsedObj.is_doctor;
  }

  static getIsStaffActiveInClinic() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    return parsedObj.is_active;
  }

  static getClinicHashTimestamp() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    return parsedObj.timestamp;
  }

  static getClinicCity() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    return parsedObj.city;
  }

  static getClinicState() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    return parsedObj.state;
  }

  static getClinicAddress() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    return parsedObj.address;
  }

  static getClinicCountry() {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    return parsedObj.country;
  }

  static clearCookies() {
    localStorage.clear();
  }

  static clearClinicHashCookies() {
    CookieUtils.setCookie(Constants.clinicHash, '');
  }

  static clearLoginHashCookies() {
    CookieUtils.setCookie(Constants.loginHash, '');
  }

  static clearClinicDetailsHashCookies() {
    CookieUtils.setCookie(Constants.clinicDetailsHash, '');
  }

  static setStaffId(staffId) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    parsedObj.staff_id = staffId;
    CookieUtils.setCookie(Constants.loginHash, parsedObj);
  }

  static setStaffName(staffName) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    parsedObj.staff_name = staffName;
    CookieUtils.setCookie(Constants.loginHash, parsedObj);
  }

  static setLoginHash(loginHash) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    parsedObj.loginHash = loginHash;
    CookieUtils.setCookie(Constants.loginHash, parsedObj);
  }

  static setBannerMsg(bannerMsg) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    parsedObj.banner_msg = bannerMsg;
    CookieUtils.setCookie(Constants.loginHash, parsedObj);
  }

  static setDegree(degree) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.loginHash);
    parsedObj.degree = degree;
    CookieUtils.setCookie(Constants.loginHash, parsedObj);
  }

  static setClinicId(clinicId) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    parsedObj.clinic_id = clinicId;
    CookieUtils.setCookie(Constants.clinicHash, parsedObj);
  }

  static setStaffClinicRole(role) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    parsedObj.role = role;
    CookieUtils.setCookie(Constants.clinicHash, parsedObj);
  }

  static setIsStaffActiveInClinic(isActive) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    parsedObj.is_active = isActive;
    CookieUtils.setCookie(Constants.clinicHash, parsedObj);
  }

  static setStaffEmpId(empId) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    parsedObj.emp_id = empId;
    CookieUtils.setCookie(Constants.clinicHash, parsedObj);
  }

  static setIsDoctor(isDoctor) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    parsedObj.is_doctor = isDoctor;
    CookieUtils.setCookie(Constants.clinicHash, parsedObj);
  }

  static setClinicHashTimestamp(timeStamp) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicHash);
    parsedObj.timeStamp = timeStamp;
    CookieUtils.setCookie(Constants.clinicHash, parsedObj);
  }

  static setClinicCity(clinicCity) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    parsedObj.city = clinicCity;
    CookieUtils.setCookie(Constants.clinicDetailsHash, parsedObj);
  }

  static setClinicState(clinicState) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    parsedObj.state = clinicState;
    CookieUtils.setCookie(Constants.clinicDetailsHash, parsedObj);
  }

  static setClinicAddress(clinicAddress) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    parsedObj.address = clinicAddress;
    CookieUtils.setCookie(Constants.clinicDetailsHash, parsedObj);
  }

  static setClinicCountry(clinicCountry) {
    const parsedObj = CookieUtils.getParsedCookie(Constants.clinicDetailsHash);
    parsedObj.country = clinicCountry;
    CookieUtils.setCookie(Constants.clinicDetailsHash, parsedObj);
  }

  static setCookie(key, value) {
    const jsonValue = JSON.stringify(value);
    const eValue = Crypto.AES.encrypt(jsonValue, 'R#(@ui*54$@y').toString();
    localStorage.setItem(key, eValue);
  }
}

export { CookieUtils };
