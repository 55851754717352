import {
  Box, Typography
} from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';

const ClinicHome = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Home - DocKit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}>
        <Typography variant="h6">
          Home
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default ClinicHome;
