import {
  Box
} from '@mui/material';
import React from 'react';
import LogoutAccount from '../components/accountsettings/LogoutAccount';

const Logout = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}>
      <LogoutAccount />
    </Box>
  );
};

export default Logout;
