import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import TSHomeBackDrop from '../tscomponents/TSHomeBackDrop';
import { Utils } from '../../utils/UtilFunctions';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { validateStringForNull } from '../../utils/FieldValidations';
import AddIcon from '@mui/icons-material/Add';
import Services from '../../utils/Services';
import { cloneDeep } from 'lodash';
import APIData from '../../utils/APIData';
import { CONTACT_TYPE } from '../../utils/EnumDefinitions';
import { UiUtils } from '../../utils/UiUtilFunctions';

const SetupClinicContactDetails = ({ clinicId, onSuccessfulCommit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    CREATE_CLINIC_CONTACT: 'CCCC01'
  };
  const [contactDetails, setContactDetails] = useState({});
  const contactDetailsLength = Object.keys(contactDetails).length;
  const clientErrMsg = {};

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const [screenWidth] = UiUtils.useWindowSize();

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const validateParams = (inputParams) => {
    if (!validateStringForNull(clinicId)) {
      throw new Error('Invalid Clinic');
    }
    const params = [];
    Object.keys(inputParams).forEach((contactTypeKey) => {
      const contactTypeId = inputParams[contactTypeKey][0].contact_type;
      inputParams[contactTypeKey].forEach((contact) => {
        if (validateStringForNull(contact.contact)) {
          params.push({
            contact_type: contactTypeId,
            contact: contact.contact
          });
        }
      });
    });

    if (params.length === 0) {
      throw new Error('No valid contact details provided');
    }

    params.forEach((param) => {
      if (!validateStringForNull(param.contact)) {
        throw new Error('Invalid contact detail: ' + param.contact);
      }
      if (param.contact_type === 0) {
        throw new Error('Select contact type for the contact ' + param.contact);
      }
    });
    return params;
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.createClinicContact && apiToken == APIToken.CREATE_CLINIC_CONTACT) {
      onSuccessfulCommit();
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.createClinicContact && apiToken == APIToken.CREATE_CLINIC_CONTACT) {
      showSnackBar('error', err.message ?? 'Updating clinic contact details failed.');
    }
    setIsLoading(false);
  };

  const raiseRequest = (inputParams) => {
    setIsLoading(true);
    const bodyParams = {
      contact_details: inputParams
    };
    Services.sendBackendRequest({ apiData: APIData.createClinicContact, params: bodyParams, uriValues: [clinicId] },
      APIToken.CREATE_CLINIC_CONTACT, processSuccess, processError);
  };

  const handleSkip = () => {
    onSuccessfulCommit();
  };

  const handleSave = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(contactDetails);
      const filteredParams = validateParams(params);
      raiseRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Updating clinic contact details failed');
      setIsLoading(false);
    }
  };

  const addContactType = (typeId) => {
    const contactTypeId = parseInt(typeId);
    const type = CONTACT_TYPE[contactTypeId];
    const key = getContactKey(type);
    if (!contactDetails[key]) {
      setContactDetails({ ...contactDetails, [key]: [{ id: 1, contact: '', contact_type: contactTypeId }] });
    }
  };

  const handleAddField = (contactType) => {
    const newContact = {
      id: contactDetails[contactType].length + 1 || 1,
      contact: ''
    };
    setContactDetails({
      ...contactDetails,
      [contactType]: [...(contactDetails[contactType] || []), newContact]
    });
  };

  const handleInputChange = (e, contactType, index) => {
    const newContacts = [...contactDetails[contactType]];
    newContacts[index][e.target.name] = e.target.value;
    setContactDetails({ ...contactDetails, [contactType]: newContacts });
  };

  const handleRemoveField = (contactType, index) => {
    const newContacts = contactDetails[contactType].filter((_, i) => i !== index);
    if (newContacts.length === 0) {
      const updatedContactDetails = { ...contactDetails };
      delete updatedContactDetails[contactType];
      setContactDetails(updatedContactDetails);
    } else {
      setContactDetails({ ...contactDetails, [contactType]: newContacts });
    }
  };

  const getContactKey = (key) => key.toLowerCase().replace(/\s/g, '_');

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={4}
      sx={{ height: 'inherit' }}
    >
      <Grid item xs={12} sm={12} md={12} sx={{ height: 'fit-content' }}>
        <Stack sx={{ width: '100%' }}>
          <Typography variant='heading'> Contact Information </Typography>
          <Typography variant='body' sx={{ mt: 0.5 }}>Please fill up the contact information
            to set up a new clinic below.
          </Typography>
        </Stack>
      </Grid>
      <Grid item container xs={12} sx={{ minHeight: '74%', alignContent: 'flex-start' }}>
        {contactDetailsLength > 0 ? (
          <React.Fragment>
            <Grid item xs={12} sx={{ pb: 3, mb: { xs: 3, lg: 1 } }}>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ mt: 1.25 }}>
                {Object.keys(contactDetails).map((contactType) => (
                  <React.Fragment key={contactType}>
                    <Grid item xs={12}>
                      <Typography variant='subHeading4'>
                        {CONTACT_TYPE[(contactDetails[contactType][0].contact_type)]}
                      </Typography>
                    </Grid>
                    {contactDetails[contactType].map((contact, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ position: 'relative', mb: 2 }}>
                          <TextField
                            variant="outlined"
                            value={contact.contact}
                            name='contact'
                            autoComplete='off'
                            onChange={(e) => handleInputChange(e, contactType, index)}
                            sx={UiUtils.getTextFieldStyle('contact', clientErrMsg)}
                            size='small'
                            fullWidth
                          />
                          <Paper
                            elevation={3}
                            sx={{
                              position: 'absolute',
                              right: 10,
                              bottom: '-10px',
                              display: 'flex',
                              alignItems: 'center',
                              zIndex: 1,
                              py: 1.5,
                              borderRadius: '50px',
                              height: '10px',
                              width: 'fit-content'
                            }}
                          >
                            {index === contactDetails[contactType].length - 1 && (
                              <IconButton
                                aria-label="add"
                                color='primary'
                                onClick={() => handleAddField(contactType)}
                                size="small"
                              >
                                <AddIcon sx={{ fontSize: '1.2rem' }} />
                              </IconButton>
                            )}
                            <IconButton
                              aria-label="delete"
                              color='error'
                              onClick={() => handleRemoveField(contactType, index)}
                              size="small"
                            >
                              <img src='/static/images/delete.svg' alt="Remove" style={{ height: '1.1rem' }} />
                            </IconButton>
                          </Paper>
                        </Grid>
                        {index === contactDetails[contactType].length - 1 && (
                          <Grid item xs={12}>
                            <Divider sx={{ my: 1 }} />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
            {contactDetailsLength != Object.keys(CONTACT_TYPE).length ? (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='body' textAlign='center'>You can also add</Typography>
              </Grid>
            ) : ''}
          </React.Fragment>
        ) : (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3 }}>
            <Typography variant='label' textAlign='center' sx={{ fontSize: screenWidth < 380 ? '0.9rem' : '1rem' }}>
              Choose what you want to start adding </Typography>
          </Grid>
        )}
        <Grid item container>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center',
            lg: contactDetailsLength > 0 ? 'center' : 'flex-start' }, flexWrap: 'wrap', pl: 0 }}>
            {Object.keys(CONTACT_TYPE).filter((key) => !contactDetails[getContactKey(CONTACT_TYPE[key])]).map((key) => (
              <Button
                key={key}
                variant="outlined"
                onClick={() => addContactType(key)}
                sx={{ borderRadius: '2rem', height: '1.8rem', m: 1, ml: 0 }}
              >
                {CONTACT_TYPE[key]}
              </Button>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        alignItems={{ xs: 'center', md: 'flex-end' }}
        sx={{ height: 'fit-content' }}
      >
        <Grid item>
          <Button
            type="submit"
            size="small"
            color='primary'
            variant="outlined"
            sx={{ height: '2rem', width: screenWidth < 380 ? '6.5rem' : '8rem', borderRadius: '2rem', m: 2, mr: 0 }}
            onClick={handleSkip}
          >
            Skip
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            size="small"
            color='primary'
            variant="contained"
            sx={{ height: '2rem', width: screenWidth < 380 ? '6.5rem' : '8rem', borderRadius: '2rem', m: 2, mr: 0 }}
            onClick={handleSave}
            disabled={ contactDetailsLength == 0 }
          >
            Continue
          </Button>
        </Grid>
      </Grid>
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
    </Grid>
  );
};

SetupClinicContactDetails.propTypes = {
  clinicId: PropTypes.string.isRequired,
  onSuccessfulCommit: PropTypes.func.isRequired
};
export default SetupClinicContactDetails;
