import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';

const TSBackDrop = ({ isLoading }) => {
  return (
    <Backdrop open={isLoading}>
      <CircularProgress style={{ color: blue[200] }} />
    </Backdrop>
  );
};

TSBackDrop.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

export default TSBackDrop;
