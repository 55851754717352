import { Box, Paper, Table, TableBody,
  TableCell, tableCellClasses, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DAYS_OF_WEEK } from '../../utils/EnumDefinitions';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import Services from '../../utils/Services';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import APIData from '../../utils/APIData';
import { validateStringForNull } from '../../utils/FieldValidations';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

const ViewOpenHours = ({ clinicId, staffId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [OpenHours, setOpenHours] = useState([]);
  const APIToken = {
    GET_OPEN_HOURS: 'CSOH01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetOpenHoursDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if ( apiToken == APIToken.GET_OPEN_HOURS ) {
      if (apiData == APIData.getStaffOpenHoursDetails) {
        setOpenHours(response.data ?? []);
      } else if (apiData == APIData.getClinicDetails) {
        setOpenHours(response.data.open_hours ?? []);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if ( apiToken == APIToken.GET_OPEN_HOURS ) {
      if (apiData == APIData.getStaffOpenHoursDetails || apiData == APIData.getClinicDetails) {
        setSnackBarStatus({
          open: true,
          severity: 'error',
          message: err.message ?? 'Failed to retrieve Open Hours'
        });
      }
    }
    setIsLoading(false);
  };

  const raiseGetOpenHoursDetails = () => {
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getStaffOpenHoursDetails, uriValues: [clinicId, staffId] },
        APIToken.GET_OPEN_HOURS, processSuccess, processError);
    } else {
      const params = 'fetch_modules=open_hours';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
        APIToken.GET_OPEN_HOURS, processSuccess, processError);
    }
  };

  const getOpenHoursData = () => {
    const data = [];
    if (OpenHours.length > 0 ) {
      Object.keys(DAYS_OF_WEEK).map((i) => {
        const filterDays = OpenHours.filter((obj) => (obj.day.value == i));
        let isFirstTime = true;
        filterDays.map((filterDay) => {
          const openDay = (
            <TableRow key={data.length}>
              <TableCell style={{ width: '50%' }}>
                {isFirstTime == true ? DAYS_OF_WEEK[i]: ''}
              </TableCell>
              <TableCell style={{ width: '50%' }}>
                {filterDay.start_time == undefined ? 'Closed' : (filterDay.start_time.display+ ' - ' + filterDay.end_time.display)}
              </TableCell>
            </TableRow>
          );
          data.push(openDay);
          isFirstTime = false;
        });
      });
    } else {
      return (
        <TableRow>
          <TableCell >
            No Information Available
          </TableCell>
        </TableRow>
      );
    }
    return data;
  };

  return (
    <React.Fragment>
      <Box>
        <PaperBase elevation={2} sx={{
          flexGrow: 1,
          m: 5,
          mt: 0
        }}
        >
          <InputLabel sx={{ p: 2 }}>
            Open hours
          </InputLabel>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableBody>
              {getOpenHoursData()}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewOpenHours.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string
};

export default ViewOpenHours;
