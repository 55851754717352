import { Box, Paper, Table, TableBody, TableCell,
  tableCellClasses,
  TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Services from '../../utils/Services';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import { INVITE_STATUS, USER_TYPE } from '../../utils/EnumDefinitions';
import APIData from '../../utils/APIData';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

const ShowInviteDetails = ({ inviteId, onError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inviteDetails, setInviteDetails] = useState();
  const APIToken = {
    GET_INVITE_DETAILS: 'CSID01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    if ( inviteId != undefined) {
      raiseGetInviteDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getInvite && apiToken == APIToken.GET_INVITE_DETAILS ) {
      setInviteDetails(response.data);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.getInvite && apiToken == APIToken.GET_INVITE_DETAILS ) {
      if (onError != undefined) {
        onError(err);
      } else {
        setSnackBarStatus({
          open: true,
          severity: 'error',
          message: err.message ?? 'Failed to retrieve Invite Details'
        });
      }
    }
    setIsLoading(false);
  };

  const raiseGetInviteDetails = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getInvite, uriValues: [inviteId] },
      APIToken.GET_INVITE_DETAILS, processSuccess, processError);
  };

  const getTableRow = (key, value) => {
    if (value != undefined) {
      return (
        <TableRow >
          <TableCell >
            {key}
          </TableCell>
          <TableCell >
            {value.toString()}
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow></TableRow>
      );
    }
  };

  return (
    <React.Fragment>
      {inviteDetails != undefined ? (
        <Box>
          <PaperBase elevation = {2} sx={{
            flexGrow: 1,
            m: 5
          }} >
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none'
                }
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <InputLabel>
                      Invite Details
                    </InputLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRow('Staff Name', inviteDetails.staff_name)}
                {getTableRow('Phone', inviteDetails.phone)}
                {getTableRow('Email', inviteDetails.email)}
                {getTableRow('Role', USER_TYPE[inviteDetails.role])}
                {getTableRow('Is Doctor', inviteDetails.is_doctor)}
                {getTableRow('Is Expired', inviteDetails.is_expired)}
                {getTableRow('Status', INVITE_STATUS[inviteDetails.invite_status])}
                {getTableRow('Invite Time', inviteDetails.invite_time ? inviteDetails.invite_time.display : undefined)}
              </TableBody>
            </Table>
          </PaperBase>
        </Box>
      ) : ''}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ShowInviteDetails.propTypes = {
  inviteId: PropTypes.string,
  onError: PropTypes.func
};

export default ShowInviteDetails;
