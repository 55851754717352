import { Avatar, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import APIData from '../../utils/APIData';
import TSHomeBackDrop from '../tscomponents/TSHomeBackDrop';

const ViewClinicDetails = ({ clinicId, setClinicName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clinicDetails, setClinicDetails] = useState({});

  const APIToken = {
    GET_CLINIC_BASIC_DETAILS: 'CSCD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetClinicDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CLINIC_BASIC_DETAILS ) {
      setClinicDetails(response.data.basic_details ?? []);
      if (setClinicName != undefined) {
        setClinicName(response.data.basic_details.clinic_name);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CLINIC_BASIC_DETAILS ) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Failed to retrieve Clinic Details'
      });
    }
    setIsLoading(false);
  };

  const raiseGetClinicDetails = () => {
    const params = 'fetch_modules=basic_details';
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
      APIToken.GET_CLINIC_BASIC_DETAILS, processSuccess, processError);
  };

  return (
    <React.Fragment>
      {clinicDetails.clinic_name && (
        <Grid container alignItems="stretch" columnSpacing={1} sx={{ mt: { lg: 4, sm: 2, xs: 2 }, px: { sm: '0px', xs: '15px', md: '1px' } }}>
          <Grid item container xs={12} alignItems='center' display='flex'>
            <Grid item xs={2}>
              <Avatar variant='circular'
                src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                sx={{
                  width: { lg: 70, xs: 60 },
                  height: { lg: 70, xs: 60 },
                  bgcolor: 'main.textLightGrey'
                }}
              />
            </Grid>
            <Grid item xs={10} sx={{ px: { xs: '20px', sm: '0px' } }} >
              <Typography variant= "subHeading1" color="main.primary" sx={{ ml: { sm: 2, md: 2.5, xs: 2 } }}>
                {clinicDetails.clinic_name.toString()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} sx={{ mt: 4 }} rowSpacing={0.2}>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="subHeading3" style={{ color: 'main.black' }}>
                Address
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant= "label"
                style={{ wordWrap: 'break-word',
                  overflow: 'hidden', textOverflow: 'ellipsis', color: 'main.black' }}>
                {clinicDetails.address.toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant= "label"
                style={{ wordWrap: 'break-word', overflow: 'hidden', color: 'main.black' }}>
                {clinicDetails.city.toString()}
              </Typography>
            </Grid>
            <Grid item xs={1120}>
              <Typography variant= "label"
                style={{ wordWrap: 'break-word', overflow: 'hidden', color: 'main.black' }}>
                {clinicDetails.state.toString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant= "label"
                style={{ wordWrap: 'break-word', overflow: 'hidden', color: 'main.black' }}>
                {clinicDetails.pincode.toString()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} sx={{ mt: 4 }} rowSpacing={0.2}>
            <Grid item container xs={6} sm={12}>
              <Grid item xs={12} >
                <Typography variant= "subHeading3" style={{ color: 'main.black' }}>
                  Phone Number
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant= "label" style={{ wordWrap: 'break-word', overflow: 'hidden', color: 'main.black' }}>
                  {clinicDetails.phone.toString() ?? 'Not provided'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6} sm={12} sx={{ mt: { lg: 2, sm: 2, md: 2, xs: 0 } }}>
              <Grid item xs={12}>
                <Typography variant= "subHeading3" style={{ color: 'main.black' }}>
                Email
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant= "label" sx={{ overflowWrap: 'break-word', wordWrap: 'break-word',
                  width: '100%', maxWidth: '100%', display: 'block', whiteSpace: 'normal' }}>
                  {clinicDetails.email ?? 'Not provided'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewClinicDetails.propTypes = {
  clinicId: PropTypes.string,
  setClinicName: PropTypes.func
};

export default ViewClinicDetails;
