import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Services from '../utils/Services';
import { TextField, InputAdornment, IconButton, Typography, Stack } from '@mui/material';
import TSBackDrop from '../components/tscomponents/TSBackDrop';
import { Helmet } from 'react-helmet';
import Divider from '@mui/material/Divider';
import { validateEmailId, validateNumber, validateStringForNull } from '../utils/FieldValidations';
import { useNavigate } from 'react-router-dom';
import APIData from '../utils/APIData';
import { CookieUtils } from '../utils/CookieUtils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SignUpLogoPanel from '../components/uicomponents/SignUpLogoPanel';
import { cloneDeep } from 'lodash';
import { UiUtils } from '../utils/UiUtilFunctions';
import { API_RESPONSE_TYPE } from '../utils/EnumDefinitions';
import TSCustomServerStatus from '../components/tscomponents/TSCustomServerStatus';

const SignIn = () => {
  const navigate = useNavigate();
  const [signInDetails, setSignInDetails] = useState({
    username: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [failureCounter, setFailureCounter] = useState(0);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const [clientErrMsg, setClientErrMsg] = useState({});
  const [serverMsg, setServerMsg] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const APIToken = {
    USER_LOGIN: 'PSI01'
  };

  const validateParams = (params) => {
    const errMsg = {};
    if (!validateEmailId(params.username)) {
      if (!validateNumber(params.username)) {
        errMsg.username = 'Invalid email/phone';
      }
    }
    if (!validateStringForNull(params.password)) {
      errMsg.password = 'Invalid password';
    };
    const isValid = Object.keys(errMsg).length == 0;
    setClientErrMsg(errMsg);
    return isValid;
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.login && apiToken == APIToken.USER_LOGIN ) {
      CookieUtils.setStaffId(response.data.staff_id);
      CookieUtils.setLoginHash(response.data.login_hash);
      CookieUtils.setBannerMsg(response.data.banner_msg);
      CookieUtils.setDegree(response.data.degree);
      if (window.location.pathname === '/signin') {
        navigate('/home');
      } else {
        window.location.reload();
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    const errMsg = {};
    if (apiData == APIData.login && apiToken == APIToken.USER_LOGIN) {
      defaultMsg = 'Login failed';
      setFailureCounter(failureCounter + 1);
      if (failureCounter >= 2) {
        setIsLoginButtonDisabled(true);
      };
    }
    errMsg.message = err.message ?? defaultMsg;
    errMsg.type = API_RESPONSE_TYPE.error;
    setServerMsg(errMsg);
    setIsLoading(false);
  };

  const raiseSigninRequest = (finalParams) => {
    setServerMsg({});
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.login, params: finalParams },
      APIToken.USER_LOGIN, processSuccess, processError);
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const params = cloneDeep(signInDetails);
    try {
      if (validateParams(params)) {
        raiseSigninRequest(params);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      const errMsg = {
        message: err.message ?? 'Login failed',
        type: API_RESPONSE_TYPE.error
      };
      setServerMsg(errMsg);
      setIsLoading(false);
    }
  };

  const handleTextChange = (event) => {
    const errObj = cloneDeep(clientErrMsg);
    delete errObj[event.target.name];
    setClientErrMsg(errObj);
    setSignInDetails({ ...signInDetails, [event.target.name]: event.target.value });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>SignIn - DocKit</title>
      </Helmet>
      <Grid container sx={{ backgroundColor: 'main.primary', minHeight: '100vh' }}>
        <SignUpLogoPanel />
        <Grid item container xs={12} sm={12} md={6} sx={{ p: 2, overflowY: { xs: 'scroll', sm: 'scroll', md: 'unset' },
          minHeight: { xs: '75vh', sm: '65vh', md: '100vh' } }}>
          <Grid item container xs={12}
            sx={{ backgroundColor: 'white', borderRadius: '20px',
              px: {
                lg: 12,
                xs: 2,
                sm: 12,
                md: 8
              }
            }}>
            <Stack sx={{ width: '100%', pt: { xs: 4, sm: 5, md: 6, lg: 7.5 } }} spacing={2} >
              <Stack sx={{ width: '100%' }}>
                <Typography variant='heading'> Login </Typography>
                <Typography variant='body' sx={{ mt: 0.5 }}>Login with your registered Email or Phone Number</Typography>
              </Stack>
              <Stack sx={{ width: '100%' }}>
                <Typography variant='label' sx={{ mt: 1 }}>Email or Phone Number*</Typography>
                <TextField
                  error = {validateStringForNull(clientErrMsg.username)}
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  autoFocus
                  size="small"
                  autoComplete ='off'
                  onChange={(event) => handleTextChange(event)}
                  sx={UiUtils.getTextFieldStyle('username', clientErrMsg)}
                  helperText = {clientErrMsg.username}
                />
              </Stack>
              <Stack>
                <Typography variant='label'>Password*</Typography>
                <TextField
                  error = {validateStringForNull(clientErrMsg.password)}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  size="small"
                  autoComplete= 'new-password'
                  sx={UiUtils.getTextFieldStyle('password', clientErrMsg)}
                  helperText = {clientErrMsg.password}
                  onChange={(event) => handleTextChange(event)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleClickShowPassword}
                          sx={{ color: 'inputAdornment.color' }}
                        >
                          {showPassword ? <VisibilityOff sx={{ fontSize: 'inputAdornment.fontSize' }} /> :
                            <Visibility sx={{ fontSize: 'inputAdornment.fontSize' }}/>}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Link href="/resetpassword" sx={{ textDecoration: 'none', mt: 0.75 }}>
                  <Typography variant='hyperLinks' >Forgot password?</Typography>
                </Link>
                <TSCustomServerStatus status={serverMsg} />
                <Stack alignItems='center' sx={{ mt: 2 }}>
                  <Button
                    type='submit'
                    fullWidth
                    size="small"
                    variant="contained"
                    disabled={isLoginButtonDisabled}
                    onClick={handleSubmit}
                    sx={{ height: '2.5rem', width: '12rem', borderRadius: '2rem' }}
                  >
                    Login
                  </Button>
                </Stack>
                <Stack alignItems='center' sx={{ mt: 1 }}>
                  <Typography variant='body'>
                    New to DocKit?{' '}
                    <Link href="/signup" sx={{ textDecoration: 'none' }}>
                      Sign Up
                    </Link>
                  </Typography>
                </Stack>
                <Stack sx={{ m: 2, alignItems: 'center', display: 'flex' }}>
                  <Divider sx={{ width: { xs: '17rem', sm: '20rem', lg: '20rem' } }}>
                    <Typography variant='body' sx={{ color: 'main.textMiddleGrey', p: 1 }}> Or </Typography></Divider>
                </Stack>
                <Stack alignItems='center' >
                  <Button
                    type="submit"
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{
                      width: { xs: '17rem', sm: '20rem', lg: '20rem' },
                      height: '2.5rem',
                      borderRadius: '2rem'
                    }}
                  >
                    Login with OTP
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ mt: 2, mb: { xs: 5 },
                      width: { xs: '17rem', sm: '20rem', lg: '20rem' },
                      height: '2.5rem',
                      borderRadius: '2rem'
                    }}
                  >
                    Login with Google
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

export default SignIn;
