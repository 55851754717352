import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SignUpLogoPanel = ({ showVerificationImage }) => {
  return (
    <Grid item container xs={12} sm={12} md={6} direction="column" rowSpacing={5}>
      <Grid item
        sx={{
          display: 'flex',
          backgroundColor: 'main.primary',
          justifyContent: 'center',
          height: 'fit-content',
          pb: 3,
          mt: { xs: 1, sm: 5, md: 7.5, lg: 8 },
          position: { xs: 'sticky', sm: 'sticky', lg: 'static' },
          top: 0,
          zIndex: 1000
        }}
      >
        <Box
          component="img"
          sx={{
            height: '8rem',
            maxHeight: { xs: '3.5rem', sm: '5rem', md: '7rem' }
          }}
          alt='DocKit'
          src='/static/images/Logo.png'
        />
      </Grid>
      <Grid item container direction="column" alignItems="center"
        sx={{
          height: 'fit-content',
          display: 'flex'
        }}
      >
        <Grid item>
          <Box
            component="img"
            sx={{
              height: '15rem',
              maxHeight: { xs: '5rem', sm: '8rem', md: '13rem' },
              mt: { sm: -3, md: 0 }
            }}
            alt="DocKit"
            src={showVerificationImage == true ?
              '/static/images/SignupPanelBG2.png': '/static/images/SignupPanelBG.png'}
          />
        </Grid>
        <Grid item>
          <Typography variant="body"
            sx={{ textAlign: 'center',
              color: 'main.textLightGrey',
              display: 'flex',
              mt: { xs: 1, sm: 1, md: 3.5 },
              mb: 2,
              width: { xs: '17rem', md: '26rem', sm: '28rem' }
            }}>
              Revolutionize patient care with DocKit.<br/>
              Streamline records, appointments, and billing for a healthier, more
              efficient practice. Experience the future of healthcare administration today.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

SignUpLogoPanel.propTypes = {
  showVerificationImage: PropTypes.bool
};

export default SignUpLogoPanel;
