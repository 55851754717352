import React, { useEffect, useState } from 'react';
import Services from '../../utils/Services';
import { Utils } from '../../utils/UtilFunctions';
import { Box, Button,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  TextField,
  Typography } from '@mui/material';
import TSBackDrop from '../tscomponents/TSBackDrop';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { validateDate, validateStringForNull } from '../../utils/FieldValidations';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import styled from '@emotion/styled';
import { cloneDeep } from 'lodash';
import APIData from '../../utils/APIData';
import { CookieUtils } from '../../utils/CookieUtils';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const PaperBase = styled(Paper)(()=>({
  height: 'fit-content'
}));

const ManageDoctorDetails = () => {
  const [doctorDetails, setDoctorDetails] = useState({
    degree: '',
    specialization: '',
    registration_no: '',
    medical_council: '',
    registration_date: null,
    valid_until: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDoctorDetailsDialogOpen, setIsDeleteDoctorDetailsDialogOpen] = useState(false);
  const [originalValue, setOriginalValue] = useState(undefined);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);

  useEffect(() => {
    raiseGetDoctorDetailsRequest();
  }, []);

  const APIToken = {
    GET_DOCTOR_DETAILS: 'CMDD01',
    CREATE_DOCTOR_DETAILS: 'CMDD02',
    MODIFY_DOCTOR_DETAILS: 'CMDD03',
    DELETE_DOCTOR_DETAILS: 'CMDD04'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const updateLoginHashCookieUtils = (degree) => {
    CookieUtils.setDegree(degree);
    CookieUtils.setBannerMsg(undefined);
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getDoctorDetails && apiToken == APIToken.GET_DOCTOR_DETAILS ) {
      if (response.data == undefined) {
        setIsUpdateRequest(false);
        setOriginalValue(doctorDetails);
      } else {
        const obj = {
          degree: response.data.degree,
          specialization: response.data.specialization,
          registration_no: response.data.registration_no,
          medical_council: response.data.medical_council,
          registration_date: validateStringForNull(response.data.registration_date) ?
            moment(response.data.registration_date.value, 'yyyy-MM-DD') : null,
          valid_until: validateStringForNull(response.data.valid_until) ? moment(response.data.valid_until.value, 'yyyy-MM-DD') : null
        };
        setIsUpdateRequest(true);
        setDoctorDetails(obj);
        setOriginalValue(obj);
      }
      setIsLoading(false);
    } else if (apiData == APIData.createDoctorDetails && apiToken == APIToken.CREATE_DOCTOR_DETAILS ) {
      showSnackBar('success', response.message ?? 'Doctor Details Created successfully');
      updateLoginHashCookieUtils(callbackValues.degree);
      raiseGetDoctorDetailsRequest();
    } else if (apiData == APIData.modifyDoctorDetails && apiToken == APIToken.MODIFY_DOCTOR_DETAILS ) {
      showSnackBar('success', response.message ?? 'Doctor details updated successfully');
      raiseGetDoctorDetailsRequest();
    } else if (apiData == APIData.deleteDoctorDetails && apiToken == APIToken.DELETE_DOCTOR_DETAILS ) {
      showSnackBar('success', response.message ?? 'Doctor details deleted successfully');
      setIsDeleteDoctorDetailsDialogOpen(false);
      raiseGetDoctorDetailsRequest();
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getDoctorDetails && apiToken == APIToken.GET_DOCTOR_DETAILS) {
      defaultMsg ='Failed to retrieve Doctor Details';
    } else if (apiData == APIData.createDoctorDetails && apiToken == APIToken.CREATE_DOCTOR_DETAILS ) {
      defaultMsg = 'Failed to create Doctor details';
    } else if (apiData == APIData.modifyDoctorDetails && apiToken == APIToken.MODIFY_DOCTOR_DETAILS ) {
      defaultMsg = 'Failed to Update Doctor details';
    } else if (apiData == APIData.deleteDoctorDetails && apiToken == APIToken.DELETE_DOCTOR_DETAILS ) {
      defaultMsg = 'Failed to delete doctor details';
      setIsDeleteDoctorDetailsDialogOpen(false);
      setDoctorDetails(originalValue);
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetDoctorDetailsRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getDoctorDetails },
      APIToken.GET_DOCTOR_DETAILS, processSuccess, processError);
  };

  const raiseCreateDoctorDetailsRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.createDoctorDetails, params: finalParams },
      APIToken.CREATE_DOCTOR_DETAILS, processSuccess, processError, { degree: finalParams.degree });
  };

  const raiseUpdateDoctorDetailsRequest = (finalParams) => {
    setIsLoading(true);
    if (Object.keys(finalParams).length == 0) {
      throw new Error('Nothing to update');
    }
    Services.sendBackendRequest({ apiData: APIData.modifyDoctorDetails, params: finalParams },
      APIToken.MODIFY_DOCTOR_DETAILS, processSuccess, processError);
  };

  const getModifiedParams = (params) => {
    const modifiedParams = {};
    if (originalValue.degree != params.degree) {
      modifiedParams.degree = params.degree;
    };
    if (originalValue.specialization != params.specialization) {
      modifiedParams.specialization = params.specialization;
    };
    if (originalValue.registration_no != params.registration_no) {
      modifiedParams.registration_no = params.registration_no;
    };
    if (originalValue.medical_council != params.medical_council) {
      modifiedParams.medical_council = params.medical_council;
    };
    if (moment(originalValue.registration_date).format('yyyy-MM-DD') != moment(params.registration_date).format('yyyy-MM-DD')) {
      modifiedParams.registration_date = moment(params.registration_date).format('yyyy-MM-DD');
    };
    if (moment(originalValue.valid_until).format('yyyy-MM-DD') != moment(params.valid_until).format('yyyy-MM-DD')) {
      modifiedParams.valid_until = moment(params.valid_until).format('yyyy-MM-DD');
    };
    return modifiedParams;
  };

  const validateDoctorDetailsParams = (params) => {
    if (!validateStringForNull(params.degree)) {
      throw new Error('Provide valid Degree');
    }
    if (!validateStringForNull(params.specialization)) {
      throw new Error('Provide valid Specialization');
    }
    if (!validateStringForNull(params.registration_no)) {
      throw new Error('Provide valid Registration Number');
    }
    if (!validateStringForNull(params.medical_council)) {
      throw new Error('Provide valid Medical Council Name');
    }
    if (!validateDate(params.registration_date)) {
      throw new Error('Invalid Registration Date');
    }
    if (!validateDate(params.valid_until)) {
      throw new Error('Invalid Date - Valid Until');
    }
  };

  const handleDoctorDetailsSaveClick = () => {
    try {
      const params = cloneDeep(doctorDetails);
      if (isUpdateRequest == false) {
        validateDoctorDetailsParams(params);
        params.registration_date = moment(params.registration_date).format('yyyy-MM-DD');
        params.valid_until = moment(params.valid_until).format('yyyy-MM-DD');
        raiseCreateDoctorDetailsRequest(params);
      } else {
        validateDoctorDetailsParams(params);
        const finalParams = getModifiedParams(params);
        raiseUpdateDoctorDetailsRequest(finalParams);
      }
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to update Doctor Details');
      setIsLoading(false);
    };
  };

  const handleDoctorDetailsDeleteClick = () => {
    setIsDeleteDoctorDetailsDialogOpen(false);
    try {
      raiseDeleteDoctorDetailsRequest();
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Update Doctor Details');
      setIsLoading(false);
    };
  };

  const raiseDeleteDoctorDetailsRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.deleteDoctorDetails },
      APIToken.DELETE_DOCTOR_DETAILS, processSuccess, processError);
  };

  const handleTextChange = (event) => {
    setDoctorDetails({ ...doctorDetails, [event.target.name]: event.target.value });
  };

  const handleDoctorDetailsDeleteDialog = (isOpen) => {
    setIsDeleteDoctorDetailsDialogOpen(isOpen);
  };

  const handleResetClick = () => {
    setDoctorDetails(originalValue);
  };

  const getDeleteDoctorDetailsConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isDeleteDoctorDetailsDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Delete Doctor Information</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to remove your details ?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
            <Button
              color='primary'
              size='small'
              onClick={() =>handleDoctorDetailsDeleteDialog(false)}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => handleDoctorDetailsDeleteClick()}
            >
              Remove
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction='column'
      >
        <PaperBase
          elevation={12}
          sx={{
            p: 5,
            pb: 0,
            m: 5,
            mb: 0
          }}
        >
          <Grid item>
            <Typography component="h5" variant="h5" >
            Doctor Details
            </Typography>
          </Grid>
          <Grid item container >
            <Grid item xs={12} justifyContent='space-between' alignItems='center' columnSpacing={2} >
              <TextField
                margin="normal"
                fullWidth
                id="degree"
                label="Degree"
                name="degree"
                autoComplete ='off'
                value={doctorDetails.degree}
                onChange={(event) => {handleTextChange(event);}}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="specialization"
                label="Specialization"
                name="specialization"
                autoComplete ='off'
                value={doctorDetails.specialization}
                onChange={(event) => {handleTextChange(event);}}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="Registration No"
                label="Registration No"
                name="registration_no"
                autoComplete ='off'
                value={doctorDetails.registration_no}
                onChange={(event) => {handleTextChange(event);}}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="Medical Council"
                label="Medical Council"
                name="medical_council"
                autoComplete ='off'
                value={doctorDetails.medical_council}
                onChange={(event) => {handleTextChange(event);}}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Registration Date"
                  format="DD/MM/YYYY"
                  sx={{ width: '100%' }}
                  value={doctorDetails.registration_date}
                  onChange={(newValue) => {setDoctorDetails({ ...doctorDetails, registration_date: newValue });}}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Valid Until"
                  format="DD/MM/YYYY"
                  sx={{ width: '100%' }}
                  value={doctorDetails.valid_until}
                  onChange={(newValue) => {setDoctorDetails({ ...doctorDetails, valid_until: newValue });}}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                type="submit"
                size="small"
                color='primary'
                variant="outlined"
                sx={{ m: 2, mr: 0 }}
                onClick={handleResetClick}
              >
                Reset
              </Button>
            </Grid>
            {isUpdateRequest == true ? (
              <Grid item>
                <Button
                  type="submit"
                  size="small"
                  color='error'
                  variant="outlined"
                  sx={{ m: 2, mr: 0 }}
                  onClick={() =>handleDoctorDetailsDeleteDialog(true)}
                >
                  Delete
                </Button>
              </Grid>
            ) : ''}
            <Grid item>
              <Button
                type="submit"
                size="small"
                color='primary'
                variant="contained"
                sx={{ m: 2, mr: 0 }}
                onClick={handleDoctorDetailsSaveClick}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </PaperBase>
      </Grid>
      {getDeleteDoctorDetailsConfirmationDialog()}
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

export default ManageDoctorDetails;
