import { Box, Paper, Table, TableBody, TableCell,
  tableCellClasses,
  TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Services from '../../utils/Services';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import TSBackDrop from '../tscomponents/TSBackDrop';
import APIData from '../../utils/APIData';
import { Utils } from '../../utils/UtilFunctions';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

const ViewStaffBasicDetails = ({ clinicId, staffId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clinicDetails, setClinicDetails] = useState({});
  const APIToken = {
    GET_CLINIC_BASIC_DETAILS: 'CSCD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    if ( clinicId != undefined && staffId != undefined ) {
      raiseGetClinicDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getStaffDetails && apiToken == APIToken.GET_CLINIC_BASIC_DETAILS ) {
      setClinicDetails(response.data.basic_details ?? {});
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.getStaffDetails && apiToken == APIToken.GET_CLINIC_BASIC_DETAILS ) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Failed to retrieve Clinic Details'
      });
    }
    setIsLoading(false);
  };

  const raiseGetClinicDetails = () => {
    const params = 'fetch_modules=basic_details';
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getStaffDetails, uriValues: [clinicId, staffId], params: params },
      APIToken.GET_CLINIC_BASIC_DETAILS, processSuccess, processError);
  };

  const getTableRow = (key, value) => {
    if (value != undefined) {
      return (
        <TableRow >
          <TableCell style={{ width: '50%' }}>
            {key}
          </TableCell>
          <TableCell style={{ width: '50%' }}>
            {value.toString()}
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow></TableRow>
      );
    }
  };

  return (
    <React.Fragment>
      <Box>
        <PaperBase elevation = {12} sx={{
          flexGrow: 1,
          m: 5
        }} >
          <InputLabel sx={{ p: 2 }}>
            Staff Details
          </InputLabel>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableBody>
              {getTableRow('Staff Name', clinicDetails.staff_name)}
              {getTableRow('DOB', clinicDetails.dob)}
              {getTableRow('Age', clinicDetails.age != undefined ? clinicDetails.age.display : undefined)}
              {getTableRow('Phone', clinicDetails.phone)}
              {getTableRow('Email', clinicDetails.email)}
              {getTableRow('Is Active', clinicDetails.is_active)}
            </TableBody>
          </Table>
        </PaperBase>
      </Box>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewStaffBasicDetails.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string
};

export default ViewStaffBasicDetails;
