import {
  Box,
  Paper,
  Typography
} from '@mui/material';
import styled from '@emotion/styled';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import CreateAppointment from '../components/Appointment/CreateAppointment';
import { Helmet } from 'react-helmet';

const PaperBase = styled(Paper)(()=>({
  width: 'fit-content',
  height: 'fit-content'
}));

const CreateNewAppointment = () => {
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  return (
    <React.Fragment>
      <Helmet>
        <title>Create Appointment - DocKit</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        pt: 10,
        px: 2,
        pb: 10,
        justifyContent: 'center',
        display: 'flex'
      }}>
        <PaperBase elevation={5} sx={{ p: 5 }}>
          <Typography variant="h4" align='center' sx={{ backgroundColor: 'white' }}>
            Create New Appointment
          </Typography>
          <CreateAppointment clinicId={clinicId}/>
        </PaperBase>
      </Box>
    </React.Fragment>
  );
};

export default CreateNewAppointment;
