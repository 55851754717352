import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import APIData from '../../utils/APIData';
import { validateStringForNull } from '../../utils/FieldValidations';
import { CircleRounded } from '@mui/icons-material';
import TSHomeBackDrop from '../tscomponents/TSHomeBackDrop';

const ViewHolidayHours = ({ clinicId, staffId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [holidayTimings, setHolidayTimings] = useState([]);
  const APIToken = {
    GET_HOLIDAY_HOURS: 'CSHT01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetHolidayOpenHoursDetails();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if ( apiToken == APIToken.GET_HOLIDAY_HOURS ) {
      if (apiData == APIData.getStaffHolidayHoursDetails) {
        setHolidayTimings(response.data ?? []);
      } else if (apiData == APIData.getClinicDetails) {
        setHolidayTimings(response.data.holiday_hours ?? []);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if ( apiToken == APIToken.GET_HOLIDAY_HOURS ) {
      if (apiData == APIData.getStaffHolidayHoursDetails || apiData == APIData.getClinicDetails) {
        setSnackBarStatus({
          open: true,
          severity: 'error',
          message: err.message ?? 'Failed to retrieve Holiday Open Hours'
        });
      }
    }
    setIsLoading(false);
  };

  const raiseGetHolidayOpenHoursDetails = () => {
    setIsLoading(true);
    if (validateStringForNull(staffId)) {
      Services.sendBackendRequest({ apiData: APIData.getStaffHolidayHoursDetails, uriValues: [clinicId, staffId] },
        APIToken.GET_HOLIDAY_HOURS, processSuccess, processError);
    } else {
      const params = 'fetch_modules=holiday_hours';
      Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
        APIToken.GET_HOLIDAY_HOURS, processSuccess, processError);
    }
  };

  const getHolidayHours = () => {
    const data = [];
    if (holidayTimings.length > 0) {
      const addedDates = [];
      holidayTimings.forEach((obj, i) => {
        if (addedDates.includes(obj.special_date.value)) {
          return;
        }
        addedDates.push(obj.special_date.value);
        const filterDays = holidayTimings.filter((obj1) => obj1.special_date.value == obj.special_date.value);
        const openDay = (
          <Grid item container xs={12} key={`${i}_timings`}
            sx={{ borderBottom: '0.75px solid #E0E0E0', py: 2, width: '100%', ml: 0 }}>
            <Grid item xs={5} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} >
              <CircleRounded
                sx={{
                  fontSize: 'small',
                  color: obj.start_time !== undefined ? '#84C602' : '#ff3300'
                }}
              />
              <Typography variant="subHeading2" sx={{ display: 'flex', ml: 1, color: 'main.black' }}>
                {obj.special_date.display}
              </Typography>
            </Grid>
            <Grid item container xs={7} sm={6} direction='row' rowSpacing={1.5}>
              {filterDays.map((filterDay, index) => (
                <Grid item xs={12} key={`${i}_${index}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                  {filterDay.start_time !== undefined && (
                    <Grid item>
                      <Typography variant='subHeading2' style={{ whiteSpace: 'nowrap' }}
                        color= 'main.black'>{filterDay.start_time.display}</Typography>
                    </Grid>
                  )}
                  {filterDay.start_time !== undefined && filterDay.end_time !== undefined && (
                    <Grid item>
                      <Divider
                        sx={{
                          width: '15px',
                          height: '2px',
                          borderStyle: 'groove',
                          borderBottomWidth: 'thin',
                          backgroundColor: 'main.textLightGrey',
                          mx: 1.5
                        }} />
                    </Grid>
                  )}
                  {filterDay.end_time !== undefined && (
                    <Grid item>
                      <Typography variant='subHeading2' style={{ whiteSpace: 'nowrap' }}
                        color= 'main.black'>{filterDay.end_time.display}</Typography>
                    </Grid>
                  )}
                  {!filterDay.start_time && !filterDay.end_time && (
                    <Typography variant='subHeading2' color= 'main.black'>Closed</Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        );
        data.push(openDay);
      });
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="subHeading1" sx={{ display: 'flex', justifyContent: 'center', color: 'main.black', mt: 8 }}>
            {isLoading ? 'Loading...' : 'No Information Available'}
          </Typography>
        </Grid>
      );
    }
    return data;
  };

  return (
    <React.Fragment>
      <Grid container sx= {{ mt: 1 }}>
        {getHolidayHours()}
      </Grid>
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewHolidayHours.propTypes = {
  clinicId: PropTypes.string,
  staffId: PropTypes.string
};

export default ViewHolidayHours;
