import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { TextField, InputAdornment, IconButton, Typography, Stack, Box, useMediaQuery } from '@mui/material';
import TSBackDrop from '../components/tscomponents/TSBackDrop';
import { validateStringForNull, validateEmailId, validateNumber } from '../utils/FieldValidations';
import React, { useEffect, useState, useRef } from 'react';
import Services from '../utils/Services';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import APIData from '../utils/APIData';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SignUpLogoPanel from '../components/uicomponents/SignUpLogoPanel';
import ReCAPTCHA from 'react-google-recaptcha';
import { UiUtils } from '../utils/UiUtilFunctions';
import { API_RESPONSE_TYPE } from '../utils/EnumDefinitions';
import TSCustomServerStatus from '../components/tscomponents/TSCustomServerStatus';
import { Utils } from '../utils/UtilFunctions';

const SignUp = () => {
  const navigate = useNavigate();
  const isXsScreen = useMediaQuery(UiUtils.xsScreenMaxWidth);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);
  const [isSignupButtonDisabled, setIsSignupButtonDisabled] = useState(true);
  const [signupDetails, setSignupDetails] = useState({
    staff_name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    email_otp: ['D', 'K', '', '', '', '', '', '']
  });
  const otpFieldsRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  // Disable all input fields when its in invite mode
  const [isInputFieldsDisabled, setIsInputFieldsDisabled] = useState(false);
  const inviteId = searchParams.get('invite_id');
  const [activeStep, setActiveStep] = useState(0);

  const [resendOTPTimer, setResendOTPTimer] = useState(60);
  const [showResendOtpLink, setShowResendOtpLink] = useState(true);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [otpSendEmailId, setOtpSendEmailId] = useState(undefined);

  const [isVerifyButtonDisable, setIsVerifyButtonDisable] = useState(false);
  const [verifyAttempts, setVerifyAttempts] = useState(0);

  const [clientErrMsg, setClientErrMsg] = useState({});
  const [serverMsg, setServerMsg] = useState({});

  const APIToken = {
    GET_INVITE: 'PSU01',
    GET_USER_SIGN_UP_OTP: 'PSU02',
    SIGN_UP: 'PSU03'
  };

  useEffect(() => {
    const timer =
      !showResendOtpLink && resendOTPTimer > 0 ? setInterval(() => setResendOTPTimer((prevCounter) => prevCounter - 1), 1000) : null;
    if (!showResendOtpLink && resendOTPTimer === 0) {
      setShowResendOtpLink(true);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [showResendOtpLink, resendOTPTimer]);

  useEffect(() => {
    setIsVerifyButtonDisable(verifyAttempts > 2);
    if (resendAttempts === 1) {
      setShowResendOtpLink(false);
    }
  }, [resendAttempts, verifyAttempts]);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    const successMsg = {};
    successMsg.type = API_RESPONSE_TYPE.success;
    if (apiData == APIData.getInvite && apiToken == APIToken.GET_INVITE ) {
      setSignupDetails({ ...signupDetails,
        staff_name: response.data.staff_name,
        phone: response.data.phone,
        email: response.data.email
      });
      setIsInputFieldsDisabled(true);
    } else if (apiData == APIData.getStaffSignUpOtp && apiToken == APIToken.GET_USER_SIGN_UP_OTP) {
      setActiveStep(1);
      setShowResendOtpLink(false);
      setOtpSendEmailId(callbackValues.emailId);
      successMsg.message = 'OTP send successfully to your email';
      setServerMsg(successMsg);
      setResendOTPTimer(callbackValues.isResend ? 120 : 60);
    } else if (apiData == APIData.signup && apiToken == APIToken.SIGN_UP) {
      successMsg.message = 'User registration successful. Redirecting to login page..';
      setServerMsg(successMsg);
      setTimeout(() => navigate('/signin'), 3000);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    const errMsg = {};
    if (apiData == APIData.getInvite && apiToken == APIToken.GET_INVITE) {
      setInviteDetails(undefined);
      defaultMsg = 'Unable to get Invite Details';
    } else if (apiData == APIData.getStaffSignUpOtp && apiToken == APIToken.GET_USER_SIGN_UP_OTP) {
      defaultMsg = 'Failed to send OTP';
    } else if (apiData == APIData.signup && apiToken == APIToken.SIGN_UP) {
      defaultMsg = 'Registration failed';
    }
    errMsg.message = err.message ?? defaultMsg;
    errMsg.type = API_RESPONSE_TYPE.error;
    setServerMsg(errMsg);
    setIsLoading(false);
  };

  const raiseGetInviteRequest = () => {
    setIsLoading(true);
    setServerMsg({});
    Services.sendBackendRequest({ apiData: APIData.getInvite, uriValues: [inviteId] },
      APIToken.GET_INVITE, processSuccess, processError);
  };

  useEffect(() => {
    if (validateStringForNull(inviteId)) {
      raiseGetInviteRequest();
    }
  }, []);

  const handleOTPTextChange = (index, value) => {
    if (value.length > 1) {
      value = value[0];
    }
    const newOtpValues = cloneDeep(signupDetails.email_otp);
    newOtpValues[index] = value;
    setSignupDetails({ ...signupDetails,
      email_otp: newOtpValues });
    if (value && index < signupDetails.email_otp.length - 1) {
      otpFieldsRefs[index + 1].current.focus();
    }
  };

  const validateParams = (inputParams) => {
    const errMsg = {};
    if (!validateStringForNull(inputParams.staff_name)) {
      errMsg.staff_name = 'Invalid Name';
    }

    if (!validateEmailId(inputParams.email)) {
      errMsg.email = 'Invalid Email Address';
    }

    if (inputParams.phone == null || !validateNumber(inputParams.phone)) {
      errMsg.phone = 'Invalid Phone Number';
    }

    if (!validateStringForNull(inputParams.password)) {
      errMsg.password = 'Invalid Password';
    }

    if (!validateStringForNull(inputParams.confirm_password)) {
      errMsg.confirm_password = 'Invalid Confirm Password';
    } else if (inputParams.password !== inputParams.confirm_password) {
      errMsg.confirm_password = 'Password Mismatched';
    }
    const isValid = Object.keys(errMsg).length == 0;
    setClientErrMsg(errMsg);
    return isValid;
  };

  const validateOTP = (inputOTP) => {
    const clientErr = {};
    let isValid = true;
    for (let i=2; i< inputOTP.length; i++ ) {
      if (!validateNumber(inputOTP[i])) {
        if (clientErr.otp == undefined) {
          clientErr.otp = [];
        }
        clientErr.otp.push(i);
        isValid = false;
      }
    }
    if (isValid == false) {
      clientErr.otp_msg = 'Invalid OTP';
    }
    setClientErrMsg(clientErr);
    return isValid;
  };

  const raiseStaffSignUpOtpRequest = (emailId, isResend) => {
    setIsLoading(true);
    setServerMsg({});
    if (isResend) {
      setSignupDetails({ ...signupDetails,
        email_otp: ['D', 'K', '', '', '', '', '', '']
      });
      setResendAttempts((prevAttempts) => prevAttempts + 1);
      setVerifyAttempts(0);
    }
    const finalParams = {
      email: emailId
    };
    Services.sendBackendRequest({ apiData: APIData.getStaffSignUpOtp, params: finalParams },
      APIToken.GET_USER_SIGN_UP_OTP, processSuccess, processError, { emailId: emailId, isResend: isResend });
  };

  const raiseSignupRequest = (inputParams) => {
    setIsLoading(true);
    setServerMsg({});
    setVerifyAttempts((prevAttempts) => prevAttempts + 1);
    Services.sendBackendRequest({ apiData: APIData.signup, params: inputParams },
      APIToken.SIGN_UP, processSuccess, processError);
  };

  const handleVerifyClick = () => {
    setIsLoading(true);
    try {
      const inputParams = cloneDeep(signupDetails);
      let otpValue = '';
      if (validateOTP(inputParams.email_otp)) {
        otpValue = inputParams.email_otp.join('');
        inputParams.email_otp = otpValue;
        delete inputParams.confirm_password;
        if (validateStringForNull(inviteId)) {
          inputParams.invite_id = inviteId;
        }
        raiseSignupRequest(inputParams);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      const errMsg = {
        message: err.message ?? 'Registration failed',
        type: API_RESPONSE_TYPE.error
      };
      setServerMsg(errMsg);
      setIsLoading(false);
    }
  };

  const handleSignUpClick = () => {
    try {
      const inputParams = cloneDeep(signupDetails);
      if (validateParams(inputParams)) {
        if (otpSendEmailId === inputParams.email) {
          setActiveStep(1);
        } else {
          setResendAttempts(0);
          setVerifyAttempts(0);
          raiseStaffSignUpOtpRequest(inputParams.email, false);
        }
      }
    } catch (err) {
      const errMsg = {
        message: err.message ?? 'Failed to send OTP',
        type: API_RESPONSE_TYPE.error
      };
      setServerMsg(errMsg);
      setIsLoading(false);
    }
  };

  const handleTextChange = (event) => {
    const errObj = cloneDeep(clientErrMsg);
    delete errObj[event.target.name];
    setClientErrMsg(errObj);
    setSignupDetails({ ...signupDetails, [event.target.name]: event.target.value });
  };

  const handleGoBackClick = () => {
    setIsSignupButtonDisabled(true);
    setActiveStep(0);
  };

  const passwordStrength = Utils.evaluatePasswordStrength(signupDetails.password);
  const passwordStrengthObj = UiUtils.getPasswordStrengthTextAndImage(passwordStrength);

  const getSignUpContent = () => {
    return (
      <Grid item container xs={12}
        sx={{ backgroundColor: 'white', borderRadius: '20px',
          px: {
            lg: 12,
            xs: 2,
            sm: 12,
            md: 8
          }
        }}>
        <Stack sx={{ width: '100%', pt: { xs: 4, md: 6, lg: 5 } }} spacing={2}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='heading'> Sign Up </Typography>
            <Typography variant='body' sx={{ mt: 0.5 }}>Fill up the details below to get started.</Typography>
          </Stack>
          <Stack sx={{ width: '100%', mt: 1 }}>
            <Typography variant='label'>Name*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.staff_name)}
              margin="normal"
              required
              fullWidth
              id="name"
              disabled={isInputFieldsDisabled}
              name="staff_name"
              autoFocus
              size="small"
              autoComplete ='off'
              value={signupDetails.staff_name}
              onChange={(event) => {handleTextChange(event);}}
              sx={UiUtils.getTextFieldStyle('staff_name', clientErrMsg)}
              helperText = {clientErrMsg.staff_name}
            />
          </Stack>
          <Stack>
            <Typography variant='label'>Email*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.email)}
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              size="small"
              disabled={isInputFieldsDisabled}
              value={signupDetails.email}
              sx={UiUtils.getTextFieldStyle('email', clientErrMsg)}
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.email}
            />
          </Stack>
          <Stack>
            <Typography variant='label'>Phone Number*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.phone)}
              margin="normal"
              required
              fullWidth
              id="phone"
              name="phone"
              type='tel'
              size="small"
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('phone', clientErrMsg)}
              disabled={isInputFieldsDisabled}
              value={signupDetails.phone}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.phone}
            />
          </Stack>
          <Stack>
            <Typography variant='label'>Password*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.password)}
              margin="normal"
              required
              fullWidth
              size="small"
              name="password"
              autoComplete= 'new-password'
              type={showPassword ? 'text' : 'password'}
              id="password"
              sx={UiUtils.getTextFieldStyle('password', clientErrMsg)}
              value={signupDetails.password}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {validateStringForNull(signupDetails.password) ?
                      <img src={passwordStrengthObj.indicatorImage} width='58px' /> : ''}
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                      sx={{ color: 'inputAdornment.color' }}
                    >
                      {showPassword ? <VisibilityOff sx={{ fontSize: 'inputAdornment.fontSize' }}/> :
                        <Visibility sx={{ fontSize: 'inputAdornment.fontSize' }} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {validateStringForNull(signupDetails.password) ? (
              <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <img
                    src={passwordStrengthObj.textImage}
                    style={{ marginRight: '5px', height: '0.8 rem', width: 'auto', verticalAlign: 'middle' }}
                  />
                  <Typography variant='body' style={{ color: passwordStrengthObj.color, verticalAlign: 'middle' }}>
                    {passwordStrengthObj.text}
                  </Typography>
                </Box>
              </React.Fragment>
            ) : '' }
          </Stack>
          <Stack>
            <Typography variant='label'>Confirm Password*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.confirm_password)}
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              type={showConfirmPassword ? 'text' : 'password'}
              size="small"
              autoComplete= 'new-password'
              id="confirm_password"
              sx={ UiUtils.getTextFieldStyle('confirm_password', clientErrMsg)}
              value={signupDetails.confirm_password}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.confirm_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPasswordClick}
                      onMouseDown={handleClickShowConfirmPasswordClick}
                      sx={{ color: 'inputAdornment.color' }}
                    >
                      {showConfirmPassword ? <VisibilityOff sx={{ fontSize: 'inputAdornment.fontSize' }}/> :
                        <Visibility sx={{ fontSize: 'inputAdornment.fontSize' }} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Stack>
          <Stack>
            <ReCAPTCHA
              key={activeStep}
              sitekey='6Lc3-H4lAAAAAK1tql_SSIDWFQ7HOBPuSLFNnbC2'
              data-type="image"
              onChange={() =>setIsSignupButtonDisabled(false)}
            />
          </Stack>
          <TSCustomServerStatus status={serverMsg} />
          <Stack alignItems='center' sx={{ mt: 2 }}>
            <Button
              type="submit"
              fullWidth
              size="small"
              variant="contained"
              onClick={handleSignUpClick}
              disabled={isSignupButtonDisabled}
              sx={{ height: '2.5rem', width: '12rem', borderRadius: '2rem' }}
            >
              Get Started
            </Button>
          </Stack>
          <Stack alignItems='center' sx={{ mt: 1 }}>
            <Typography variant='label' sx={{ mb: { xs: 5 } }}>
              Existing user?{' '}
              <Typography variant='hyperLinks'>
                <Link href="/login" sx={{ textDecoration: 'none' }}>
                  Log In
                </Link>
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    );
  };

  const getOtpConfirmationContent = () => {
    return (
      <Grid item container xs={12}
        sx={{ backgroundColor: 'white', borderRadius: '15px',
          px: {
            lg: 12,
            xs: 2,
            sm: 12,
            md: 8
          }
        }}>
        <Stack sx={{ width: '100%', pt: { xs: 4, md: 6, lg: 7.5 } }} spacing={2}>
          <Stack display= 'flex' alignItems='flex-start'>
            <IconButton
              edge="start"
              color="main.primary"
              onClick={handleGoBackClick}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='heading'>Verification</Typography>
            <Typography variant='body' sx={{ mt: 0.5 }}>We have send a verification code to your registered
              phone number and Email</Typography>
          </Stack>
          <Stack direction='row' spacing={1} sx={{ display: 'flex', justifyContent: 'space-evenly' }} >
            {signupDetails.email_otp.map((value, index) => (
              <Stack key={index} >
                <TextField
                  error = {clientErrMsg.otp != undefined && clientErrMsg.otp.includes(index)}
                  inputRef={otpFieldsRefs[index]}
                  margin="normal"
                  required
                  fullWidth
                  id={`otp-${index}`}
                  name={`otp_${index}`}
                  autoFocus={index === 2}
                  disabled = {index == 0 || index == 1 ? true : false}
                  size="small"
                  autoComplete="off"
                  value={value}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  inputProps={{
                    style: {
                      height: isXsScreen ? '1rem' : '1.5rem',
                      width: isXsScreen ? '1rem' : '1.5rem',
                      textAlign: 'center',
                      fontSize: isXsScreen? 'medium' :'large'
                    }
                  }}
                  onChange={(event) => handleOTPTextChange(index, event.target.value)}
                />
              </Stack>
            ))}
          </Stack>
          <Stack>
            {clientErrMsg.otp_msg != undefined ? (
              <Typography variant='errorText'>
                {clientErrMsg.otp_msg}
              </Typography>
            ) : ''}
          </Stack>
          <TSCustomServerStatus status={serverMsg} />
          <Stack alignItems='center'>
            <Button
              type="button"
              fullWidth
              variant="contained"
              size="small"
              onClick={handleVerifyClick}
              disabled={signupDetails.email_otp.some((value) => !value) || isVerifyButtonDisable}
              sx={{ height: '2.5rem', width: '12rem', borderRadius: '2rem' }}
            >
              Verify
            </Button>
          </Stack>
          <Stack alignItems='center'>
            {resendAttempts <= 1 ? (
              activeStep === 1 && showResendOtpLink ? (
                <Typography variant='label' sx={{ mb: { xs: 5 } }}>
                  Didn&apos;t get code?{' '}
                  <Typography variant='hyperLinks'>
                    <Link
                      onClick={() => {raiseStaffSignUpOtpRequest(signupDetails.email, true);}}
                      sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                      Resend Code
                    </Link>
                  </Typography>
                </Typography>
              ) : (
                <Typography variant="body" color='main.primary'>
                  {`Try again in ${resendOTPTimer} seconds`}
                </Typography>
              )
            ) : ''}
          </Stack>
        </Stack>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>SignUp - DocKit</title>
      </Helmet>
      <Grid
        container
        sx={{ backgroundColor: 'main.primary', minHeight: '100vh' }}
      >
        <SignUpLogoPanel showVerificationImage={activeStep == 1} />
        <Grid item container xs={12} sm={12} md={6} sx={{ p: 2, overflowY: { xs: 'scroll', sm: 'scroll', md: 'unset' },
          minHeight: { xs: '75vh', sm: '65vh', md: '100vh' } }}>
          {activeStep === 0 ? getSignUpContent() : getOtpConfirmationContent()}
        </Grid>
      </Grid>
      <TSBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

export default SignUp;
