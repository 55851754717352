import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SummaryChart = ({ title, chartData }) => {
  const theme = useTheme();

  const getLabels = () =>{
    const labels = [];
    chartData.map((x) => labels.push(x.op_date.display));
    return labels;
  };

  const getData = () =>{
    const data = [];
    chartData.map((x) => data.push(x.consulted_appointments));
    return data;
  };

  const getChartData = () => {
    const data = {};
    data.datasets = [
      {
        label: 'Patient Count',
        data: getData(),
        backgroundColor: '#2C74B3'
      }
    ];
    data.labels = getLabels();
    return data;
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom'
      },
      datalabels: {
        display: true,
        color: theme.palette.text.primary,
        anchor: 'end',
        offset: -20,
        align: 'start'
      },
      title: {
        display: true
      }
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title= {title}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <Bar
            data={getChartData()}
            options={options}
            plugins={[ChartDataLabels]}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

SummaryChart.propTypes = {
  chartData: PropTypes.array,
  title: PropTypes.string
};

export default SummaryChart;
