import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Container,
  Grid,
  Typography,
  Button,
  styled,
  StepConnector,
  stepConnectorClasses,
  IconButton,
  Box,
  useMediaQuery
} from '@mui/material';
import SetupClinicBasicDetails from '../components/setupClinic/SetupClinicBasicDetails';
import SetupClinicContactDetails from '../components/setupClinic/SetupClinicContactDetails';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { UiUtils } from '../utils/UiUtilFunctions';
import SetupClinicOpenHours from '../components/setupClinic/SetupClinicOpenHours';
import SetupClinicHolidayTimings from '../components/setupClinic/SetupClinicHolidayTimings';

const CustomStepIconRoot = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center'
}));

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} >
      {completed ? (
        <CheckCircleOutlineIcon sx={{ color: 'passwordStrength.green', backgroundColor: 'main.background' }} />
      ) : active ? (
        <RadioButtonCheckedIcon sx={{ color: 'main.primary', backgroundColor: 'main.background' }} />
      ) : (
        <RadioButtonUncheckedIcon sx={{ color: 'main.textMiddleGrey', backgroundColor: 'main.background', borderRadius: '50%' }} />
      )}
    </CustomStepIconRoot>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

const AddClinic = () => {
  const isXsScreen = useMediaQuery(UiUtils.xsScreenMaxWidth);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [createdClinicId, setCreatedClinicId] = useState();
  const [screenWidth] = UiUtils.useWindowSize();

  const getSteps = () => {
    return [
      'Basic Information',
      'Contact Information',
      'Working Hours',
      'Holiday Hours',
      'Complete'
    ];
  };
  const steps = getSteps();

  const onSuccessfulClinicCreation = (clinicId) => {
    setCreatedClinicId(clinicId);
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleDone = () => {
    navigate(`/clinic?clinicId=${createdClinicId}`);
  };

  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#205295'
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#84C602'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'main.textMiddleGrey',
      borderStyle: 'dotted',
      ...(isXsScreen ?
        {
          borderTopWidth: '0px',
          minWidth: screenWidth < 380 ? '2rem' : '3.1rem',
          borderLeft: 'none',
          marginTop: '-1px',
          marginLeft: screenWidth < 380 ? '-1px' : '-3px'
        } :
        {
          borderLeftWidth: '3px',
          minHeight: '4rem',
          borderTop: 'none',
          borderBottom: 'none',
          borderRight: 'none',
          marginLeft: '-1px',
          marginTop: '-5px',
          marginBottom: '8px'
        })
    }
  }));

  const getCompletionContent = () => {
    return (
      <Container>
        <Grid
          container
          sx={{ pt: 4 }}>
          <Grid item xs={12} display='flex' justifyContent='center' alignItems='center'>
            <Box
              component="img"
              sx={{
                height: { xs: '8rem', sm: '10rem', md: '15rem' }
              }}
              alt='DocKit'
              src='/static/images/createclinicsuccess.png'
            />
          </Grid>
          <Grid item xs={12} display='grid' justifyContent='center' sx={{ mb: 3, mt: 2 }}>
            <Typography variant="subHeading1" align='center'>
              Hooray! <br/> You have finished setting up your clinic successfully!
            </Typography>
          </Grid>
          <Grid item xs={12} display='grid' justifyContent='center'>
            <Button
              endIcon={<ArrowForwardIcon />}
              variant='contained'
              color='primary'
              onClick={() => handleDone()}
              sx={{ height: '2.5rem', width: '12rem', borderRadius: '2rem' }}
            >
              Visit my Clinic
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const getStepContent = (step) => {
    switch (step) {
    case 0:
      return <SetupClinicBasicDetails onSuccessfulCommit={onSuccessfulClinicCreation} />;
    case 1:
      return <SetupClinicContactDetails clinicId={createdClinicId} onSuccessfulCommit={handleNext} />;
    case 2:
      return <SetupClinicOpenHours clinicId={createdClinicId} onSuccessfulCommit={handleNext} isClinicSetupInProgress={true} />;
    case 3:
      return <SetupClinicHolidayTimings clinicId={createdClinicId} onSuccessfulCommit={handleNext} isClinicSetupInProgress={true} />;
    case 4:
      return getCompletionContent();
    default:
      return 'unknown step';
    }
  };

  const handleGoBackClick = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepLabelColor = (currentStep, index) => {
    if (currentStep == index) {
      return 'main.primary';
    } else if (currentStep > index) {
      return 'main.black';
    } else {
      return 'main.textMiddleGrey';
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Setup Clinic - DocKit</title>
      </Helmet>
      <Grid container sx={{ maxHeight: { xs: 'fit-content', sm: '100%' }, height: '100%', alignContent: 'flex-start' }} >
        <Grid item xs={12} sm={3.5} md={2.5} lg={2} sx={{ display: 'flex', justifyContent: 'flex-start',
          alignItems: 'flex-start', pt: { xs: 3, sm: 6 }, px: { xs: 2, sm: 4 }, height: isXsScreen ? 'fit-content' : 'inherit',
          zIndex: 200, borderRight: 2, borderColor: 'main.textLightGrey', overflowY: { xs: 'hidden', sm: 'auto' },
          maxHeight: 'inherit', overflowX: 'hidden' }}>
          <Stepper alternativeLabel = { isXsScreen ? true : false }
            orientation= { isXsScreen ? 'horizontal' : 'vertical'} activeStep={activeStep }
            connector={<CustomStepConnector />}
            sx={{ width: isXsScreen ? '100%' : 'auto', flexDirection: isXsScreen ? 'row' : 'column' }}
          >
            {steps.map((label, index) => {
              return (
                <Step key={index} sx={{ mb: { xs: 0, sm: -2 }, mt: { xs: -1, sm: -2 }, ml: { xs: -1, sm: 0 } }}>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    sx={{
                      flexDirection: isXsScreen ? 'column' : 'row',
                      alignItems: isXsScreen ? 'center' : 'flex-start',
                      textAlign: isXsScreen ? 'center' : 'left'
                    }}
                  >
                    <Typography
                      variant='body'
                      color={getStepLabelColor(activeStep, index)}
                    >
                      {isXsScreen ? <>STEP<br/>{index + 1}</> : <>STEP {index + 1}<br/></>}
                    </Typography>
                    <Typography
                      variant='body'
                      color={getStepLabelColor(activeStep, index)}
                      display={ isXsScreen ? 'none' : 'block'}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid item xs={12} sm={8.5} md={9.5} lg={10} sx={{ overflowY: { xs: 'auto', sm: 'auto' },
          px: 3, maxHeight: { xs: 'fit-content', sm: '100%' }, pt: 4, height: { xs: '85%', sm: '100%' } }}>
          {activeStep >= 1 && activeStep <=3 ? (
            <Grid item xs={12} sx={{ mt: { xs: -2, sm: -4.5 } }}>
              <IconButton
                edge="start"
                color="main.primary"
                onClick={handleGoBackClick}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
          ) : ''}
          <Grid item xs={12} sx={{ height: '99%' }}>
            {getStepContent(activeStep)}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddClinic;
