import moment from 'moment';

class Utils {
  static getInitials(name) {
    if (name == undefined) {
      return 'A';
    }
    return name
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join('');
  }

  static getInitialStatusBarState() {
    return {
      open: false,
      severity: 'info',
      message: ''
    };
  }

  static isMobile() {
    return window.innerWidth <= 480;
  }

  static isDesktop() {
    return window.innerWidth >= 900;
  }

  static getWindowWidth() {
    return window.innerWidth;
  }

  static calculateDobYearDifference(params, showAgeField) {
    let yearDifference = 0;
    if (showAgeField == false) {
      yearDifference = moment().diff(moment(params.dob), 'year', true);
    } else {
      const age = params.age;
      const ageIn = params.age_in;
      if (ageIn == 1 ) {
        yearDifference = age/365;
      } else if (ageIn == 2) {
        yearDifference = age/52;
      } else if (ageIn == 3) {
        yearDifference = age/ 12;
      } else if (ageIn == 4) {
        yearDifference = age;
      }
    }
    return yearDifference;
  }

  static getLabelToDisplay = (input) => {
    const arr = input.split('_');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const finalString = arr.join(' ');
    return finalString;
  };

  static evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (password.match(/[a-z]/)) strength += 1;
    if (password.match(/[A-Z]/)) strength += 1;
    if (password.match(/[0-9]/)) strength += 1;
    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) strength += 1;

    return strength;
  };
}

class EnumUtils {
  static getEnumOptions(enumObj) {
    const arr = [];
    Object.keys(enumObj).forEach((key) => {
      const obj = {
        label: enumObj[key],
        value: key
      };
      arr.push(obj);
    });
    return arr;
  }
}

export { Utils, EnumUtils };
