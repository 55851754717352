import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import ManageAppointmentTabContent from './ManageAppointmentTabContent';
import ManageOPDetails from './ManageOPDetails';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import { cloneDeep } from 'lodash';

function TabPanel(props) {
  const { node, value, index } = props;
  if (value == index) {
    return (node);
  } else {
    return <React.Fragment/>;
  }
}

TabPanel.propTypes = {
  node: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const ManageAppointmentTabs = ({ isDoctor }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const getTabContent = (tabId) => {
    const selectedTab = tabs.filter((tab) => tab.id == tabId);
    if (selectedTab.length > 0) {
      return <ManageAppointmentTabContent clinicId={clinicId} opId={selectedTab[0].opId} isDoctor={isDoctor} />;
    } else {
      return <React.Fragment/>;
    }
  };

  const validateNewTabDetails = (tabDetails) => {
    try {
      const filtered = tabs.filter((obj) => obj.opId == tabDetails.op_id);
      if (filtered.length > 0) {
        throw new Error('Tab already exists with given details');
      }
      return true;
    } catch (err) {
      showSnackBar('error', err.message);
      return false;
    };
  };

  const handleManageTabClick = (tabDetails) => {
    if (tabDetails != null && tabDetails.op_id != undefined) {
      addNewTab(tabDetails);
    }
  };

  const addNewTab = (tabDetails) => {
    if (!validateNewTabDetails(tabDetails)) {
      return;
    }
    const newTab = {};
    newTab.id = tabs.length > 0 ? tabs[tabs.length - 1].id + 1 : 1;
    newTab.opId = tabDetails.op_id;
    newTab.label= tabDetails.doctor_name + ' : ' + moment(tabDetails.op_date).format('DD/MM/yyyy') + ' : ' + tabDetails.session_id;

    const tmp = cloneDeep(tabs);
    tmp.push(newTab);
    setTabs(tmp);
    setCurrentTab(newTab.id);
  };

  const handleRemoveTabClick = (id) => {
    const tmp = tabs.filter((obj) => obj.id != id);
    setTabs(tmp);
    setCurrentTab(0);
  };

  const handleTabChange = (event, newValue) => {
    if (event.target.nodeName == 'svg' || event.target.nodeName == 'path' ||newValue == 0) {
      setCurrentTab(0);
    } else {
      const objIndex = tabs.findIndex((obj) => obj.id == newValue);
      setCurrentTab(objIndex !== -1 ? newValue : 0);
    }
  };

  const getTabs = (newTabs) => {
    const tabs = newTabs.map((tab) => (
      <Tab key={tab.id}
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {tab.label}
            <CancelIcon sx={{ pl: 1 }} fontSize="medium" onClick={() => {handleRemoveTabClick(tab.id);}}/>
          </Box>
        } id={tab.id} value={tab.id}/>
    ));
    return tabs;
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          minHeight: '100%',
          width: '100%',
          alignItems: 'flex-start',
          py: 2,
          pt: 10,
          px: 2
        }}
      >
        <Tabs value={currentTab}
          sx={{ backgroundColor: 'white', width: '100%', border: '1px' }}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor="primary">
          {getTabs(tabs)}
          <Tab key={0}
            label={<AddCircleOutlineIcon />}
            id={0} value={0} />
        </Tabs>
        {tabs.map((tab) => (
          <TabPanel key={tab.id} value={currentTab} index={tab.id} node={getTabContent(tab.id)}/>
        ))}
        <TabPanel key={0} value={currentTab} index={0} node={<ManageOPDetails manageCallbackFunc={handleManageTabClick} isDoctor={isDoctor}/>}/>
      </Box>
      {getSnackbar}
    </React.Fragment>
  );
};

ManageAppointmentTabs.propTypes = {
  isDoctor: PropTypes.bool
};

export default ManageAppointmentTabs;
