import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import SearchPatientAppointment from '../components/Appointment/SearchPatientAppointment';

const SearchPatients = () => {
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  return (
    <React.Fragment>
      <Helmet>
        <title>Search Appointments - DocKit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          alignItems: 'flex-start',
          py: 2,
          pt: 10,
          px: 2,
          overflow: 'hidden'
        }}
      >
        <SearchPatientAppointment clinicId={clinicId} />
      </Box>
    </React.Fragment>
  );
};

export default SearchPatients;
