import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  IconButton
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { Utils } from '../../utils/UtilFunctions';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { validateDate, validateEmailId, validateNumber, validateStringForNull } from '../../utils/FieldValidations';
import Services from '../../utils/Services';
import { cloneDeep } from 'lodash';
import APIData from '../../utils/APIData';
import { UiUtils } from '../../utils/UiUtilFunctions';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import TSHomeBackDrop from '../tscomponents/TSHomeBackDrop';

const SetupClinicBasicDetails = ({ onSuccessfulCommit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientErrMsg, setClientErrMsg] = useState({});
  const [clinicDetails, setClinicDetails] = useState({
    clinic_name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    email: '',
    phone: '',
    website: '',
    start_date: null,
    is_doctor: false
  });

  const APIToken = {
    CREATE_CLINIC: 'CCBD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.createClinic && apiToken == APIToken.CREATE_CLINIC) {
      if (response.data.clinic_id != undefined) {
        onSuccessfulCommit(response.data.clinic_id);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.createClinic && apiToken == APIToken.CREATE_CLINIC) {
      showSnackBar('error', err.message ?? 'Clinic setup failed.');
    }
    setIsLoading(false);
  };

  const raiseRequest = (inputParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.createClinic, params: inputParams },
      APIToken.CREATE_CLINIC, processSuccess, processError);
  };

  const validateParams = (params) => {
    const errMsg = {};
    if (!validateStringForNull(params.clinic_name)) {
      errMsg.clinic_name = 'Invalid Clinic name';
    }
    if (!validateStringForNull(params.address)) {
      errMsg.address = 'Invalid Address';
    }
    if (!validateStringForNull(params.city)) {
      errMsg.city = 'Invalid City';
    }
    if (!validateStringForNull(params.state)) {
      errMsg.state = 'Invalid State';
    }
    if (!validateStringForNull(params.country)) {
      errMsg.country = 'Invalid Country';
    }
    if (!validateStringForNull(params.pincode)) {
      errMsg.pincode = 'Invalid Pincode';
    }
    if (!validateNumber(params.phone)) {
      errMsg.phone = 'Invalid Phone Number';
    }

    if (validateStringForNull(params.email) && !validateEmailId(params.email)) {
      errMsg.email = 'Invalid Email Address';
    }

    if (params.start_date != undefined) {
      if (!validateDate(params.start_date)) {
        errMsg.start_date = 'Invalid Start Date';
      } else {
        params.start_date = moment(params.start_date).format('yyyy-MM-DD');
      }
    }
    if (!validateStringForNull(params.website)) {
      delete params.website;
    }
    if (!validateStringForNull(params.email)) {
      delete params.email;
    }
    if (!validateStringForNull(params.start_date)) {
      delete params.start_date;
    }
    const isValid = Object.keys(errMsg).length == 0;
    setClientErrMsg(errMsg);
    return isValid;
  };

  const handleCreate = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(clinicDetails);
      if (validateParams(params)) {
        raiseRequest(params);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      const errMsg = {
        message: err.message ?? 'Clinic setup failed.',
        type: API_RESPONSE_TYPE.error
      };
      setServerMsg(errMsg);
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setClinicDetails({ ...clinicDetails, is_doctor: event.target.checked });
  };

  const handleTextChange = (event) => {
    const errObj = cloneDeep(clientErrMsg);
    delete errObj[event.target.name];
    setClientErrMsg(errObj);
    setClinicDetails({ ...clinicDetails, [event.target.name]: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={4}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='heading'> Basic Information </Typography>
            <Typography variant='body' sx={{ mt: 0.5 }}>Please fill up the basic information
              to set up a new clinic below.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
            <IconButton sx={{ p: 1.5,
              backgroundColor: 'main.textLightGrey',
              '&:hover': {
                backgroundColor: 'main.textLightGrey'
              }
            }}>
              <AddPhotoAlternateOutlinedIcon sx={{ fontSize: '2.5rem', backgroundColor: 'main.textLightGrey' }} />
            </IconButton>
            <Button
              variant='outlined'
              sx={{ height: '2rem', width: { xs: '4.5rem', sm: '6rem' }, borderRadius: '2rem', mx: 2 }}>
                Upload
            </Button>
            <Button
              variant='outlined'
              color='error'
              sx={{ height: '2rem', width: { xs: '4.5rem', sm: '6rem' }, borderRadius: '2rem',
                color: 'passwordStrength.red',
                backgroundColor: 'clientError.textFieldFill',
                '&:hover': {
                  backgroundColor: 'clientError.textFieldFill'
                }
              }}>
                Remove
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>Clinic Name*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.clinic_name)}
              fullWidth
              size='small'
              margin="normal"
              variant="outlined"
              name="clinic_name"
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('clinic_name', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.clinic_name}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>Address*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.address)}
              fullWidth
              size='small'
              margin="normal"
              variant="outlined"
              name="address"
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('address', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.address}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>City*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.city)}
              fullWidth
              value={clinicDetails.city ?? ''}
              margin="normal"
              size='small'
              variant="outlined"
              autoComplete ='off'
              name="city"
              sx={UiUtils.getTextFieldStyle('city', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.city}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>State*</Typography>
            <TextField
              error = {validateStringForNull(clientErrMsg.state)}
              fullWidth
              value={clinicDetails.state ?? ''}
              margin="normal"
              size='small'
              variant="outlined"
              name="state"
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('state', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.state}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>Country*</Typography>
            <TextField
              fullWidth
              error = {validateStringForNull(clientErrMsg.country)}
              value={clinicDetails.country ?? ''}
              margin="normal"
              size='small'
              variant="outlined"
              name="country"
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('country', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.country}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>Pincode*</Typography>
            <TextField
              fullWidth
              error = {validateStringForNull(clientErrMsg.pincode)}
              value={clinicDetails.pincode ?? ''}
              margin="normal"
              name="pincode"
              size='small'
              autoComplete ='off'
              onChange={(event) => {handleTextChange(event);}}
              sx={UiUtils.getTextFieldStyle('pincode', clientErrMsg)}
              variant="outlined"
              helperText = {clientErrMsg.pincode}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>Phone Number*</Typography>
            <TextField
              fullWidth
              error = {validateStringForNull(clientErrMsg.phone)}
              value={clinicDetails.phone ?? ''}
              margin="normal"
              variant="outlined"
              name="phone"
              type='phone'
              size='small'
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('phone', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.phone}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>Email</Typography>
            <TextField
              fullWidth
              error = {validateStringForNull(clientErrMsg.email)}
              value={clinicDetails.email ?? ''}
              margin="normal"
              variant="outlined"
              name="email"
              type='email'
              size='small'
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('email', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
              helperText = {clientErrMsg.email}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1 }}>Website</Typography>
            <TextField
              fullWidth
              value={clinicDetails.website ?? ''}
              margin="normal"
              variant="outlined"
              name="website"
              size='small'
              autoComplete ='off'
              sx={UiUtils.getTextFieldStyle('website', clientErrMsg)}
              onChange={(event) => {handleTextChange(event);}}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant='label' sx={{ mt: 1, mb: 0.5 }}>Clinic Start Date</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                format="DD/MM/YYYY"
                sx={{ width: '100%',
                  '& .MuiSvgIcon-root': {
                    height: '20px',
                    color: 'main.primary'
                  }
                }}
                value={clinicDetails.start_date}
                onChange={(newValue) => {setClinicDetails({ ...clinicDetails, start_date: newValue });}}
                slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item container xs={12} sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox checked={clinicDetails.is_doctor ?? false} onChange={(event) => handleCheckboxChange(event)}/>
              }
              label={<Typography variant='label'
                sx={{ color: 'main.primary', fontSize: '1.05rem' }}>Are you a doctor?</Typography>}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-end' }, my: 2 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleCreate()}
              sx={{ height: '2.5rem', width: { xs: '8rem', sm: '12rem' }, borderRadius: '2rem' }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

SetupClinicBasicDetails.propTypes = {
  onSuccessfulCommit: PropTypes.func.isRequired
};
export default SetupClinicBasicDetails;
