import { Container, Grid, InputAdornment, TextField, IconButton, Checkbox,
  Button, FormControlLabel, Typography, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import TSSnackbar from '../tscomponents/TSSnackbar';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { EnumUtils, Utils } from '../../utils/UtilFunctions';
import TSBackDrop from '../tscomponents/TSBackDrop';
import Services from '../../utils/Services';
import { validateEmailId, validateNumber, validateStringForNull } from '../../utils/FieldValidations';
import { USER_TYPE } from '../../utils/EnumDefinitions';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import APIData from '../../utils/APIData';

const InviteStaff = ({ clinicId, onSuccess }) => {
  const [isStaffDetailsDisable, setIsStaffDetailsDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [staffDetails, setStaffDetails] = useState({
    staff_name: '',
    staff_id: '',
    email: '',
    phone: '',
    role: '',
    is_doctor: false
  });
  const APIToken = {
    SEARCH_STAFF: 'CIS01',
    INVITE_STAFF: 'CIS02'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.searchStaffDetails && apiToken == APIToken.SEARCH_STAFF ) {
      setIsStaffDetailsDisable(true);
      setStaffDetails(response.data);
    } else if (apiData == APIData.inviteStaff && apiToken == APIToken.INVITE_STAFF ) {
      showSnackBar('success', response.message ?? 'Staff Invited successfully');
      setStaffDetails({ ...staffDetails,
        staff_name: '',
        role: '',
        is_doctor: false,
        staff_id: '',
        phone: '',
        email: ''
      });
      onSuccess();
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.searchStaffDetails && apiToken == APIToken.SEARCH_STAFF) {
      defaultMsg = 'No Staffs found';
      setIsStaffDetailsDisable(false);
      setStaffDetails({ ...staffDetails,
        staff_name: '',
        role: '',
        is_doctor: false,
        staff_id: ''
      });
    } else if (apiData == APIData.inviteStaff && apiToken == APIToken.INVITE_STAFF ) {
      defaultMsg = 'Failed to invite staff';
    };
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const handleTextAndComboBoxChange = (event) => {
    setStaffDetails({ ...staffDetails, [event.target.name]: event.target.value });
  };

  const handleValueChange = (newValue) => {
    if (validateStringForNull(newValue)) {
      setStaffDetails({ ...staffDetails,
        role: newValue.value
      });
    } else {
      setStaffDetails({ ...staffDetails,
        role: null
      });
    }
  };

  const raiseGetStaffDetails = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.searchStaffDetails, params: finalParams },
      APIToken.SEARCH_STAFF, processSuccess, processError);
  };

  const handleStaffSearchClick = (field) => {
    if (field == 'Email') {
      if (validateEmailId(staffDetails.email)) {
        const params = 'email=' + staffDetails.email;
        raiseGetStaffDetails(params);
      }
    } else if (field == 'Phone') {
      if (validateNumber(staffDetails.phone)) {
        const params = 'phone=' + staffDetails.phone;
        raiseGetStaffDetails(params);
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setStaffDetails({ ...staffDetails, is_doctor: event.target.checked });
  };

  const validateParams = (inputParams) => {
    const modifiedParams = {};
    if (!validateStringForNull(inputParams.staff_id)) {
      if (!validateEmailId(inputParams.email)) {
        throw new Error('Provide valid Email');
      }
      if (!validateStringForNull(inputParams.staff_name)) {
        throw new Error('Provide valid Name');
      }
      if (!validateNumber(inputParams.phone)) {
        throw new Error('Provide valid Phone Number');
      }
      modifiedParams.staff_name = inputParams.staff_name;
      modifiedParams.email = inputParams.email;
      modifiedParams.phone = inputParams.phone;
    } else {
      modifiedParams.staff_id = inputParams.staff_id;
    }
    if (!validateStringForNull(inputParams.role)) {
      throw new Error('Select valid Role');
    } else {
      modifiedParams.role = parseInt(inputParams.role);
    }
    if (modifiedParams.role == 1) {
      modifiedParams.is_doctor = inputParams.is_doctor ?? false;
    }
    return modifiedParams;
  };

  const raiseStaffInviteRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.inviteStaff, uriValues: [clinicId], params: finalParams },
      APIToken.INVITE_STAFF, processSuccess, processError);
  };

  const handleStaffInviteClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(staffDetails);
      const finalParams = validateParams(params);
      raiseStaffInviteRequest(finalParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Invite Staff');
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', mt: 5 }} >
      <Grid container justifyContent='space-between' alignItems='center' rowSpacing={3} columnSpacing={3} >
        <Grid item xs={12} >
          <Typography sx={{ mb: 2 }} variant='h4' align='center'>
            Invite Staff
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            name="email"
            size='small'
            value={staffDetails.email}
            label="Email"
            autoComplete ='off'
            fullWidth
            onChange={(event) => {handleTextAndComboBoxChange(event);}}
            onBlur={() => handleStaffSearchClick('Email')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title='Search Staff by email'>
                    <IconButton tabIndex={-1}
                      onClick={() => handleStaffSearchClick('Email')}
                    >
                      <PersonSearchIcon/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="phone"
            name="phone"
            size='small'
            value={staffDetails.phone}
            label="Phone"
            autoComplete ='off'
            fullWidth
            onChange={(event) => {handleTextAndComboBoxChange(event);}}
            onBlur={() => handleStaffSearchClick('Phone')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title='Search Patient by phone'>
                    <IconButton tabIndex={-1}
                      onClick={() => handleStaffSearchClick('Phone')}
                    >
                      <PersonSearchIcon/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <TextField
            disabled = {isStaffDetailsDisable}
            id="Name"
            label="Staff Name"
            name="staff_name"
            autoComplete ='off'
            size='small'
            value={staffDetails.staff_name}
            onChange={(event) => {handleTextAndComboBoxChange(event);}}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <Autocomplete
            disablePortal
            id="role"
            name='role'
            value={USER_TYPE[staffDetails.role] ?? null}
            onChange={(event, newValue) => {handleValueChange(newValue);}}
            options={EnumUtils.getEnumOptions(USER_TYPE)}
            isOptionEqualToValue={(option, value) => {
              return option.label === value;
            }}
            size="small"
            autoSelect
            autoHighlight
            renderInput={(params) => <TextField size="small" {...params} error={false} fullWidth label="Role" />}
          />
        </Grid>
        {staffDetails.role == 1 ? (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox checked={staffDetails.is_doctor ?? false} onChange={(event) => handleCheckboxChange(event)}/>
              }
              label={<Typography variant="body2">Is Staff a Doctor?</Typography>}
            />
          </Grid>
        ) : (<div />)}
        <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} xs={12} >
          <Grid item>
            <Tooltip title='Send Invite'>
              <Button
                type="submit"
                variant="contained"
                size="small"
                onClick={handleStaffInviteClick}
              >
                Invite
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {getSnackbar}
      <TSBackDrop isLoading={isLoading} />
    </Container>
  );
};

InviteStaff.propTypes = {
  clinicId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default InviteStaff;
