import { Checkbox, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { validateStringForNull } from '../../utils/FieldValidations';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const TSTimeSlots = ({ timeSlots, anchorEl, onTimeSlotClick, onClose }) => {
  const [filteredTimeSlots, setFilteredTimeSlots] = useState([]);
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [showAllAppointmentSlot, setShowAllAppointmentSlot] = useState(false);

  useEffect(() => {
    const sortedSlotList = sortAppointmentSlots(timeSlots);
    setAllTimeSlots(sortedSlotList);
    const availableSlots = getAvailableTimeSlots(sortedSlotList);
    setFilteredTimeSlots(availableSlots);
  }, [timeSlots]);

  const sortAppointmentSlots = (slots) => {
    if (!validateStringForNull(slots)) {
      return undefined;
    }
    return [...slots].sort((a, b) => a.start_time.value.localeCompare(b.start_time.value));
  };

  const getAvailableTimeSlots = (slots) => {
    return validateStringForNull(slots) ? slots.filter((slot) => slot.is_available) : undefined;
  };

  const handleSlotClick = (appointmentTime) => {
    if (onTimeSlotClick!=undefined) {
      onTimeSlotClick(appointmentTime.display);
    }
  };

  const handleSlotCheckboxChange = (isChecked) => {
    setShowAllAppointmentSlot(isChecked);
    if (isChecked) {
      setFilteredTimeSlots(allTimeSlots);
    } else {
      const availableSlots = getAvailableTimeSlots(allTimeSlots);
      setFilteredTimeSlots(availableSlots);
    }
  };

  const getAvailableUISlots = () => {
    if (filteredTimeSlots.length <= 0 ) {
      return (
        <MenuItem>
          <Typography sx={{ px: 2 }} variant='h5'>
            No available slots
          </Typography>
        </MenuItem>
      );
    }
    const timeSlotArray = [];
    filteredTimeSlots.map((slot) => {
      timeSlotArray.push(
        <MenuItem
          key={slot.start_time.value}
          onClick={() => handleSlotClick(slot.start_time)}
          disabled={!slot.is_available}
          style={{
            width: '100%',
            cursor: 'pointer',
            borderTop: '1px solid rgba(132, 125, 125, 0.31)',
            textAlign: 'center'
          }}
          sx={{ px: 3, py: 1,
            '&:hover': {
              backgroundColor: '#B9E9FC'
            } }}
        >
          {slot.start_time.display}
        </MenuItem>
      );
    });
    return timeSlotArray;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => onClose()}
      MenuListProps={{
        sx: {
          minWidth: anchorEl && anchorEl.clientWidth
        }
      }}
      sx={{ maxHeight: '55%' }}
    >
      {allTimeSlots.length > 0 ? (
        <MenuItem>
          <Checkbox checked={showAllAppointmentSlot} onChange={(event)=> handleSlotCheckboxChange(event.target.checked)} />
          Show All Slots
        </MenuItem>
      ) : '' }
      {allTimeSlots.length > 0 ? (
        getAvailableUISlots()
      ) : (
        <MenuItem>
          <Typography sx={{ px: 2 }} variant='h5'>
            No slots available
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

TSTimeSlots.propTypes = {
  timeSlots: PropTypes.array,
  anchorEl: PropTypes.object,
  onTimeSlotClick: PropTypes.func,
  onClose: PropTypes.func
};

export default TSTimeSlots;
