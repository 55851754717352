import {
  Box, Paper
} from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import ModifySettings from '../components/settings/ModifySettings';
import { CookieUtils } from '../utils/CookieUtils';

const PaperBase = styled(Paper)(()=>({
  width: {
    xs: '100%',
    lg: 'fit-content'
  },
  height: 'fit-content'
}));

const ClinicStaffSettings = () => {
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get('clinicId');
  const staffId = CookieUtils.getStaffId();

  return (
    <React.Fragment>
      <Helmet>
        <title>Settings - DocKit</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        pt: 10,
        px: 2,
        pb: 10,
        justifyContent: 'center',
        display: 'flex'
      }}>
        <PaperBase elevation={5} sx={{ p: 5 }}>
          <ModifySettings clinicId={clinicId} staffId={staffId} />
        </PaperBase>
      </Box>
    </React.Fragment>
  );
};

export default ClinicStaffSettings;
