import React, { useEffect, useState } from 'react';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import { Utils } from '../../utils/UtilFunctions';
import { Box, Button, Checkbox, Container,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography } from '@mui/material';
import TSBackDrop from '../tscomponents/TSBackDrop';
import TSSnackbar from '../tscomponents/TSSnackbar';
import styled from '@emotion/styled';
import { validateStringForNull } from '../../utils/FieldValidations';
import { cloneDeep } from 'lodash';
import APIData from '../../utils/APIData';
import TSConfirmationDialog from '../tscomponents/TSConfirmationDialog';

const PaperBase = styled(Paper)(()=>({
  width: 'auto'
}));

const PatientParameterDetails = ({ clinicId, appointmentId }) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePatientParameterDialogOpen, setIsDeletePatientParameterDialogOpen] = useState(false);
  const [isDeleteButtonEnable, setIsDeleteButtonEnable] = useState(false);
  const [originalValue, setOriginalValue] = useState(undefined);

  const APIToken = {
    GET_PATIENT_PARAMETERS_LIST: 'CPP01',
    GET_PATIENT_PARAMETERS: 'CPP02',
    MODIFY_PATIENT_PARAMETERS: 'CPP03',
    DELETE_PATIENT_PARAMETERS: 'CPP04'
  };

  useEffect(() => {
    raiseGetpatientParametersListRequest();
  }, []);

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    let hideLoading = false;
    if (apiData == APIData.getPatientParametersList && apiToken == APIToken.GET_PATIENT_PARAMETERS_LIST ) {
      const patientParameterArray = [];
      response.data.map((obj, i) => {
        const object = {
          id: i,
          parameter_name: obj.parameter_name,
          value: '',
          is_checked: false,
          disabled: true
        };
        patientParameterArray.push(object);
      });
      setRows(patientParameterArray);
      raiseGetAppointmentPatientParametersDetails(patientParameterArray);
    } else if (apiData == APIData.getPatientParameters && apiToken == APIToken.GET_PATIENT_PARAMETERS ) {
      const cloned = cloneDeep(callbackValues.ppList);
      if (response.data == undefined) {
        setOriginalValue(cloned);
      } else {
        Object.keys(response.data.patient_parameters).map((key) => {
          const filteredRow = cloned.filter((obj) => (obj.parameter_name == key));
          if (filteredRow.length > 0) {
            filteredRow[0].value = response.data.patient_parameters[key];
          } else if (filteredRow.length == 0) {
            const newObj = {
              id: cloned.length + 1,
              parameter_name: key,
              value: response.data.patient_parameters[key],
              is_checked: false,
              disabled: false
            };
            cloned.push(newObj);
          }
        });
        setRows(cloned);
        const temp = cloneDeep(cloned);
        setOriginalValue(temp);
      }
      hideLoading = true;
    } else if (apiData == APIData.modifyPatientParameters && apiToken == APIToken.MODIFY_PATIENT_PARAMETERS ) {
      showSnackBar('success', response.message ?? 'patient parameter details updated successfully');
      raiseGetpatientParametersListRequest();
    } else if (apiData == APIData.deletePatientParameters && apiToken == APIToken.DELETE_PATIENT_PARAMETERS ) {
      showSnackBar('success', response.message ?? 'patient parameter details deleted successfully');
      raiseGetpatientParametersListRequest();
    }
    if (hideLoading) {
      setIsLoading(false);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPatientParametersList && apiToken == APIToken.GET_PATIENT_PARAMETERS_LIST) {
      defaultMsg = 'Failed to get patient parameter list';
    } else if (apiData == APIData.getPatientParameters && apiToken == APIToken.GET_PATIENT_PARAMETERS ) {
      defaultMsg = 'Failed to retrieve Patient Parameters';
    } else if (apiData == APIData.modifyPatientParameters && apiToken == APIToken.MODIFY_PATIENT_PARAMETERS ) {
      defaultMsg = 'Failed to Update patient parameter details';
    } else if (apiData == APIData.deletePatientParameters && apiToken == APIToken.DELETE_PATIENT_PARAMETERS ) {
      defaultMsg = 'Failed to delete patient parameter details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetpatientParametersListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getPatientParametersList },
      APIToken.GET_PATIENT_PARAMETERS_LIST, processSuccess, processError);
  };

  const raiseGetAppointmentPatientParametersDetails = (ppList) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getPatientParameters, uriValues: [clinicId, appointmentId] },
      APIToken.GET_PATIENT_PARAMETERS, processSuccess, processError, { ppList: ppList });
  };

  const raiseUpdatePatientParameterRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.modifyPatientParameters, uriValues: [clinicId, appointmentId], params: finalParams },
      APIToken.MODIFY_PATIENT_PARAMETERS, processSuccess, processError);
  };

  const getModifiedParams = (rows) => {
    const modifiedParam = {};
    rows.map((obj) => {
      const filterParameter = originalValue.filter((newObj) => (newObj.parameter_name == obj.parameter_name));
      if (filterParameter.length > 0) {
        if (filterParameter[0].value != obj.value) {
          modifiedParam[obj.parameter_name] = obj.value;
        }
      } else if (filterParameter.length == 0) {
        modifiedParam[obj.parameter_name] = obj.value;
      }
    });
    Object.keys(modifiedParam).forEach((key) => {
      if (!validateStringForNull(modifiedParam[key])) {
        throw new Error(`Invalid value for ${key}`);
      }
    });
    const newObj = {
      patient_parameters: modifiedParam
    };
    return newObj;
  };

  const handlePatientParameterSaveClick = () => {
    try {
      const params = cloneDeep(rows);
      const finalParams = getModifiedParams(params);
      raiseUpdatePatientParameterRequest(finalParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Update Patient Parameter Details');
      setIsLoading(false);
    };
  };

  const handlePatientParameterDeleteClick = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(rows);
      const finalParams = handleDeleteClick(params);
      raiseDeletePatientParameterRequest(finalParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Update Patient Parameter Details');
      setIsLoading(false);
    };
  };

  const raiseDeletePatientParameterRequest = (finalParams) => {
    setIsLoading(true);
    setIsDeletePatientParameterDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.deletePatientParameters, uriValues: [clinicId, appointmentId], params: finalParams },
      APIToken.DELETE_PATIENT_PARAMETERS, processSuccess, processError);
  };

  const handleAddRowClick = () => {
    const tempRows = cloneDeep(rows);
    tempRows.push({
      id: rows.length,
      parameter_name: '',
      value: '',
      disabled: false,
      is_checked: false
    });
    setRows(tempRows);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };

  const handlePatienParameterDeleteDialog = (isOpen) => {
    setIsDeletePatientParameterDialogOpen(isOpen);
  };

  const handleDeleteClick = () => {
    const resultArray = [];
    const filteredRow = rows.filter((obj) => (obj.is_checked == true));
    filteredRow.map((obj) => {
      resultArray.push(obj.parameter_name);
    });
    const deltObj = {
      patient_parameters: resultArray
    };
    return deltObj;
  };

  const handleCheckboxChange = (event, index) => {
    const { name, checked } = event.target;
    const list = [...rows];
    list[index][name] = checked;
    setRows(list);
    const filteredRow = rows.filter((obj) => (obj.is_checked == true));
    if (filteredRow.length > 0) {
      setIsDeleteButtonEnable(true);
    } else {
      setIsDeleteButtonEnable(false);
    }
  };
  const handleResetClick = () => {
    setRows(originalValue);
  };

  const getDeletePatientParameterConfirmationDialog = () => {
    return (
      <Box>
        <TSConfirmationDialog
          open={isDeletePatientParameterDialogOpen}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Delete Patient Parameters</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to remove selected Parameters ?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              color='primary'
              size='small'
              onClick={() =>handlePatienParameterDeleteDialog(false)}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={() => handlePatientParameterDeleteClick()}
            >
              Delete Parameter
            </Button>
          </DialogActions>
        </TSConfirmationDialog>
      </Box>
    );
  };


  return (
    <React.Fragment>
      <Container>
        <Grid
          container
          justifyContent='space-between'
          sx={{ mt: 5 }}
        >
          <Grid item xs={12} >
            <PaperBase elevation = {4} sx={{
              flexGrow: 1,
              p: 2
            }} >
              <Button
                type="submit"
                size="small"
                variant="outlined"
                onClick={handleAddRowClick}
              >
                Add Parameter
              </Button>
              {isDeleteButtonEnable == true ? (
                <Button
                  type="submit"
                  size="small"
                  color='error'
                  variant="outlined"
                  sx={{ ml: 2 }}
                  onClick={() =>handlePatienParameterDeleteDialog(true)}
                >
                  Delete Parameter
                </Button>
              ) : ''}
            </PaperBase>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="h5">
                      Parameter name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">
                      Value
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox name='is_checked' checked={rows.is_checked} onChange={(event) => handleCheckboxChange(event, i)}/>
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.parameter_name}
                          name='parameter_name'
                          onChange={(e) => handleInputChange(e, i)}
                          size='small'
                          disabled={row.disabled}
                          autoFocus={i + 1 == rows.length}
                          variant="standard"
                          autoComplete ='off'
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.value}
                          name='value'
                          onChange={(e) => handleInputChange(e, i)}
                          variant="standard"
                          size='small'
                          autoComplete ='off'
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                type="submit"
                size="small"
                color='primary'
                variant="outlined"
                sx={{ m: 2, mr: 0 }}
                onClick={handleResetClick}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                size="small"
                color='primary'
                variant="contained"
                sx={{ m: 2, mr: 0 }}
                onClick={handlePatientParameterSaveClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {getDeletePatientParameterConfirmationDialog()}
        {getSnackbar}
        <TSBackDrop isLoading={isLoading} />
      </Container>
    </React.Fragment>
  );
};

PatientParameterDetails.propTypes = {
  clinicId: PropTypes.string.isRequired,
  appointmentId: PropTypes.string
};

export default PatientParameterDetails;
