import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CONTACT_TYPE } from '../../utils/EnumDefinitions';
import Services from '../../utils/Services';
import PropTypes from 'prop-types';
import TSSnackbar from '../tscomponents/TSSnackbar';
import { Utils } from '../../utils/UtilFunctions';
import APIData from '../../utils/APIData';
import TSHomeBackDrop from '../tscomponents/TSHomeBackDrop';

const ViewClinicContactDetails = ({ clinicId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactDetails, setClinicContactDetails] = useState([]);

  const APIToken = {
    GET_CLINIC_CONTACT_DETAILS: 'CSCCD01'
  };

  useEffect(() => {
    if ( clinicId != undefined) {
      raiseGetClinicContactDetails();
    }
  }, []);

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };

  const getSnackbar = (
    <TSSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CLINIC_CONTACT_DETAILS ) {
      setClinicContactDetails(response.data.clinic_contact_details.active ?? []);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.getClinicDetails && apiToken == APIToken.GET_CLINIC_CONTACT_DETAILS ) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Failed to retrieve Clinic Contact Details'
      });
    }
    setIsLoading(false);
  };

  const raiseGetClinicContactDetails = () => {
    const params = 'fetch_modules=clinic_contact_details';
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getClinicDetails, uriValues: [clinicId], params: params },
      APIToken.GET_CLINIC_CONTACT_DETAILS, processSuccess, processError);
  };

  return (
    <React.Fragment>
      <Grid container sx={{ p: { xs: '15px', sm: '0px', md: '1px' }, mt: { xs: '-20px' } }}>
        { !isLoading ? (
          <Grid item xs={12}>
            <Typography variant="subHeading1" style={{ color: 'main.black' }}>Contact Details</Typography>
          </Grid>) : ''}
        {contactDetails.length > 0 ? (
          Object.entries(
            contactDetails.reduce((acc, contactDetail) => {
              if (!acc[contactDetail.contact_type]) {
                acc[contactDetail.contact_type] = [];
              }
              acc[contactDetail.contact_type].push(contactDetail.contact);
              return acc;
            }, {})
          ).map(([contactType, contacts], i) => (
            <Grid item container xs={6} key={i} sx={{ mt: 2.5 }} alignContent='flex-start'>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="subHeading3" style={{ color: 'main.black' }}>
                  {CONTACT_TYPE[contactType]}
                </Typography>
              </Grid>
              {contacts.map((contact, j) => (
                <Grid item xs={12} key={j} sx={{ mb: 0.5 }}>
                  <Typography variant="label" style={{ color: 'main.black' }}
                    sx={{ overflowWrap: 'break-word', wordWrap: 'break-word',
                      width: '100%', maxWidth: '100%', display: 'block', whiteSpace: 'normal' }}>
                    {contact}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="subHeading1" sx={{ display: 'flex', justifyContent: 'center', color: 'main.black', mt: 4 }}>
              {isLoading ? 'Loading...' : 'No Information Available'}
            </Typography>
          </Grid>
        )}
      </Grid>
      {getSnackbar}
      <TSHomeBackDrop isLoading={isLoading} />
    </React.Fragment>
  );
};

ViewClinicContactDetails.propTypes = {
  clinicId: PropTypes.string
};

export default ViewClinicContactDetails;
