import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 670,
      md: 950,
      lg: 1200,
      xl: 1536
    }
  }
});

theme = createTheme(theme, {
  palette: {
    background: {
      default: '#f4f6f8',
      paper: '#ffffff'
    },
    primary: {
      contrastText: '#ffffff',
      main: '#205295'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    // New UI CSS
    main: {
      primary: '#205295',
      accent: '#BEDAFF',
      textOnPrimary: '#FFFFFF',
      textMiddleGrey: '#A8A8A8',
      textLightGrey: '#E0E0E0',
      background: '#f4f6f8',
      black: '#000000'
    },
    inputAdornment: {
      color: '#A8A8A8',
      fontSize: '1.2rem'
    },
    passwordStrength: {
      green: '#84C602',
      red: '#FC3D3D',
      yellow: '#EFAD02'
    },
    serverStatus: {
      successBoxBackground: '#CBEE96',
      errorBoxBackground: '#fde0e0'
    },
    clientError: {
      textFieldFill: '#ffe8e8'
    }
  },
  shadows: [
    'none',
    '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)'
  ],
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px'
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px'
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px'
    },
    overline: {
      fontWeight: 500
    },
    // New UI typography CSS
    heading: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '1.25rem'
    },
    subHeading1: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '1.05rem'
    },
    subHeading2: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '0.7rem'
    },
    subHeading3: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '0.85rem'
    },
    subHeading4: {
      fontFamily: 'TSFont-Medium, sans-serif',
      fontSize: '1rem'
    },
    body: {
      fontFamily: 'TSFont-Regular, sans-serif',
      fontSize: '0.8rem'
    },
    label: {
      fontFamily: 'TSFont-Medium, sans-serif',
      fontSize: '0.8rem'
    },
    buttonLabel: {
      fontFamily: 'TSFont-Medium, sans-serif',
      fontSize: '0.7rem'
    },
    errorText: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '0.75rem',
      color: '#FC3D3D'
    },
    successText: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '0.75rem',
      color: '#416D19'
    },
    hyperLinks: {
      fontFamily: 'TSFont-Bold, sans-serif',
      fontSize: '0.8rem'
    }
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1350
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1150
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '0.5rem',
          fontSize: '0.8rem'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'TSFont-SemiBold, sans-serif',
          fontSize: '0.8rem',
          textTransform: 'none'
        }
      }
    }
  }
});

export default theme;
